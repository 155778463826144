export enum ConstructorEditTypeEnum {
    backgroundImage = 'backgroundImage',
    text = 'text',
    tooltip = 'tooltip',
    fontSize = 'fontSize',
    textAlign = 'textAlign',
    settings = 'settings',
    position = 'position',
    color = 'fill',
    strokeColor = 'stroke',
    backgroundColor = 'backgroundColor',

    overlayColor = 'overlayColor',
    borderColor = 'borderColor',
    borderWidth = 'strokeWidth',
    borderRadius = 'borderRadius',
    angle = 'angle',

    opacity = 'opacity',
    fontWeight = 'fontWeight',
    fontStyle = 'fontStyle',
    isBtn = 'isBtn',
    image = 'image',
    type = 'type',
}
