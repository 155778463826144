function t(t, e, s) {
  return (e = function (t) {
    var e = function (t, e) {
      if ("object" != typeof t || !t) return t;
      var s = t[Symbol.toPrimitive];
      if (void 0 !== s) {
        var i = s.call(t, e || "default");
        if ("object" != typeof i) return i;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return ("string" === e ? String : Number)(t);
    }(t, "string");
    return "symbol" == typeof e ? e : e + "";
  }(e)) in t ? Object.defineProperty(t, e, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = s, t;
}
function e(t, e) {
  var s = Object.keys(t);
  if (Object.getOwnPropertySymbols) {
    var i = Object.getOwnPropertySymbols(t);
    e && (i = i.filter(function (e) {
      return Object.getOwnPropertyDescriptor(t, e).enumerable;
    })), s.push.apply(s, i);
  }
  return s;
}
function s(s) {
  for (var i = 1; i < arguments.length; i++) {
    var r = null != arguments[i] ? arguments[i] : {};
    i % 2 ? e(Object(r), !0).forEach(function (e) {
      t(s, e, r[e]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(s, Object.getOwnPropertyDescriptors(r)) : e(Object(r)).forEach(function (t) {
      Object.defineProperty(s, t, Object.getOwnPropertyDescriptor(r, t));
    });
  }
  return s;
}
function i(t, e) {
  if (null == t) return {};
  var s,
    i,
    r = function (t, e) {
      if (null == t) return {};
      var s = {};
      for (var i in t) if ({}.hasOwnProperty.call(t, i)) {
        if (e.indexOf(i) >= 0) continue;
        s[i] = t[i];
      }
      return s;
    }(t, e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(t);
    for (i = 0; i < n.length; i++) s = n[i], e.indexOf(s) >= 0 || {}.propertyIsEnumerable.call(t, s) && (r[s] = t[s]);
  }
  return r;
}
function r(t, e) {
  return e || (e = t.slice(0)), Object.freeze(Object.defineProperties(t, {
    raw: {
      value: Object.freeze(e)
    }
  }));
}
class n {
  constructor() {
    t(this, "browserShadowBlurConstant", 1), t(this, "DPI", 96), t(this, "devicePixelRatio", "undefined" != typeof window ? window.devicePixelRatio : 1), t(this, "perfLimitSizeTotal", 2097152), t(this, "maxCacheSideLimit", 4096), t(this, "minCacheSideLimit", 256), t(this, "disableStyleCopyPaste", !1), t(this, "enableGLFiltering", !0), t(this, "textureSize", 4096), t(this, "forceGLPutImageData", !1), t(this, "cachesBoundsOfCurve", !1), t(this, "fontPaths", {}), t(this, "NUM_FRACTION_DIGITS", 4);
  }
}
const o = new class extends n {
    constructor(t) {
      super(), this.configure(t);
    }
    configure() {
      let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      Object.assign(this, t);
    }
    addFonts() {
      let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      this.fontPaths = s(s({}, this.fontPaths), t);
    }
    removeFonts() {
      (arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : []).forEach(t => {
        delete this.fontPaths[t];
      });
    }
    clearFonts() {
      this.fontPaths = {};
    }
    restoreDefaults(t) {
      const e = new n(),
        s = (null == t ? void 0 : t.reduce((t, s) => (t[s] = e[s], t), {})) || e;
      this.configure(s);
    }
  }(),
  a = function (t) {
    for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
    return console[t]("fabric", ...s);
  };
class h extends Error {
  constructor(t, e) {
    super("fabric: ".concat(t), e);
  }
}
class c extends h {
  constructor(t) {
    super("".concat(t, " 'options.signal' is in 'aborted' state"));
  }
}
class l {}
class u extends l {
  testPrecision(t, e) {
    const s = "precision ".concat(e, " float;\nvoid main(){}"),
      i = t.createShader(t.FRAGMENT_SHADER);
    return !!i && (t.shaderSource(i, s), t.compileShader(i), !!t.getShaderParameter(i, t.COMPILE_STATUS));
  }
  queryWebGL(t) {
    const e = t.getContext("webgl");
    e && (this.maxTextureSize = e.getParameter(e.MAX_TEXTURE_SIZE), this.GLPrecision = ["highp", "mediump", "lowp"].find(t => this.testPrecision(e, t)), e.getExtension("WEBGL_lose_context").loseContext(), a("log", "WebGL: max texture size ".concat(this.maxTextureSize)));
  }
  isSupported(t) {
    return !!this.maxTextureSize && this.maxTextureSize >= t;
  }
}
const d = {};
let g;
const f = t => {
    g = t;
  },
  p = () => g || (g = {
    document: document,
    window: window,
    isTouchSupported: "ontouchstart" in window || "ontouchstart" in document || window && window.navigator && window.navigator.maxTouchPoints > 0,
    WebGLProbe: new u(),
    dispose() {},
    copyPasteData: d
  }),
  m = () => p().document,
  v = () => p().window,
  y = () => {
    var t;
    return Math.max(null !== (t = o.devicePixelRatio) && void 0 !== t ? t : v().devicePixelRatio, 1);
  };
const _ = new class {
  constructor() {
    t(this, "charWidthsCache", {}), t(this, "boundsOfCurveCache", {});
  }
  getFontCache(t) {
    let {
      fontFamily: e,
      fontStyle: s,
      fontWeight: i
    } = t;
    e = e.toLowerCase(), this.charWidthsCache[e] || (this.charWidthsCache[e] = {});
    const r = this.charWidthsCache[e],
      n = "".concat(s.toLowerCase(), "_").concat((i + "").toLowerCase());
    return r[n] || (r[n] = {}), r[n];
  }
  clearFontCache(t) {
    (t = (t || "").toLowerCase()) ? this.charWidthsCache[t] && delete this.charWidthsCache[t] : this.charWidthsCache = {};
  }
  limitDimsByArea(t) {
    const {
        perfLimitSizeTotal: e
      } = o,
      s = Math.sqrt(e * t);
    return [Math.floor(s), Math.floor(e / s)];
  }
}();
const x = "6.4.2";
function C() {}
const b = Math.PI / 2,
  w = 2 * Math.PI,
  S = Math.PI / 180,
  T = Object.freeze([1, 0, 0, 1, 0, 0]),
  O = 16,
  k = .4477152502,
  D = "center",
  M = "left",
  P = "top",
  E = "bottom",
  A = "right",
  j = "none",
  F = /\r?\n/,
  L = "moving",
  R = "scaling",
  I = "rotating",
  B = "rotate",
  X = "skewing",
  W = "resizing",
  Y = "modifyPoly",
  V = "modifyPath",
  H = "changed",
  z = "scale",
  G = "scaleX",
  N = "scaleY",
  U = "skewX",
  q = "skewY",
  K = "fill",
  J = "stroke",
  Q = "modified",
  Z = "json",
  $ = "svg";
const tt = new class {
  constructor() {
    this[Z] = new Map(), this[$] = new Map();
  }
  has(t) {
    return this[Z].has(t);
  }
  getClass(t) {
    const e = this[Z].get(t);
    if (!e) throw new h("No class registered for ".concat(t));
    return e;
  }
  setClass(t, e) {
    e ? this[Z].set(e, t) : (this[Z].set(t.type, t), this[Z].set(t.type.toLowerCase(), t));
  }
  getSVGClass(t) {
    return this[$].get(t);
  }
  setSVGClass(t, e) {
    this[$].set(null != e ? e : t.type.toLowerCase(), t);
  }
}();
const et = new class extends Array {
  remove(t) {
    const e = this.indexOf(t);
    e > -1 && this.splice(e, 1);
  }
  cancelAll() {
    const t = this.splice(0);
    return t.forEach(t => t.abort()), t;
  }
  cancelByCanvas(t) {
    if (!t) return [];
    const e = this.filter(e => {
      var s;
      return e.target === t || "object" == typeof e.target && (null === (s = e.target) || void 0 === s ? void 0 : s.canvas) === t;
    });
    return e.forEach(t => t.abort()), e;
  }
  cancelByTarget(t) {
    if (!t) return [];
    const e = this.filter(e => e.target === t);
    return e.forEach(t => t.abort()), e;
  }
}();
class st {
  constructor() {
    t(this, "__eventListeners", {});
  }
  on(t, e) {
    if (this.__eventListeners || (this.__eventListeners = {}), "object" == typeof t) return Object.entries(t).forEach(t => {
      let [e, s] = t;
      this.on(e, s);
    }), () => this.off(t);
    if (e) {
      const s = t;
      return this.__eventListeners[s] || (this.__eventListeners[s] = []), this.__eventListeners[s].push(e), () => this.off(s, e);
    }
    return () => !1;
  }
  once(t, e) {
    if ("object" == typeof t) {
      const e = [];
      return Object.entries(t).forEach(t => {
        let [s, i] = t;
        e.push(this.once(s, i));
      }), () => e.forEach(t => t());
    }
    if (e) {
      const s = this.on(t, function () {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        e.call(this, ...i), s();
      });
      return s;
    }
    return () => !1;
  }
  _removeEventListener(t, e) {
    if (this.__eventListeners[t]) if (e) {
      const s = this.__eventListeners[t],
        i = s.indexOf(e);
      i > -1 && s.splice(i, 1);
    } else this.__eventListeners[t] = [];
  }
  off(t, e) {
    if (this.__eventListeners) if (void 0 === t) for (const t in this.__eventListeners) this._removeEventListener(t);else "object" == typeof t ? Object.entries(t).forEach(t => {
      let [e, s] = t;
      this._removeEventListener(e, s);
    }) : this._removeEventListener(t, e);
  }
  fire(t, e) {
    var s;
    if (!this.__eventListeners) return;
    const i = null === (s = this.__eventListeners[t]) || void 0 === s ? void 0 : s.concat();
    if (i) for (let t = 0; t < i.length; t++) i[t].call(this, e || {});
  }
}
const it = (t, e) => {
    const s = t.indexOf(e);
    return -1 !== s && t.splice(s, 1), t;
  },
  rt = t => {
    if (0 === t) return 1;
    switch (Math.abs(t) / b) {
      case 1:
      case 3:
        return 0;
      case 2:
        return -1;
    }
    return Math.cos(t);
  },
  nt = t => {
    if (0 === t) return 0;
    const e = t / b,
      s = Math.sign(t);
    switch (e) {
      case 1:
        return s;
      case 2:
        return 0;
      case 3:
        return -s;
    }
    return Math.sin(t);
  };
class ot {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
      e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
    "object" == typeof t ? (this.x = t.x, this.y = t.y) : (this.x = t, this.y = e);
  }
  add(t) {
    return new ot(this.x + t.x, this.y + t.y);
  }
  addEquals(t) {
    return this.x += t.x, this.y += t.y, this;
  }
  scalarAdd(t) {
    return new ot(this.x + t, this.y + t);
  }
  scalarAddEquals(t) {
    return this.x += t, this.y += t, this;
  }
  subtract(t) {
    return new ot(this.x - t.x, this.y - t.y);
  }
  subtractEquals(t) {
    return this.x -= t.x, this.y -= t.y, this;
  }
  scalarSubtract(t) {
    return new ot(this.x - t, this.y - t);
  }
  scalarSubtractEquals(t) {
    return this.x -= t, this.y -= t, this;
  }
  multiply(t) {
    return new ot(this.x * t.x, this.y * t.y);
  }
  scalarMultiply(t) {
    return new ot(this.x * t, this.y * t);
  }
  scalarMultiplyEquals(t) {
    return this.x *= t, this.y *= t, this;
  }
  divide(t) {
    return new ot(this.x / t.x, this.y / t.y);
  }
  scalarDivide(t) {
    return new ot(this.x / t, this.y / t);
  }
  scalarDivideEquals(t) {
    return this.x /= t, this.y /= t, this;
  }
  eq(t) {
    return this.x === t.x && this.y === t.y;
  }
  lt(t) {
    return this.x < t.x && this.y < t.y;
  }
  lte(t) {
    return this.x <= t.x && this.y <= t.y;
  }
  gt(t) {
    return this.x > t.x && this.y > t.y;
  }
  gte(t) {
    return this.x >= t.x && this.y >= t.y;
  }
  lerp(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : .5;
    return e = Math.max(Math.min(1, e), 0), new ot(this.x + (t.x - this.x) * e, this.y + (t.y - this.y) * e);
  }
  distanceFrom(t) {
    const e = this.x - t.x,
      s = this.y - t.y;
    return Math.sqrt(e * e + s * s);
  }
  midPointFrom(t) {
    return this.lerp(t);
  }
  min(t) {
    return new ot(Math.min(this.x, t.x), Math.min(this.y, t.y));
  }
  max(t) {
    return new ot(Math.max(this.x, t.x), Math.max(this.y, t.y));
  }
  toString() {
    return "".concat(this.x, ",").concat(this.y);
  }
  setXY(t, e) {
    return this.x = t, this.y = e, this;
  }
  setX(t) {
    return this.x = t, this;
  }
  setY(t) {
    return this.y = t, this;
  }
  setFromPoint(t) {
    return this.x = t.x, this.y = t.y, this;
  }
  swap(t) {
    const e = this.x,
      s = this.y;
    this.x = t.x, this.y = t.y, t.x = e, t.y = s;
  }
  clone() {
    return new ot(this.x, this.y);
  }
  rotate(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : at;
    const s = nt(t),
      i = rt(t),
      r = this.subtract(e);
    return new ot(r.x * i - r.y * s, r.x * s + r.y * i).add(e);
  }
  transform(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return new ot(t[0] * this.x + t[2] * this.y + (e ? 0 : t[4]), t[1] * this.x + t[3] * this.y + (e ? 0 : t[5]));
  }
}
const at = new ot(0, 0),
  ht = t => !!t && Array.isArray(t._objects);
function ct(e) {
  class s extends e {
    constructor() {
      super(...arguments), t(this, "_objects", []);
    }
    _onObjectAdded(t) {}
    _onObjectRemoved(t) {}
    _onStackOrderChanged(t) {}
    add() {
      for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
      const i = this._objects.push(...e);
      return e.forEach(t => this._onObjectAdded(t)), i;
    }
    insertAt(t) {
      for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
      return this._objects.splice(t, 0, ...s), s.forEach(t => this._onObjectAdded(t)), this._objects.length;
    }
    remove() {
      const t = this._objects,
        e = [];
      for (var s = arguments.length, i = new Array(s), r = 0; r < s; r++) i[r] = arguments[r];
      return i.forEach(s => {
        const i = t.indexOf(s);
        -1 !== i && (t.splice(i, 1), e.push(s), this._onObjectRemoved(s));
      }), e;
    }
    forEachObject(t) {
      this.getObjects().forEach((e, s, i) => t(e, s, i));
    }
    getObjects() {
      for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
      return 0 === e.length ? [...this._objects] : this._objects.filter(t => t.isType(...e));
    }
    item(t) {
      return this._objects[t];
    }
    isEmpty() {
      return 0 === this._objects.length;
    }
    size() {
      return this._objects.length;
    }
    contains(t, e) {
      return !!this._objects.includes(t) || !!e && this._objects.some(e => e instanceof s && e.contains(t, !0));
    }
    complexity() {
      return this._objects.reduce((t, e) => t += e.complexity ? e.complexity() : 0, 0);
    }
    sendObjectToBack(t) {
      return !(!t || t === this._objects[0]) && (it(this._objects, t), this._objects.unshift(t), this._onStackOrderChanged(t), !0);
    }
    bringObjectToFront(t) {
      return !(!t || t === this._objects[this._objects.length - 1]) && (it(this._objects, t), this._objects.push(t), this._onStackOrderChanged(t), !0);
    }
    sendObjectBackwards(t, e) {
      if (!t) return !1;
      const s = this._objects.indexOf(t);
      if (0 !== s) {
        const i = this.findNewLowerIndex(t, s, e);
        return it(this._objects, t), this._objects.splice(i, 0, t), this._onStackOrderChanged(t), !0;
      }
      return !1;
    }
    bringObjectForward(t, e) {
      if (!t) return !1;
      const s = this._objects.indexOf(t);
      if (s !== this._objects.length - 1) {
        const i = this.findNewUpperIndex(t, s, e);
        return it(this._objects, t), this._objects.splice(i, 0, t), this._onStackOrderChanged(t), !0;
      }
      return !1;
    }
    moveObjectTo(t, e) {
      return t !== this._objects[e] && (it(this._objects, t), this._objects.splice(e, 0, t), this._onStackOrderChanged(t), !0);
    }
    findNewLowerIndex(t, e, s) {
      let i;
      if (s) {
        i = e;
        for (let s = e - 1; s >= 0; --s) if (t.isOverlapping(this._objects[s])) {
          i = s;
          break;
        }
      } else i = e - 1;
      return i;
    }
    findNewUpperIndex(t, e, s) {
      let i;
      if (s) {
        i = e;
        for (let s = e + 1; s < this._objects.length; ++s) if (t.isOverlapping(this._objects[s])) {
          i = s;
          break;
        }
      } else i = e + 1;
      return i;
    }
    collectObjects(t) {
      let {
          left: e,
          top: s,
          width: i,
          height: r
        } = t,
        {
          includeIntersecting: n = !0
        } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
      const o = [],
        a = new ot(e, s),
        h = a.add(new ot(i, r));
      for (let t = this._objects.length - 1; t >= 0; t--) {
        const e = this._objects[t];
        e.selectable && e.visible && (n && e.intersectsWithRect(a, h) || e.isContainedWithinRect(a, h) || n && e.containsPoint(a) || n && e.containsPoint(h)) && o.push(e);
      }
      return o;
    }
  }
  return s;
}
class lt extends st {
  _setOptions() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    for (const e in t) this.set(e, t[e]);
  }
  _setObject(t) {
    for (const e in t) this._set(e, t[e]);
  }
  set(t, e) {
    return "object" == typeof t ? this._setObject(t) : this._set(t, e), this;
  }
  _set(t, e) {
    this[t] = e;
  }
  toggle(t) {
    const e = this.get(t);
    return "boolean" == typeof e && this.set(t, !e), this;
  }
  get(t) {
    return this[t];
  }
}
function ut(t) {
  return v().requestAnimationFrame(t);
}
function dt(t) {
  return v().cancelAnimationFrame(t);
}
let gt = 0;
const ft = () => gt++,
  pt = () => {
    const t = m().createElement("canvas");
    if (!t || void 0 === t.getContext) throw new h("Failed to create `canvas` element");
    return t;
  },
  mt = () => m().createElement("img"),
  vt = (t, e, s) => t.toDataURL("image/".concat(e), s),
  yt = t => t * S,
  _t = t => t / S,
  xt = t => t.every((t, e) => t === T[e]),
  Ct = (t, e, s) => new ot(t).transform(e, s),
  bt = t => {
    const e = 1 / (t[0] * t[3] - t[1] * t[2]),
      s = [e * t[3], -e * t[1], -e * t[2], e * t[0], 0, 0],
      {
        x: i,
        y: r
      } = new ot(t[4], t[5]).transform(s, !0);
    return s[4] = -i, s[5] = -r, s;
  },
  wt = (t, e, s) => [t[0] * e[0] + t[2] * e[1], t[1] * e[0] + t[3] * e[1], t[0] * e[2] + t[2] * e[3], t[1] * e[2] + t[3] * e[3], s ? 0 : t[0] * e[4] + t[2] * e[5] + t[4], s ? 0 : t[1] * e[4] + t[3] * e[5] + t[5]],
  St = (t, e) => t.reduceRight((t, s) => s && t ? wt(s, t, e) : s || t, void 0) || T.concat(),
  Tt = t => {
    let [e, s] = t;
    return Math.atan2(s, e);
  },
  Ot = t => {
    const e = Tt(t),
      s = Math.pow(t[0], 2) + Math.pow(t[1], 2),
      i = Math.sqrt(s),
      r = (t[0] * t[3] - t[2] * t[1]) / i,
      n = Math.atan2(t[0] * t[2] + t[1] * t[3], s);
    return {
      angle: _t(e),
      scaleX: i,
      scaleY: r,
      skewX: _t(n),
      skewY: 0,
      translateX: t[4] || 0,
      translateY: t[5] || 0
    };
  },
  kt = function (t) {
    return [1, 0, 0, 1, t, arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0];
  };
function Dt() {
  let {
      angle: t = 0
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
    {
      x: e = 0,
      y: s = 0
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  const i = yt(t),
    r = rt(i),
    n = nt(i);
  return [r, n, -n, r, e ? e - (r * e - n * s) : 0, s ? s - (n * e + r * s) : 0];
}
const Mt = function (t) {
    return [t, 0, 0, arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : t, 0, 0];
  },
  Pt = t => Math.tan(yt(t)),
  Et = t => [1, 0, Pt(t), 1, 0, 0],
  At = t => [1, Pt(t), 0, 1, 0, 0],
  jt = t => {
    let {
        scaleX: e = 1,
        scaleY: s = 1,
        flipX: i = !1,
        flipY: r = !1,
        skewX: n = 0,
        skewY: o = 0
      } = t,
      a = Mt(i ? -e : e, r ? -s : s);
    return n && (a = wt(a, Et(n), !0)), o && (a = wt(a, At(o), !0)), a;
  },
  Ft = t => {
    const {
      translateX: e = 0,
      translateY: s = 0,
      angle: i = 0
    } = t;
    let r = kt(e, s);
    i && (r = wt(r, Dt({
      angle: i
    })));
    const n = jt(t);
    return xt(n) || (r = wt(r, n)), r;
  },
  Lt = function (t) {
    let {
      signal: e,
      crossOrigin: s = null
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    return new Promise(function (i, r) {
      if (e && e.aborted) return r(new c("loadImage"));
      const n = mt();
      let o;
      e && (o = function (t) {
        n.src = "", r(t);
      }, e.addEventListener("abort", o, {
        once: !0
      }));
      const a = function () {
        n.onload = n.onerror = null, o && (null == e || e.removeEventListener("abort", o)), i(n);
      };
      t ? (n.onload = a, n.onerror = function () {
        o && (null == e || e.removeEventListener("abort", o)), r(new h("Error loading ".concat(n.src)));
      }, s && (n.crossOrigin = s), n.src = t) : a();
    });
  },
  Rt = function (t) {
    let {
      signal: e,
      reviver: s = C
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    return new Promise((i, r) => {
      const n = [];
      e && e.addEventListener("abort", r, {
        once: !0
      }), Promise.all(t.map(t => tt.getClass(t.type).fromObject(t, {
        signal: e
      }).then(e => (s(t, e), n.push(e), e)))).then(i).catch(t => {
        n.forEach(t => {
          t.dispose && t.dispose();
        }), r(t);
      }).finally(() => {
        e && e.removeEventListener("abort", r);
      });
    });
  },
  It = function (t) {
    let {
      signal: e
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    return new Promise((s, i) => {
      const r = [];
      e && e.addEventListener("abort", i, {
        once: !0
      });
      const n = Object.values(t).map(t => t && t.type && tt.has(t.type) ? Rt([t], {
          signal: e
        }).then(t => {
          let [e] = t;
          return r.push(e), e;
        }) : t),
        o = Object.keys(t);
      Promise.all(n).then(t => t.reduce((t, e, s) => (t[o[s]] = e, t), {})).then(s).catch(t => {
        r.forEach(t => {
          t.dispose && t.dispose();
        }), i(t);
      }).finally(() => {
        e && e.removeEventListener("abort", i);
      });
    });
  },
  Bt = function (t) {
    return (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : []).reduce((e, s) => (s in t && (e[s] = t[s]), e), {});
  },
  Xt = (t, e) => Object.keys(t).reduce((s, i) => (e(t[i], i, t) && (s[i] = t[i]), s), {}),
  Wt = {
    aliceblue: "#F0F8FF",
    antiquewhite: "#FAEBD7",
    aqua: "#0FF",
    aquamarine: "#7FFFD4",
    azure: "#F0FFFF",
    beige: "#F5F5DC",
    bisque: "#FFE4C4",
    black: "#000",
    blanchedalmond: "#FFEBCD",
    blue: "#00F",
    blueviolet: "#8A2BE2",
    brown: "#A52A2A",
    burlywood: "#DEB887",
    cadetblue: "#5F9EA0",
    chartreuse: "#7FFF00",
    chocolate: "#D2691E",
    coral: "#FF7F50",
    cornflowerblue: "#6495ED",
    cornsilk: "#FFF8DC",
    crimson: "#DC143C",
    cyan: "#0FF",
    darkblue: "#00008B",
    darkcyan: "#008B8B",
    darkgoldenrod: "#B8860B",
    darkgray: "#A9A9A9",
    darkgrey: "#A9A9A9",
    darkgreen: "#006400",
    darkkhaki: "#BDB76B",
    darkmagenta: "#8B008B",
    darkolivegreen: "#556B2F",
    darkorange: "#FF8C00",
    darkorchid: "#9932CC",
    darkred: "#8B0000",
    darksalmon: "#E9967A",
    darkseagreen: "#8FBC8F",
    darkslateblue: "#483D8B",
    darkslategray: "#2F4F4F",
    darkslategrey: "#2F4F4F",
    darkturquoise: "#00CED1",
    darkviolet: "#9400D3",
    deeppink: "#FF1493",
    deepskyblue: "#00BFFF",
    dimgray: "#696969",
    dimgrey: "#696969",
    dodgerblue: "#1E90FF",
    firebrick: "#B22222",
    floralwhite: "#FFFAF0",
    forestgreen: "#228B22",
    fuchsia: "#F0F",
    gainsboro: "#DCDCDC",
    ghostwhite: "#F8F8FF",
    gold: "#FFD700",
    goldenrod: "#DAA520",
    gray: "#808080",
    grey: "#808080",
    green: "#008000",
    greenyellow: "#ADFF2F",
    honeydew: "#F0FFF0",
    hotpink: "#FF69B4",
    indianred: "#CD5C5C",
    indigo: "#4B0082",
    ivory: "#FFFFF0",
    khaki: "#F0E68C",
    lavender: "#E6E6FA",
    lavenderblush: "#FFF0F5",
    lawngreen: "#7CFC00",
    lemonchiffon: "#FFFACD",
    lightblue: "#ADD8E6",
    lightcoral: "#F08080",
    lightcyan: "#E0FFFF",
    lightgoldenrodyellow: "#FAFAD2",
    lightgray: "#D3D3D3",
    lightgrey: "#D3D3D3",
    lightgreen: "#90EE90",
    lightpink: "#FFB6C1",
    lightsalmon: "#FFA07A",
    lightseagreen: "#20B2AA",
    lightskyblue: "#87CEFA",
    lightslategray: "#789",
    lightslategrey: "#789",
    lightsteelblue: "#B0C4DE",
    lightyellow: "#FFFFE0",
    lime: "#0F0",
    limegreen: "#32CD32",
    linen: "#FAF0E6",
    magenta: "#F0F",
    maroon: "#800000",
    mediumaquamarine: "#66CDAA",
    mediumblue: "#0000CD",
    mediumorchid: "#BA55D3",
    mediumpurple: "#9370DB",
    mediumseagreen: "#3CB371",
    mediumslateblue: "#7B68EE",
    mediumspringgreen: "#00FA9A",
    mediumturquoise: "#48D1CC",
    mediumvioletred: "#C71585",
    midnightblue: "#191970",
    mintcream: "#F5FFFA",
    mistyrose: "#FFE4E1",
    moccasin: "#FFE4B5",
    navajowhite: "#FFDEAD",
    navy: "#000080",
    oldlace: "#FDF5E6",
    olive: "#808000",
    olivedrab: "#6B8E23",
    orange: "#FFA500",
    orangered: "#FF4500",
    orchid: "#DA70D6",
    palegoldenrod: "#EEE8AA",
    palegreen: "#98FB98",
    paleturquoise: "#AFEEEE",
    palevioletred: "#DB7093",
    papayawhip: "#FFEFD5",
    peachpuff: "#FFDAB9",
    peru: "#CD853F",
    pink: "#FFC0CB",
    plum: "#DDA0DD",
    powderblue: "#B0E0E6",
    purple: "#800080",
    rebeccapurple: "#639",
    red: "#F00",
    rosybrown: "#BC8F8F",
    royalblue: "#4169E1",
    saddlebrown: "#8B4513",
    salmon: "#FA8072",
    sandybrown: "#F4A460",
    seagreen: "#2E8B57",
    seashell: "#FFF5EE",
    sienna: "#A0522D",
    silver: "#C0C0C0",
    skyblue: "#87CEEB",
    slateblue: "#6A5ACD",
    slategray: "#708090",
    slategrey: "#708090",
    snow: "#FFFAFA",
    springgreen: "#00FF7F",
    steelblue: "#4682B4",
    tan: "#D2B48C",
    teal: "#008080",
    thistle: "#D8BFD8",
    tomato: "#FF6347",
    turquoise: "#40E0D0",
    violet: "#EE82EE",
    wheat: "#F5DEB3",
    white: "#FFF",
    whitesmoke: "#F5F5F5",
    yellow: "#FF0",
    yellowgreen: "#9ACD32"
  },
  Yt = (t, e, s) => (s < 0 && (s += 1), s > 1 && (s -= 1), s < 1 / 6 ? t + 6 * (e - t) * s : s < .5 ? e : s < 2 / 3 ? t + (e - t) * (2 / 3 - s) * 6 : t),
  Vt = (t, e, s, i) => {
    t /= 255, e /= 255, s /= 255;
    const r = Math.max(t, e, s),
      n = Math.min(t, e, s);
    let o, a;
    const h = (r + n) / 2;
    if (r === n) o = a = 0;else {
      const i = r - n;
      switch (a = h > .5 ? i / (2 - r - n) : i / (r + n), r) {
        case t:
          o = (e - s) / i + (e < s ? 6 : 0);
          break;
        case e:
          o = (s - t) / i + 2;
          break;
        case s:
          o = (t - e) / i + 4;
      }
      o /= 6;
    }
    return [Math.round(360 * o), Math.round(100 * a), Math.round(100 * h), i];
  },
  Ht = function () {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "1";
    return parseFloat(t) / (t.endsWith("%") ? 100 : 1);
  },
  zt = t => Math.min(Math.round(t), 255).toString(16).toUpperCase().padStart(2, "0"),
  Gt = t => {
    let [e, s, i, r = 1] = t;
    const n = Math.round(.3 * e + .59 * s + .11 * i);
    return [n, n, n, r];
  };
class Nt {
  constructor(e) {
    if (t(this, "isUnrecognised", !1), e) {
      if (e instanceof Nt) this.setSource([...e._source]);else if (Array.isArray(e)) {
        const [t, s, i, r = 1] = e;
        this.setSource([t, s, i, r]);
      } else this.setSource(this._tryParsingColor(e));
    } else this.setSource([0, 0, 0, 1]);
  }
  _tryParsingColor(t) {
    return t in Wt && (t = Wt[t]), "transparent" === t ? [255, 255, 255, 0] : Nt.sourceFromHex(t) || Nt.sourceFromRgb(t) || Nt.sourceFromHsl(t) || (this.isUnrecognised = !0) && [0, 0, 0, 1];
  }
  getSource() {
    return this._source;
  }
  setSource(t) {
    this._source = t;
  }
  toRgb() {
    const [t, e, s] = this.getSource();
    return "rgb(".concat(t, ",").concat(e, ",").concat(s, ")");
  }
  toRgba() {
    return "rgba(".concat(this.getSource().join(","), ")");
  }
  toHsl() {
    const [t, e, s] = Vt(...this.getSource());
    return "hsl(".concat(t, ",").concat(e, "%,").concat(s, "%)");
  }
  toHsla() {
    const [t, e, s, i] = Vt(...this.getSource());
    return "hsla(".concat(t, ",").concat(e, "%,").concat(s, "%,").concat(i, ")");
  }
  toHex() {
    return this.toHexa().slice(0, 6);
  }
  toHexa() {
    const [t, e, s, i] = this.getSource();
    return "".concat(zt(t)).concat(zt(e)).concat(zt(s)).concat(zt(Math.round(255 * i)));
  }
  getAlpha() {
    return this.getSource()[3];
  }
  setAlpha(t) {
    return this._source[3] = t, this;
  }
  toGrayscale() {
    return this.setSource(Gt(this.getSource())), this;
  }
  toBlackWhite(t) {
    const [e,,, s] = Gt(this.getSource()),
      i = e < (t || 127) ? 0 : 255;
    return this.setSource([i, i, i, s]), this;
  }
  overlayWith(t) {
    t instanceof Nt || (t = new Nt(t));
    const e = this.getSource(),
      s = t.getSource(),
      [i, r, n] = e.map((t, e) => Math.round(.5 * t + .5 * s[e]));
    return this.setSource([i, r, n, e[3]]), this;
  }
  static fromRgb(t) {
    return Nt.fromRgba(t);
  }
  static fromRgba(t) {
    return new Nt(Nt.sourceFromRgb(t));
  }
  static sourceFromRgb(t) {
    const e = t.match(/^rgba?\(\s*(\d{0,3}(?:\.\d+)?%?)\s*[\s|,]\s*(\d{0,3}(?:\.\d+)?%?)\s*[\s|,]\s*(\d{0,3}(?:\.\d+)?%?)\s*(?:\s*[,/]\s*(\d{0,3}(?:\.\d+)?%?)\s*)?\)$/i);
    if (e) {
      const [t, s, i] = e.slice(1, 4).map(t => {
        const e = parseFloat(t);
        return t.endsWith("%") ? Math.round(2.55 * e) : e;
      });
      return [t, s, i, Ht(e[4])];
    }
  }
  static fromHsl(t) {
    return Nt.fromHsla(t);
  }
  static fromHsla(t) {
    return new Nt(Nt.sourceFromHsl(t));
  }
  static sourceFromHsl(t) {
    const e = t.match(/^hsla?\(\s*([+-]?\d{0,3}(?:\.\d+)?(?:deg|turn|rad)?)\s*[\s|,]\s*(\d{0,3}(?:\.\d+)?%?)\s*[\s|,]\s*(\d{0,3}(?:\.\d+)?%?)\s*(?:\s*[,/]\s*(\d*(?:\.\d+)?%?)\s*)?\)$/i);
    if (!e) return;
    const s = (Nt.parseAngletoDegrees(e[1]) % 360 + 360) % 360 / 360,
      i = parseFloat(e[2]) / 100,
      r = parseFloat(e[3]) / 100;
    let n, o, a;
    if (0 === i) n = o = a = r;else {
      const t = r <= .5 ? r * (i + 1) : r + i - r * i,
        e = 2 * r - t;
      n = Yt(e, t, s + 1 / 3), o = Yt(e, t, s), a = Yt(e, t, s - 1 / 3);
    }
    return [Math.round(255 * n), Math.round(255 * o), Math.round(255 * a), Ht(e[4])];
  }
  static fromHex(t) {
    return new Nt(Nt.sourceFromHex(t));
  }
  static sourceFromHex(t) {
    if (t.match(/^#?(([0-9a-f]){3,4}|([0-9a-f]{2}){3,4})$/i)) {
      const e = t.slice(t.indexOf("#") + 1);
      let s;
      s = e.length <= 4 ? e.split("").map(t => t + t) : e.match(/.{2}/g);
      const [i, r, n, o = 255] = s.map(t => parseInt(t, 16));
      return [i, r, n, o / 255];
    }
  }
  static parseAngletoDegrees(t) {
    const e = t.toLowerCase(),
      s = parseFloat(e);
    return e.includes("rad") ? _t(s) : e.includes("turn") ? 360 * s : s;
  }
}
const Ut = (t, e) => parseFloat(Number(t).toFixed(e)),
  qt = function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : O;
    const s = /\D{0,2}$/.exec(t),
      i = parseFloat(t),
      r = o.DPI;
    switch (null == s ? void 0 : s[0]) {
      case "mm":
        return i * r / 25.4;
      case "cm":
        return i * r / 2.54;
      case "in":
        return i * r;
      case "pt":
        return i * r / 72;
      case "pc":
        return i * r / 72 * 12;
      case "em":
        return i * e;
      default:
        return i;
    }
  },
  Kt = t => {
    const [e, s] = t.trim().split(" "),
      [i, r] = (n = e) && n !== j ? [n.slice(1, 4), n.slice(5, 8)] : n === j ? [n, n] : ["Mid", "Mid"];
    var n;
    return {
      meetOrSlice: s || "meet",
      alignX: i,
      alignY: r
    };
  },
  Jt = t => "matrix(" + t.map(t => Ut(t, o.NUM_FRACTION_DIGITS)).join(" ") + ")",
  Qt = function (t, e) {
    let s,
      i,
      r = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2];
    if (e) {
      if (e.toLive) s = "url(#SVGID_".concat(e.id, ")");else {
        const t = new Nt(e),
          r = t.getAlpha();
        s = t.toRgb(), 1 !== r && (i = r.toString());
      }
    } else s = "none";
    return r ? "".concat(t, ": ").concat(s, "; ").concat(i ? "".concat(t, "-opacity: ").concat(i, "; ") : "") : "".concat(t, '="').concat(s, '" ').concat(i ? "".concat(t, '-opacity="').concat(i, '" ') : "");
  },
  Zt = t => !!t && void 0 !== t.toLive,
  $t = t => !!t && "function" == typeof t.toObject,
  te = t => !!t && void 0 !== t.offsetX && "source" in t,
  ee = t => !!t && "function" == typeof t._renderText,
  se = t => !!t && "multiSelectionStacking" in t;
function ie(t) {
  const e = t && re(t);
  let s = 0,
    i = 0;
  if (!t || !e) return {
    left: s,
    top: i
  };
  let r = t;
  const n = e.documentElement,
    o = e.body || {
      scrollLeft: 0,
      scrollTop: 0
    };
  for (; r && (r.parentNode || r.host) && (r = r.parentNode || r.host, r === e ? (s = o.scrollLeft || n.scrollLeft || 0, i = o.scrollTop || n.scrollTop || 0) : (s += r.scrollLeft || 0, i += r.scrollTop || 0), 1 !== r.nodeType || "fixed" !== r.style.position););
  return {
    left: s,
    top: i
  };
}
const re = t => t.ownerDocument || null,
  ne = t => {
    var e;
    return (null === (e = t.ownerDocument) || void 0 === e ? void 0 : e.defaultView) || null;
  },
  oe = function (t, e, s) {
    let {
        width: i,
        height: r
      } = s,
      n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 1;
    t.width = i, t.height = r, n > 1 && (t.setAttribute("width", (i * n).toString()), t.setAttribute("height", (r * n).toString()), e.scale(n, n));
  },
  ae = (t, e) => {
    let {
      width: s,
      height: i
    } = e;
    s && (t.style.width = "number" == typeof s ? "".concat(s, "px") : s), i && (t.style.height = "number" == typeof i ? "".concat(i, "px") : i);
  };
function he(t) {
  return void 0 !== t.onselectstart && (t.onselectstart = () => !1), t.style.userSelect = j, t;
}
class ce {
  constructor(e) {
    t(this, "_originalCanvasStyle", void 0), t(this, "lower", void 0);
    const s = this.createLowerCanvas(e);
    this.lower = {
      el: s,
      ctx: s.getContext("2d")
    };
  }
  createLowerCanvas(t) {
    const e = (s = t) && void 0 !== s.getContext ? t : t && m().getElementById(t) || pt();
    var s;
    if (e.hasAttribute("data-fabric")) throw new h("Trying to initialize a canvas that has already been initialized. Did you forget to dispose the canvas?");
    return this._originalCanvasStyle = e.style.cssText, e.setAttribute("data-fabric", "main"), e.classList.add("lower-canvas"), e;
  }
  cleanupDOM(t) {
    let {
      width: e,
      height: s
    } = t;
    const {
      el: i
    } = this.lower;
    i.classList.remove("lower-canvas"), i.removeAttribute("data-fabric"), i.setAttribute("width", "".concat(e)), i.setAttribute("height", "".concat(s)), i.style.cssText = this._originalCanvasStyle || "", this._originalCanvasStyle = void 0;
  }
  setDimensions(t, e) {
    const {
      el: s,
      ctx: i
    } = this.lower;
    oe(s, i, t, e);
  }
  setCSSDimensions(t) {
    ae(this.lower.el, t);
  }
  calcOffset() {
    return function (t) {
      var e;
      const s = t && re(t),
        i = {
          left: 0,
          top: 0
        };
      if (!s) return i;
      const r = (null === (e = ne(t)) || void 0 === e ? void 0 : e.getComputedStyle(t, null)) || {};
      i.left += parseInt(r.borderLeftWidth, 10) || 0, i.top += parseInt(r.borderTopWidth, 10) || 0, i.left += parseInt(r.paddingLeft, 10) || 0, i.top += parseInt(r.paddingTop, 10) || 0;
      let n = {
        left: 0,
        top: 0
      };
      const o = s.documentElement;
      void 0 !== t.getBoundingClientRect && (n = t.getBoundingClientRect());
      const a = ie(t);
      return {
        left: n.left + a.left - (o.clientLeft || 0) + i.left,
        top: n.top + a.top - (o.clientTop || 0) + i.top
      };
    }(this.lower.el);
  }
  dispose() {
    p().dispose(this.lower.el), delete this.lower;
  }
}
const le = {
  backgroundVpt: !0,
  backgroundColor: "",
  overlayVpt: !0,
  overlayColor: "",
  includeDefaultValues: !0,
  svgViewportTransformation: !0,
  renderOnAddRemove: !0,
  skipOffscreen: !0,
  enableRetinaScaling: !0,
  imageSmoothingEnabled: !0,
  controlsAboveOverlay: !1,
  allowTouchScrolling: !1,
  viewportTransform: [...T]
};
class ue extends ct(lt) {
  get lowerCanvasEl() {
    var t;
    return null === (t = this.elements.lower) || void 0 === t ? void 0 : t.el;
  }
  get contextContainer() {
    var t;
    return null === (t = this.elements.lower) || void 0 === t ? void 0 : t.ctx;
  }
  static getDefaults() {
    return ue.ownDefaults;
  }
  constructor(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(), Object.assign(this, this.constructor.getDefaults()), this.set(e), this.initElements(t), this._setDimensionsImpl({
      width: this.width || this.elements.lower.el.width || 0,
      height: this.height || this.elements.lower.el.height || 0
    }), this.skipControlsDrawing = !1, this.viewportTransform = [...this.viewportTransform], this.calcViewportBoundaries();
  }
  initElements(t) {
    this.elements = new ce(t);
  }
  add() {
    const t = super.add(...arguments);
    return arguments.length > 0 && this.renderOnAddRemove && this.requestRenderAll(), t;
  }
  insertAt(t) {
    for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
    const r = super.insertAt(t, ...s);
    return s.length > 0 && this.renderOnAddRemove && this.requestRenderAll(), r;
  }
  remove() {
    const t = super.remove(...arguments);
    return t.length > 0 && this.renderOnAddRemove && this.requestRenderAll(), t;
  }
  _onObjectAdded(t) {
    t.canvas && t.canvas !== this && (a("warn", "Canvas is trying to add an object that belongs to a different canvas.\nResulting to default behavior: removing object from previous canvas and adding to new canvas"), t.canvas.remove(t)), t._set("canvas", this), t.setCoords(), this.fire("object:added", {
      target: t
    }), t.fire("added", {
      target: this
    });
  }
  _onObjectRemoved(t) {
    t._set("canvas", void 0), this.fire("object:removed", {
      target: t
    }), t.fire("removed", {
      target: this
    });
  }
  _onStackOrderChanged() {
    this.renderOnAddRemove && this.requestRenderAll();
  }
  getRetinaScaling() {
    return this.enableRetinaScaling ? y() : 1;
  }
  calcOffset() {
    return this._offset = this.elements.calcOffset();
  }
  getWidth() {
    return this.width;
  }
  getHeight() {
    return this.height;
  }
  setWidth(t, e) {
    return this.setDimensions({
      width: t
    }, e);
  }
  setHeight(t, e) {
    return this.setDimensions({
      height: t
    }, e);
  }
  _setDimensionsImpl(t) {
    let {
      cssOnly: e = !1,
      backstoreOnly: i = !1
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (!e) {
      const e = s({
        width: this.width,
        height: this.height
      }, t);
      this.elements.setDimensions(e, this.getRetinaScaling()), this.hasLostContext = !0, this.width = e.width, this.height = e.height;
    }
    i || this.elements.setCSSDimensions(t), this.calcOffset();
  }
  setDimensions(t, e) {
    this._setDimensionsImpl(t, e), e && e.cssOnly || this.requestRenderAll();
  }
  getZoom() {
    return this.viewportTransform[0];
  }
  setViewportTransform(t) {
    this.viewportTransform = t, this.calcViewportBoundaries(), this.renderOnAddRemove && this.requestRenderAll();
  }
  zoomToPoint(t, e) {
    const s = t,
      i = [...this.viewportTransform],
      r = Ct(t, bt(i));
    i[0] = e, i[3] = e;
    const n = Ct(r, i);
    i[4] += s.x - n.x, i[5] += s.y - n.y, this.setViewportTransform(i);
  }
  setZoom(t) {
    this.zoomToPoint(new ot(0, 0), t);
  }
  absolutePan(t) {
    const e = [...this.viewportTransform];
    return e[4] = -t.x, e[5] = -t.y, this.setViewportTransform(e);
  }
  relativePan(t) {
    return this.absolutePan(new ot(-t.x - this.viewportTransform[4], -t.y - this.viewportTransform[5]));
  }
  getElement() {
    return this.elements.lower.el;
  }
  clearContext(t) {
    t.clearRect(0, 0, this.width, this.height);
  }
  getContext() {
    return this.elements.lower.ctx;
  }
  clear() {
    this.remove(...this.getObjects()), this.backgroundImage = void 0, this.overlayImage = void 0, this.backgroundColor = "", this.overlayColor = "", this.clearContext(this.getContext()), this.fire("canvas:cleared"), this.renderOnAddRemove && this.requestRenderAll();
  }
  renderAll() {
    this.cancelRequestedRender(), this.destroyed || this.renderCanvas(this.getContext(), this._objects);
  }
  renderAndReset() {
    this.nextRenderHandle = 0, this.renderAll();
  }
  requestRenderAll() {
    this.nextRenderHandle || this.disposed || this.destroyed || (this.nextRenderHandle = ut(() => this.renderAndReset()));
  }
  calcViewportBoundaries() {
    const t = this.width,
      e = this.height,
      s = bt(this.viewportTransform),
      i = Ct({
        x: 0,
        y: 0
      }, s),
      r = Ct({
        x: t,
        y: e
      }, s),
      n = i.min(r),
      o = i.max(r);
    return this.vptCoords = {
      tl: n,
      tr: new ot(o.x, n.y),
      bl: new ot(n.x, o.y),
      br: o
    };
  }
  cancelRequestedRender() {
    this.nextRenderHandle && (dt(this.nextRenderHandle), this.nextRenderHandle = 0);
  }
  drawControls(t) {}
  renderCanvas(t, e) {
    if (this.destroyed) return;
    const s = this.viewportTransform,
      i = this.clipPath;
    this.calcViewportBoundaries(), this.clearContext(t), t.imageSmoothingEnabled = this.imageSmoothingEnabled, t.patternQuality = "best", this.fire("before:render", {
      ctx: t
    }), this._renderBackground(t), t.save(), t.transform(s[0], s[1], s[2], s[3], s[4], s[5]), this._renderObjects(t, e), t.restore(), this.controlsAboveOverlay || this.skipControlsDrawing || this.drawControls(t), i && (i._set("canvas", this), i.shouldCache(), i._transformDone = !0, i.renderCache({
      forClipping: !0
    }), this.drawClipPathOnCanvas(t, i)), this._renderOverlay(t), this.controlsAboveOverlay && !this.skipControlsDrawing && this.drawControls(t), this.fire("after:render", {
      ctx: t
    }), this.__cleanupTask && (this.__cleanupTask(), this.__cleanupTask = void 0);
  }
  drawClipPathOnCanvas(t, e) {
    const s = this.viewportTransform;
    t.save(), t.transform(...s), t.globalCompositeOperation = "destination-in", e.transform(t), t.scale(1 / e.zoomX, 1 / e.zoomY), t.drawImage(e._cacheCanvas, -e.cacheTranslationX, -e.cacheTranslationY), t.restore();
  }
  _renderObjects(t, e) {
    for (let s = 0, i = e.length; s < i; ++s) e[s] && e[s].render(t);
  }
  _renderBackgroundOrOverlay(t, e) {
    const s = this["".concat(e, "Color")],
      i = this["".concat(e, "Image")],
      r = this.viewportTransform,
      n = this["".concat(e, "Vpt")];
    if (!s && !i) return;
    const o = Zt(s);
    if (s) {
      if (t.save(), t.beginPath(), t.moveTo(0, 0), t.lineTo(this.width, 0), t.lineTo(this.width, this.height), t.lineTo(0, this.height), t.closePath(), t.fillStyle = o ? s.toLive(t) : s, n && t.transform(...r), o) {
        t.transform(1, 0, 0, 1, s.offsetX || 0, s.offsetY || 0);
        const e = s.gradientTransform || s.patternTransform;
        e && t.transform(...e);
      }
      t.fill(), t.restore();
    }
    if (i) {
      t.save();
      const {
        skipOffscreen: e
      } = this;
      this.skipOffscreen = n, n && t.transform(...r), i.render(t), this.skipOffscreen = e, t.restore();
    }
  }
  _renderBackground(t) {
    this._renderBackgroundOrOverlay(t, "background");
  }
  _renderOverlay(t) {
    this._renderBackgroundOrOverlay(t, "overlay");
  }
  getCenter() {
    return {
      top: this.height / 2,
      left: this.width / 2
    };
  }
  getCenterPoint() {
    return new ot(this.width / 2, this.height / 2);
  }
  centerObjectH(t) {
    return this._centerObject(t, new ot(this.getCenterPoint().x, t.getCenterPoint().y));
  }
  centerObjectV(t) {
    return this._centerObject(t, new ot(t.getCenterPoint().x, this.getCenterPoint().y));
  }
  centerObject(t) {
    return this._centerObject(t, this.getCenterPoint());
  }
  viewportCenterObject(t) {
    return this._centerObject(t, this.getVpCenter());
  }
  viewportCenterObjectH(t) {
    return this._centerObject(t, new ot(this.getVpCenter().x, t.getCenterPoint().y));
  }
  viewportCenterObjectV(t) {
    return this._centerObject(t, new ot(t.getCenterPoint().x, this.getVpCenter().y));
  }
  getVpCenter() {
    return Ct(this.getCenterPoint(), bt(this.viewportTransform));
  }
  _centerObject(t, e) {
    t.setXY(e, D, D), t.setCoords(), this.renderOnAddRemove && this.requestRenderAll();
  }
  toDatalessJSON(t) {
    return this.toDatalessObject(t);
  }
  toObject(t) {
    return this._toObjectMethod("toObject", t);
  }
  toJSON() {
    return this.toObject();
  }
  toDatalessObject(t) {
    return this._toObjectMethod("toDatalessObject", t);
  }
  _toObjectMethod(t, e) {
    const i = this.clipPath,
      r = i && !i.excludeFromExport ? this._toObject(i, t, e) : null;
    return s(s(s({
      version: x
    }, Bt(this, e)), {}, {
      objects: this._objects.filter(t => !t.excludeFromExport).map(s => this._toObject(s, t, e))
    }, this.__serializeBgOverlay(t, e)), r ? {
      clipPath: r
    } : null);
  }
  _toObject(t, e, s) {
    let i;
    this.includeDefaultValues || (i = t.includeDefaultValues, t.includeDefaultValues = !1);
    const r = t[e](s);
    return this.includeDefaultValues || (t.includeDefaultValues = !!i), r;
  }
  __serializeBgOverlay(t, e) {
    const s = {},
      i = this.backgroundImage,
      r = this.overlayImage,
      n = this.backgroundColor,
      o = this.overlayColor;
    return Zt(n) ? n.excludeFromExport || (s.background = n.toObject(e)) : n && (s.background = n), Zt(o) ? o.excludeFromExport || (s.overlay = o.toObject(e)) : o && (s.overlay = o), i && !i.excludeFromExport && (s.backgroundImage = this._toObject(i, t, e)), r && !r.excludeFromExport && (s.overlayImage = this._toObject(r, t, e)), s;
  }
  toSVG() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
      e = arguments.length > 1 ? arguments[1] : void 0;
    t.reviver = e;
    const s = [];
    return this._setSVGPreamble(s, t), this._setSVGHeader(s, t), this.clipPath && s.push('<g clip-path="url(#'.concat(this.clipPath.clipPathId, ')" >\n')), this._setSVGBgOverlayColor(s, "background"), this._setSVGBgOverlayImage(s, "backgroundImage", e), this._setSVGObjects(s, e), this.clipPath && s.push("</g>\n"), this._setSVGBgOverlayColor(s, "overlay"), this._setSVGBgOverlayImage(s, "overlayImage", e), s.push("</svg>"), s.join("");
  }
  _setSVGPreamble(t, e) {
    e.suppressPreamble || t.push('<?xml version="1.0" encoding="', e.encoding || "UTF-8", '" standalone="no" ?>\n', '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" ', '"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n');
  }
  _setSVGHeader(t, e) {
    const s = e.width || "".concat(this.width),
      i = e.height || "".concat(this.height),
      r = o.NUM_FRACTION_DIGITS,
      n = e.viewBox;
    let a;
    if (n) a = 'viewBox="'.concat(n.x, " ").concat(n.y, " ").concat(n.width, " ").concat(n.height, '" ');else if (this.svgViewportTransformation) {
      const t = this.viewportTransform;
      a = 'viewBox="'.concat(Ut(-t[4] / t[0], r), " ").concat(Ut(-t[5] / t[3], r), " ").concat(Ut(this.width / t[0], r), " ").concat(Ut(this.height / t[3], r), '" ');
    } else a = 'viewBox="0 0 '.concat(this.width, " ").concat(this.height, '" ');
    t.push("<svg ", 'xmlns="http://www.w3.org/2000/svg" ', 'xmlns:xlink="http://www.w3.org/1999/xlink" ', 'version="1.1" ', 'width="', s, '" ', 'height="', i, '" ', a, 'xml:space="preserve">\n', "<desc>Created with Fabric.js ", x, "</desc>\n", "<defs>\n", this.createSVGFontFacesMarkup(), this.createSVGRefElementsMarkup(), this.createSVGClipPathMarkup(e), "</defs>\n");
  }
  createSVGClipPathMarkup(t) {
    const e = this.clipPath;
    return e ? (e.clipPathId = "CLIPPATH_".concat(ft()), '<clipPath id="'.concat(e.clipPathId, '" >\n').concat(e.toClipPathSVG(t.reviver), "</clipPath>\n")) : "";
  }
  createSVGRefElementsMarkup() {
    return ["background", "overlay"].map(t => {
      const e = this["".concat(t, "Color")];
      if (Zt(e)) {
        const s = this["".concat(t, "Vpt")],
          i = this.viewportTransform,
          r = {
            isType: () => !1,
            width: this.width / (s ? i[0] : 1),
            height: this.height / (s ? i[3] : 1)
          };
        return e.toSVG(r, {
          additionalTransform: s ? Jt(i) : ""
        });
      }
    }).join("");
  }
  createSVGFontFacesMarkup() {
    const t = [],
      e = {},
      s = o.fontPaths;
    this._objects.forEach(function e(s) {
      t.push(s), ht(s) && s._objects.forEach(e);
    }), t.forEach(t => {
      if (!ee(t)) return;
      const {
        styles: i,
        fontFamily: r
      } = t;
      !e[r] && s[r] && (e[r] = !0, i && Object.values(i).forEach(t => {
        Object.values(t).forEach(t => {
          let {
            fontFamily: i = ""
          } = t;
          !e[i] && s[i] && (e[i] = !0);
        });
      }));
    });
    const i = Object.keys(e).map(t => "\t\t@font-face {\n\t\t\tfont-family: '".concat(t, "';\n\t\t\tsrc: url('").concat(s[t], "');\n\t\t}\n")).join("");
    return i ? '\t<style type="text/css"><![CDATA[\n'.concat(i, "]]></style>\n") : "";
  }
  _setSVGObjects(t, e) {
    this.forEachObject(s => {
      s.excludeFromExport || this._setSVGObject(t, s, e);
    });
  }
  _setSVGObject(t, e, s) {
    t.push(e.toSVG(s));
  }
  _setSVGBgOverlayImage(t, e, s) {
    const i = this[e];
    i && !i.excludeFromExport && i.toSVG && t.push(i.toSVG(s));
  }
  _setSVGBgOverlayColor(t, e) {
    const s = this["".concat(e, "Color")];
    if (s) if (Zt(s)) {
      const i = s.repeat || "",
        r = this.width,
        n = this.height,
        o = this["".concat(e, "Vpt")] ? Jt(bt(this.viewportTransform)) : "";
      t.push('<rect transform="'.concat(o, " translate(").concat(r / 2, ",").concat(n / 2, ')" x="').concat(s.offsetX - r / 2, '" y="').concat(s.offsetY - n / 2, '" width="').concat("repeat-y" !== i && "no-repeat" !== i || !te(s) ? r : s.source.width, '" height="').concat("repeat-x" !== i && "no-repeat" !== i || !te(s) ? n : s.source.height, '" fill="url(#SVGID_').concat(s.id, ')"></rect>\n'));
    } else t.push('<rect x="0" y="0" width="100%" height="100%" ', 'fill="', s, '"', "></rect>\n");
  }
  loadFromJSON(t, e) {
    let {
      signal: s
    } = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
    if (!t) return Promise.reject(new h("`json` is undefined"));
    const i = "string" == typeof t ? JSON.parse(t) : t,
      {
        objects: r = [],
        backgroundImage: n,
        background: o,
        overlayImage: a,
        overlay: c,
        clipPath: l
      } = i,
      u = this.renderOnAddRemove;
    return this.renderOnAddRemove = !1, Promise.all([Rt(r, {
      reviver: e,
      signal: s
    }), It({
      backgroundImage: n,
      backgroundColor: o,
      overlayImage: a,
      overlayColor: c,
      clipPath: l
    }, {
      signal: s
    })]).then(t => {
      let [e, s] = t;
      return this.clear(), this.add(...e), this.set(i), this.set(s), this.renderOnAddRemove = u, this;
    });
  }
  clone(t) {
    const e = this.toObject(t);
    return this.cloneWithoutData().loadFromJSON(e);
  }
  cloneWithoutData() {
    const t = pt();
    return t.width = this.width, t.height = this.height, new this.constructor(t);
  }
  toDataURL() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    const {
        format: e = "png",
        quality: s = 1,
        multiplier: i = 1,
        enableRetinaScaling: r = !1
      } = t,
      n = i * (r ? this.getRetinaScaling() : 1);
    return vt(this.toCanvasElement(n, t), e, s);
  }
  toCanvasElement() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
      {
        width: e,
        height: s,
        left: i,
        top: r,
        filter: n
      } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const o = (e || this.width) * t,
      a = (s || this.height) * t,
      h = this.getZoom(),
      c = this.width,
      l = this.height,
      u = this.skipControlsDrawing,
      d = h * t,
      g = this.viewportTransform,
      f = [d, 0, 0, d, (g[4] - (i || 0)) * t, (g[5] - (r || 0)) * t],
      p = this.enableRetinaScaling,
      m = pt(),
      v = n ? this._objects.filter(t => n(t)) : this._objects;
    return m.width = o, m.height = a, this.enableRetinaScaling = !1, this.viewportTransform = f, this.width = o, this.height = a, this.skipControlsDrawing = !0, this.calcViewportBoundaries(), this.renderCanvas(m.getContext("2d"), v), this.viewportTransform = g, this.width = c, this.height = l, this.calcViewportBoundaries(), this.enableRetinaScaling = p, this.skipControlsDrawing = u, m;
  }
  dispose() {
    return !this.disposed && this.elements.cleanupDOM({
      width: this.width,
      height: this.height
    }), et.cancelByCanvas(this), this.disposed = !0, new Promise((t, e) => {
      const s = () => {
        this.destroy(), t(!0);
      };
      s.kill = e, this.__cleanupTask && this.__cleanupTask.kill("aborted"), this.destroyed ? t(!1) : this.nextRenderHandle ? this.__cleanupTask = s : s();
    });
  }
  destroy() {
    this.destroyed = !0, this.cancelRequestedRender(), this.forEachObject(t => t.dispose()), this._objects = [], this.backgroundImage && this.backgroundImage.dispose(), this.backgroundImage = void 0, this.overlayImage && this.overlayImage.dispose(), this.overlayImage = void 0, this.elements.dispose();
  }
  toString() {
    return "#<Canvas (".concat(this.complexity(), "): { objects: ").concat(this._objects.length, " }>");
  }
}
t(ue, "ownDefaults", le);
const de = ["touchstart", "touchmove", "touchend"];
const ge = t => {
    const e = ie(t.target),
      s = function (t) {
        const e = t.changedTouches;
        return e && e[0] ? e[0] : t;
      }(t);
    return new ot(s.clientX + e.left, s.clientY + e.top);
  },
  fe = t => de.includes(t.type) || "touch" === t.pointerType,
  pe = t => {
    t.preventDefault(), t.stopPropagation();
  },
  me = t => {
    let e = 0,
      s = 0,
      i = 0,
      r = 0;
    for (let n = 0, o = t.length; n < o; n++) {
      const {
        x: o,
        y: a
      } = t[n];
      (o > i || !n) && (i = o), (o < e || !n) && (e = o), (a > r || !n) && (r = a), (a < s || !n) && (s = a);
    }
    return {
      left: e,
      top: s,
      width: i - e,
      height: r - s
    };
  },
  ve = ["translateX", "translateY", "scaleX", "scaleY"],
  ye = (t, e) => _e(t, wt(e, t.calcOwnMatrix())),
  _e = (t, e) => {
    const s = Ot(e),
      {
        translateX: r,
        translateY: n,
        scaleX: o,
        scaleY: a
      } = s,
      h = i(s, ve),
      c = new ot(r, n);
    t.flipX = !1, t.flipY = !1, Object.assign(t, h), t.set({
      scaleX: o,
      scaleY: a
    }), t.setPositionByOrigin(c, D, D);
  },
  xe = t => {
    t.scaleX = 1, t.scaleY = 1, t.skewX = 0, t.skewY = 0, t.flipX = !1, t.flipY = !1, t.rotate(0);
  },
  Ce = t => ({
    scaleX: t.scaleX,
    scaleY: t.scaleY,
    skewX: t.skewX,
    skewY: t.skewY,
    angle: t.angle,
    left: t.left,
    flipX: t.flipX,
    flipY: t.flipY,
    top: t.top
  }),
  be = (t, e, s) => {
    const i = t / 2,
      r = e / 2,
      n = [new ot(-i, -r), new ot(i, -r), new ot(-i, r), new ot(i, r)].map(t => t.transform(s)),
      o = me(n);
    return new ot(o.width, o.height);
  },
  we = function () {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : T;
    return wt(bt(arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : T), t);
  },
  Se = function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : T,
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : T;
    return t.transform(we(e, s));
  },
  Te = function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : T,
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : T;
    return t.transform(we(e, s), !0);
  },
  Oe = (t, e, s) => {
    const i = we(e, s);
    return _e(t, wt(i, t.calcOwnMatrix())), i;
  },
  ke = (t, e) => {
    var i;
    const {
      transform: {
        target: r
      }
    } = e;
    null === (i = r.canvas) || void 0 === i || i.fire("object:".concat(t), s(s({}, e), {}, {
      target: r
    })), r.fire(t, e);
  },
  De = {
    left: -.5,
    top: -.5,
    center: 0,
    bottom: .5,
    right: .5
  },
  Me = t => "string" == typeof t ? De[t] : t - .5,
  Pe = "not-allowed";
function Ee(t) {
  return Me(t.originX) === Me(D) && Me(t.originY) === Me(D);
}
function Ae(t) {
  return .5 - Me(t);
}
const je = (t, e) => t[e],
  Fe = (t, e, s, i) => ({
    e: t,
    transform: e,
    pointer: new ot(s, i)
  });
function Le(t, e) {
  const s = t.getTotalAngle() + _t(Math.atan2(e.y, e.x)) + 360;
  return Math.round(s % 360 / 45);
}
function Re(t, e, s, i, r) {
  var n;
  let {
    target: o,
    corner: a
  } = t;
  const h = o.controls[a],
    c = (null === (n = o.canvas) || void 0 === n ? void 0 : n.getZoom()) || 1,
    l = o.padding / c,
    u = function (t, e, s, i) {
      const r = t.getRelativeCenterPoint(),
        n = void 0 !== s && void 0 !== i ? t.translateToGivenOrigin(r, D, D, s, i) : new ot(t.left, t.top);
      return (t.angle ? e.rotate(-yt(t.angle), r) : e).subtract(n);
    }(o, new ot(i, r), e, s);
  return u.x >= l && (u.x -= l), u.x <= -l && (u.x += l), u.y >= l && (u.y -= l), u.y <= l && (u.y += l), u.x -= h.offsetX, u.y -= h.offsetY, u;
}
const Ie = (t, e, s, i) => {
  const {
      target: r,
      offsetX: n,
      offsetY: o
    } = e,
    a = s - n,
    h = i - o,
    c = !je(r, "lockMovementX") && r.left !== a,
    l = !je(r, "lockMovementY") && r.top !== h;
  return c && r.set(M, a), l && r.set(P, h), (c || l) && ke(L, Fe(t, e, s, i)), c || l;
};
class Be {
  getSvgStyles(t) {
    const e = this.fillRule ? this.fillRule : "nonzero",
      s = this.strokeWidth ? this.strokeWidth : "0",
      i = this.strokeDashArray ? this.strokeDashArray.join(" ") : j,
      r = this.strokeDashOffset ? this.strokeDashOffset : "0",
      n = this.strokeLineCap ? this.strokeLineCap : "butt",
      o = this.strokeLineJoin ? this.strokeLineJoin : "miter",
      a = this.strokeMiterLimit ? this.strokeMiterLimit : "4",
      h = void 0 !== this.opacity ? this.opacity : "1",
      c = this.visible ? "" : " visibility: hidden;",
      l = t ? "" : this.getSvgFilter(),
      u = Qt(K, this.fill);
    return [Qt(J, this.stroke), "stroke-width: ", s, "; ", "stroke-dasharray: ", i, "; ", "stroke-linecap: ", n, "; ", "stroke-dashoffset: ", r, "; ", "stroke-linejoin: ", o, "; ", "stroke-miterlimit: ", a, "; ", u, "fill-rule: ", e, "; ", "opacity: ", h, ";", l, c].join("");
  }
  getSvgFilter() {
    return this.shadow ? "filter: url(#SVGID_".concat(this.shadow.id, ");") : "";
  }
  getSvgCommons() {
    return [this.id ? 'id="'.concat(this.id, '" ') : "", this.clipPath ? 'clip-path="url(#'.concat(this.clipPath.clipPathId, ')" ') : ""].join("");
  }
  getSvgTransform(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    const s = t ? this.calcTransformMatrix() : this.calcOwnMatrix(),
      i = 'transform="'.concat(Jt(s));
    return "".concat(i).concat(e, '" ');
  }
  _toSVG(t) {
    return [""];
  }
  toSVG(t) {
    return this._createBaseSVGMarkup(this._toSVG(t), {
      reviver: t
    });
  }
  toClipPathSVG(t) {
    return "\t" + this._createBaseClipPathSVGMarkup(this._toSVG(t), {
      reviver: t
    });
  }
  _createBaseClipPathSVGMarkup(t) {
    let {
      reviver: e,
      additionalTransform: s = ""
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const i = [this.getSvgTransform(!0, s), this.getSvgCommons()].join(""),
      r = t.indexOf("COMMON_PARTS");
    return t[r] = i, e ? e(t.join("")) : t.join("");
  }
  _createBaseSVGMarkup(t) {
    let {
      noStyle: e,
      reviver: s,
      withShadow: i,
      additionalTransform: r
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const n = e ? "" : 'style="'.concat(this.getSvgStyles(), '" '),
      o = i ? 'style="'.concat(this.getSvgFilter(), '" ') : "",
      a = this.clipPath,
      h = this.strokeUniform ? 'vector-effect="non-scaling-stroke" ' : "",
      c = a && a.absolutePositioned,
      l = this.stroke,
      u = this.fill,
      d = this.shadow,
      g = [],
      f = t.indexOf("COMMON_PARTS");
    let p;
    a && (a.clipPathId = "CLIPPATH_".concat(ft()), p = '<clipPath id="'.concat(a.clipPathId, '" >\n').concat(a.toClipPathSVG(s), "</clipPath>\n")), c && g.push("<g ", o, this.getSvgCommons(), " >\n"), g.push("<g ", this.getSvgTransform(!1), c ? "" : o + this.getSvgCommons(), " >\n");
    const m = [n, h, e ? "" : this.addPaintOrder(), " ", r ? 'transform="'.concat(r, '" ') : ""].join("");
    return t[f] = m, Zt(u) && g.push(u.toSVG(this)), Zt(l) && g.push(l.toSVG(this)), d && g.push(d.toSVG(this)), a && g.push(p), g.push(t.join("")), g.push("</g>\n"), c && g.push("</g>\n"), s ? s(g.join("")) : g.join("");
  }
  addPaintOrder() {
    return this.paintFirst !== K ? ' paint-order="'.concat(this.paintFirst, '" ') : "";
  }
}
function Xe(t) {
  return new RegExp("^(" + t.join("|") + ")\\b", "i");
}
var We;
const Ye = String.raw(We || (We = r(["(?:[-+]?(?:d*.d+|d+.?)(?:[eE][-+]?d+)?)"], ["(?:[-+]?(?:\\d*\\.\\d+|\\d+\\.?)(?:[eE][-+]?\\d+)?)"]))),
  Ve = "http://www.w3.org/2000/svg",
  He = new RegExp("(normal|italic)?\\s*(normal|small-caps)?\\s*(normal|bold|bolder|lighter|100|200|300|400|500|600|700|800|900)?\\s*(" + Ye + "(?:px|cm|mm|em|pt|pc|in)*)(?:\\/(normal|" + Ye + "))?\\s+(.*)"),
  ze = {
    cx: M,
    x: M,
    r: "radius",
    cy: P,
    y: P,
    display: "visible",
    visibility: "visible",
    transform: "transformMatrix",
    "fill-opacity": "fillOpacity",
    "fill-rule": "fillRule",
    "font-family": "fontFamily",
    "font-size": "fontSize",
    "font-style": "fontStyle",
    "font-weight": "fontWeight",
    "letter-spacing": "charSpacing",
    "paint-order": "paintFirst",
    "stroke-dasharray": "strokeDashArray",
    "stroke-dashoffset": "strokeDashOffset",
    "stroke-linecap": "strokeLineCap",
    "stroke-linejoin": "strokeLineJoin",
    "stroke-miterlimit": "strokeMiterLimit",
    "stroke-opacity": "strokeOpacity",
    "stroke-width": "strokeWidth",
    "text-decoration": "textDecoration",
    "text-anchor": "textAnchor",
    opacity: "opacity",
    "clip-path": "clipPath",
    "clip-rule": "clipRule",
    "vector-effect": "strokeUniform",
    "image-rendering": "imageSmoothing"
  },
  Ge = "font-size",
  Ne = "clip-path",
  Ue = Xe(["path", "circle", "polygon", "polyline", "ellipse", "rect", "line", "image", "text"]),
  qe = Xe(["symbol", "image", "marker", "pattern", "view", "svg"]),
  Ke = Xe(["symbol", "g", "a", "svg", "clipPath", "defs"]),
  Je = new RegExp("^\\s*(" + Ye + "+)\\s*,?\\s*(" + Ye + "+)\\s*,?\\s*(" + Ye + "+)\\s*,?\\s*(" + Ye + "+)\\s*$"),
  Qe = new ot(1, 0),
  Ze = new ot(),
  $e = (t, e) => t.rotate(e),
  ts = (t, e) => new ot(e).subtract(t),
  es = t => t.distanceFrom(Ze),
  ss = (t, e) => Math.atan2(os(t, e), as(t, e)),
  is = t => ss(Qe, t),
  rs = t => t.eq(Ze) ? t : t.scalarDivide(es(t)),
  ns = function (t) {
    let e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    return rs(new ot(-t.y, t.x).scalarMultiply(e ? 1 : -1));
  },
  os = (t, e) => t.x * e.y - t.y * e.x,
  as = (t, e) => t.x * e.x + t.y * e.y,
  hs = (t, e, s) => {
    if (t.eq(e) || t.eq(s)) return !0;
    const i = os(e, s),
      r = os(e, t),
      n = os(s, t);
    return i >= 0 ? r >= 0 && n <= 0 : !(r <= 0 && n >= 0);
  },
  cs = "(-?\\d+(?:\\.\\d*)?(?:px)?(?:\\s?|$))?",
  ls = new RegExp("(?:\\s|^)" + cs + cs + "(" + Ye + "?(?:px)?)?(?:\\s?|$)(?:$|\\s)");
class us {
  constructor(t) {
    const e = "string" == typeof t ? us.parseShadow(t) : t;
    Object.assign(this, us.ownDefaults, e), this.id = ft();
  }
  static parseShadow(t) {
    const e = t.trim(),
      [, s = 0, i = 0, r = 0] = (ls.exec(e) || []).map(t => parseFloat(t) || 0);
    return {
      color: (e.replace(ls, "") || "rgb(0,0,0)").trim(),
      offsetX: s,
      offsetY: i,
      blur: r
    };
  }
  toString() {
    return [this.offsetX, this.offsetY, this.blur, this.color].join("px ");
  }
  toSVG(t) {
    const e = $e(new ot(this.offsetX, this.offsetY), yt(-t.angle)),
      s = new Nt(this.color);
    let i = 40,
      r = 40;
    return t.width && t.height && (i = 100 * Ut((Math.abs(e.x) + this.blur) / t.width, o.NUM_FRACTION_DIGITS) + 20, r = 100 * Ut((Math.abs(e.y) + this.blur) / t.height, o.NUM_FRACTION_DIGITS) + 20), t.flipX && (e.x *= -1), t.flipY && (e.y *= -1), '<filter id="SVGID_'.concat(this.id, '" y="-').concat(r, '%" height="').concat(100 + 2 * r, '%" x="-').concat(i, '%" width="').concat(100 + 2 * i, '%" >\n\t<feGaussianBlur in="SourceAlpha" stdDeviation="').concat(Ut(this.blur ? this.blur / 2 : 0, o.NUM_FRACTION_DIGITS), '"></feGaussianBlur>\n\t<feOffset dx="').concat(Ut(e.x, o.NUM_FRACTION_DIGITS), '" dy="').concat(Ut(e.y, o.NUM_FRACTION_DIGITS), '" result="oBlur" ></feOffset>\n\t<feFlood flood-color="').concat(s.toRgb(), '" flood-opacity="').concat(s.getAlpha(), '"/>\n\t<feComposite in2="oBlur" operator="in" />\n\t<feMerge>\n\t\t<feMergeNode></feMergeNode>\n\t\t<feMergeNode in="SourceGraphic"></feMergeNode>\n\t</feMerge>\n</filter>\n');
  }
  toObject() {
    const t = {
        color: this.color,
        blur: this.blur,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
        affectStroke: this.affectStroke,
        nonScaling: this.nonScaling,
        type: this.constructor.type
      },
      e = us.ownDefaults;
    return this.includeDefaultValues ? t : Xt(t, (t, s) => t !== e[s]);
  }
  static async fromObject(t) {
    return new this(t);
  }
}
t(us, "ownDefaults", {
  color: "rgb(0,0,0)",
  blur: 0,
  offsetX: 0,
  offsetY: 0,
  affectStroke: !1,
  includeDefaultValues: !0,
  nonScaling: !1
}), t(us, "type", "shadow"), tt.setClass(us, "shadow");
const ds = (t, e, s) => Math.max(t, Math.min(e, s)),
  gs = [P, M, G, N, "flipX", "flipY", "originX", "originY", "angle", "opacity", "globalCompositeOperation", "shadow", "visible", U, q],
  fs = [K, J, "strokeWidth", "strokeDashArray", "width", "height", "paintFirst", "strokeUniform", "strokeLineCap", "strokeDashOffset", "strokeLineJoin", "strokeMiterLimit", "backgroundColor", "clipPath"],
  ps = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    angle: 0,
    flipX: !1,
    flipY: !1,
    scaleX: 1,
    scaleY: 1,
    minScaleLimit: 0,
    skewX: 0,
    skewY: 0,
    originX: M,
    originY: P,
    strokeWidth: 1,
    strokeUniform: !1,
    padding: 0,
    opacity: 1,
    paintFirst: K,
    fill: "rgb(0,0,0)",
    fillRule: "nonzero",
    stroke: null,
    strokeDashArray: null,
    strokeDashOffset: 0,
    strokeLineCap: "butt",
    strokeLineJoin: "miter",
    strokeMiterLimit: 4,
    globalCompositeOperation: "source-over",
    backgroundColor: "",
    shadow: null,
    visible: !0,
    includeDefaultValues: !0,
    excludeFromExport: !1,
    objectCaching: !0,
    clipPath: void 0,
    inverted: !1,
    absolutePositioned: !1,
    centeredRotation: !0,
    centeredScaling: !1,
    dirty: !0
  },
  ms = (t, e, s, i) => (t < Math.abs(e) ? (t = e, i = s / 4) : i = 0 === e && 0 === t ? s / w * Math.asin(1) : s / w * Math.asin(e / t), {
    a: t,
    c: e,
    p: s,
    s: i
  }),
  vs = (t, e, s, i, r) => t * Math.pow(2, 10 * (i -= 1)) * Math.sin((i * r - e) * w / s),
  ys = (t, e, s, i) => -s * Math.cos(t / i * b) + s + e,
  _s = (t, e, s, i) => (t /= i) < 1 / 2.75 ? s * (7.5625 * t * t) + e : t < 2 / 2.75 ? s * (7.5625 * (t -= 1.5 / 2.75) * t + .75) + e : t < 2.5 / 2.75 ? s * (7.5625 * (t -= 2.25 / 2.75) * t + .9375) + e : s * (7.5625 * (t -= 2.625 / 2.75) * t + .984375) + e,
  xs = (t, e, s, i) => s - _s(i - t, 0, s, i) + e;
var Cs = Object.freeze({
  __proto__: null,
  defaultEasing: ys,
  easeInBack: function (t, e, s, i) {
    let r = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 1.70158;
    return s * (t /= i) * t * ((r + 1) * t - r) + e;
  },
  easeInBounce: xs,
  easeInCirc: (t, e, s, i) => -s * (Math.sqrt(1 - (t /= i) * t) - 1) + e,
  easeInCubic: (t, e, s, i) => s * (t / i) ** 3 + e,
  easeInElastic: (t, e, s, i) => {
    const r = s;
    let n = 0;
    if (0 === t) return e;
    if (1 === (t /= i)) return e + s;
    n || (n = .3 * i);
    const {
      a: o,
      s: a,
      p: h
    } = ms(r, s, n, 1.70158);
    return -vs(o, a, h, t, i) + e;
  },
  easeInExpo: (t, e, s, i) => 0 === t ? e : s * 2 ** (10 * (t / i - 1)) + e,
  easeInOutBack: function (t, e, s, i) {
    let r = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 1.70158;
    return (t /= i / 2) < 1 ? s / 2 * (t * t * ((1 + (r *= 1.525)) * t - r)) + e : s / 2 * ((t -= 2) * t * ((1 + (r *= 1.525)) * t + r) + 2) + e;
  },
  easeInOutBounce: (t, e, s, i) => t < i / 2 ? .5 * xs(2 * t, 0, s, i) + e : .5 * _s(2 * t - i, 0, s, i) + .5 * s + e,
  easeInOutCirc: (t, e, s, i) => (t /= i / 2) < 1 ? -s / 2 * (Math.sqrt(1 - t ** 2) - 1) + e : s / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + e,
  easeInOutCubic: (t, e, s, i) => (t /= i / 2) < 1 ? s / 2 * t ** 3 + e : s / 2 * ((t - 2) ** 3 + 2) + e,
  easeInOutElastic: (t, e, s, i) => {
    const r = s;
    let n = 0;
    if (0 === t) return e;
    if (2 === (t /= i / 2)) return e + s;
    n || (n = i * (.3 * 1.5));
    const {
      a: o,
      s: a,
      p: h,
      c: c
    } = ms(r, s, n, 1.70158);
    return t < 1 ? -.5 * vs(o, a, h, t, i) + e : o * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * i - a) * w / h) * .5 + c + e;
  },
  easeInOutExpo: (t, e, s, i) => 0 === t ? e : t === i ? e + s : (t /= i / 2) < 1 ? s / 2 * 2 ** (10 * (t - 1)) + e : s / 2 * -(2 ** (-10 * --t) + 2) + e,
  easeInOutQuad: (t, e, s, i) => (t /= i / 2) < 1 ? s / 2 * t ** 2 + e : -s / 2 * (--t * (t - 2) - 1) + e,
  easeInOutQuart: (t, e, s, i) => (t /= i / 2) < 1 ? s / 2 * t ** 4 + e : -s / 2 * ((t -= 2) * t ** 3 - 2) + e,
  easeInOutQuint: (t, e, s, i) => (t /= i / 2) < 1 ? s / 2 * t ** 5 + e : s / 2 * ((t - 2) ** 5 + 2) + e,
  easeInOutSine: (t, e, s, i) => -s / 2 * (Math.cos(Math.PI * t / i) - 1) + e,
  easeInQuad: (t, e, s, i) => s * (t /= i) * t + e,
  easeInQuart: (t, e, s, i) => s * (t /= i) * t ** 3 + e,
  easeInQuint: (t, e, s, i) => s * (t / i) ** 5 + e,
  easeInSine: (t, e, s, i) => -s * Math.cos(t / i * b) + s + e,
  easeOutBack: function (t, e, s, i) {
    let r = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 1.70158;
    return s * ((t = t / i - 1) * t * ((r + 1) * t + r) + 1) + e;
  },
  easeOutBounce: _s,
  easeOutCirc: (t, e, s, i) => s * Math.sqrt(1 - (t = t / i - 1) * t) + e,
  easeOutCubic: (t, e, s, i) => s * ((t / i - 1) ** 3 + 1) + e,
  easeOutElastic: (t, e, s, i) => {
    const r = s;
    let n = 0;
    if (0 === t) return e;
    if (1 === (t /= i)) return e + s;
    n || (n = .3 * i);
    const {
      a: o,
      s: a,
      p: h,
      c: c
    } = ms(r, s, n, 1.70158);
    return o * 2 ** (-10 * t) * Math.sin((t * i - a) * w / h) + c + e;
  },
  easeOutExpo: (t, e, s, i) => t === i ? e + s : s * -(2 ** (-10 * t / i) + 1) + e,
  easeOutQuad: (t, e, s, i) => -s * (t /= i) * (t - 2) + e,
  easeOutQuart: (t, e, s, i) => -s * ((t = t / i - 1) * t ** 3 - 1) + e,
  easeOutQuint: (t, e, s, i) => s * ((t / i - 1) ** 5 + 1) + e,
  easeOutSine: (t, e, s, i) => s * Math.sin(t / i * b) + e
});
const bs = () => !1;
class ws {
  constructor(e) {
    let {
      startValue: s,
      byValue: i,
      duration: r = 500,
      delay: n = 0,
      easing: o = ys,
      onStart: a = C,
      onChange: h = C,
      onComplete: c = C,
      abort: l = bs,
      target: u
    } = e;
    t(this, "_state", "pending"), t(this, "durationProgress", 0), t(this, "valueProgress", 0), this.tick = this.tick.bind(this), this.duration = r, this.delay = n, this.easing = o, this._onStart = a, this._onChange = h, this._onComplete = c, this._abort = l, this.target = u, this.startValue = s, this.byValue = i, this.value = this.startValue, this.endValue = Object.freeze(this.calculate(this.duration).value);
  }
  get state() {
    return this._state;
  }
  isDone() {
    return "aborted" === this._state || "completed" === this._state;
  }
  start() {
    const t = t => {
      "pending" === this._state && (this.startTime = t || +new Date(), this._state = "running", this._onStart(), this.tick(this.startTime));
    };
    this.register(), this.delay > 0 ? setTimeout(() => ut(t), this.delay) : ut(t);
  }
  tick(t) {
    const e = (t || +new Date()) - this.startTime,
      s = Math.min(e, this.duration);
    this.durationProgress = s / this.duration;
    const {
      value: i,
      valueProgress: r
    } = this.calculate(s);
    this.value = Object.freeze(i), this.valueProgress = r, "aborted" !== this._state && (this._abort(this.value, this.valueProgress, this.durationProgress) ? (this._state = "aborted", this.unregister()) : e >= this.duration ? (this.durationProgress = this.valueProgress = 1, this._onChange(this.endValue, this.valueProgress, this.durationProgress), this._state = "completed", this._onComplete(this.endValue, this.valueProgress, this.durationProgress), this.unregister()) : (this._onChange(this.value, this.valueProgress, this.durationProgress), ut(this.tick)));
  }
  register() {
    et.push(this);
  }
  unregister() {
    et.remove(this);
  }
  abort() {
    this._state = "aborted", this.unregister();
  }
}
const Ss = ["startValue", "endValue"];
class Ts extends ws {
  constructor(t) {
    let {
      startValue: e = 0,
      endValue: r = 100
    } = t;
    super(s(s({}, i(t, Ss)), {}, {
      startValue: e,
      byValue: r - e
    }));
  }
  calculate(t) {
    const e = this.easing(t, this.startValue, this.byValue, this.duration);
    return {
      value: e,
      valueProgress: Math.abs((e - this.startValue) / this.byValue)
    };
  }
}
const Os = ["startValue", "endValue"];
class ks extends ws {
  constructor(t) {
    let {
      startValue: e = [0],
      endValue: r = [100]
    } = t;
    super(s(s({}, i(t, Os)), {}, {
      startValue: e,
      byValue: r.map((t, s) => t - e[s])
    }));
  }
  calculate(t) {
    const e = this.startValue.map((e, s) => this.easing(t, e, this.byValue[s], this.duration, s));
    return {
      value: e,
      valueProgress: Math.abs((e[0] - this.startValue[0]) / this.byValue[0])
    };
  }
}
const Ds = ["startValue", "endValue", "easing", "onChange", "onComplete", "abort"],
  Ms = (t, e, s, i) => e + s * (1 - Math.cos(t / i * b)),
  Ps = t => t && ((e, s, i) => t(new Nt(e).toRgba(), s, i));
class Es extends ws {
  constructor(t) {
    let {
        startValue: e,
        endValue: r,
        easing: n = Ms,
        onChange: o,
        onComplete: a,
        abort: h
      } = t,
      c = i(t, Ds);
    const l = new Nt(e).getSource(),
      u = new Nt(r).getSource();
    super(s(s({}, c), {}, {
      startValue: l,
      byValue: u.map((t, e) => t - l[e]),
      easing: n,
      onChange: Ps(o),
      onComplete: Ps(a),
      abort: Ps(h)
    }));
  }
  calculate(t) {
    const [e, s, i, r] = this.startValue.map((e, s) => this.easing(t, e, this.byValue[s], this.duration, s)),
      n = [...[e, s, i].map(Math.round), ds(0, r, 1)];
    return {
      value: n,
      valueProgress: n.map((t, e) => 0 !== this.byValue[e] ? Math.abs((t - this.startValue[e]) / this.byValue[e]) : 0).find(t => 0 !== t) || 0
    };
  }
}
function As(t) {
  const e = (t => Array.isArray(t.startValue) || Array.isArray(t.endValue))(t) ? new ks(t) : new Ts(t);
  return e.start(), e;
}
function js(t) {
  const e = new Es(t);
  return e.start(), e;
}
class Fs {
  constructor(t) {
    this.status = t, this.points = [];
  }
  includes(t) {
    return this.points.some(e => e.eq(t));
  }
  append() {
    for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
    return this.points = this.points.concat(e.filter(t => !this.includes(t))), this;
  }
  static isPointContained(t, e, s) {
    let i = arguments.length > 3 && void 0 !== arguments[3] && arguments[3];
    if (e.eq(s)) return t.eq(e);
    if (e.x === s.x) return t.x === e.x && (i || t.y >= Math.min(e.y, s.y) && t.y <= Math.max(e.y, s.y));
    if (e.y === s.y) return t.y === e.y && (i || t.x >= Math.min(e.x, s.x) && t.x <= Math.max(e.x, s.x));
    {
      const r = ts(e, s),
        n = ts(e, t).divide(r);
      return i ? Math.abs(n.x) === Math.abs(n.y) : n.x === n.y && n.x >= 0 && n.x <= 1;
    }
  }
  static isPointInPolygon(t, e) {
    const s = new ot(t).setX(Math.min(t.x - 1, ...e.map(t => t.x)));
    let i = 0;
    for (let r = 0; r < e.length; r++) {
      const n = this.intersectSegmentSegment(e[r], e[(r + 1) % e.length], t, s);
      if (n.includes(t)) return !0;
      i += Number("Intersection" === n.status);
    }
    return i % 2 == 1;
  }
  static intersectLineLine(t, e, s, i) {
    let r = !(arguments.length > 4 && void 0 !== arguments[4]) || arguments[4],
      n = !(arguments.length > 5 && void 0 !== arguments[5]) || arguments[5];
    const o = e.x - t.x,
      a = e.y - t.y,
      h = i.x - s.x,
      c = i.y - s.y,
      l = t.x - s.x,
      u = t.y - s.y,
      d = h * u - c * l,
      g = o * u - a * l,
      f = c * o - h * a;
    if (0 !== f) {
      const e = d / f,
        s = g / f;
      return (r || 0 <= e && e <= 1) && (n || 0 <= s && s <= 1) ? new Fs("Intersection").append(new ot(t.x + e * o, t.y + e * a)) : new Fs();
    }
    if (0 === d || 0 === g) {
      const o = r || n || Fs.isPointContained(t, s, i) || Fs.isPointContained(e, s, i) || Fs.isPointContained(s, t, e) || Fs.isPointContained(i, t, e);
      return new Fs(o ? "Coincident" : void 0);
    }
    return new Fs("Parallel");
  }
  static intersectSegmentLine(t, e, s, i) {
    return Fs.intersectLineLine(t, e, s, i, !1, !0);
  }
  static intersectSegmentSegment(t, e, s, i) {
    return Fs.intersectLineLine(t, e, s, i, !1, !1);
  }
  static intersectLinePolygon(t, e, s) {
    let i = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3];
    const r = new Fs(),
      n = s.length;
    for (let o, a, h, c = 0; c < n; c++) {
      if (o = s[c], a = s[(c + 1) % n], h = Fs.intersectLineLine(t, e, o, a, i, !1), "Coincident" === h.status) return h;
      r.append(...h.points);
    }
    return r.points.length > 0 && (r.status = "Intersection"), r;
  }
  static intersectSegmentPolygon(t, e, s) {
    return Fs.intersectLinePolygon(t, e, s, !1);
  }
  static intersectPolygonPolygon(t, e) {
    const s = new Fs(),
      i = t.length,
      r = [];
    for (let n = 0; n < i; n++) {
      const o = t[n],
        a = t[(n + 1) % i],
        h = Fs.intersectSegmentPolygon(o, a, e);
      "Coincident" === h.status ? (r.push(h), s.append(o, a)) : s.append(...h.points);
    }
    return r.length > 0 && r.length === t.length ? new Fs("Coincident") : (s.points.length > 0 && (s.status = "Intersection"), s);
  }
  static intersectPolygonRectangle(t, e, s) {
    const i = e.min(s),
      r = e.max(s),
      n = new ot(r.x, i.y),
      o = new ot(i.x, r.y);
    return Fs.intersectPolygonPolygon(t, [i, n, r, o]);
  }
}
class Ls extends lt {
  getX() {
    return this.getXY().x;
  }
  setX(t) {
    this.setXY(this.getXY().setX(t));
  }
  getY() {
    return this.getXY().y;
  }
  setY(t) {
    this.setXY(this.getXY().setY(t));
  }
  getRelativeX() {
    return this.left;
  }
  setRelativeX(t) {
    this.left = t;
  }
  getRelativeY() {
    return this.top;
  }
  setRelativeY(t) {
    this.top = t;
  }
  getXY() {
    const t = this.getRelativeXY();
    return this.group ? Ct(t, this.group.calcTransformMatrix()) : t;
  }
  setXY(t, e, s) {
    this.group && (t = Ct(t, bt(this.group.calcTransformMatrix()))), this.setRelativeXY(t, e, s);
  }
  getRelativeXY() {
    return new ot(this.left, this.top);
  }
  setRelativeXY(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : this.originX,
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.originY;
    this.setPositionByOrigin(t, e, s);
  }
  isStrokeAccountedForInDimensions() {
    return !1;
  }
  getCoords() {
    const {
        tl: t,
        tr: e,
        br: s,
        bl: i
      } = this.aCoords || (this.aCoords = this.calcACoords()),
      r = [t, e, s, i];
    if (this.group) {
      const t = this.group.calcTransformMatrix();
      return r.map(e => Ct(e, t));
    }
    return r;
  }
  intersectsWithRect(t, e) {
    return "Intersection" === Fs.intersectPolygonRectangle(this.getCoords(), t, e).status;
  }
  intersectsWithObject(t) {
    const e = Fs.intersectPolygonPolygon(this.getCoords(), t.getCoords());
    return "Intersection" === e.status || "Coincident" === e.status || t.isContainedWithinObject(this) || this.isContainedWithinObject(t);
  }
  isContainedWithinObject(t) {
    return this.getCoords().every(e => t.containsPoint(e));
  }
  isContainedWithinRect(t, e) {
    const {
      left: s,
      top: i,
      width: r,
      height: n
    } = this.getBoundingRect();
    return s >= t.x && s + r <= e.x && i >= t.y && i + n <= e.y;
  }
  isOverlapping(t) {
    return this.intersectsWithObject(t) || this.isContainedWithinObject(t) || t.isContainedWithinObject(this);
  }
  containsPoint(t) {
    return Fs.isPointInPolygon(t, this.getCoords());
  }
  isOnScreen() {
    if (!this.canvas) return !1;
    const {
      tl: t,
      br: e
    } = this.canvas.vptCoords;
    return !!this.getCoords().some(s => s.x <= e.x && s.x >= t.x && s.y <= e.y && s.y >= t.y) || !!this.intersectsWithRect(t, e) || this.containsPoint(t.midPointFrom(e));
  }
  isPartiallyOnScreen() {
    if (!this.canvas) return !1;
    const {
      tl: t,
      br: e
    } = this.canvas.vptCoords;
    if (this.intersectsWithRect(t, e)) return !0;
    return this.getCoords().every(s => (s.x >= e.x || s.x <= t.x) && (s.y >= e.y || s.y <= t.y)) && this.containsPoint(t.midPointFrom(e));
  }
  getBoundingRect() {
    return me(this.getCoords());
  }
  getScaledWidth() {
    return this._getTransformedDimensions().x;
  }
  getScaledHeight() {
    return this._getTransformedDimensions().y;
  }
  scale(t) {
    this._set(G, t), this._set(N, t), this.setCoords();
  }
  scaleToWidth(t) {
    const e = this.getBoundingRect().width / this.getScaledWidth();
    return this.scale(t / this.width / e);
  }
  scaleToHeight(t) {
    const e = this.getBoundingRect().height / this.getScaledHeight();
    return this.scale(t / this.height / e);
  }
  getCanvasRetinaScaling() {
    var t;
    return (null === (t = this.canvas) || void 0 === t ? void 0 : t.getRetinaScaling()) || 1;
  }
  getTotalAngle() {
    return this.group ? _t(Tt(this.calcTransformMatrix())) : this.angle;
  }
  getViewportTransform() {
    var t;
    return (null === (t = this.canvas) || void 0 === t ? void 0 : t.viewportTransform) || T.concat();
  }
  calcACoords() {
    const t = Dt({
        angle: this.angle
      }),
      {
        x: e,
        y: s
      } = this.getRelativeCenterPoint(),
      i = kt(e, s),
      r = wt(i, t),
      n = this._getTransformedDimensions(),
      o = n.x / 2,
      a = n.y / 2;
    return {
      tl: Ct({
        x: -o,
        y: -a
      }, r),
      tr: Ct({
        x: o,
        y: -a
      }, r),
      bl: Ct({
        x: -o,
        y: a
      }, r),
      br: Ct({
        x: o,
        y: a
      }, r)
    };
  }
  setCoords() {
    this.aCoords = this.calcACoords();
  }
  transformMatrixKey() {
    let t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      e = [];
    return !t && this.group && (e = this.group.transformMatrixKey(t)), e.push(this.top, this.left, this.width, this.height, this.scaleX, this.scaleY, this.angle, this.strokeWidth, this.skewX, this.skewY, +this.flipX, +this.flipY, Me(this.originX), Me(this.originY)), e;
  }
  calcTransformMatrix() {
    let t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      e = this.calcOwnMatrix();
    if (t || !this.group) return e;
    const s = this.transformMatrixKey(t),
      i = this.matrixCache;
    return i && i.key.every((t, e) => t === s[e]) ? i.value : (this.group && (e = wt(this.group.calcTransformMatrix(!1), e)), this.matrixCache = {
      key: s,
      value: e
    }, e);
  }
  calcOwnMatrix() {
    const t = this.transformMatrixKey(!0),
      e = this.ownMatrixCache;
    if (e && e.key === t) return e.value;
    const s = this.getRelativeCenterPoint(),
      i = {
        angle: this.angle,
        translateX: s.x,
        translateY: s.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        skewX: this.skewX,
        skewY: this.skewY,
        flipX: this.flipX,
        flipY: this.flipY
      },
      r = Ft(i);
    return this.ownMatrixCache = {
      key: t,
      value: r
    }, r;
  }
  _getNonTransformedDimensions() {
    return new ot(this.width, this.height).scalarAdd(this.strokeWidth);
  }
  _calculateCurrentDimensions(t) {
    return this._getTransformedDimensions(t).transform(this.getViewportTransform(), !0).scalarAdd(2 * this.padding);
  }
  _getTransformedDimensions() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    const e = s({
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        skewX: this.skewX,
        skewY: this.skewY,
        width: this.width,
        height: this.height,
        strokeWidth: this.strokeWidth
      }, t),
      i = e.strokeWidth;
    let r = i,
      n = 0;
    this.strokeUniform && (r = 0, n = i);
    const o = e.width + r,
      a = e.height + r;
    let h;
    return h = 0 === e.skewX && 0 === e.skewY ? new ot(o * e.scaleX, a * e.scaleY) : be(o, a, jt(e)), h.scalarAdd(n);
  }
  translateToGivenOrigin(t, e, s, i, r) {
    let n = t.x,
      o = t.y;
    const a = Me(i) - Me(e),
      h = Me(r) - Me(s);
    if (a || h) {
      const t = this._getTransformedDimensions();
      n += a * t.x, o += h * t.y;
    }
    return new ot(n, o);
  }
  translateToCenterPoint(t, e, s) {
    if (e === D && s === D) return t;
    const i = this.translateToGivenOrigin(t, e, s, D, D);
    return this.angle ? i.rotate(yt(this.angle), t) : i;
  }
  translateToOriginPoint(t, e, s) {
    const i = this.translateToGivenOrigin(t, D, D, e, s);
    return this.angle ? i.rotate(yt(this.angle), t) : i;
  }
  getCenterPoint() {
    const t = this.getRelativeCenterPoint();
    return this.group ? Ct(t, this.group.calcTransformMatrix()) : t;
  }
  getRelativeCenterPoint() {
    return this.translateToCenterPoint(new ot(this.left, this.top), this.originX, this.originY);
  }
  getPointByOrigin(t, e) {
    return this.translateToOriginPoint(this.getRelativeCenterPoint(), t, e);
  }
  setPositionByOrigin(t, e, s) {
    const i = this.translateToCenterPoint(t, e, s),
      r = this.translateToOriginPoint(i, this.originX, this.originY);
    this.set({
      left: r.x,
      top: r.y
    });
  }
  _getLeftTopCoords() {
    return this.translateToOriginPoint(this.getRelativeCenterPoint(), M, P);
  }
}
const Rs = ["type"],
  Is = ["extraParam"];
let Bs = class e extends Ls {
  static getDefaults() {
    return e.ownDefaults;
  }
  get type() {
    const t = this.constructor.type;
    return "FabricObject" === t ? "object" : t.toLowerCase();
  }
  set type(t) {
    a("warn", "Setting type has no effect", t);
  }
  constructor(s) {
    super(), t(this, "_cacheContext", null), Object.assign(this, e.ownDefaults), this.setOptions(s);
  }
  _createCacheCanvas() {
    this._cacheCanvas = pt(), this._cacheContext = this._cacheCanvas.getContext("2d"), this._updateCacheCanvas(), this.dirty = !0;
  }
  _limitCacheSize(t) {
    const e = t.width,
      s = t.height,
      i = o.maxCacheSideLimit,
      r = o.minCacheSideLimit;
    if (e <= i && s <= i && e * s <= o.perfLimitSizeTotal) return e < r && (t.width = r), s < r && (t.height = r), t;
    const n = e / s,
      [a, h] = _.limitDimsByArea(n),
      c = ds(r, a, i),
      l = ds(r, h, i);
    return e > c && (t.zoomX /= e / c, t.width = c, t.capped = !0), s > l && (t.zoomY /= s / l, t.height = l, t.capped = !0), t;
  }
  _getCacheCanvasDimensions() {
    const t = this.getTotalObjectScaling(),
      e = this._getTransformedDimensions({
        skewX: 0,
        skewY: 0
      }),
      s = e.x * t.x / this.scaleX,
      i = e.y * t.y / this.scaleY;
    return {
      width: s + 2,
      height: i + 2,
      zoomX: t.x,
      zoomY: t.y,
      x: s,
      y: i
    };
  }
  _updateCacheCanvas() {
    const t = this._cacheCanvas,
      e = this._cacheContext,
      s = this._limitCacheSize(this._getCacheCanvasDimensions()),
      i = o.minCacheSideLimit,
      r = s.width,
      n = s.height,
      a = s.zoomX,
      h = s.zoomY,
      c = r !== t.width || n !== t.height,
      l = this.zoomX !== a || this.zoomY !== h;
    if (!t || !e) return !1;
    let u,
      d,
      g = c || l,
      f = 0,
      p = 0,
      m = !1;
    if (c) {
      const t = this._cacheCanvas.width,
        e = this._cacheCanvas.height,
        o = r > t || n > e;
      m = o || (r < .9 * t || n < .9 * e) && t > i && e > i, o && !s.capped && (r > i || n > i) && (f = .1 * r, p = .1 * n);
    }
    return ee(this) && this.path && (g = !0, m = !0, f += this.getHeightOfLine(0) * this.zoomX, p += this.getHeightOfLine(0) * this.zoomY), !!g && (m ? (t.width = Math.ceil(r + f), t.height = Math.ceil(n + p)) : (e.setTransform(1, 0, 0, 1, 0, 0), e.clearRect(0, 0, t.width, t.height)), u = s.x / 2, d = s.y / 2, this.cacheTranslationX = Math.round(t.width / 2 - u) + u, this.cacheTranslationY = Math.round(t.height / 2 - d) + d, e.translate(this.cacheTranslationX, this.cacheTranslationY), e.scale(a, h), this.zoomX = a, this.zoomY = h, !0);
  }
  setOptions() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    this._setOptions(t);
  }
  transform(t) {
    const e = this.group && !this.group._transformDone || this.group && this.canvas && t === this.canvas.contextTop,
      s = this.calcTransformMatrix(!e);
    t.transform(s[0], s[1], s[2], s[3], s[4], s[5]);
  }
  getObjectScaling() {
    if (!this.group) return new ot(Math.abs(this.scaleX), Math.abs(this.scaleY));
    const t = Ot(this.calcTransformMatrix());
    return new ot(Math.abs(t.scaleX), Math.abs(t.scaleY));
  }
  getTotalObjectScaling() {
    const t = this.getObjectScaling();
    if (this.canvas) {
      const e = this.canvas.getZoom(),
        s = this.getCanvasRetinaScaling();
      return t.scalarMultiply(e * s);
    }
    return t;
  }
  getObjectOpacity() {
    let t = this.opacity;
    return this.group && (t *= this.group.getObjectOpacity()), t;
  }
  _constrainScale(t) {
    return Math.abs(t) < this.minScaleLimit ? t < 0 ? -this.minScaleLimit : this.minScaleLimit : 0 === t ? 1e-4 : t;
  }
  _set(t, e) {
    t !== G && t !== N || (e = this._constrainScale(e)), t === G && e < 0 ? (this.flipX = !this.flipX, e *= -1) : "scaleY" === t && e < 0 ? (this.flipY = !this.flipY, e *= -1) : "shadow" !== t || !e || e instanceof us || (e = new us(e));
    const s = this[t] !== e;
    return this[t] = e, s && this.constructor.cacheProperties.includes(t) && (this.dirty = !0), this.parent && (this.dirty || s && this.constructor.stateProperties.includes(t)) && this.parent._set("dirty", !0), this;
  }
  isNotVisible() {
    return 0 === this.opacity || !this.width && !this.height && 0 === this.strokeWidth || !this.visible;
  }
  render(t) {
    this.isNotVisible() || this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen() || (t.save(), this._setupCompositeOperation(t), this.drawSelectionBackground(t), this.transform(t), this._setOpacity(t), this._setShadow(t), this.shouldCache() ? (this.renderCache(), this.drawCacheOnCanvas(t)) : (this._removeCacheCanvas(), this.drawObject(t), this.dirty = !1), t.restore());
  }
  drawSelectionBackground(t) {}
  renderCache(t) {
    t = t || {}, this._cacheCanvas && this._cacheContext || this._createCacheCanvas(), this.isCacheDirty() && this._cacheContext && (this.drawObject(this._cacheContext, t.forClipping), this.dirty = !1);
  }
  _removeCacheCanvas() {
    this._cacheCanvas = void 0, this._cacheContext = null;
  }
  hasStroke() {
    return this.stroke && "transparent" !== this.stroke && 0 !== this.strokeWidth;
  }
  hasFill() {
    return this.fill && "transparent" !== this.fill;
  }
  needsItsOwnCache() {
    return !!(this.paintFirst === J && this.hasFill() && this.hasStroke() && this.shadow) || !!this.clipPath;
  }
  shouldCache() {
    return this.ownCaching = this.needsItsOwnCache() || this.objectCaching && (!this.parent || !this.parent.isOnACache()), this.ownCaching;
  }
  willDrawShadow() {
    return !!this.shadow && (0 !== this.shadow.offsetX || 0 !== this.shadow.offsetY);
  }
  drawClipPathOnCache(t, e) {
    if (t.save(), e.inverted ? t.globalCompositeOperation = "destination-out" : t.globalCompositeOperation = "destination-in", e.absolutePositioned) {
      const e = bt(this.calcTransformMatrix());
      t.transform(e[0], e[1], e[2], e[3], e[4], e[5]);
    }
    e.transform(t), t.scale(1 / e.zoomX, 1 / e.zoomY), t.drawImage(e._cacheCanvas, -e.cacheTranslationX, -e.cacheTranslationY), t.restore();
  }
  drawObject(t, e) {
    const s = this.fill,
      i = this.stroke;
    e ? (this.fill = "black", this.stroke = "", this._setClippingProperties(t)) : this._renderBackground(t), this._render(t), this._drawClipPath(t, this.clipPath), this.fill = s, this.stroke = i;
  }
  _drawClipPath(t, e) {
    e && (e._set("canvas", this.canvas), e.shouldCache(), e._transformDone = !0, e.renderCache({
      forClipping: !0
    }), this.drawClipPathOnCache(t, e));
  }
  drawCacheOnCanvas(t) {
    t.scale(1 / this.zoomX, 1 / this.zoomY), t.drawImage(this._cacheCanvas, -this.cacheTranslationX, -this.cacheTranslationY);
  }
  isCacheDirty() {
    let t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
    if (this.isNotVisible()) return !1;
    const e = this._cacheCanvas,
      s = this._cacheContext;
    return !(!e || !s || t || !this._updateCacheCanvas()) || !!(this.dirty || this.clipPath && this.clipPath.absolutePositioned) && (e && s && !t && (s.save(), s.setTransform(1, 0, 0, 1, 0, 0), s.clearRect(0, 0, e.width, e.height), s.restore()), !0);
  }
  _renderBackground(t) {
    if (!this.backgroundColor) return;
    const e = this._getNonTransformedDimensions();
    t.fillStyle = this.backgroundColor, t.fillRect(-e.x / 2, -e.y / 2, e.x, e.y), this._removeShadow(t);
  }
  _setOpacity(t) {
    this.group && !this.group._transformDone ? t.globalAlpha = this.getObjectOpacity() : t.globalAlpha *= this.opacity;
  }
  _setStrokeStyles(t, e) {
    const s = e.stroke;
    s && (t.lineWidth = e.strokeWidth, t.lineCap = e.strokeLineCap, t.lineDashOffset = e.strokeDashOffset, t.lineJoin = e.strokeLineJoin, t.miterLimit = e.strokeMiterLimit, Zt(s) ? "percentage" === s.gradientUnits || s.gradientTransform || s.patternTransform ? this._applyPatternForTransformedGradient(t, s) : (t.strokeStyle = s.toLive(t), this._applyPatternGradientTransform(t, s)) : t.strokeStyle = e.stroke);
  }
  _setFillStyles(t, e) {
    let {
      fill: s
    } = e;
    s && (Zt(s) ? (t.fillStyle = s.toLive(t), this._applyPatternGradientTransform(t, s)) : t.fillStyle = s);
  }
  _setClippingProperties(t) {
    t.globalAlpha = 1, t.strokeStyle = "transparent", t.fillStyle = "#000000";
  }
  _setLineDash(t, e) {
    e && 0 !== e.length && (1 & e.length && e.push(...e), t.setLineDash(e));
  }
  _setShadow(t) {
    if (!this.shadow) return;
    const e = this.shadow,
      s = this.canvas,
      i = this.getCanvasRetinaScaling(),
      [r,,, n] = (null == s ? void 0 : s.viewportTransform) || T,
      a = r * i,
      h = n * i,
      c = e.nonScaling ? new ot(1, 1) : this.getObjectScaling();
    t.shadowColor = e.color, t.shadowBlur = e.blur * o.browserShadowBlurConstant * (a + h) * (c.x + c.y) / 4, t.shadowOffsetX = e.offsetX * a * c.x, t.shadowOffsetY = e.offsetY * h * c.y;
  }
  _removeShadow(t) {
    this.shadow && (t.shadowColor = "", t.shadowBlur = t.shadowOffsetX = t.shadowOffsetY = 0);
  }
  _applyPatternGradientTransform(t, e) {
    if (!Zt(e)) return {
      offsetX: 0,
      offsetY: 0
    };
    const s = e.gradientTransform || e.patternTransform,
      i = -this.width / 2 + e.offsetX || 0,
      r = -this.height / 2 + e.offsetY || 0;
    return "percentage" === e.gradientUnits ? t.transform(this.width, 0, 0, this.height, i, r) : t.transform(1, 0, 0, 1, i, r), s && t.transform(s[0], s[1], s[2], s[3], s[4], s[5]), {
      offsetX: i,
      offsetY: r
    };
  }
  _renderPaintInOrder(t) {
    this.paintFirst === J ? (this._renderStroke(t), this._renderFill(t)) : (this._renderFill(t), this._renderStroke(t));
  }
  _render(t) {}
  _renderFill(t) {
    this.fill && (t.save(), this._setFillStyles(t, this), "evenodd" === this.fillRule ? t.fill("evenodd") : t.fill(), t.restore());
  }
  _renderStroke(t) {
    if (this.stroke && 0 !== this.strokeWidth) {
      if (this.shadow && !this.shadow.affectStroke && this._removeShadow(t), t.save(), this.strokeUniform) {
        const e = this.getObjectScaling();
        t.scale(1 / e.x, 1 / e.y);
      }
      this._setLineDash(t, this.strokeDashArray), this._setStrokeStyles(t, this), t.stroke(), t.restore();
    }
  }
  _applyPatternForTransformedGradient(t, e) {
    var s;
    const i = this._limitCacheSize(this._getCacheCanvasDimensions()),
      r = pt(),
      n = this.getCanvasRetinaScaling(),
      o = i.x / this.scaleX / n,
      a = i.y / this.scaleY / n;
    r.width = Math.ceil(o), r.height = Math.ceil(a);
    const h = r.getContext("2d");
    h && (h.beginPath(), h.moveTo(0, 0), h.lineTo(o, 0), h.lineTo(o, a), h.lineTo(0, a), h.closePath(), h.translate(o / 2, a / 2), h.scale(i.zoomX / this.scaleX / n, i.zoomY / this.scaleY / n), this._applyPatternGradientTransform(h, e), h.fillStyle = e.toLive(t), h.fill(), t.translate(-this.width / 2 - this.strokeWidth / 2, -this.height / 2 - this.strokeWidth / 2), t.scale(n * this.scaleX / i.zoomX, n * this.scaleY / i.zoomY), t.strokeStyle = null !== (s = h.createPattern(r, "no-repeat")) && void 0 !== s ? s : "");
  }
  _findCenterFromElement() {
    return new ot(this.left + this.width / 2, this.top + this.height / 2);
  }
  clone(t) {
    const e = this.toObject(t);
    return this.constructor.fromObject(e);
  }
  cloneAsImage(t) {
    const e = this.toCanvasElement(t);
    return new (tt.getClass("image"))(e);
  }
  toCanvasElement() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    const e = Ce(this),
      s = this.group,
      i = this.shadow,
      r = Math.abs,
      n = t.enableRetinaScaling ? y() : 1,
      o = (t.multiplier || 1) * n,
      a = t.canvasProvider || (t => new ue(t, {
        enableRetinaScaling: !1,
        renderOnAddRemove: !1,
        skipOffscreen: !1
      }));
    delete this.group, t.withoutTransform && xe(this), t.withoutShadow && (this.shadow = null), t.viewportTransform && Oe(this, this.getViewportTransform()), this.setCoords();
    const h = pt(),
      c = this.getBoundingRect(),
      l = this.shadow,
      u = new ot();
    if (l) {
      const t = l.blur,
        e = l.nonScaling ? new ot(1, 1) : this.getObjectScaling();
      u.x = 2 * Math.round(r(l.offsetX) + t) * r(e.x), u.y = 2 * Math.round(r(l.offsetY) + t) * r(e.y);
    }
    const d = c.width + u.x,
      g = c.height + u.y;
    h.width = Math.ceil(d), h.height = Math.ceil(g);
    const f = a(h);
    "jpeg" === t.format && (f.backgroundColor = "#fff"), this.setPositionByOrigin(new ot(f.width / 2, f.height / 2), D, D);
    const p = this.canvas;
    f._objects = [this], this.set("canvas", f), this.setCoords();
    const m = f.toCanvasElement(o || 1, t);
    return this.set("canvas", p), this.shadow = i, s && (this.group = s), this.set(e), this.setCoords(), f._objects = [], f.destroy(), m;
  }
  toDataURL() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    return vt(this.toCanvasElement(t), t.format || "png", t.quality || 1);
  }
  isType() {
    for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
    return e.includes(this.constructor.type) || e.includes(this.type);
  }
  complexity() {
    return 1;
  }
  toJSON() {
    return this.toObject();
  }
  rotate(t) {
    const {
      centeredRotation: e,
      originX: s,
      originY: i
    } = this;
    if (e) {
      const {
        x: t,
        y: e
      } = this.getRelativeCenterPoint();
      this.originX = D, this.originY = D, this.left = t, this.top = e;
    }
    if (this.set("angle", t), e) {
      const {
        x: t,
        y: e
      } = this.translateToOriginPoint(this.getRelativeCenterPoint(), s, i);
      this.left = t, this.top = e, this.originX = s, this.originY = i;
    }
  }
  setOnGroup() {}
  _setupCompositeOperation(t) {
    this.globalCompositeOperation && (t.globalCompositeOperation = this.globalCompositeOperation);
  }
  dispose() {
    et.cancelByTarget(this), this.off(), this._set("canvas", void 0), this._cacheCanvas && p().dispose(this._cacheCanvas), this._cacheCanvas = void 0, this._cacheContext = null;
  }
  animate(t, e) {
    return Object.entries(t).reduce((t, s) => {
      let [i, r] = s;
      return t[i] = this._animate(i, r, e), t;
    }, {});
  }
  _animate(t, e) {
    let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
    const r = t.split("."),
      n = this.constructor.colorProperties.includes(r[r.length - 1]),
      {
        abort: o,
        startValue: a,
        onChange: h,
        onComplete: c
      } = i,
      l = s(s({}, i), {}, {
        target: this,
        startValue: null != a ? a : r.reduce((t, e) => t[e], this),
        endValue: e,
        abort: null == o ? void 0 : o.bind(this),
        onChange: (t, e, s) => {
          r.reduce((e, s, i) => (i === r.length - 1 && (e[s] = t), e[s]), this), h && h(t, e, s);
        },
        onComplete: (t, e, s) => {
          this.setCoords(), c && c(t, e, s);
        }
      });
    return n ? js(l) : As(l);
  }
  isDescendantOf(t) {
    const {
      parent: e,
      group: s
    } = this;
    return e === t || s === t || !!e && e.isDescendantOf(t) || !!s && s !== e && s.isDescendantOf(t);
  }
  getAncestors() {
    const t = [];
    let e = this;
    do {
      e = e.parent, e && t.push(e);
    } while (e);
    return t;
  }
  findCommonAncestors(t) {
    if (this === t) return {
      fork: [],
      otherFork: [],
      common: [this, ...this.getAncestors()]
    };
    const e = this.getAncestors(),
      s = t.getAncestors();
    if (0 === e.length && s.length > 0 && this === s[s.length - 1]) return {
      fork: [],
      otherFork: [t, ...s.slice(0, s.length - 1)],
      common: [this]
    };
    for (let i, r = 0; r < e.length; r++) {
      if (i = e[r], i === t) return {
        fork: [this, ...e.slice(0, r)],
        otherFork: [],
        common: e.slice(r)
      };
      for (let n = 0; n < s.length; n++) {
        if (this === s[n]) return {
          fork: [],
          otherFork: [t, ...s.slice(0, n)],
          common: [this, ...e]
        };
        if (i === s[n]) return {
          fork: [this, ...e.slice(0, r)],
          otherFork: [t, ...s.slice(0, n)],
          common: e.slice(r)
        };
      }
    }
    return {
      fork: [this, ...e],
      otherFork: [t, ...s],
      common: []
    };
  }
  hasCommonAncestors(t) {
    const e = this.findCommonAncestors(t);
    return e && !!e.common.length;
  }
  isInFrontOf(t) {
    if (this === t) return;
    const e = this.findCommonAncestors(t);
    if (e.fork.includes(t)) return !0;
    if (e.otherFork.includes(this)) return !1;
    const s = e.common[0] || this.canvas;
    if (!s) return;
    const i = e.fork.pop(),
      r = e.otherFork.pop(),
      n = s._objects.indexOf(i),
      o = s._objects.indexOf(r);
    return n > -1 && n > o;
  }
  toObject() {
    const t = (arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : []).concat(e.customProperties, this.constructor.customProperties || []);
    let i;
    const r = o.NUM_FRACTION_DIGITS,
      {
        clipPath: n,
        fill: a,
        stroke: h,
        shadow: c,
        strokeDashArray: l,
        left: u,
        top: d,
        originX: g,
        originY: f,
        width: p,
        height: m,
        strokeWidth: v,
        strokeLineCap: y,
        strokeDashOffset: _,
        strokeLineJoin: C,
        strokeUniform: b,
        strokeMiterLimit: w,
        scaleX: S,
        scaleY: T,
        angle: O,
        flipX: k,
        flipY: D,
        opacity: M,
        visible: P,
        backgroundColor: E,
        fillRule: A,
        paintFirst: j,
        globalCompositeOperation: F,
        skewX: L,
        skewY: R
      } = this;
    n && !n.excludeFromExport && (i = n.toObject(t.concat("inverted", "absolutePositioned")));
    const I = t => Ut(t, r),
      B = s(s({}, Bt(this, t)), {}, {
        type: this.constructor.type,
        version: x,
        originX: g,
        originY: f,
        left: I(u),
        top: I(d),
        width: I(p),
        height: I(m),
        fill: $t(a) ? a.toObject() : a,
        stroke: $t(h) ? h.toObject() : h,
        strokeWidth: I(v),
        strokeDashArray: l ? l.concat() : l,
        strokeLineCap: y,
        strokeDashOffset: _,
        strokeLineJoin: C,
        strokeUniform: b,
        strokeMiterLimit: I(w),
        scaleX: I(S),
        scaleY: I(T),
        angle: I(O),
        flipX: k,
        flipY: D,
        opacity: I(M),
        shadow: c ? c.toObject() : c,
        visible: P,
        backgroundColor: E,
        fillRule: A,
        paintFirst: j,
        globalCompositeOperation: F,
        skewX: I(L),
        skewY: I(R)
      }, i ? {
        clipPath: i
      } : null);
    return this.includeDefaultValues ? B : this._removeDefaultValues(B);
  }
  toDatalessObject(t) {
    return this.toObject(t);
  }
  _removeDefaultValues(t) {
    const e = this.constructor.getDefaults(),
      s = Object.keys(e).length > 0 ? e : Object.getPrototypeOf(this);
    return Xt(t, (t, e) => {
      if (e === M || e === P || "type" === e) return !0;
      const i = s[e];
      return t !== i && !(Array.isArray(t) && Array.isArray(i) && 0 === t.length && 0 === i.length);
    });
  }
  toString() {
    return "#<".concat(this.constructor.type, ">");
  }
  static _fromObject(t) {
    let e = i(t, Rs),
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      {
        extraParam: r
      } = s,
      n = i(s, Is);
    return It(e, n).then(t => r ? (delete t[r], new this(e[r], t)) : new this(t));
  }
  static fromObject(t, e) {
    return this._fromObject(t, e);
  }
};
t(Bs, "stateProperties", gs), t(Bs, "cacheProperties", fs), t(Bs, "ownDefaults", ps), t(Bs, "type", "FabricObject"), t(Bs, "colorProperties", [K, J, "backgroundColor"]), t(Bs, "customProperties", []), tt.setClass(Bs), tt.setClass(Bs, "object");
const Xs = (t, e, i) => (r, n, o, a) => {
  const h = e(r, n, o, a);
  return h && ke(t, s(s({}, Fe(r, n, o, a)), i)), h;
};
function Ws(t) {
  return (e, s, i, r) => {
    const {
        target: n,
        originX: o,
        originY: a
      } = s,
      h = n.getRelativeCenterPoint(),
      c = n.translateToOriginPoint(h, o, a),
      l = t(e, s, i, r);
    return n.setPositionByOrigin(c, s.originX, s.originY), l;
  };
}
const Ys = Xs(W, Ws((t, e, s, i) => {
  const r = Re(e, e.originX, e.originY, s, i);
  if (Me(e.originX) === Me(D) || Me(e.originX) === Me(A) && r.x < 0 || Me(e.originX) === Me(M) && r.x > 0) {
    const {
        target: t
      } = e,
      s = t.strokeWidth / (t.strokeUniform ? t.scaleX : 1),
      i = Ee(e) ? 2 : 1,
      n = t.width,
      o = Math.ceil(Math.abs(r.x * i / t.scaleX) - s);
    return t.set("width", Math.max(o, 0)), n !== t.width;
  }
  return !1;
}));
function Vs(t, e, s, i, r) {
  i = i || {};
  const n = this.sizeX || i.cornerSize || r.cornerSize,
    o = this.sizeY || i.cornerSize || r.cornerSize,
    a = void 0 !== i.transparentCorners ? i.transparentCorners : r.transparentCorners,
    h = a ? J : K,
    c = !a && (i.cornerStrokeColor || r.cornerStrokeColor);
  let l,
    u = e,
    d = s;
  t.save(), t.fillStyle = i.cornerColor || r.cornerColor || "", t.strokeStyle = i.cornerStrokeColor || r.cornerStrokeColor || "", n > o ? (l = n, t.scale(1, o / n), d = s * n / o) : o > n ? (l = o, t.scale(n / o, 1), u = e * o / n) : l = n, t.lineWidth = 1, t.beginPath(), t.arc(u, d, l / 2, 0, w, !1), t[h](), c && t.stroke(), t.restore();
}
function Hs(t, e, s, i, r) {
  i = i || {};
  const n = this.sizeX || i.cornerSize || r.cornerSize,
    o = this.sizeY || i.cornerSize || r.cornerSize,
    a = void 0 !== i.transparentCorners ? i.transparentCorners : r.transparentCorners,
    h = a ? J : K,
    c = !a && (i.cornerStrokeColor || r.cornerStrokeColor),
    l = n / 2,
    u = o / 2;
  t.save(), t.fillStyle = i.cornerColor || r.cornerColor || "", t.strokeStyle = i.cornerStrokeColor || r.cornerStrokeColor || "", t.lineWidth = 1, t.translate(e, s);
  const d = r.getTotalAngle();
  t.rotate(yt(d)), t["".concat(h, "Rect")](-l, -u, n, o), c && t.strokeRect(-l, -u, n, o), t.restore();
}
class zs {
  constructor(e) {
    t(this, "visible", !0), t(this, "actionName", z), t(this, "angle", 0), t(this, "x", 0), t(this, "y", 0), t(this, "offsetX", 0), t(this, "offsetY", 0), t(this, "sizeX", 0), t(this, "sizeY", 0), t(this, "touchSizeX", 0), t(this, "touchSizeY", 0), t(this, "cursorStyle", "crosshair"), t(this, "withConnection", !1), Object.assign(this, e);
  }
  shouldActivate(t, e, s, i) {
    var r;
    let {
      tl: n,
      tr: o,
      br: a,
      bl: h
    } = i;
    return (null === (r = e.canvas) || void 0 === r ? void 0 : r.getActiveObject()) === e && e.isControlVisible(t) && Fs.isPointInPolygon(s, [n, o, a, h]);
  }
  getActionHandler(t, e, s) {
    return this.actionHandler;
  }
  getMouseDownHandler(t, e, s) {
    return this.mouseDownHandler;
  }
  getMouseUpHandler(t, e, s) {
    return this.mouseUpHandler;
  }
  cursorStyleHandler(t, e, s) {
    return e.cursorStyle;
  }
  getActionName(t, e, s) {
    return e.actionName;
  }
  getVisibility(t, e) {
    var s, i;
    return null !== (s = null === (i = t._controlsVisibility) || void 0 === i ? void 0 : i[e]) && void 0 !== s ? s : this.visible;
  }
  setVisibility(t, e, s) {
    this.visible = t;
  }
  positionHandler(t, e, s, i) {
    return new ot(this.x * t.x + this.offsetX, this.y * t.y + this.offsetY).transform(e);
  }
  calcCornerCoords(t, e, s, i, r, n) {
    const o = St([kt(s, i), Dt({
      angle: t
    }), Mt((r ? this.touchSizeX : this.sizeX) || e, (r ? this.touchSizeY : this.sizeY) || e)]);
    return {
      tl: new ot(-.5, -.5).transform(o),
      tr: new ot(.5, -.5).transform(o),
      br: new ot(.5, .5).transform(o),
      bl: new ot(-.5, .5).transform(o)
    };
  }
  render(t, e, s, i, r) {
    if ("circle" === ((i = i || {}).cornerStyle || r.cornerStyle)) Vs.call(this, t, e, s, i, r);else Hs.call(this, t, e, s, i, r);
  }
}
const Gs = (t, e, s) => s.lockRotation ? Pe : e.cursorStyle,
  Ns = Xs(I, Ws((t, e, s, i) => {
    let {
      target: r,
      ex: n,
      ey: o,
      theta: a,
      originX: h,
      originY: c
    } = e;
    const l = r.translateToOriginPoint(r.getRelativeCenterPoint(), h, c);
    if (je(r, "lockRotation")) return !1;
    const u = Math.atan2(o - l.y, n - l.x),
      d = Math.atan2(i - l.y, s - l.x);
    let g = _t(d - u + a);
    if (r.snapAngle && r.snapAngle > 0) {
      const t = r.snapAngle,
        e = r.snapThreshold || t,
        s = Math.ceil(g / t) * t,
        i = Math.floor(g / t) * t;
      Math.abs(g - i) < e ? g = i : Math.abs(g - s) < e && (g = s);
    }
    g < 0 && (g = 360 + g), g %= 360;
    const f = r.angle !== g;
    return r.angle = g, f;
  }));
function Us(t, e) {
  const s = e.canvas,
    i = t[s.uniScaleKey];
  return s.uniformScaling && !i || !s.uniformScaling && i;
}
function qs(t, e, s) {
  const i = je(t, "lockScalingX"),
    r = je(t, "lockScalingY");
  if (i && r) return !0;
  if (!e && (i || r) && s) return !0;
  if (i && "x" === e) return !0;
  if (r && "y" === e) return !0;
  const {
    width: n,
    height: o,
    strokeWidth: a
  } = t;
  return 0 === n && 0 === a && "y" !== e || 0 === o && 0 === a && "x" !== e;
}
const Ks = ["e", "se", "s", "sw", "w", "nw", "n", "ne", "e"],
  Js = (t, e, s) => {
    const i = Us(t, s);
    if (qs(s, 0 !== e.x && 0 === e.y ? "x" : 0 === e.x && 0 !== e.y ? "y" : "", i)) return Pe;
    const r = Le(s, e);
    return "".concat(Ks[r], "-resize");
  };
function Qs(t, e, s, i) {
  let r = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : {};
  const n = e.target,
    o = r.by,
    a = Us(t, n);
  let h, c, l, u, d, g;
  if (qs(n, o, a)) return !1;
  if (e.gestureScale) c = e.scaleX * e.gestureScale, l = e.scaleY * e.gestureScale;else {
    if (h = Re(e, e.originX, e.originY, s, i), d = "y" !== o ? Math.sign(h.x || e.signX || 1) : 1, g = "x" !== o ? Math.sign(h.y || e.signY || 1) : 1, e.signX || (e.signX = d), e.signY || (e.signY = g), je(n, "lockScalingFlip") && (e.signX !== d || e.signY !== g)) return !1;
    if (u = n._getTransformedDimensions(), a && !o) {
      const t = Math.abs(h.x) + Math.abs(h.y),
        {
          original: s
        } = e,
        i = t / (Math.abs(u.x * s.scaleX / n.scaleX) + Math.abs(u.y * s.scaleY / n.scaleY));
      c = s.scaleX * i, l = s.scaleY * i;
    } else c = Math.abs(h.x * n.scaleX / u.x), l = Math.abs(h.y * n.scaleY / u.y);
    Ee(e) && (c *= 2, l *= 2), e.signX !== d && "y" !== o && (e.originX = Ae(e.originX), c *= -1, e.signX = d), e.signY !== g && "x" !== o && (e.originY = Ae(e.originY), l *= -1, e.signY = g);
  }
  const f = n.scaleX,
    p = n.scaleY;
  return o ? ("x" === o && n.set(G, c), "y" === o && n.set(N, l)) : (!je(n, "lockScalingX") && n.set(G, c), !je(n, "lockScalingY") && n.set(N, l)), f !== n.scaleX || p !== n.scaleY;
}
const Zs = Xs(R, Ws((t, e, s, i) => Qs(t, e, s, i))),
  $s = Xs(R, Ws((t, e, s, i) => Qs(t, e, s, i, {
    by: "x"
  }))),
  ti = Xs(R, Ws((t, e, s, i) => Qs(t, e, s, i, {
    by: "y"
  }))),
  ei = ["target", "ex", "ey", "skewingSide"],
  si = {
    x: {
      counterAxis: "y",
      scale: G,
      skew: U,
      lockSkewing: "lockSkewingX",
      origin: "originX",
      flip: "flipX"
    },
    y: {
      counterAxis: "x",
      scale: N,
      skew: q,
      lockSkewing: "lockSkewingY",
      origin: "originY",
      flip: "flipY"
    }
  },
  ii = ["ns", "nesw", "ew", "nwse"],
  ri = (t, e, s) => {
    if (0 !== e.x && je(s, "lockSkewingY")) return Pe;
    if (0 !== e.y && je(s, "lockSkewingX")) return Pe;
    const i = Le(s, e) % 4;
    return "".concat(ii[i], "-resize");
  };
function ni(t, e, r, n, o) {
  const {
      target: a
    } = r,
    {
      counterAxis: h,
      origin: c,
      lockSkewing: l,
      skew: u,
      flip: d
    } = si[t];
  if (je(a, l)) return !1;
  const {
      origin: g,
      flip: f
    } = si[h],
    p = Me(r[g]) * (a[f] ? -1 : 1),
    m = -Math.sign(p) * (a[d] ? -1 : 1),
    v = .5 * -((0 === a[u] && Re(r, D, D, n, o)[t] > 0 || a[u] > 0 ? 1 : -1) * m) + .5,
    y = Xs(X, Ws((e, s, r, n) => function (t, e, s) {
      let {
          target: r,
          ex: n,
          ey: o,
          skewingSide: a
        } = e,
        h = i(e, ei);
      const {
          skew: c
        } = si[t],
        l = s.subtract(new ot(n, o)).divide(new ot(r.scaleX, r.scaleY))[t],
        u = r[c],
        d = h[c],
        g = Math.tan(yt(d)),
        f = "y" === t ? r._getTransformedDimensions({
          scaleX: 1,
          scaleY: 1,
          skewX: 0
        }).x : r._getTransformedDimensions({
          scaleX: 1,
          scaleY: 1
        }).y,
        p = 2 * l * a / Math.max(f, 1) + g,
        m = _t(Math.atan(p));
      r.set(c, m);
      const v = u !== r[c];
      if (v && "y" === t) {
        const {
            skewX: t,
            scaleX: e
          } = r,
          s = r._getTransformedDimensions({
            skewY: u
          }),
          i = r._getTransformedDimensions(),
          n = 0 !== t ? s.x / i.x : 1;
        1 !== n && r.set(G, n * e);
      }
      return v;
    }(t, s, new ot(r, n))));
  return y(e, s(s({}, r), {}, {
    [c]: v,
    skewingSide: m
  }), n, o);
}
const oi = (t, e, s, i) => ni("x", t, e, s, i),
  ai = (t, e, s, i) => ni("y", t, e, s, i);
function hi(t, e) {
  return t[e.canvas.altActionKey];
}
const ci = (t, e, s) => {
    const i = hi(t, s);
    return 0 === e.x ? i ? U : N : 0 === e.y ? i ? q : G : "";
  },
  li = (t, e, s) => hi(t, s) ? ri(0, e, s) : Js(t, e, s),
  ui = (t, e, s, i) => hi(t, e.target) ? ai(t, e, s, i) : $s(t, e, s, i),
  di = (t, e, s, i) => hi(t, e.target) ? oi(t, e, s, i) : ti(t, e, s, i),
  gi = () => ({
    ml: new zs({
      x: -.5,
      y: 0,
      cursorStyleHandler: li,
      actionHandler: ui,
      getActionName: ci
    }),
    mr: new zs({
      x: .5,
      y: 0,
      cursorStyleHandler: li,
      actionHandler: ui,
      getActionName: ci
    }),
    mb: new zs({
      x: 0,
      y: .5,
      cursorStyleHandler: li,
      actionHandler: di,
      getActionName: ci
    }),
    mt: new zs({
      x: 0,
      y: -.5,
      cursorStyleHandler: li,
      actionHandler: di,
      getActionName: ci
    }),
    tl: new zs({
      x: -.5,
      y: -.5,
      cursorStyleHandler: Js,
      actionHandler: Zs
    }),
    tr: new zs({
      x: .5,
      y: -.5,
      cursorStyleHandler: Js,
      actionHandler: Zs
    }),
    bl: new zs({
      x: -.5,
      y: .5,
      cursorStyleHandler: Js,
      actionHandler: Zs
    }),
    br: new zs({
      x: .5,
      y: .5,
      cursorStyleHandler: Js,
      actionHandler: Zs
    }),
    mtr: new zs({
      x: 0,
      y: -.5,
      actionHandler: Ns,
      cursorStyleHandler: Gs,
      offsetY: -40,
      withConnection: !0,
      actionName: B
    })
  }),
  fi = () => ({
    mr: new zs({
      x: .5,
      y: 0,
      actionHandler: Ys,
      cursorStyleHandler: li,
      actionName: W
    }),
    ml: new zs({
      x: -.5,
      y: 0,
      actionHandler: Ys,
      cursorStyleHandler: li,
      actionName: W
    })
  }),
  pi = () => s(s({}, gi()), fi());
class mi extends Bs {
  static getDefaults() {
    return s(s({}, super.getDefaults()), mi.ownDefaults);
  }
  constructor(t) {
    super(), Object.assign(this, this.constructor.createControls(), mi.ownDefaults), this.setOptions(t);
  }
  static createControls() {
    return {
      controls: gi()
    };
  }
  _updateCacheCanvas() {
    const t = this.canvas;
    if (this.noScaleCache && t && t._currentTransform) {
      const e = t._currentTransform,
        s = e.target,
        i = e.action;
      if (this === s && i && i.startsWith(z)) return !1;
    }
    return super._updateCacheCanvas();
  }
  getActiveControl() {
    const t = this.__corner;
    return t ? {
      key: t,
      control: this.controls[t],
      coord: this.oCoords[t]
    } : void 0;
  }
  findControl(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (!this.hasControls || !this.canvas) return;
    this.__corner = void 0;
    const s = Object.entries(this.oCoords);
    for (let i = s.length - 1; i >= 0; i--) {
      const [r, n] = s[i],
        o = this.controls[r];
      if (o.shouldActivate(r, this, t, e ? n.touchCorner : n.corner)) return this.__corner = r, {
        key: r,
        control: o,
        coord: this.oCoords[r]
      };
    }
  }
  calcOCoords() {
    const t = this.getViewportTransform(),
      e = this.getCenterPoint(),
      s = kt(e.x, e.y),
      i = Dt({
        angle: this.getTotalAngle() - (this.group && this.flipX ? 180 : 0)
      }),
      r = wt(s, i),
      n = wt(t, r),
      o = wt(n, [1 / t[0], 0, 0, 1 / t[3], 0, 0]),
      a = this.group ? Ot(this.calcTransformMatrix()) : void 0;
    a && (a.scaleX = Math.abs(a.scaleX), a.scaleY = Math.abs(a.scaleY));
    const h = this._calculateCurrentDimensions(a),
      c = {};
    return this.forEachControl((t, e) => {
      const s = t.positionHandler(h, o, this, t);
      c[e] = Object.assign(s, this._calcCornerCoords(t, s));
    }), c;
  }
  _calcCornerCoords(t, e) {
    const s = this.getTotalAngle();
    return {
      corner: t.calcCornerCoords(s, this.cornerSize, e.x, e.y, !1, this),
      touchCorner: t.calcCornerCoords(s, this.touchCornerSize, e.x, e.y, !0, this)
    };
  }
  setCoords() {
    super.setCoords(), this.canvas && (this.oCoords = this.calcOCoords());
  }
  forEachControl(t) {
    for (const e in this.controls) t(this.controls[e], e, this);
  }
  drawSelectionBackground(t) {
    if (!this.selectionBackgroundColor || this.canvas && this.canvas._activeObject !== this) return;
    t.save();
    const e = this.getRelativeCenterPoint(),
      s = this._calculateCurrentDimensions(),
      i = this.getViewportTransform();
    t.translate(e.x, e.y), t.scale(1 / i[0], 1 / i[3]), t.rotate(yt(this.angle)), t.fillStyle = this.selectionBackgroundColor, t.fillRect(-s.x / 2, -s.y / 2, s.x, s.y), t.restore();
  }
  strokeBorders(t, e) {
    t.strokeRect(-e.x / 2, -e.y / 2, e.x, e.y);
  }
  _drawBorders(t, e) {
    let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
    const r = s({
      hasControls: this.hasControls,
      borderColor: this.borderColor,
      borderDashArray: this.borderDashArray
    }, i);
    t.save(), t.strokeStyle = r.borderColor, this._setLineDash(t, r.borderDashArray), this.strokeBorders(t, e), r.hasControls && this.drawControlsConnectingLines(t, e), t.restore();
  }
  _renderControls(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const {
        hasBorders: i,
        hasControls: r
      } = this,
      n = s({
        hasBorders: i,
        hasControls: r
      }, e),
      o = this.getViewportTransform(),
      a = n.hasBorders,
      h = n.hasControls,
      c = wt(o, this.calcTransformMatrix()),
      l = Ot(c);
    t.save(), t.translate(l.translateX, l.translateY), t.lineWidth = 1 * this.borderScaleFactor, this.group === this.parent && (t.globalAlpha = this.isMoving ? this.borderOpacityWhenMoving : 1), this.flipX && (l.angle -= 180), t.rotate(yt(this.group ? l.angle : this.angle)), a && this.drawBorders(t, l, e), h && this.drawControls(t, e), t.restore();
  }
  drawBorders(t, e, s) {
    let i;
    if (s && s.forActiveSelection || this.group) {
      const t = be(this.width, this.height, jt(e)),
        s = this.isStrokeAccountedForInDimensions() ? at : (this.strokeUniform ? new ot().scalarAdd(this.canvas ? this.canvas.getZoom() : 1) : new ot(e.scaleX, e.scaleY)).scalarMultiply(this.strokeWidth);
      i = t.add(s).scalarAdd(this.borderScaleFactor).scalarAdd(2 * this.padding);
    } else i = this._calculateCurrentDimensions().scalarAdd(this.borderScaleFactor);
    this._drawBorders(t, i, s);
  }
  drawControlsConnectingLines(t, e) {
    let s = !1;
    t.beginPath(), this.forEachControl((i, r) => {
      i.withConnection && i.getVisibility(this, r) && (s = !0, t.moveTo(i.x * e.x, i.y * e.y), t.lineTo(i.x * e.x + i.offsetX, i.y * e.y + i.offsetY));
    }), s && t.stroke();
  }
  drawControls(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    t.save();
    const i = this.getCanvasRetinaScaling(),
      {
        cornerStrokeColor: r,
        cornerDashArray: n,
        cornerColor: o
      } = this,
      a = s({
        cornerStrokeColor: r,
        cornerDashArray: n,
        cornerColor: o
      }, e);
    t.setTransform(i, 0, 0, i, 0, 0), t.strokeStyle = t.fillStyle = a.cornerColor, this.transparentCorners || (t.strokeStyle = a.cornerStrokeColor), this._setLineDash(t, a.cornerDashArray), this.forEachControl((e, s) => {
      if (e.getVisibility(this, s)) {
        const i = this.oCoords[s];
        e.render(t, i.x, i.y, a, this);
      }
    }), t.restore();
  }
  isControlVisible(t) {
    return this.controls[t] && this.controls[t].getVisibility(this, t);
  }
  setControlVisible(t, e) {
    this._controlsVisibility || (this._controlsVisibility = {}), this._controlsVisibility[t] = e;
  }
  setControlsVisibility() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    Object.entries(t).forEach(t => {
      let [e, s] = t;
      return this.setControlVisible(e, s);
    });
  }
  clearContextTop(t) {
    if (!this.canvas) return;
    const e = this.canvas.contextTop;
    if (!e) return;
    const s = this.canvas.viewportTransform;
    e.save(), e.transform(s[0], s[1], s[2], s[3], s[4], s[5]), this.transform(e);
    const i = this.width + 4,
      r = this.height + 4;
    return e.clearRect(-i / 2, -r / 2, i, r), t || e.restore(), e;
  }
  onDeselect(t) {
    return !1;
  }
  onSelect(t) {
    return !1;
  }
  shouldStartDragging(t) {
    return !1;
  }
  onDragStart(t) {
    return !1;
  }
  canDrop(t) {
    return !1;
  }
  renderDragSourceEffect(t) {}
  renderDropTargetEffect(t) {}
}
function vi(t, e) {
  return e.forEach(e => {
    Object.getOwnPropertyNames(e.prototype).forEach(s => {
      "constructor" !== s && Object.defineProperty(t.prototype, s, Object.getOwnPropertyDescriptor(e.prototype, s) || Object.create(null));
    });
  }), t;
}
t(mi, "ownDefaults", {
  noScaleCache: !0,
  lockMovementX: !1,
  lockMovementY: !1,
  lockRotation: !1,
  lockScalingX: !1,
  lockScalingY: !1,
  lockSkewingX: !1,
  lockSkewingY: !1,
  lockScalingFlip: !1,
  cornerSize: 13,
  touchCornerSize: 24,
  transparentCorners: !0,
  cornerColor: "rgb(178,204,255)",
  cornerStrokeColor: "",
  cornerStyle: "rect",
  cornerDashArray: null,
  hasControls: !0,
  borderColor: "rgb(178,204,255)",
  borderDashArray: null,
  borderOpacityWhenMoving: .4,
  borderScaleFactor: 1,
  hasBorders: !0,
  selectionBackgroundColor: "",
  selectable: !0,
  evented: !0,
  perPixelTargetFind: !1,
  activeOn: "down",
  hoverCursor: null,
  moveCursor: null
});
class yi extends mi {}
vi(yi, [Be]), tt.setClass(yi), tt.setClass(yi, "object");
const _i = (t, e, s, i) => {
  const r = 2 * (i = Math.round(i)) + 1,
    {
      data: n
    } = t.getImageData(e - i, s - i, r, r);
  for (let t = 3; t < n.length; t += 4) {
    if (n[t] > 0) return !1;
  }
  return !0;
};
class xi {
  constructor(t) {
    this.options = t, this.strokeProjectionMagnitude = this.options.strokeWidth / 2, this.scale = new ot(this.options.scaleX, this.options.scaleY), this.strokeUniformScalar = this.options.strokeUniform ? new ot(1 / this.options.scaleX, 1 / this.options.scaleY) : new ot(1, 1);
  }
  createSideVector(t, e) {
    const s = ts(t, e);
    return this.options.strokeUniform ? s.multiply(this.scale) : s;
  }
  projectOrthogonally(t, e, s) {
    return this.applySkew(t.add(this.calcOrthogonalProjection(t, e, s)));
  }
  isSkewed() {
    return 0 !== this.options.skewX || 0 !== this.options.skewY;
  }
  applySkew(t) {
    const e = new ot(t);
    return e.y += e.x * Math.tan(yt(this.options.skewY)), e.x += e.y * Math.tan(yt(this.options.skewX)), e;
  }
  scaleUnitVector(t, e) {
    return t.multiply(this.strokeUniformScalar).scalarMultiply(e);
  }
}
const Ci = new ot();
class bi extends xi {
  static getOrthogonalRotationFactor(t, e) {
    const s = e ? ss(t, e) : is(t);
    return Math.abs(s) < b ? -1 : 1;
  }
  constructor(e, s, i, r) {
    super(r), t(this, "AB", void 0), t(this, "AC", void 0), t(this, "alpha", void 0), t(this, "bisector", void 0), this.A = new ot(e), this.B = new ot(s), this.C = new ot(i), this.AB = this.createSideVector(this.A, this.B), this.AC = this.createSideVector(this.A, this.C), this.alpha = ss(this.AB, this.AC), this.bisector = rs($e(this.AB.eq(Ci) ? this.AC : this.AB, this.alpha / 2));
  }
  calcOrthogonalProjection(t, e) {
    let s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.strokeProjectionMagnitude;
    const i = this.createSideVector(t, e),
      r = ns(i),
      n = bi.getOrthogonalRotationFactor(r, this.bisector);
    return this.scaleUnitVector(r, s * n);
  }
  projectBevel() {
    const t = [];
    return (this.alpha % w == 0 ? [this.B] : [this.B, this.C]).forEach(e => {
      t.push(this.projectOrthogonally(this.A, e)), t.push(this.projectOrthogonally(this.A, e, -this.strokeProjectionMagnitude));
    }), t;
  }
  projectMiter() {
    const t = [],
      e = Math.abs(this.alpha),
      s = 1 / Math.sin(e / 2),
      i = this.scaleUnitVector(this.bisector, -this.strokeProjectionMagnitude * s),
      r = this.options.strokeUniform ? es(this.scaleUnitVector(this.bisector, this.options.strokeMiterLimit)) : this.options.strokeMiterLimit;
    return es(i) / this.strokeProjectionMagnitude <= r && t.push(this.applySkew(this.A.add(i))), t.push(...this.projectBevel()), t;
  }
  projectRoundNoSkew(t, e) {
    const s = [],
      i = new ot(bi.getOrthogonalRotationFactor(this.bisector), bi.getOrthogonalRotationFactor(new ot(this.bisector.y, this.bisector.x)));
    return [new ot(1, 0).scalarMultiply(this.strokeProjectionMagnitude).multiply(this.strokeUniformScalar).multiply(i), new ot(0, 1).scalarMultiply(this.strokeProjectionMagnitude).multiply(this.strokeUniformScalar).multiply(i)].forEach(i => {
      hs(i, t, e) && s.push(this.A.add(i));
    }), s;
  }
  projectRoundWithSkew(t, e) {
    const s = [],
      {
        skewX: i,
        skewY: r,
        scaleX: n,
        scaleY: o,
        strokeUniform: a
      } = this.options,
      h = new ot(Math.tan(yt(i)), Math.tan(yt(r))),
      c = this.strokeProjectionMagnitude,
      l = a ? c / o / Math.sqrt(1 / o ** 2 + 1 / n ** 2 * h.y ** 2) : c / Math.sqrt(1 + h.y ** 2),
      u = new ot(Math.sqrt(Math.max(c ** 2 - l ** 2, 0)), l),
      d = a ? c / Math.sqrt(1 + h.x ** 2 * (1 / o) ** 2 / (1 / n + 1 / n * h.x * h.y) ** 2) : c / Math.sqrt(1 + h.x ** 2 / (1 + h.x * h.y) ** 2),
      g = new ot(d, Math.sqrt(Math.max(c ** 2 - d ** 2, 0)));
    return [g, g.scalarMultiply(-1), u, u.scalarMultiply(-1)].map(t => this.applySkew(a ? t.multiply(this.strokeUniformScalar) : t)).forEach(i => {
      hs(i, t, e) && s.push(this.applySkew(this.A).add(i));
    }), s;
  }
  projectRound() {
    const t = [];
    t.push(...this.projectBevel());
    const e = this.alpha % w == 0,
      s = this.applySkew(this.A),
      i = t[e ? 0 : 2].subtract(s),
      r = t[e ? 1 : 0].subtract(s),
      n = e ? this.applySkew(this.AB.scalarMultiply(-1)) : this.applySkew(this.bisector.multiply(this.strokeUniformScalar).scalarMultiply(-1)),
      o = os(i, n) > 0,
      a = o ? i : r,
      h = o ? r : i;
    return this.isSkewed() ? t.push(...this.projectRoundWithSkew(a, h)) : t.push(...this.projectRoundNoSkew(a, h)), t;
  }
  projectPoints() {
    switch (this.options.strokeLineJoin) {
      case "miter":
        return this.projectMiter();
      case "round":
        return this.projectRound();
      default:
        return this.projectBevel();
    }
  }
  project() {
    return this.projectPoints().map(t => ({
      originPoint: this.A,
      projectedPoint: t,
      angle: this.alpha,
      bisector: this.bisector
    }));
  }
}
class wi extends xi {
  constructor(t, e, s) {
    super(s), this.A = new ot(t), this.T = new ot(e);
  }
  calcOrthogonalProjection(t, e) {
    let s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.strokeProjectionMagnitude;
    const i = this.createSideVector(t, e);
    return this.scaleUnitVector(ns(i), s);
  }
  projectButt() {
    return [this.projectOrthogonally(this.A, this.T, this.strokeProjectionMagnitude), this.projectOrthogonally(this.A, this.T, -this.strokeProjectionMagnitude)];
  }
  projectRound() {
    const t = [];
    if (!this.isSkewed() && this.A.eq(this.T)) {
      const e = new ot(1, 1).scalarMultiply(this.strokeProjectionMagnitude).multiply(this.strokeUniformScalar);
      t.push(this.applySkew(this.A.add(e)), this.applySkew(this.A.subtract(e)));
    } else t.push(...new bi(this.A, this.T, this.T, this.options).projectRound());
    return t;
  }
  projectSquare() {
    const t = [];
    if (this.A.eq(this.T)) {
      const e = new ot(1, 1).scalarMultiply(this.strokeProjectionMagnitude).multiply(this.strokeUniformScalar);
      t.push(this.A.add(e), this.A.subtract(e));
    } else {
      const e = this.calcOrthogonalProjection(this.A, this.T, this.strokeProjectionMagnitude),
        s = this.scaleUnitVector(rs(this.createSideVector(this.A, this.T)), -this.strokeProjectionMagnitude),
        i = this.A.add(s);
      t.push(i.add(e), i.subtract(e));
    }
    return t.map(t => this.applySkew(t));
  }
  projectPoints() {
    switch (this.options.strokeLineCap) {
      case "round":
        return this.projectRound();
      case "square":
        return this.projectSquare();
      default:
        return this.projectButt();
    }
  }
  project() {
    return this.projectPoints().map(t => ({
      originPoint: this.A,
      projectedPoint: t
    }));
  }
}
const Si = function (t, e) {
    let s = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
    const i = [];
    if (0 === t.length) return i;
    const r = t.reduce((t, e) => (t[t.length - 1].eq(e) || t.push(new ot(e)), t), [new ot(t[0])]);
    if (1 === r.length) s = !0;else if (!s) {
      const t = r[0],
        e = ((t, e) => {
          for (let s = t.length - 1; s >= 0; s--) if (e(t[s], s, t)) return s;
          return -1;
        })(r, e => !e.eq(t));
      r.splice(e + 1);
    }
    return r.forEach((t, r, n) => {
      let o, a;
      0 === r ? (a = n[1], o = s ? t : n[n.length - 1]) : r === n.length - 1 ? (o = n[r - 1], a = s ? t : n[0]) : (o = n[r - 1], a = n[r + 1]), s && 1 === n.length ? i.push(...new wi(t, t, e).project()) : !s || 0 !== r && r !== n.length - 1 ? i.push(...new bi(t, o, a, e).project()) : i.push(...new wi(t, 0 === r ? a : o, e).project());
    }), i;
  },
  Ti = t => {
    const e = {};
    return Object.keys(t).forEach(i => {
      e[i] = {}, Object.keys(t[i]).forEach(r => {
        e[i][r] = s({}, t[i][r]);
      });
    }), e;
  },
  Oi = t => t.replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/'/g, "&apos;").replace(/</g, "&lt;").replace(/>/g, "&gt;"),
  ki = t => {
    const e = [];
    for (let s, i = 0; i < t.length; i++) !1 !== (s = Di(t, i)) && e.push(s);
    return e;
  },
  Di = (t, e) => {
    const s = t.charCodeAt(e);
    if (isNaN(s)) return "";
    if (s < 55296 || s > 57343) return t.charAt(e);
    if (55296 <= s && s <= 56319) {
      if (t.length <= e + 1) throw "High surrogate without following low surrogate";
      const s = t.charCodeAt(e + 1);
      if (56320 > s || s > 57343) throw "High surrogate without following low surrogate";
      return t.charAt(e) + t.charAt(e + 1);
    }
    if (0 === e) throw "Low surrogate without preceding high surrogate";
    const i = t.charCodeAt(e - 1);
    if (55296 > i || i > 56319) throw "Low surrogate without preceding high surrogate";
    return !1;
  };
var Mi = Object.freeze({
  __proto__: null,
  capitalize: function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return "".concat(t.charAt(0).toUpperCase()).concat(e ? t.slice(1) : t.slice(1).toLowerCase());
  },
  escapeXml: Oi,
  graphemeSplit: ki
});
const Pi = function (t, e) {
    let s = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
    return t.fill !== e.fill || t.stroke !== e.stroke || t.strokeWidth !== e.strokeWidth || t.fontSize !== e.fontSize || t.fontFamily !== e.fontFamily || t.fontWeight !== e.fontWeight || t.fontStyle !== e.fontStyle || t.textBackgroundColor !== e.textBackgroundColor || t.deltaY !== e.deltaY || s && (t.overline !== e.overline || t.underline !== e.underline || t.linethrough !== e.linethrough);
  },
  Ei = (t, e) => {
    const s = e.split("\n"),
      i = [];
    let r = -1,
      n = {};
    t = Ti(t);
    for (let e = 0; e < s.length; e++) {
      const o = ki(s[e]);
      if (t[e]) for (let s = 0; s < o.length; s++) {
        r++;
        const o = t[e][s];
        o && Object.keys(o).length > 0 && (Pi(n, o, !0) ? i.push({
          start: r,
          end: r + 1,
          style: o
        }) : i[i.length - 1].end++), n = o || {};
      } else r += o.length, n = {};
    }
    return i;
  },
  Ai = (t, e) => {
    if (!Array.isArray(t)) return Ti(t);
    const i = e.split(F),
      r = {};
    let n = -1,
      o = 0;
    for (let e = 0; e < i.length; e++) {
      const a = ki(i[e]);
      for (let i = 0; i < a.length; i++) n++, t[o] && t[o].start <= n && n < t[o].end && (r[e] = r[e] || {}, r[e][i] = s({}, t[o].style), n === t[o].end - 1 && o++);
    }
    return r;
  },
  ji = ["display", "transform", K, "fill-opacity", "fill-rule", "opacity", J, "stroke-dasharray", "stroke-linecap", "stroke-dashoffset", "stroke-linejoin", "stroke-miterlimit", "stroke-opacity", "stroke-width", "id", "paint-order", "vector-effect", "instantiated_by_use", "clip-path"];
function Fi(t, e) {
  const s = t.nodeName,
    i = t.getAttribute("class"),
    r = t.getAttribute("id"),
    n = "(?![a-zA-Z\\-]+)";
  let o;
  if (o = new RegExp("^" + s, "i"), e = e.replace(o, ""), r && e.length && (o = new RegExp("#" + r + n, "i"), e = e.replace(o, "")), i && e.length) {
    const t = i.split(" ");
    for (let s = t.length; s--;) o = new RegExp("\\." + t[s] + n, "i"), e = e.replace(o, "");
  }
  return 0 === e.length;
}
function Li(t, e) {
  let s = !0;
  const i = Fi(t, e.pop());
  return i && e.length && (s = function (t, e) {
    let s,
      i = !0;
    for (; t.parentElement && 1 === t.parentElement.nodeType && e.length;) i && (s = e.pop()), i = Fi(t = t.parentElement, s);
    return 0 === e.length;
  }(t, e)), i && s && 0 === e.length;
}
const Ri = t => {
    var e;
    return null !== (e = ze[t]) && void 0 !== e ? e : t;
  },
  Ii = new RegExp("(".concat(Ye, ")"), "gi"),
  Bi = t => t.replace(Ii, " $1 ").replace(/,/gi, " ").replace(/\s+/gi, " ");
var Xi, Wi, Yi, Vi, Hi, zi, Gi;
const Ni = "(".concat(Ye, ")"),
  Ui = String.raw(Xi || (Xi = r(["(skewX)(", ")"], ["(skewX)\\(", "\\)"])), Ni),
  qi = String.raw(Wi || (Wi = r(["(skewY)(", ")"], ["(skewY)\\(", "\\)"])), Ni),
  Ki = String.raw(Yi || (Yi = r(["(rotate)(", "(?: ", " ", ")?)"], ["(rotate)\\(", "(?: ", " ", ")?\\)"])), Ni, Ni, Ni),
  Ji = String.raw(Vi || (Vi = r(["(scale)(", "(?: ", ")?)"], ["(scale)\\(", "(?: ", ")?\\)"])), Ni, Ni),
  Qi = String.raw(Hi || (Hi = r(["(translate)(", "(?: ", ")?)"], ["(translate)\\(", "(?: ", ")?\\)"])), Ni, Ni),
  Zi = String.raw(zi || (zi = r(["(matrix)(", " ", " ", " ", " ", " ", ")"], ["(matrix)\\(", " ", " ", " ", " ", " ", "\\)"])), Ni, Ni, Ni, Ni, Ni, Ni),
  $i = "(?:".concat(Zi, "|").concat(Qi, "|").concat(Ki, "|").concat(Ji, "|").concat(Ui, "|").concat(qi, ")"),
  tr = "(?:".concat($i, "*)"),
  er = String.raw(Gi || (Gi = r(["^s*(?:", "?)s*$"], ["^\\s*(?:", "?)\\s*$"])), tr),
  sr = new RegExp(er),
  ir = new RegExp($i),
  rr = new RegExp($i, "g");
function nr(t) {
  const e = [];
  if (!(t = Bi(t).replace(/\s*([()])\s*/gi, "$1")) || t && !sr.test(t)) return [...T];
  for (const s of t.matchAll(rr)) {
    const t = ir.exec(s[0]);
    if (!t) continue;
    let i = T;
    const r = t.filter(t => !!t),
      [, n, ...o] = r,
      [a, h, c, l, u, d] = o.map(t => parseFloat(t));
    switch (n) {
      case "translate":
        i = kt(a, h);
        break;
      case B:
        i = Dt({
          angle: a
        }, {
          x: h,
          y: c
        });
        break;
      case z:
        i = Mt(a, h);
        break;
      case U:
        i = Et(a);
        break;
      case q:
        i = At(a);
        break;
      case "matrix":
        i = [a, h, c, l, u, d];
    }
    e.push(i);
  }
  return St(e);
}
function or(t, e, s, i) {
  const r = Array.isArray(e);
  let n,
    o = e;
  if (t !== K && t !== J || e !== j) {
    if ("strokeUniform" === t) return "non-scaling-stroke" === e;
    if ("strokeDashArray" === t) o = e === j ? null : e.replace(/,/g, " ").split(/\s+/).map(parseFloat);else if ("transformMatrix" === t) o = s && s.transformMatrix ? wt(s.transformMatrix, nr(e)) : nr(e);else if ("visible" === t) o = e !== j && "hidden" !== e, s && !1 === s.visible && (o = !1);else if ("opacity" === t) o = parseFloat(e), s && void 0 !== s.opacity && (o *= s.opacity);else if ("textAnchor" === t) o = "start" === e ? M : "end" === e ? A : D;else if ("charSpacing" === t) n = qt(e, i) / i * 1e3;else if ("paintFirst" === t) {
      const t = e.indexOf(K),
        s = e.indexOf(J);
      o = K, (t > -1 && s > -1 && s < t || -1 === t && s > -1) && (o = J);
    } else {
      if ("href" === t || "xlink:href" === t || "font" === t || "id" === t) return e;
      if ("imageSmoothing" === t) return "optimizeQuality" === e;
      n = r ? e.map(qt) : qt(e, i);
    }
  } else o = "";
  return !r && isNaN(n) ? o : n;
}
function ar(t, e) {
  const s = t.match(He);
  if (!s) return;
  const i = s[1],
    r = s[3],
    n = s[4],
    o = s[5],
    a = s[6];
  i && (e.fontStyle = i), r && (e.fontWeight = isNaN(parseFloat(r)) ? r : parseFloat(r)), n && (e.fontSize = qt(n)), a && (e.fontFamily = a), o && (e.lineHeight = "normal" === o ? 1 : o);
}
function hr(t, e) {
  t.replace(/;\s*$/, "").split(";").forEach(t => {
    if (!t) return;
    const [s, i] = t.split(":");
    e[s.trim().toLowerCase()] = i.trim();
  });
}
function cr(t) {
  const e = {},
    s = t.getAttribute("style");
  return s ? ("string" == typeof s ? hr(s, e) : function (t, e) {
    Object.entries(t).forEach(t => {
      let [s, i] = t;
      void 0 !== i && (e[s.toLowerCase()] = i);
    });
  }(s, e), e) : e;
}
const lr = {
  stroke: "strokeOpacity",
  fill: "fillOpacity"
};
function ur(t, e, i) {
  if (!t) return {};
  let r,
    n = {},
    o = O;
  t.parentNode && Ke.test(t.parentNode.nodeName) && (n = ur(t.parentElement, e, i), n.fontSize && (r = o = qt(n.fontSize)));
  const a = s(s(s({}, e.reduce((e, s) => {
    const i = t.getAttribute(s);
    return i && (e[s] = i), e;
  }, {})), function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      i = {};
    for (const r in e) Li(t, r.split(" ")) && (i = s(s({}, i), e[r]));
    return i;
  }(t, i)), cr(t));
  a[Ne] && t.setAttribute(Ne, a[Ne]), a[Ge] && (r = qt(a[Ge], o), a[Ge] = "".concat(r));
  const h = {};
  for (const t in a) {
    const e = Ri(t),
      s = or(e, a[t], n, r);
    h[e] = s;
  }
  h && h.font && ar(h.font, h);
  const c = s(s({}, n), h);
  return Ke.test(t.nodeName) ? c : function (t) {
    const e = yi.getDefaults();
    return Object.entries(lr).forEach(s => {
      let [i, r] = s;
      if (void 0 === t[r] || "" === t[i]) return;
      if (void 0 === t[i]) {
        if (!e[i]) return;
        t[i] = e[i];
      }
      if (0 === t[i].indexOf("url(")) return;
      const n = new Nt(t[i]);
      t[i] = n.setAlpha(Ut(n.getAlpha() * t[r], 2)).toRgba();
    }), t;
  }(c);
}
const dr = ["left", "top", "width", "height", "visible"],
  gr = ["rx", "ry"];
class fr extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), fr.ownDefaults);
  }
  constructor(t) {
    super(), Object.assign(this, fr.ownDefaults), this.setOptions(t), this._initRxRy();
  }
  _initRxRy() {
    const {
      rx: t,
      ry: e
    } = this;
    t && !e ? this.ry = t : e && !t && (this.rx = e);
  }
  _render(t) {
    const {
        width: e,
        height: s
      } = this,
      i = -e / 2,
      r = -s / 2,
      n = this.rx ? Math.min(this.rx, e / 2) : 0,
      o = this.ry ? Math.min(this.ry, s / 2) : 0,
      a = 0 !== n || 0 !== o;
    t.beginPath(), t.moveTo(i + n, r), t.lineTo(i + e - n, r), a && t.bezierCurveTo(i + e - k * n, r, i + e, r + k * o, i + e, r + o), t.lineTo(i + e, r + s - o), a && t.bezierCurveTo(i + e, r + s - k * o, i + e - k * n, r + s, i + e - n, r + s), t.lineTo(i + n, r + s), a && t.bezierCurveTo(i + k * n, r + s, i, r + s - k * o, i, r + s - o), t.lineTo(i, r + o), a && t.bezierCurveTo(i, r + k * o, i + k * n, r, i + n, r), t.closePath(), this._renderPaintInOrder(t);
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return super.toObject([...gr, ...t]);
  }
  _toSVG() {
    const {
      width: t,
      height: e,
      rx: s,
      ry: i
    } = this;
    return ["<rect ", "COMMON_PARTS", 'x="'.concat(-t / 2, '" y="').concat(-e / 2, '" rx="').concat(s, '" ry="').concat(i, '" width="').concat(t, '" height="').concat(e, '" />\n')];
  }
  static async fromElement(t, e, r) {
    const n = ur(t, this.ATTRIBUTE_NAMES, r),
      {
        left: o = 0,
        top: a = 0,
        width: h = 0,
        height: c = 0,
        visible: l = !0
      } = n,
      u = i(n, dr);
    return new this(s(s(s({}, e), u), {}, {
      left: o,
      top: a,
      width: h,
      height: c,
      visible: Boolean(l && h && c)
    }));
  }
}
t(fr, "type", "Rect"), t(fr, "cacheProperties", [...fs, ...gr]), t(fr, "ownDefaults", {
  rx: 0,
  ry: 0
}), t(fr, "ATTRIBUTE_NAMES", [...ji, "x", "y", "rx", "ry", "width", "height"]), tt.setClass(fr), tt.setSVGClass(fr);
const pr = "initialization",
  mr = "added",
  vr = "removed",
  yr = "imperative",
  _r = (t, e) => {
    const {
        strokeUniform: s,
        strokeWidth: i,
        width: r,
        height: n,
        group: o
      } = e,
      a = o && o !== t ? we(o.calcTransformMatrix(), t.calcTransformMatrix()) : null,
      h = a ? e.getRelativeCenterPoint().transform(a) : e.getRelativeCenterPoint(),
      c = !e.isStrokeAccountedForInDimensions(),
      l = s && c ? Te(new ot(i, i), void 0, t.calcTransformMatrix()) : at,
      u = !s && c ? i : 0,
      d = be(r + u, n + u, St([a, e.calcOwnMatrix()], !0)).add(l).scalarDivide(2);
    return [h.subtract(d), h.add(d)];
  };
class xr {
  calcLayoutResult(t, e) {
    if (this.shouldPerformLayout(t)) return this.calcBoundingBox(e, t);
  }
  shouldPerformLayout(t) {
    let {
      type: e,
      prevStrategy: s,
      strategy: i
    } = t;
    return e === pr || e === yr || !!s && i !== s;
  }
  shouldLayoutClipPath(t) {
    let {
      type: e,
      target: {
        clipPath: s
      }
    } = t;
    return e !== pr && s && !s.absolutePositioned;
  }
  getInitialSize(t, e) {
    return e.size;
  }
  calcBoundingBox(t, e) {
    const {
      type: s,
      target: i
    } = e;
    if (s === yr && e.overrides) return e.overrides;
    if (0 === t.length) return;
    const {
        left: r,
        top: n,
        width: o,
        height: a
      } = me(t.map(t => _r(i, t)).reduce((t, e) => t.concat(e), [])),
      h = new ot(o, a),
      c = new ot(r, n).add(h.scalarDivide(2));
    if (s === pr) {
      const t = this.getInitialSize(e, {
        size: h,
        center: c
      });
      return {
        center: c,
        relativeCorrection: new ot(0, 0),
        size: t
      };
    }
    return {
      center: c.transform(i.calcOwnMatrix()),
      size: h
    };
  }
}
t(xr, "type", "strategy");
class Cr extends xr {
  shouldPerformLayout(t) {
    return !0;
  }
}
t(Cr, "type", "fit-content"), tt.setClass(Cr);
const br = ["strategy"],
  wr = ["target", "strategy", "bubbles", "prevStrategy"],
  Sr = "layoutManager";
class Tr {
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : new Cr();
    t(this, "strategy", void 0), this.strategy = e, this._subscriptions = new Map();
  }
  performLayout(t) {
    const e = s(s({
      bubbles: !0,
      strategy: this.strategy
    }, t), {}, {
      prevStrategy: this._prevLayoutStrategy,
      stopPropagation() {
        this.bubbles = !1;
      }
    });
    this.onBeforeLayout(e);
    const i = this.getLayoutResult(e);
    i && this.commitLayout(e, i), this.onAfterLayout(e, i), this._prevLayoutStrategy = e.strategy;
  }
  attachHandlers(t, e) {
    const {
      target: s
    } = e;
    return [Q, L, W, I, R, X, H, Y, V].map(e => t.on(e, t => this.performLayout(e === Q ? {
      type: "object_modified",
      trigger: e,
      e: t,
      target: s
    } : {
      type: "object_modifying",
      trigger: e,
      e: t,
      target: s
    })));
  }
  subscribe(t, e) {
    this.unsubscribe(t, e);
    const s = this.attachHandlers(t, e);
    this._subscriptions.set(t, s);
  }
  unsubscribe(t, e) {
    (this._subscriptions.get(t) || []).forEach(t => t()), this._subscriptions.delete(t);
  }
  unsubscribeTargets(t) {
    t.targets.forEach(e => this.unsubscribe(e, t));
  }
  subscribeTargets(t) {
    t.targets.forEach(e => this.subscribe(e, t));
  }
  onBeforeLayout(t) {
    const {
        target: e,
        type: r
      } = t,
      {
        canvas: n
      } = e;
    if (r === pr || r === mr ? this.subscribeTargets(t) : r === vr && this.unsubscribeTargets(t), e.fire("layout:before", {
      context: t
    }), n && n.fire("object:layout:before", {
      target: e,
      context: t
    }), r === yr && t.deep) {
      const r = i(t, br);
      e.forEachObject(t => t.layoutManager && t.layoutManager.performLayout(s(s({}, r), {}, {
        bubbles: !1,
        target: t
      })));
    }
  }
  getLayoutResult(t) {
    const {
        target: e,
        strategy: s,
        type: i
      } = t,
      r = s.calcLayoutResult(t, e.getObjects());
    if (!r) return;
    const n = i === pr ? new ot() : e.getRelativeCenterPoint(),
      {
        center: o,
        correction: a = new ot(),
        relativeCorrection: h = new ot()
      } = r,
      c = n.subtract(o).add(a).transform(i === pr ? T : bt(e.calcOwnMatrix()), !0).add(h);
    return {
      result: r,
      prevCenter: n,
      nextCenter: o,
      offset: c
    };
  }
  commitLayout(t, e) {
    const {
        target: s
      } = t,
      {
        result: {
          size: i
        },
        nextCenter: r
      } = e;
    var n, o;
    (s.set({
      width: i.x,
      height: i.y
    }), this.layoutObjects(t, e), t.type === pr) ? s.set({
      left: null !== (n = t.x) && void 0 !== n ? n : r.x + i.x * Me(s.originX),
      top: null !== (o = t.y) && void 0 !== o ? o : r.y + i.y * Me(s.originY)
    }) : (s.setPositionByOrigin(r, D, D), s.setCoords(), s.set("dirty", !0));
  }
  layoutObjects(t, e) {
    const {
      target: s
    } = t;
    s.forEachObject(i => {
      i.group === s && this.layoutObject(t, e, i);
    }), t.strategy.shouldLayoutClipPath(t) && this.layoutObject(t, e, s.clipPath);
  }
  layoutObject(t, e, s) {
    let {
      offset: i
    } = e;
    s.set({
      left: s.left + i.x,
      top: s.top + i.y
    });
  }
  onAfterLayout(t, e) {
    const {
        target: r,
        strategy: n,
        bubbles: o,
        prevStrategy: a
      } = t,
      h = i(t, wr),
      {
        canvas: c
      } = r;
    r.fire("layout:after", {
      context: t,
      result: e
    }), c && c.fire("object:layout:after", {
      context: t,
      result: e,
      target: r
    });
    const l = r.parent;
    o && null != l && l.layoutManager && ((h.path || (h.path = [])).push(r), l.layoutManager.performLayout(s(s({}, h), {}, {
      target: l
    }))), r.set("dirty", !0);
  }
  dispose() {
    const {
      _subscriptions: t
    } = this;
    t.forEach(t => t.forEach(t => t())), t.clear();
  }
  toObject() {
    return {
      type: Sr,
      strategy: this.strategy.constructor.type
    };
  }
  toJSON() {
    return this.toObject();
  }
}
tt.setClass(Tr, Sr);
const Or = ["type", "objects", "layoutManager"];
class kr extends Tr {
  performLayout() {}
}
class Dr extends ct(yi) {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Dr.ownDefaults);
  }
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(), t(this, "_activeObjects", []), t(this, "__objectSelectionTracker", void 0), t(this, "__objectSelectionDisposer", void 0), Object.assign(this, Dr.ownDefaults), this.setOptions(s), this.groupInit(e, s);
  }
  groupInit(t, e) {
    var s;
    this._objects = [...t], this.__objectSelectionTracker = this.__objectSelectionMonitor.bind(this, !0), this.__objectSelectionDisposer = this.__objectSelectionMonitor.bind(this, !1), this.forEachObject(t => {
      this.enterGroup(t, !1);
    }), this.layoutManager = null !== (s = e.layoutManager) && void 0 !== s ? s : new Tr(), this.layoutManager.performLayout({
      type: pr,
      target: this,
      targets: [...t],
      x: e.left,
      y: e.top
    });
  }
  canEnterGroup(t) {
    return t === this || this.isDescendantOf(t) ? (a("error", "Group: circular object trees are not supported, this call has no effect"), !1) : -1 === this._objects.indexOf(t) || (a("error", "Group: duplicate objects are not supported inside group, this call has no effect"), !1);
  }
  _filterObjectsBeforeEnteringGroup(t) {
    return t.filter((t, e, s) => this.canEnterGroup(t) && s.indexOf(t) === e);
  }
  add() {
    for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
    const i = this._filterObjectsBeforeEnteringGroup(e),
      r = super.add(...i);
    return this._onAfterObjectsChange(mr, i), r;
  }
  insertAt(t) {
    for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
    const r = this._filterObjectsBeforeEnteringGroup(s),
      n = super.insertAt(t, ...r);
    return this._onAfterObjectsChange(mr, r), n;
  }
  remove() {
    const t = super.remove(...arguments);
    return this._onAfterObjectsChange(vr, t), t;
  }
  _onObjectAdded(t) {
    this.enterGroup(t, !0), this.fire("object:added", {
      target: t
    }), t.fire("added", {
      target: this
    });
  }
  _onObjectRemoved(t, e) {
    this.exitGroup(t, e), this.fire("object:removed", {
      target: t
    }), t.fire("removed", {
      target: this
    });
  }
  _onAfterObjectsChange(t, e) {
    this.layoutManager.performLayout({
      type: t,
      targets: e,
      target: this
    });
  }
  _onStackOrderChanged() {
    this._set("dirty", !0);
  }
  _set(t, e) {
    const s = this[t];
    return super._set(t, e), "canvas" === t && s !== e && (this._objects || []).forEach(s => {
      s._set(t, e);
    }), this;
  }
  _shouldSetNestedCoords() {
    return this.subTargetCheck;
  }
  removeAll() {
    return this._activeObjects = [], this.remove(...this._objects);
  }
  __objectSelectionMonitor(t, e) {
    let {
      target: s
    } = e;
    const i = this._activeObjects;
    if (t) i.push(s), this._set("dirty", !0);else if (i.length > 0) {
      const t = i.indexOf(s);
      t > -1 && (i.splice(t, 1), this._set("dirty", !0));
    }
  }
  _watchObject(t, e) {
    t && this._watchObject(!1, e), t ? (e.on("selected", this.__objectSelectionTracker), e.on("deselected", this.__objectSelectionDisposer)) : (e.off("selected", this.__objectSelectionTracker), e.off("deselected", this.__objectSelectionDisposer));
  }
  enterGroup(t, e) {
    t.group && t.group.remove(t), t._set("parent", this), this._enterGroup(t, e);
  }
  _enterGroup(t, e) {
    e && _e(t, wt(bt(this.calcTransformMatrix()), t.calcTransformMatrix())), this._shouldSetNestedCoords() && t.setCoords(), t._set("group", this), t._set("canvas", this.canvas), this._watchObject(!0, t);
    const s = this.canvas && this.canvas.getActiveObject && this.canvas.getActiveObject();
    s && (s === t || t.isDescendantOf(s)) && this._activeObjects.push(t);
  }
  exitGroup(t, e) {
    this._exitGroup(t, e), t._set("parent", void 0), t._set("canvas", void 0);
  }
  _exitGroup(t, e) {
    t._set("group", void 0), e || (_e(t, wt(this.calcTransformMatrix(), t.calcTransformMatrix())), t.setCoords()), this._watchObject(!1, t);
    const s = this._activeObjects.length > 0 ? this._activeObjects.indexOf(t) : -1;
    s > -1 && this._activeObjects.splice(s, 1);
  }
  shouldCache() {
    const t = yi.prototype.shouldCache.call(this);
    if (t) for (let t = 0; t < this._objects.length; t++) if (this._objects[t].willDrawShadow()) return this.ownCaching = !1, !1;
    return t;
  }
  willDrawShadow() {
    if (super.willDrawShadow()) return !0;
    for (let t = 0; t < this._objects.length; t++) if (this._objects[t].willDrawShadow()) return !0;
    return !1;
  }
  isOnACache() {
    return this.ownCaching || !!this.parent && this.parent.isOnACache();
  }
  drawObject(t) {
    this._renderBackground(t);
    for (let s = 0; s < this._objects.length; s++) {
      var e;
      null !== (e = this.canvas) && void 0 !== e && e.preserveObjectStacking && this._objects[s].group !== this ? (t.save(), t.transform(...bt(this.calcTransformMatrix())), this._objects[s].render(t), t.restore()) : this._objects[s].group === this && this._objects[s].render(t);
    }
    this._drawClipPath(t, this.clipPath);
  }
  setCoords() {
    super.setCoords(), this._shouldSetNestedCoords() && this.forEachObject(t => t.setCoords());
  }
  triggerLayout() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    this.layoutManager.performLayout(s({
      target: this,
      type: yr
    }, t));
  }
  render(t) {
    this._transformDone = !0, super.render(t), this._transformDone = !1;
  }
  __serializeObjects(t, e) {
    const s = this.includeDefaultValues;
    return this._objects.filter(function (t) {
      return !t.excludeFromExport;
    }).map(function (i) {
      const r = i.includeDefaultValues;
      i.includeDefaultValues = s;
      const n = i[t || "toObject"](e);
      return i.includeDefaultValues = r, n;
    });
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    const e = this.layoutManager.toObject();
    return s(s(s({}, super.toObject(["subTargetCheck", "interactive", ...t])), "fit-content" !== e.strategy || this.includeDefaultValues ? {
      layoutManager: e
    } : {}), {}, {
      objects: this.__serializeObjects("toObject", t)
    });
  }
  toString() {
    return "#<Group: (".concat(this.complexity(), ")>");
  }
  dispose() {
    this.layoutManager.unsubscribeTargets({
      targets: this.getObjects(),
      target: this
    }), this._activeObjects = [], this.forEachObject(t => {
      this._watchObject(!1, t), t.dispose();
    }), super.dispose();
  }
  _createSVGBgRect(t) {
    if (!this.backgroundColor) return "";
    const e = fr.prototype._toSVG.call(this),
      s = e.indexOf("COMMON_PARTS");
    e[s] = 'for="group" ';
    const i = e.join("");
    return t ? t(i) : i;
  }
  _toSVG(t) {
    const e = ["<g ", "COMMON_PARTS", " >\n"],
      s = this._createSVGBgRect(t);
    s && e.push("\t\t", s);
    for (let s = 0; s < this._objects.length; s++) e.push("\t\t", this._objects[s].toSVG(t));
    return e.push("</g>\n"), e;
  }
  getSvgStyles() {
    const t = void 0 !== this.opacity && 1 !== this.opacity ? "opacity: ".concat(this.opacity, ";") : "",
      e = this.visible ? "" : " visibility: hidden;";
    return [t, this.getSvgFilter(), e].join("");
  }
  toClipPathSVG(t) {
    const e = [],
      s = this._createSVGBgRect(t);
    s && e.push("\t", s);
    for (let s = 0; s < this._objects.length; s++) e.push("\t", this._objects[s].toClipPathSVG(t));
    return this._createBaseClipPathSVGMarkup(e, {
      reviver: t
    });
  }
  static fromObject(t, e) {
    let {
        type: r,
        objects: n = [],
        layoutManager: o
      } = t,
      a = i(t, Or);
    return Promise.all([Rt(n, e), It(a, e)]).then(t => {
      let [e, i] = t;
      const r = new this(e, s(s(s({}, a), i), {}, {
        layoutManager: new kr()
      }));
      if (o) {
        const t = tt.getClass(o.type),
          e = tt.getClass(o.strategy);
        r.layoutManager = new t(new e());
      } else r.layoutManager = new Tr();
      return r.layoutManager.subscribeTargets({
        type: pr,
        target: r,
        targets: r.getObjects()
      }), r.setCoords(), r;
    });
  }
}
t(Dr, "type", "Group"), t(Dr, "ownDefaults", {
  strokeWidth: 0,
  subTargetCheck: !1,
  interactive: !1
}), tt.setClass(Dr);
const Mr = (t, e) => Math.min(e.width / t.width, e.height / t.height),
  Pr = (t, e) => Math.max(e.width / t.width, e.height / t.height),
  Er = "\\s*,?\\s*",
  Ar = "".concat(Er, "(").concat(Ye, ")"),
  jr = "".concat(Ar).concat(Ar).concat(Ar).concat(Er, "([01])").concat(Er, "([01])").concat(Ar).concat(Ar),
  Fr = {
    m: "l",
    M: "L"
  },
  Lr = (t, e, s, i, r, n, o, a, h, c, l) => {
    const u = rt(t),
      d = nt(t),
      g = rt(e),
      f = nt(e),
      p = s * r * g - i * n * f + o,
      m = i * r * g + s * n * f + a;
    return ["C", c + h * (-s * r * d - i * n * u), l + h * (-i * r * d + s * n * u), p + h * (s * r * f + i * n * g), m + h * (i * r * f - s * n * g), p, m];
  },
  Rr = (t, e, s, i) => {
    const r = Math.atan2(e, t),
      n = Math.atan2(i, s);
    return n >= r ? n - r : 2 * Math.PI - (r - n);
  };
function Ir(t, e, s, i, r, n, a, h) {
  let c;
  if (o.cachesBoundsOfCurve && (c = [...arguments].join(), _.boundsOfCurveCache[c])) return _.boundsOfCurveCache[c];
  const l = Math.sqrt,
    u = Math.abs,
    d = [],
    g = [[0, 0], [0, 0]];
  let f = 6 * t - 12 * s + 6 * r,
    p = -3 * t + 9 * s - 9 * r + 3 * a,
    m = 3 * s - 3 * t;
  for (let t = 0; t < 2; ++t) {
    if (t > 0 && (f = 6 * e - 12 * i + 6 * n, p = -3 * e + 9 * i - 9 * n + 3 * h, m = 3 * i - 3 * e), u(p) < 1e-12) {
      if (u(f) < 1e-12) continue;
      const t = -m / f;
      0 < t && t < 1 && d.push(t);
      continue;
    }
    const s = f * f - 4 * m * p;
    if (s < 0) continue;
    const r = l(s),
      o = (-f + r) / (2 * p);
    0 < o && o < 1 && d.push(o);
    const a = (-f - r) / (2 * p);
    0 < a && a < 1 && d.push(a);
  }
  let v = d.length;
  const y = v,
    x = Yr(t, e, s, i, r, n, a, h);
  for (; v--;) {
    const {
      x: t,
      y: e
    } = x(d[v]);
    g[0][v] = t, g[1][v] = e;
  }
  g[0][y] = t, g[1][y] = e, g[0][y + 1] = a, g[1][y + 1] = h;
  const C = [new ot(Math.min(...g[0]), Math.min(...g[1])), new ot(Math.max(...g[0]), Math.max(...g[1]))];
  return o.cachesBoundsOfCurve && (_.boundsOfCurveCache[c] = C), C;
}
const Br = (t, e, s) => {
    let [i, r, n, o, a, h, c, l] = s;
    const u = ((t, e, s, i, r, n, o) => {
      if (0 === s || 0 === i) return [];
      let a = 0,
        h = 0,
        c = 0;
      const l = Math.PI,
        u = o * S,
        d = nt(u),
        g = rt(u),
        f = .5 * (-g * t - d * e),
        p = .5 * (-g * e + d * t),
        m = s ** 2,
        v = i ** 2,
        y = p ** 2,
        _ = f ** 2,
        x = m * v - m * y - v * _;
      let C = Math.abs(s),
        b = Math.abs(i);
      if (x < 0) {
        const t = Math.sqrt(1 - x / (m * v));
        C *= t, b *= t;
      } else c = (r === n ? -1 : 1) * Math.sqrt(x / (m * y + v * _));
      const w = c * C * p / b,
        T = -c * b * f / C,
        O = g * w - d * T + .5 * t,
        k = d * w + g * T + .5 * e;
      let D = Rr(1, 0, (f - w) / C, (p - T) / b),
        M = Rr((f - w) / C, (p - T) / b, (-f - w) / C, (-p - T) / b);
      0 === n && M > 0 ? M -= 2 * l : 1 === n && M < 0 && (M += 2 * l);
      const P = Math.ceil(Math.abs(M / l * 2)),
        E = [],
        A = M / P,
        j = 8 / 3 * Math.sin(A / 4) * Math.sin(A / 4) / Math.sin(A / 2);
      let F = D + A;
      for (let t = 0; t < P; t++) E[t] = Lr(D, F, g, d, C, b, O, k, j, a, h), a = E[t][5], h = E[t][6], D = F, F += A;
      return E;
    })(c - t, l - e, r, n, a, h, o);
    for (let s = 0, i = u.length; s < i; s++) u[s][1] += t, u[s][2] += e, u[s][3] += t, u[s][4] += e, u[s][5] += t, u[s][6] += e;
    return u;
  },
  Xr = t => {
    let e = 0,
      s = 0,
      i = 0,
      r = 0;
    const n = [];
    let o,
      a = 0,
      h = 0;
    for (const c of t) {
      const t = [...c];
      let l;
      switch (t[0]) {
        case "l":
          t[1] += e, t[2] += s;
        case "L":
          e = t[1], s = t[2], l = ["L", e, s];
          break;
        case "h":
          t[1] += e;
        case "H":
          e = t[1], l = ["L", e, s];
          break;
        case "v":
          t[1] += s;
        case "V":
          s = t[1], l = ["L", e, s];
          break;
        case "m":
          t[1] += e, t[2] += s;
        case "M":
          e = t[1], s = t[2], i = t[1], r = t[2], l = ["M", e, s];
          break;
        case "c":
          t[1] += e, t[2] += s, t[3] += e, t[4] += s, t[5] += e, t[6] += s;
        case "C":
          a = t[3], h = t[4], e = t[5], s = t[6], l = ["C", t[1], t[2], a, h, e, s];
          break;
        case "s":
          t[1] += e, t[2] += s, t[3] += e, t[4] += s;
        case "S":
          "C" === o ? (a = 2 * e - a, h = 2 * s - h) : (a = e, h = s), e = t[3], s = t[4], l = ["C", a, h, t[1], t[2], e, s], a = l[3], h = l[4];
          break;
        case "q":
          t[1] += e, t[2] += s, t[3] += e, t[4] += s;
        case "Q":
          a = t[1], h = t[2], e = t[3], s = t[4], l = ["Q", a, h, e, s];
          break;
        case "t":
          t[1] += e, t[2] += s;
        case "T":
          "Q" === o ? (a = 2 * e - a, h = 2 * s - h) : (a = e, h = s), e = t[1], s = t[2], l = ["Q", a, h, e, s];
          break;
        case "a":
          t[6] += e, t[7] += s;
        case "A":
          Br(e, s, t).forEach(t => n.push(t)), e = t[6], s = t[7];
          break;
        case "z":
        case "Z":
          e = i, s = r, l = ["Z"];
      }
      l ? (n.push(l), o = l[0]) : o = "";
    }
    return n;
  },
  Wr = (t, e, s, i) => Math.sqrt((s - t) ** 2 + (i - e) ** 2),
  Yr = (t, e, s, i, r, n, o, a) => h => {
    const c = h ** 3,
      l = (t => 3 * t ** 2 * (1 - t))(h),
      u = (t => 3 * t * (1 - t) ** 2)(h),
      d = (t => (1 - t) ** 3)(h);
    return new ot(o * c + r * l + s * u + t * d, a * c + n * l + i * u + e * d);
  },
  Vr = t => t ** 2,
  Hr = t => 2 * t * (1 - t),
  zr = t => (1 - t) ** 2,
  Gr = (t, e, s, i, r, n, o, a) => h => {
    const c = Vr(h),
      l = Hr(h),
      u = zr(h),
      d = 3 * (u * (s - t) + l * (r - s) + c * (o - r)),
      g = 3 * (u * (i - e) + l * (n - i) + c * (a - n));
    return Math.atan2(g, d);
  },
  Nr = (t, e, s, i, r, n) => o => {
    const a = Vr(o),
      h = Hr(o),
      c = zr(o);
    return new ot(r * a + s * h + t * c, n * a + i * h + e * c);
  },
  Ur = (t, e, s, i, r, n) => o => {
    const a = 1 - o,
      h = 2 * (a * (s - t) + o * (r - s)),
      c = 2 * (a * (i - e) + o * (n - i));
    return Math.atan2(c, h);
  },
  qr = (t, e, s) => {
    let i = new ot(e, s),
      r = 0;
    for (let e = 1; e <= 100; e += 1) {
      const s = t(e / 100);
      r += Wr(i.x, i.y, s.x, s.y), i = s;
    }
    return r;
  },
  Kr = (t, e) => {
    let i,
      r = 0,
      n = 0,
      o = {
        x: t.x,
        y: t.y
      },
      a = s({}, o),
      h = .01,
      c = 0;
    const l = t.iterator,
      u = t.angleFinder;
    for (; n < e && h > 1e-4;) a = l(r), c = r, i = Wr(o.x, o.y, a.x, a.y), i + n > e ? (r -= h, h /= 2) : (o = a, r += h, n += i);
    return s(s({}, a), {}, {
      angle: u(c)
    });
  },
  Jr = t => {
    let e,
      s,
      i = 0,
      r = 0,
      n = 0,
      o = 0,
      a = 0;
    const h = [];
    for (const c of t) {
      const t = {
        x: r,
        y: n,
        command: c[0],
        length: 0
      };
      switch (c[0]) {
        case "M":
          s = t, s.x = o = r = c[1], s.y = a = n = c[2];
          break;
        case "L":
          s = t, s.length = Wr(r, n, c[1], c[2]), r = c[1], n = c[2];
          break;
        case "C":
          e = Yr(r, n, c[1], c[2], c[3], c[4], c[5], c[6]), s = t, s.iterator = e, s.angleFinder = Gr(r, n, c[1], c[2], c[3], c[4], c[5], c[6]), s.length = qr(e, r, n), r = c[5], n = c[6];
          break;
        case "Q":
          e = Nr(r, n, c[1], c[2], c[3], c[4]), s = t, s.iterator = e, s.angleFinder = Ur(r, n, c[1], c[2], c[3], c[4]), s.length = qr(e, r, n), r = c[3], n = c[4];
          break;
        case "Z":
          s = t, s.destX = o, s.destY = a, s.length = Wr(r, n, o, a), r = o, n = a;
      }
      i += s.length, h.push(s);
    }
    return h.push({
      length: i,
      x: r,
      y: n
    }), h;
  },
  Qr = function (t, e) {
    let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : Jr(t),
      r = 0;
    for (; e - i[r].length > 0 && r < i.length - 2;) e -= i[r].length, r++;
    const n = i[r],
      o = e / n.length,
      a = t[r];
    switch (n.command) {
      case "M":
        return {
          x: n.x,
          y: n.y,
          angle: 0
        };
      case "Z":
        return s(s({}, new ot(n.x, n.y).lerp(new ot(n.destX, n.destY), o)), {}, {
          angle: Math.atan2(n.destY - n.y, n.destX - n.x)
        });
      case "L":
        return s(s({}, new ot(n.x, n.y).lerp(new ot(a[1], a[2]), o)), {}, {
          angle: Math.atan2(a[2] - n.y, a[1] - n.x)
        });
      case "C":
      case "Q":
        return Kr(n, e);
    }
  },
  Zr = new RegExp("[mzlhvcsqta][^mzlhvcsqta]*", "gi"),
  $r = new RegExp(jr, "g"),
  tn = new RegExp(Ye, "gi"),
  en = {
    m: 2,
    l: 2,
    h: 1,
    v: 1,
    c: 6,
    s: 4,
    q: 4,
    t: 2,
    a: 7
  },
  sn = t => {
    var e;
    const s = [],
      i = null !== (e = t.match(Zr)) && void 0 !== e ? e : [];
    for (const t of i) {
      const e = t[0];
      if ("z" === e || "Z" === e) {
        s.push([e]);
        continue;
      }
      const i = en[e.toLowerCase()];
      let r = [];
      if ("a" === e || "A" === e) {
        $r.lastIndex = 0;
        for (let e = null; e = $r.exec(t);) r.push(...e.slice(1));
      } else r = t.match(tn) || [];
      for (let t = 0; t < r.length; t += i) {
        const n = new Array(i),
          o = Fr[e];
        n[0] = t > 0 && o ? o : e;
        for (let e = 0; e < i; e++) n[e + 1] = parseFloat(r[t + e]);
        s.push(n);
      }
    }
    return s;
  },
  rn = function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
      s = new ot(t[0]),
      i = new ot(t[1]),
      r = 1,
      n = 0;
    const o = [],
      a = t.length,
      h = a > 2;
    let c;
    for (h && (r = t[2].x < i.x ? -1 : t[2].x === i.x ? 0 : 1, n = t[2].y < i.y ? -1 : t[2].y === i.y ? 0 : 1), o.push(["M", s.x - r * e, s.y - n * e]), c = 1; c < a; c++) {
      if (!s.eq(i)) {
        const t = s.midPointFrom(i);
        o.push(["Q", s.x, s.y, t.x, t.y]);
      }
      s = t[c], c + 1 < t.length && (i = t[c + 1]);
    }
    return h && (r = s.x > t[c - 2].x ? 1 : s.x === t[c - 2].x ? 0 : -1, n = s.y > t[c - 2].y ? 1 : s.y === t[c - 2].y ? 0 : -1), o.push(["L", s.x + r * e, s.y + n * e]), o;
  },
  nn = (t, e) => t.map(t => t.map((t, s) => 0 === s || void 0 === e ? t : Ut(t, e)).join(" ")).join(" ");
function on(t, e) {
  const s = t.style;
  s && e && ("string" == typeof e ? s.cssText += ";" + e : Object.entries(e).forEach(t => {
    let [e, i] = t;
    return s.setProperty(e, i);
  }));
}
const an = (t, e) => Math.floor(Math.random() * (e - t + 1)) + t;
function hn(t) {
  let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  const s = e.onComplete || C,
    i = new (v().XMLHttpRequest)(),
    r = e.signal,
    n = function () {
      i.abort();
    },
    o = function () {
      r && r.removeEventListener("abort", n), i.onerror = i.ontimeout = C;
    };
  if (r && r.aborted) throw new c("request");
  return r && r.addEventListener("abort", n, {
    once: !0
  }), i.onreadystatechange = function () {
    4 === i.readyState && (o(), s(i), i.onreadystatechange = C);
  }, i.onerror = i.ontimeout = o, i.open("get", t, !0), i.send(), i;
}
const cn = (t, e) => {
  let s = t._findCenterFromElement();
  t.transformMatrix && ((t => {
    if (t.transformMatrix) {
      const {
        scaleX: e,
        scaleY: s,
        angle: i,
        skewX: r
      } = Ot(t.transformMatrix);
      t.flipX = !1, t.flipY = !1, t.set(G, e), t.set(N, s), t.angle = i, t.skewX = r, t.skewY = 0;
    }
  })(t), s = s.transform(t.transformMatrix)), delete t.transformMatrix, e && (t.scaleX *= e.scaleX, t.scaleY *= e.scaleY, t.cropX = e.cropX, t.cropY = e.cropY, s.x += e.offsetLeft, s.y += e.offsetTop, t.width = e.width, t.height = e.height), t.setPositionByOrigin(s, D, D);
};
var ln = Object.freeze({
  __proto__: null,
  addTransformToObject: ye,
  animate: As,
  animateColor: js,
  applyTransformToObject: _e,
  calcAngleBetweenVectors: ss,
  calcDimensionsMatrix: jt,
  calcPlaneChangeMatrix: we,
  calcVectorRotation: is,
  cancelAnimFrame: dt,
  capValue: ds,
  composeMatrix: Ft,
  copyCanvasElement: t => {
    var e;
    const s = pt();
    return s.width = t.width, s.height = t.height, null === (e = s.getContext("2d")) || void 0 === e || e.drawImage(t, 0, 0), s;
  },
  cos: rt,
  createCanvasElement: pt,
  createImage: mt,
  createRotateMatrix: Dt,
  createScaleMatrix: Mt,
  createSkewXMatrix: Et,
  createSkewYMatrix: At,
  createTranslateMatrix: kt,
  createVector: ts,
  crossProduct: os,
  degreesToRadians: yt,
  dotProduct: as,
  ease: Cs,
  enlivenObjectEnlivables: It,
  enlivenObjects: Rt,
  findScaleToCover: Pr,
  findScaleToFit: Mr,
  getBoundsOfCurve: Ir,
  getOrthonormalVector: ns,
  getPathSegmentsInfo: Jr,
  getPointOnPath: Qr,
  getPointer: ge,
  getRandomInt: an,
  getRegularPolygonPath: (t, e) => {
    const s = 2 * Math.PI / t;
    let i = -b;
    t % 2 == 0 && (i += s / 2);
    const r = new Array(t + 1);
    for (let n = 0; n < t; n++) {
      const t = n * s + i,
        {
          x: o,
          y: a
        } = new ot(rt(t), nt(t)).scalarMultiply(e);
      r[n] = [0 === n ? "M" : "L", o, a];
    }
    return r[t] = ["Z"], r;
  },
  getSmoothPathFromPoints: rn,
  getSvgAttributes: t => {
    const e = ["instantiated_by_use", "style", "id", "class"];
    switch (t) {
      case "linearGradient":
        return e.concat(["x1", "y1", "x2", "y2", "gradientUnits", "gradientTransform"]);
      case "radialGradient":
        return e.concat(["gradientUnits", "gradientTransform", "cx", "cy", "r", "fx", "fy", "fr"]);
      case "stop":
        return e.concat(["offset", "stop-color", "stop-opacity"]);
    }
    return e;
  },
  getUnitVector: rs,
  groupSVGElements: (t, e) => t && 1 === t.length ? t[0] : new Dr(t, e),
  hasStyleChanged: Pi,
  invertTransform: bt,
  isBetweenVectors: hs,
  isIdentityMatrix: xt,
  isTouchEvent: fe,
  isTransparent: _i,
  joinPath: nn,
  loadImage: Lt,
  magnitude: es,
  makeBoundingBoxFromPoints: me,
  makePathSimpler: Xr,
  matrixToSVG: Jt,
  mergeClipPaths: (t, e) => {
    var s;
    let i = t,
      r = e;
    i.inverted && !r.inverted && (i = e, r = t), Oe(r, null === (s = r.group) || void 0 === s ? void 0 : s.calcTransformMatrix(), i.calcTransformMatrix());
    const n = i.inverted && r.inverted;
    return n && (i.inverted = r.inverted = !1), new Dr([i], {
      clipPath: r,
      inverted: n
    });
  },
  multiplyTransformMatrices: wt,
  multiplyTransformMatrixArray: St,
  parsePath: sn,
  parsePreserveAspectRatioAttribute: Kt,
  parseUnit: qt,
  pick: Bt,
  projectStrokeOnPoints: Si,
  qrDecompose: Ot,
  radiansToDegrees: _t,
  removeFromArray: it,
  removeTransformFromObject: (t, e) => {
    const s = bt(e),
      i = wt(s, t.calcOwnMatrix());
    _e(t, i);
  },
  removeTransformMatrixForSvgParsing: cn,
  request: hn,
  requestAnimFrame: ut,
  resetObjectTransform: xe,
  rotatePoint: (t, e, s) => t.rotate(s, e),
  rotateVector: $e,
  saveObjectTransform: Ce,
  sendObjectToPlane: Oe,
  sendPointToPlane: Se,
  sendVectorToPlane: Te,
  setStyle: on,
  sin: nt,
  sizeAfterTransform: be,
  string: Mi,
  stylesFromArray: Ai,
  stylesToArray: Ei,
  toDataURL: vt,
  toFixed: Ut,
  transformPath: (t, e, s) => (s && (e = wt(e, [1, 0, 0, 1, -s.x, -s.y])), t.map(t => {
    const s = [...t];
    for (let i = 1; i < t.length - 1; i += 2) {
      const {
        x: r,
        y: n
      } = Ct({
        x: t[i],
        y: t[i + 1]
      }, e);
      s[i] = r, s[i + 1] = n;
    }
    return s;
  })),
  transformPoint: Ct
});
class un extends ce {
  constructor(e) {
    let {
      allowTouchScrolling: s = !1,
      containerClass: i = ""
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(e), t(this, "upper", void 0), t(this, "container", void 0);
    const {
        el: r
      } = this.lower,
      n = this.createUpperCanvas();
    this.upper = {
      el: n,
      ctx: n.getContext("2d")
    }, this.applyCanvasStyle(r, {
      allowTouchScrolling: s
    }), this.applyCanvasStyle(n, {
      allowTouchScrolling: s,
      styles: {
        position: "absolute",
        left: "0",
        top: "0"
      }
    });
    const o = this.createContainerElement();
    o.classList.add(i), r.parentNode && r.parentNode.replaceChild(o, r), o.append(r, n), this.container = o;
  }
  createUpperCanvas() {
    const {
        el: t
      } = this.lower,
      e = pt();
    return e.className = t.className, e.classList.remove("lower-canvas"), e.classList.add("upper-canvas"), e.setAttribute("data-fabric", "top"), e.style.cssText = t.style.cssText, e.setAttribute("draggable", "true"), e;
  }
  createContainerElement() {
    const t = m().createElement("div");
    return t.setAttribute("data-fabric", "wrapper"), on(t, {
      position: "relative"
    }), he(t), t;
  }
  applyCanvasStyle(t, e) {
    const {
      styles: i,
      allowTouchScrolling: r
    } = e;
    on(t, s(s({}, i), {}, {
      "touch-action": r ? "manipulation" : j
    })), he(t);
  }
  setDimensions(t, e) {
    super.setDimensions(t, e);
    const {
      el: s,
      ctx: i
    } = this.upper;
    oe(s, i, t, e);
  }
  setCSSDimensions(t) {
    super.setCSSDimensions(t), ae(this.upper.el, t), ae(this.container, t);
  }
  cleanupDOM(t) {
    const e = this.container,
      {
        el: s
      } = this.lower,
      {
        el: i
      } = this.upper;
    super.cleanupDOM(t), e.removeChild(i), e.removeChild(s), e.parentNode && e.parentNode.replaceChild(s, e);
  }
  dispose() {
    super.dispose(), p().dispose(this.upper.el), delete this.upper, delete this.container;
  }
}
class dn extends ue {
  constructor() {
    super(...arguments), t(this, "targets", []), t(this, "_hoveredTargets", []), t(this, "_objectsToRender", void 0), t(this, "_currentTransform", null), t(this, "_groupSelector", null), t(this, "contextTopDirty", !1);
  }
  static getDefaults() {
    return s(s({}, super.getDefaults()), dn.ownDefaults);
  }
  get upperCanvasEl() {
    var t;
    return null === (t = this.elements.upper) || void 0 === t ? void 0 : t.el;
  }
  get contextTop() {
    var t;
    return null === (t = this.elements.upper) || void 0 === t ? void 0 : t.ctx;
  }
  get wrapperEl() {
    return this.elements.container;
  }
  initElements(t) {
    this.elements = new un(t, {
      allowTouchScrolling: this.allowTouchScrolling,
      containerClass: this.containerClass
    }), this._createCacheCanvas();
  }
  _onObjectAdded(t) {
    this._objectsToRender = void 0, super._onObjectAdded(t);
  }
  _onObjectRemoved(t) {
    this._objectsToRender = void 0, t === this._activeObject && (this.fire("before:selection:cleared", {
      deselected: [t]
    }), this._discardActiveObject(), this.fire("selection:cleared", {
      deselected: [t]
    }), t.fire("deselected", {
      target: t
    })), t === this._hoveredTarget && (this._hoveredTarget = void 0, this._hoveredTargets = []), super._onObjectRemoved(t);
  }
  _onStackOrderChanged() {
    this._objectsToRender = void 0, super._onStackOrderChanged();
  }
  _chooseObjectsToRender() {
    const t = this._activeObject;
    return !this.preserveObjectStacking && t ? this._objects.filter(e => !e.group && e !== t).concat(t) : this._objects;
  }
  renderAll() {
    this.cancelRequestedRender(), this.destroyed || (!this.contextTopDirty || this._groupSelector || this.isDrawingMode || (this.clearContext(this.contextTop), this.contextTopDirty = !1), this.hasLostContext && (this.renderTopLayer(this.contextTop), this.hasLostContext = !1), !this._objectsToRender && (this._objectsToRender = this._chooseObjectsToRender()), this.renderCanvas(this.getContext(), this._objectsToRender));
  }
  renderTopLayer(t) {
    t.save(), this.isDrawingMode && this._isCurrentlyDrawing && (this.freeDrawingBrush && this.freeDrawingBrush._render(), this.contextTopDirty = !0), this.selection && this._groupSelector && (this._drawSelection(t), this.contextTopDirty = !0), t.restore();
  }
  renderTop() {
    const t = this.contextTop;
    this.clearContext(t), this.renderTopLayer(t), this.fire("after:render", {
      ctx: t
    });
  }
  setTargetFindTolerance(t) {
    t = Math.round(t), this.targetFindTolerance = t;
    const e = this.getRetinaScaling(),
      s = Math.ceil((2 * t + 1) * e);
    this.pixelFindCanvasEl.width = this.pixelFindCanvasEl.height = s, this.pixelFindContext.scale(e, e);
  }
  isTargetTransparent(t, e, s) {
    const i = this.targetFindTolerance,
      r = this.pixelFindContext;
    this.clearContext(r), r.save(), r.translate(-e + i, -s + i), r.transform(...this.viewportTransform);
    const n = t.selectionBackgroundColor;
    t.selectionBackgroundColor = "", t.render(r), t.selectionBackgroundColor = n, r.restore();
    const o = Math.round(i * this.getRetinaScaling());
    return _i(r, o, o, o);
  }
  _isSelectionKeyPressed(t) {
    const e = this.selectionKey;
    return !!e && (Array.isArray(e) ? !!e.find(e => !!e && !0 === t[e]) : t[e]);
  }
  _shouldClearSelection(t, e) {
    const s = this.getActiveObjects(),
      i = this._activeObject;
    return !!(!e || e && i && s.length > 1 && -1 === s.indexOf(e) && i !== e && !this._isSelectionKeyPressed(t) || e && !e.evented || e && !e.selectable && i && i !== e);
  }
  _shouldCenterTransform(t, e, s) {
    if (!t) return;
    let i;
    return e === z || e === G || e === N || e === W ? i = this.centeredScaling || t.centeredScaling : e === B && (i = this.centeredRotation || t.centeredRotation), i ? !s : s;
  }
  _getOriginFromCorner(t, e) {
    const s = {
      x: t.originX,
      y: t.originY
    };
    return e ? (["ml", "tl", "bl"].includes(e) ? s.x = A : ["mr", "tr", "br"].includes(e) && (s.x = M), ["tl", "mt", "tr"].includes(e) ? s.y = E : ["bl", "mb", "br"].includes(e) && (s.y = P), s) : s;
  }
  _setupCurrentTransform(t, e, i) {
    var r;
    const n = e.group ? Se(this.getScenePoint(t), void 0, e.group.calcTransformMatrix()) : this.getScenePoint(t),
      {
        key: o = "",
        control: a
      } = e.getActiveControl() || {},
      h = i && a ? null === (r = a.getActionHandler(t, e, a)) || void 0 === r ? void 0 : r.bind(a) : Ie,
      c = ((t, e, s, i) => {
        if (!e || !t) return "drag";
        const r = i.controls[e];
        return r.getActionName(s, r, i);
      })(i, o, t, e),
      l = t[this.centeredKey],
      u = this._shouldCenterTransform(e, c, l) ? {
        x: D,
        y: D
      } : this._getOriginFromCorner(e, o),
      d = {
        target: e,
        action: c,
        actionHandler: h,
        actionPerformed: !1,
        corner: o,
        scaleX: e.scaleX,
        scaleY: e.scaleY,
        skewX: e.skewX,
        skewY: e.skewY,
        offsetX: n.x - e.left,
        offsetY: n.y - e.top,
        originX: u.x,
        originY: u.y,
        ex: n.x,
        ey: n.y,
        lastX: n.x,
        lastY: n.y,
        theta: yt(e.angle),
        width: e.width,
        height: e.height,
        shiftKey: t.shiftKey,
        altKey: l,
        original: s(s({}, Ce(e)), {}, {
          originX: u.x,
          originY: u.y
        })
      };
    this._currentTransform = d, this.fire("before:transform", {
      e: t,
      transform: d
    });
  }
  setCursor(t) {
    this.upperCanvasEl.style.cursor = t;
  }
  _drawSelection(t) {
    const {
        x: e,
        y: s,
        deltaX: i,
        deltaY: r
      } = this._groupSelector,
      n = new ot(e, s).transform(this.viewportTransform),
      o = new ot(e + i, s + r).transform(this.viewportTransform),
      a = this.selectionLineWidth / 2;
    let h = Math.min(n.x, o.x),
      c = Math.min(n.y, o.y),
      l = Math.max(n.x, o.x),
      u = Math.max(n.y, o.y);
    this.selectionColor && (t.fillStyle = this.selectionColor, t.fillRect(h, c, l - h, u - c)), this.selectionLineWidth && this.selectionBorderColor && (t.lineWidth = this.selectionLineWidth, t.strokeStyle = this.selectionBorderColor, h += a, c += a, l -= a, u -= a, yi.prototype._setLineDash.call(this, t, this.selectionDashArray), t.strokeRect(h, c, l - h, u - c));
  }
  findTarget(t) {
    if (this.skipTargetFind) return;
    const e = this.getViewportPoint(t),
      s = this._activeObject,
      i = this.getActiveObjects();
    if (this.targets = [], s && i.length >= 1) {
      if (s.findControl(e, fe(t))) return s;
      if (i.length > 1 && this.searchPossibleTargets([s], e)) return s;
      if (s === this.searchPossibleTargets([s], e)) {
        if (this.preserveObjectStacking) {
          const i = this.targets;
          this.targets = [];
          const r = this.searchPossibleTargets(this._objects, e);
          return t[this.altSelectionKey] && r && r !== s ? (this.targets = i, s) : r;
        }
        return s;
      }
    }
    return this.searchPossibleTargets(this._objects, e);
  }
  _pointIsInObjectSelectionArea(t, e) {
    let s = t.getCoords();
    const i = this.getZoom(),
      r = t.padding / i;
    if (r) {
      const [t, e, i, n] = s,
        o = Math.atan2(e.y - t.y, e.x - t.x),
        a = rt(o) * r,
        h = nt(o) * r,
        c = a + h,
        l = a - h;
      s = [new ot(t.x - l, t.y - c), new ot(e.x + c, e.y - l), new ot(i.x + l, i.y + c), new ot(n.x - c, n.y + l)];
    }
    return Fs.isPointInPolygon(e, s);
  }
  _checkTarget(t, e) {
    if (t && t.visible && t.evented && this._pointIsInObjectSelectionArea(t, Se(e, void 0, this.viewportTransform))) {
      if (!this.perPixelTargetFind && !t.perPixelTargetFind || t.isEditing) return !0;
      if (!this.isTargetTransparent(t, e.x, e.y)) return !0;
    }
    return !1;
  }
  _searchPossibleTargets(t, e) {
    let s = t.length;
    for (; s--;) {
      const i = t[s];
      if (this._checkTarget(i, e)) {
        if (ht(i) && i.subTargetCheck) {
          const t = this._searchPossibleTargets(i._objects, e);
          t && this.targets.push(t);
        }
        return i;
      }
    }
  }
  searchPossibleTargets(t, e) {
    const s = this._searchPossibleTargets(t, e);
    if (s && ht(s) && s.interactive && this.targets[0]) {
      const t = this.targets;
      for (let e = t.length - 1; e > 0; e--) {
        const s = t[e];
        if (!ht(s) || !s.interactive) return s;
      }
      return t[0];
    }
    return s;
  }
  getViewportPoint(t) {
    return this._pointer ? this._pointer : this.getPointer(t, !0);
  }
  getScenePoint(t) {
    return this._absolutePointer ? this._absolutePointer : this.getPointer(t);
  }
  getPointer(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    const s = this.upperCanvasEl,
      i = s.getBoundingClientRect();
    let r = ge(t),
      n = i.width || 0,
      o = i.height || 0;
    n && o || (P in i && E in i && (o = Math.abs(i.top - i.bottom)), A in i && M in i && (n = Math.abs(i.right - i.left))), this.calcOffset(), r.x = r.x - this._offset.left, r.y = r.y - this._offset.top, e || (r = Se(r, void 0, this.viewportTransform));
    const a = this.getRetinaScaling();
    1 !== a && (r.x /= a, r.y /= a);
    const h = 0 === n || 0 === o ? new ot(1, 1) : new ot(s.width / n, s.height / o);
    return r.multiply(h);
  }
  _setDimensionsImpl(t, e) {
    this._resetTransformEventData(), super._setDimensionsImpl(t, e), this._isCurrentlyDrawing && this.freeDrawingBrush && this.freeDrawingBrush._setBrushStyles(this.contextTop);
  }
  _createCacheCanvas() {
    this.pixelFindCanvasEl = pt(), this.pixelFindContext = this.pixelFindCanvasEl.getContext("2d", {
      willReadFrequently: !0
    }), this.setTargetFindTolerance(this.targetFindTolerance);
  }
  getTopContext() {
    return this.elements.upper.ctx;
  }
  getSelectionContext() {
    return this.elements.upper.ctx;
  }
  getSelectionElement() {
    return this.elements.upper.el;
  }
  getActiveObject() {
    return this._activeObject;
  }
  getActiveObjects() {
    const t = this._activeObject;
    return se(t) ? t.getObjects() : t ? [t] : [];
  }
  _fireSelectionEvents(t, e) {
    let s = !1,
      i = !1;
    const r = this.getActiveObjects(),
      n = [],
      o = [];
    t.forEach(t => {
      r.includes(t) || (s = !0, t.fire("deselected", {
        e: e,
        target: t
      }), o.push(t));
    }), r.forEach(i => {
      t.includes(i) || (s = !0, i.fire("selected", {
        e: e,
        target: i
      }), n.push(i));
    }), t.length > 0 && r.length > 0 ? (i = !0, s && this.fire("selection:updated", {
      e: e,
      selected: n,
      deselected: o
    })) : r.length > 0 ? (i = !0, this.fire("selection:created", {
      e: e,
      selected: n
    })) : t.length > 0 && (i = !0, this.fire("selection:cleared", {
      e: e,
      deselected: o
    })), i && (this._objectsToRender = void 0);
  }
  setActiveObject(t, e) {
    const s = this.getActiveObjects(),
      i = this._setActiveObject(t, e);
    return this._fireSelectionEvents(s, e), i;
  }
  _setActiveObject(t, e) {
    const s = this._activeObject;
    return s !== t && !(!this._discardActiveObject(e, t) && this._activeObject) && !t.onSelect({
      e: e
    }) && (this._activeObject = t, se(t) && s !== t && t.set("canvas", this), t.setCoords(), !0);
  }
  _discardActiveObject(t, e) {
    const s = this._activeObject;
    return !!s && !s.onDeselect({
      e: t,
      object: e
    }) && (this._currentTransform && this._currentTransform.target === s && this.endCurrentTransform(t), se(s) && s === this._hoveredTarget && (this._hoveredTarget = void 0), this._activeObject = void 0, !0);
  }
  discardActiveObject(t) {
    const e = this.getActiveObjects(),
      s = this.getActiveObject();
    e.length && this.fire("before:selection:cleared", {
      e: t,
      deselected: [s]
    });
    const i = this._discardActiveObject(t);
    return this._fireSelectionEvents(e, t), i;
  }
  endCurrentTransform(t) {
    const e = this._currentTransform;
    this._finalizeCurrentTransform(t), e && e.target && (e.target.isMoving = !1), this._currentTransform = null;
  }
  _finalizeCurrentTransform(t) {
    const e = this._currentTransform,
      s = e.target,
      i = {
        e: t,
        target: s,
        transform: e,
        action: e.action
      };
    s._scaling && (s._scaling = !1), s.setCoords(), e.actionPerformed && (this.fire("object:modified", i), s.fire(Q, i));
  }
  setViewportTransform(t) {
    super.setViewportTransform(t);
    const e = this._activeObject;
    e && e.setCoords();
  }
  destroy() {
    const t = this._activeObject;
    se(t) && (t.removeAll(), t.dispose()), delete this._activeObject, super.destroy(), this.pixelFindContext = null, this.pixelFindCanvasEl = void 0;
  }
  clear() {
    this.discardActiveObject(), this._activeObject = void 0, this.clearContext(this.contextTop), super.clear();
  }
  drawControls(t) {
    const e = this._activeObject;
    e && e._renderControls(t);
  }
  _toObject(t, e, s) {
    const i = this._realizeGroupTransformOnObject(t),
      r = super._toObject(t, e, s);
    return t.set(i), r;
  }
  _realizeGroupTransformOnObject(t) {
    const {
      group: e
    } = t;
    if (e && se(e) && this._activeObject === e) {
      const s = Bt(t, ["angle", "flipX", "flipY", M, G, N, U, q, P]);
      return ye(t, e.calcOwnMatrix()), s;
    }
    return {};
  }
  _setSVGObject(t, e, s) {
    const i = this._realizeGroupTransformOnObject(e);
    super._setSVGObject(t, e, s), e.set(i);
  }
}
t(dn, "ownDefaults", {
  uniformScaling: !0,
  uniScaleKey: "shiftKey",
  centeredScaling: !1,
  centeredRotation: !1,
  centeredKey: "altKey",
  altActionKey: "shiftKey",
  selection: !0,
  selectionKey: "shiftKey",
  selectionColor: "rgba(100, 100, 255, 0.3)",
  selectionDashArray: [],
  selectionBorderColor: "rgba(255, 255, 255, 0.3)",
  selectionLineWidth: 1,
  selectionFullyContained: !1,
  hoverCursor: "move",
  moveCursor: "move",
  defaultCursor: "default",
  freeDrawingCursor: "crosshair",
  notAllowedCursor: "not-allowed",
  perPixelTargetFind: !1,
  targetFindTolerance: 0,
  skipTargetFind: !1,
  stopContextMenu: !1,
  fireRightClick: !1,
  fireMiddleClick: !1,
  enablePointerEvents: !1,
  containerClass: "canvas-container",
  preserveObjectStacking: !1
});
class gn {
  constructor(e) {
    t(this, "targets", []), t(this, "__disposer", void 0);
    const s = () => {
        const {
          hiddenTextarea: t
        } = e.getActiveObject() || {};
        t && t.focus();
      },
      i = e.upperCanvasEl;
    i.addEventListener("click", s), this.__disposer = () => i.removeEventListener("click", s);
  }
  exitTextEditing() {
    this.target = void 0, this.targets.forEach(t => {
      t.isEditing && t.exitEditing();
    });
  }
  add(t) {
    this.targets.push(t);
  }
  remove(t) {
    this.unregister(t), it(this.targets, t);
  }
  register(t) {
    this.target = t;
  }
  unregister(t) {
    t === this.target && (this.target = void 0);
  }
  onMouseMove(t) {
    var e;
    (null === (e = this.target) || void 0 === e ? void 0 : e.isEditing) && this.target.updateSelectionOnMouseMove(t);
  }
  clear() {
    this.targets = [], this.target = void 0;
  }
  dispose() {
    this.clear(), this.__disposer(), delete this.__disposer;
  }
}
const fn = ["target", "oldTarget", "fireCanvas", "e"],
  pn = {
    passive: !1
  },
  mn = (t, e) => {
    const s = t.getViewportPoint(e),
      i = t.getScenePoint(e);
    return {
      viewportPoint: s,
      scenePoint: i,
      pointer: s,
      absolutePointer: i
    };
  },
  vn = function (t) {
    for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
    return t.addEventListener(...s);
  },
  yn = function (t) {
    for (var e = arguments.length, s = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++) s[i - 1] = arguments[i];
    return t.removeEventListener(...s);
  },
  _n = {
    mouse: {
      in: "over",
      out: "out",
      targetIn: "mouseover",
      targetOut: "mouseout",
      canvasIn: "mouse:over",
      canvasOut: "mouse:out"
    },
    drag: {
      in: "enter",
      out: "leave",
      targetIn: "dragenter",
      targetOut: "dragleave",
      canvasIn: "drag:enter",
      canvasOut: "drag:leave"
    }
  };
class xn extends dn {
  constructor(e) {
    super(e, arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}), t(this, "_isClick", void 0), t(this, "textEditingManager", new gn(this)), ["_onMouseDown", "_onTouchStart", "_onMouseMove", "_onMouseUp", "_onTouchEnd", "_onResize", "_onMouseWheel", "_onMouseOut", "_onMouseEnter", "_onContextMenu", "_onDoubleClick", "_onDragStart", "_onDragEnd", "_onDragProgress", "_onDragOver", "_onDragEnter", "_onDragLeave", "_onDrop"].forEach(t => {
      this[t] = this[t].bind(this);
    }), this.addOrRemove(vn, "add");
  }
  _getEventPrefix() {
    return this.enablePointerEvents ? "pointer" : "mouse";
  }
  addOrRemove(t, e) {
    const s = this.upperCanvasEl,
      i = this._getEventPrefix();
    t(ne(s), "resize", this._onResize), t(s, i + "down", this._onMouseDown), t(s, "".concat(i, "move"), this._onMouseMove, pn), t(s, "".concat(i, "out"), this._onMouseOut), t(s, "".concat(i, "enter"), this._onMouseEnter), t(s, "wheel", this._onMouseWheel), t(s, "contextmenu", this._onContextMenu), t(s, "dblclick", this._onDoubleClick), t(s, "dragstart", this._onDragStart), t(s, "dragend", this._onDragEnd), t(s, "dragover", this._onDragOver), t(s, "dragenter", this._onDragEnter), t(s, "dragleave", this._onDragLeave), t(s, "drop", this._onDrop), this.enablePointerEvents || t(s, "touchstart", this._onTouchStart, pn);
  }
  removeListeners() {
    this.addOrRemove(yn, "remove");
    const t = this._getEventPrefix(),
      e = re(this.upperCanvasEl);
    yn(e, "".concat(t, "up"), this._onMouseUp), yn(e, "touchend", this._onTouchEnd, pn), yn(e, "".concat(t, "move"), this._onMouseMove, pn), yn(e, "touchmove", this._onMouseMove, pn);
  }
  _onMouseWheel(t) {
    this.__onMouseWheel(t);
  }
  _onMouseOut(t) {
    const e = this._hoveredTarget,
      i = s({
        e: t
      }, mn(this, t));
    this.fire("mouse:out", s(s({}, i), {}, {
      target: e
    })), this._hoveredTarget = void 0, e && e.fire("mouseout", s({}, i)), this._hoveredTargets.forEach(t => {
      this.fire("mouse:out", s(s({}, i), {}, {
        target: t
      })), t && t.fire("mouseout", s({}, i));
    }), this._hoveredTargets = [];
  }
  _onMouseEnter(t) {
    this._currentTransform || this.findTarget(t) || (this.fire("mouse:over", s({
      e: t
    }, mn(this, t))), this._hoveredTarget = void 0, this._hoveredTargets = []);
  }
  _onDragStart(t) {
    this._isClick = !1;
    const e = this.getActiveObject();
    if (e && e.onDragStart(t)) {
      this._dragSource = e;
      const s = {
        e: t,
        target: e
      };
      return this.fire("dragstart", s), e.fire("dragstart", s), void vn(this.upperCanvasEl, "drag", this._onDragProgress);
    }
    pe(t);
  }
  _renderDragEffects(t, e, s) {
    let i = !1;
    const r = this._dropTarget;
    r && r !== e && r !== s && (r.clearContextTop(), i = !0), null == e || e.clearContextTop(), s !== e && (null == s || s.clearContextTop());
    const n = this.contextTop;
    n.save(), n.transform(...this.viewportTransform), e && (n.save(), e.transform(n), e.renderDragSourceEffect(t), n.restore(), i = !0), s && (n.save(), s.transform(n), s.renderDropTargetEffect(t), n.restore(), i = !0), n.restore(), i && (this.contextTopDirty = !0);
  }
  _onDragEnd(t) {
    const e = !!t.dataTransfer && t.dataTransfer.dropEffect !== j,
      s = e ? this._activeObject : void 0,
      i = {
        e: t,
        target: this._dragSource,
        subTargets: this.targets,
        dragSource: this._dragSource,
        didDrop: e,
        dropTarget: s
      };
    yn(this.upperCanvasEl, "drag", this._onDragProgress), this.fire("dragend", i), this._dragSource && this._dragSource.fire("dragend", i), delete this._dragSource, this._onMouseUp(t);
  }
  _onDragProgress(t) {
    const e = {
      e: t,
      target: this._dragSource,
      dragSource: this._dragSource,
      dropTarget: this._draggedoverTarget
    };
    this.fire("drag", e), this._dragSource && this._dragSource.fire("drag", e);
  }
  findDragTargets(t) {
    this.targets = [];
    return {
      target: this._searchPossibleTargets(this._objects, this.getViewportPoint(t)),
      targets: [...this.targets]
    };
  }
  _onDragOver(t) {
    const e = "dragover",
      {
        target: s,
        targets: i
      } = this.findDragTargets(t),
      r = this._dragSource,
      n = {
        e: t,
        target: s,
        subTargets: i,
        dragSource: r,
        canDrop: !1,
        dropTarget: void 0
      };
    let o;
    this.fire(e, n), this._fireEnterLeaveEvents(s, n), s && (s.canDrop(t) && (o = s), s.fire(e, n));
    for (let s = 0; s < i.length; s++) {
      const r = i[s];
      r.canDrop(t) && (o = r), r.fire(e, n);
    }
    this._renderDragEffects(t, r, o), this._dropTarget = o;
  }
  _onDragEnter(t) {
    const {
        target: e,
        targets: s
      } = this.findDragTargets(t),
      i = {
        e: t,
        target: e,
        subTargets: s,
        dragSource: this._dragSource
      };
    this.fire("dragenter", i), this._fireEnterLeaveEvents(e, i);
  }
  _onDragLeave(t) {
    const e = {
      e: t,
      target: this._draggedoverTarget,
      subTargets: this.targets,
      dragSource: this._dragSource
    };
    this.fire("dragleave", e), this._fireEnterLeaveEvents(void 0, e), this._renderDragEffects(t, this._dragSource), this._dropTarget = void 0, this.targets = [], this._hoveredTargets = [];
  }
  _onDrop(t) {
    const {
        target: e,
        targets: i
      } = this.findDragTargets(t),
      r = this._basicEventHandler("drop:before", s({
        e: t,
        target: e,
        subTargets: i,
        dragSource: this._dragSource
      }, mn(this, t)));
    r.didDrop = !1, r.dropTarget = void 0, this._basicEventHandler("drop", r), this.fire("drop:after", r);
  }
  _onContextMenu(t) {
    const e = this.findTarget(t),
      s = this.targets || [],
      i = this._basicEventHandler("contextmenu:before", {
        e: t,
        target: e,
        subTargets: s
      });
    return this.stopContextMenu && pe(t), this._basicEventHandler("contextmenu", i), !1;
  }
  _onDoubleClick(t) {
    this._cacheTransformEventData(t), this._handleEvent(t, "dblclick"), this._resetTransformEventData();
  }
  getPointerId(t) {
    const e = t.changedTouches;
    return e ? e[0] && e[0].identifier : this.enablePointerEvents ? t.pointerId : -1;
  }
  _isMainEvent(t) {
    return !0 === t.isPrimary || !1 !== t.isPrimary && ("touchend" === t.type && 0 === t.touches.length || !t.changedTouches || t.changedTouches[0].identifier === this.mainTouchId);
  }
  _onTouchStart(t) {
    t.preventDefault(), void 0 === this.mainTouchId && (this.mainTouchId = this.getPointerId(t)), this.__onMouseDown(t), this._resetTransformEventData();
    const e = this.upperCanvasEl,
      s = this._getEventPrefix(),
      i = re(e);
    vn(i, "touchend", this._onTouchEnd, pn), vn(i, "touchmove", this._onMouseMove, pn), yn(e, "".concat(s, "down"), this._onMouseDown);
  }
  _onMouseDown(t) {
    this.__onMouseDown(t), this._resetTransformEventData();
    const e = this.upperCanvasEl,
      s = this._getEventPrefix();
    yn(e, "".concat(s, "move"), this._onMouseMove, pn);
    const i = re(e);
    vn(i, "".concat(s, "up"), this._onMouseUp), vn(i, "".concat(s, "move"), this._onMouseMove, pn);
  }
  _onTouchEnd(t) {
    if (t.touches.length > 0) return;
    this.__onMouseUp(t), this._resetTransformEventData(), delete this.mainTouchId;
    const e = this._getEventPrefix(),
      s = re(this.upperCanvasEl);
    yn(s, "touchend", this._onTouchEnd, pn), yn(s, "touchmove", this._onMouseMove, pn), this._willAddMouseDown && clearTimeout(this._willAddMouseDown), this._willAddMouseDown = setTimeout(() => {
      vn(this.upperCanvasEl, "".concat(e, "down"), this._onMouseDown), this._willAddMouseDown = 0;
    }, 400);
  }
  _onMouseUp(t) {
    this.__onMouseUp(t), this._resetTransformEventData();
    const e = this.upperCanvasEl,
      s = this._getEventPrefix();
    if (this._isMainEvent(t)) {
      const t = re(this.upperCanvasEl);
      yn(t, "".concat(s, "up"), this._onMouseUp), yn(t, "".concat(s, "move"), this._onMouseMove, pn), vn(e, "".concat(s, "move"), this._onMouseMove, pn);
    }
  }
  _onMouseMove(t) {
    const e = this.getActiveObject();
    !this.allowTouchScrolling && (!e || !e.shouldStartDragging(t)) && t.preventDefault && t.preventDefault(), this.__onMouseMove(t);
  }
  _onResize() {
    this.calcOffset(), this._resetTransformEventData();
  }
  _shouldRender(t) {
    const e = this.getActiveObject();
    return !!e != !!t || e && t && e !== t;
  }
  __onMouseUp(t) {
    var e;
    this._cacheTransformEventData(t), this._handleEvent(t, "up:before");
    const s = this._currentTransform,
      i = this._isClick,
      r = this._target,
      {
        button: n
      } = t;
    if (n) return (this.fireMiddleClick && 1 === n || this.fireRightClick && 2 === n) && this._handleEvent(t, "up"), void this._resetTransformEventData();
    if (this.isDrawingMode && this._isCurrentlyDrawing) return void this._onMouseUpInDrawingMode(t);
    if (!this._isMainEvent(t)) return;
    let o,
      a,
      h = !1;
    if (s && (this._finalizeCurrentTransform(t), h = s.actionPerformed), !i) {
      const e = r === this._activeObject;
      this.handleSelection(t), h || (h = this._shouldRender(r) || !e && r === this._activeObject);
    }
    if (r) {
      const e = r.findControl(this.getViewportPoint(t), fe(t)),
        {
          key: i,
          control: n
        } = e || {};
      if (a = i, r.selectable && r !== this._activeObject && "up" === r.activeOn) this.setActiveObject(r, t), h = !0;else if (n) {
        const e = n.getMouseUpHandler(t, r, n);
        e && (o = this.getScenePoint(t), e.call(n, t, s, o.x, o.y));
      }
      r.isMoving = !1;
    }
    if (s && (s.target !== r || s.corner !== a)) {
      const e = s.target && s.target.controls[s.corner],
        i = e && e.getMouseUpHandler(t, s.target, e);
      o = o || this.getScenePoint(t), i && i.call(e, t, s, o.x, o.y);
    }
    this._setCursorFromEvent(t, r), this._handleEvent(t, "up"), this._groupSelector = null, this._currentTransform = null, r && (r.__corner = void 0), h ? this.requestRenderAll() : i || null !== (e = this._activeObject) && void 0 !== e && e.isEditing || this.renderTop();
  }
  _basicEventHandler(t, e) {
    const {
      target: s,
      subTargets: i = []
    } = e;
    this.fire(t, e), s && s.fire(t, e);
    for (let r = 0; r < i.length; r++) i[r] !== s && i[r].fire(t, e);
    return e;
  }
  _handleEvent(t, e) {
    const i = this._target,
      r = this.targets || [],
      n = s(s({
        e: t,
        target: i,
        subTargets: r
      }, mn(this, t)), {}, {
        transform: this._currentTransform
      }, "up:before" === e || "up" === e ? {
        isClick: this._isClick,
        currentTarget: this.findTarget(t),
        currentSubTargets: this.targets
      } : {});
    this.fire("mouse:".concat(e), n), i && i.fire("mouse".concat(e), n);
    for (let t = 0; t < r.length; t++) r[t] !== i && r[t].fire("mouse".concat(e), n);
  }
  _onMouseDownInDrawingMode(t) {
    this._isCurrentlyDrawing = !0, this.getActiveObject() && (this.discardActiveObject(t), this.requestRenderAll());
    const e = this.getScenePoint(t);
    this.freeDrawingBrush && this.freeDrawingBrush.onMouseDown(e, {
      e: t,
      pointer: e
    }), this._handleEvent(t, "down");
  }
  _onMouseMoveInDrawingMode(t) {
    if (this._isCurrentlyDrawing) {
      const e = this.getScenePoint(t);
      this.freeDrawingBrush && this.freeDrawingBrush.onMouseMove(e, {
        e: t,
        pointer: e
      });
    }
    this.setCursor(this.freeDrawingCursor), this._handleEvent(t, "move");
  }
  _onMouseUpInDrawingMode(t) {
    const e = this.getScenePoint(t);
    this.freeDrawingBrush ? this._isCurrentlyDrawing = !!this.freeDrawingBrush.onMouseUp({
      e: t,
      pointer: e
    }) : this._isCurrentlyDrawing = !1, this._handleEvent(t, "up");
  }
  __onMouseDown(t) {
    this._isClick = !0, this._cacheTransformEventData(t), this._handleEvent(t, "down:before");
    let e = this._target;
    const {
      button: s
    } = t;
    if (s) return (this.fireMiddleClick && 1 === s || this.fireRightClick && 2 === s) && this._handleEvent(t, "down"), void this._resetTransformEventData();
    if (this.isDrawingMode) return void this._onMouseDownInDrawingMode(t);
    if (!this._isMainEvent(t)) return;
    if (this._currentTransform) return;
    let i = this._shouldRender(e),
      r = !1;
    if (this.handleMultiSelection(t, e) ? (e = this._activeObject, r = !0, i = !0) : this._shouldClearSelection(t, e) && this.discardActiveObject(t), this.selection && (!e || !e.selectable && !e.isEditing && e !== this._activeObject)) {
      const e = this.getScenePoint(t);
      this._groupSelector = {
        x: e.x,
        y: e.y,
        deltaY: 0,
        deltaX: 0
      };
    }
    if (e) {
      const s = e === this._activeObject;
      e.selectable && "down" === e.activeOn && this.setActiveObject(e, t);
      const i = e.findControl(this.getViewportPoint(t), fe(t));
      if (e === this._activeObject && (i || !r)) {
        this._setupCurrentTransform(t, e, s);
        const r = i ? i.control : void 0,
          n = this.getScenePoint(t),
          o = r && r.getMouseDownHandler(t, e, r);
        o && o.call(r, t, this._currentTransform, n.x, n.y);
      }
    }
    i && (this._objectsToRender = void 0), this._handleEvent(t, "down"), i && this.requestRenderAll();
  }
  _resetTransformEventData() {
    this._target = void 0, this._pointer = void 0, this._absolutePointer = void 0;
  }
  _cacheTransformEventData(t) {
    this._resetTransformEventData(), this._pointer = this.getViewportPoint(t), this._absolutePointer = Se(this._pointer, void 0, this.viewportTransform), this._target = this._currentTransform ? this._currentTransform.target : this.findTarget(t);
  }
  __onMouseMove(t) {
    if (this._isClick = !1, this._cacheTransformEventData(t), this._handleEvent(t, "move:before"), this.isDrawingMode) return void this._onMouseMoveInDrawingMode(t);
    if (!this._isMainEvent(t)) return;
    const e = this._groupSelector;
    if (e) {
      const s = this.getScenePoint(t);
      e.deltaX = s.x - e.x, e.deltaY = s.y - e.y, this.renderTop();
    } else if (this._currentTransform) this._transformObject(t);else {
      const e = this.findTarget(t);
      this._setCursorFromEvent(t, e), this._fireOverOutEvents(t, e);
    }
    this.textEditingManager.onMouseMove(t), this._handleEvent(t, "move"), this._resetTransformEventData();
  }
  _fireOverOutEvents(t, e) {
    const s = this._hoveredTarget,
      i = this._hoveredTargets,
      r = this.targets,
      n = Math.max(i.length, r.length);
    this.fireSyntheticInOutEvents("mouse", {
      e: t,
      target: e,
      oldTarget: s,
      fireCanvas: !0
    });
    for (let e = 0; e < n; e++) this.fireSyntheticInOutEvents("mouse", {
      e: t,
      target: r[e],
      oldTarget: i[e]
    });
    this._hoveredTarget = e, this._hoveredTargets = this.targets.concat();
  }
  _fireEnterLeaveEvents(t, e) {
    const i = this._draggedoverTarget,
      r = this._hoveredTargets,
      n = this.targets,
      o = Math.max(r.length, n.length);
    this.fireSyntheticInOutEvents("drag", s(s({}, e), {}, {
      target: t,
      oldTarget: i,
      fireCanvas: !0
    }));
    for (let t = 0; t < o; t++) this.fireSyntheticInOutEvents("drag", s(s({}, e), {}, {
      target: n[t],
      oldTarget: r[t]
    }));
    this._draggedoverTarget = t;
  }
  fireSyntheticInOutEvents(t, e) {
    let {
        target: r,
        oldTarget: n,
        fireCanvas: o,
        e: a
      } = e,
      h = i(e, fn);
    const {
        targetIn: c,
        targetOut: l,
        canvasIn: u,
        canvasOut: d
      } = _n[t],
      g = n !== r;
    if (n && g) {
      const t = s(s({}, h), {}, {
        e: a,
        target: n,
        nextTarget: r
      }, mn(this, a));
      o && this.fire(d, t), n.fire(l, t);
    }
    if (r && g) {
      const t = s(s({}, h), {}, {
        e: a,
        target: r,
        previousTarget: n
      }, mn(this, a));
      o && this.fire(u, t), r.fire(c, t);
    }
  }
  __onMouseWheel(t) {
    this._cacheTransformEventData(t), this._handleEvent(t, "wheel"), this._resetTransformEventData();
  }
  _transformObject(t) {
    const e = this.getScenePoint(t),
      s = this._currentTransform,
      i = s.target,
      r = i.group ? Se(e, void 0, i.group.calcTransformMatrix()) : e;
    s.shiftKey = t.shiftKey, s.altKey = !!this.centeredKey && t[this.centeredKey], this._performTransformAction(t, s, r), s.actionPerformed && this.requestRenderAll();
  }
  _performTransformAction(t, e, s) {
    const {
        action: i,
        actionHandler: r,
        target: n
      } = e,
      o = !!r && r(t, e, s.x, s.y);
    o && n.setCoords(), "drag" === i && o && (e.target.isMoving = !0, this.setCursor(e.target.moveCursor || this.moveCursor)), e.actionPerformed = e.actionPerformed || o;
  }
  _setCursorFromEvent(t, e) {
    if (!e) return void this.setCursor(this.defaultCursor);
    let s = e.hoverCursor || this.hoverCursor;
    const i = se(this._activeObject) ? this._activeObject : null,
      r = (!i || e.group !== i) && e.findControl(this.getViewportPoint(t));
    if (r) {
      const s = r.control;
      this.setCursor(s.cursorStyleHandler(t, s, e));
    } else e.subTargetCheck && this.targets.concat().reverse().map(t => {
      s = t.hoverCursor || s;
    }), this.setCursor(s);
  }
  handleMultiSelection(t, e) {
    const s = this._activeObject,
      i = se(s);
    if (s && this._isSelectionKeyPressed(t) && this.selection && e && e.selectable && (s !== e || i) && (i || !e.isDescendantOf(s) && !s.isDescendantOf(e)) && !e.onSelect({
      e: t
    }) && !s.getActiveControl()) {
      if (i) {
        const i = s.getObjects();
        if (e === s) {
          const s = this.getViewportPoint(t);
          if (!(e = this.searchPossibleTargets(i, s) || this.searchPossibleTargets(this._objects, s)) || !e.selectable) return !1;
        }
        e.group === s ? (s.remove(e), this._hoveredTarget = e, this._hoveredTargets = [...this.targets], 1 === s.size() && this._setActiveObject(s.item(0), t)) : (s.multiSelectAdd(e), this._hoveredTarget = s, this._hoveredTargets = [...this.targets]), this._fireSelectionEvents(i, t);
      } else {
        s.exitEditing && s.exitEditing();
        const i = new (tt.getClass("ActiveSelection"))([], {
          canvas: this
        });
        i.multiSelectAdd(s, e), this._hoveredTarget = i, this._setActiveObject(i, t), this._fireSelectionEvents([s], t);
      }
      return !0;
    }
    return !1;
  }
  handleSelection(t) {
    if (!this.selection || !this._groupSelector) return !1;
    const {
        x: e,
        y: s,
        deltaX: i,
        deltaY: r
      } = this._groupSelector,
      n = new ot(e, s),
      o = n.add(new ot(i, r)),
      a = n.min(o),
      h = n.max(o).subtract(a),
      c = this.collectObjects({
        left: a.x,
        top: a.y,
        width: h.x,
        height: h.y
      }, {
        includeIntersecting: !this.selectionFullyContained
      }),
      l = n.eq(o) ? c[0] ? [c[0]] : [] : c.length > 1 ? c.filter(e => !e.onSelect({
        e: t
      })).reverse() : c;
    if (1 === l.length) this.setActiveObject(l[0], t);else if (l.length > 1) {
      const e = tt.getClass("ActiveSelection");
      this.setActiveObject(new e(l, {
        canvas: this
      }), t);
    }
    return this._groupSelector = null, !0;
  }
  clear() {
    this.textEditingManager.clear(), super.clear();
  }
  destroy() {
    this.removeListeners(), this.textEditingManager.dispose(), super.destroy();
  }
}
const Cn = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  },
  bn = s(s({}, Cn), {}, {
    r1: 0,
    r2: 0
  }),
  wn = (t, e) => isNaN(t) && "number" == typeof e ? e : t,
  Sn = /^(\d+\.\d+)%|(\d+)%$/;
function Tn(t) {
  return t && Sn.test(t);
}
function On(t, e) {
  const s = "number" == typeof t ? t : "string" == typeof t ? parseFloat(t) / (Tn(t) ? 100 : 1) : NaN;
  return ds(0, wn(s, e), 1);
}
const kn = /\s*;\s*/,
  Dn = /\s*:\s*/;
function Mn(t, e) {
  let s, i;
  const r = t.getAttribute("style");
  if (r) {
    const t = r.split(kn);
    "" === t[t.length - 1] && t.pop();
    for (let e = t.length; e--;) {
      const [r, n] = t[e].split(Dn).map(t => t.trim());
      "stop-color" === r ? s = n : "stop-opacity" === r && (i = n);
    }
  }
  const n = new Nt(s || t.getAttribute("stop-color") || "rgb(0,0,0)");
  return {
    offset: On(t.getAttribute("offset"), 0),
    color: n.toRgb(),
    opacity: wn(parseFloat(i || t.getAttribute("stop-opacity") || ""), 1) * n.getAlpha() * e
  };
}
function Pn(t, e) {
  const s = [],
    i = t.getElementsByTagName("stop"),
    r = On(e, 1);
  for (let t = i.length; t--;) s.push(Mn(i[t], r));
  return s;
}
function En(t) {
  return "linearGradient" === t.nodeName || "LINEARGRADIENT" === t.nodeName ? "linear" : "radial";
}
function An(t) {
  return "userSpaceOnUse" === t.getAttribute("gradientUnits") ? "pixels" : "percentage";
}
function jn(t, e) {
  return t.getAttribute(e);
}
function Fn(t, e) {
  return function (t, e) {
    let s,
      {
        width: i,
        height: r,
        gradientUnits: n
      } = e;
    return Object.keys(t).reduce((e, o) => {
      const a = t[o];
      return "Infinity" === a ? s = 1 : "-Infinity" === a ? s = 0 : (s = "string" == typeof a ? parseFloat(a) : a, "string" == typeof a && Tn(a) && (s *= .01, "pixels" === n && ("x1" !== o && "x2" !== o && "r2" !== o || (s *= i), "y1" !== o && "y2" !== o || (s *= r)))), e[o] = s, e;
    }, {});
  }("linear" === En(t) ? function (t) {
    return {
      x1: jn(t, "x1") || 0,
      y1: jn(t, "y1") || 0,
      x2: jn(t, "x2") || "100%",
      y2: jn(t, "y2") || 0
    };
  }(t) : function (t) {
    return {
      x1: jn(t, "fx") || jn(t, "cx") || "50%",
      y1: jn(t, "fy") || jn(t, "cy") || "50%",
      r1: 0,
      x2: jn(t, "cx") || "50%",
      y2: jn(t, "cy") || "50%",
      r2: jn(t, "r") || "50%"
    };
  }(t), s(s({}, e), {}, {
    gradientUnits: An(t)
  }));
}
class Ln {
  constructor(t) {
    const {
      type: e = "linear",
      gradientUnits: i = "pixels",
      coords: r = {},
      colorStops: n = [],
      offsetX: o = 0,
      offsetY: a = 0,
      gradientTransform: h,
      id: c
    } = t || {};
    Object.assign(this, {
      type: e,
      gradientUnits: i,
      coords: s(s({}, "radial" === e ? bn : Cn), r),
      colorStops: n,
      offsetX: o,
      offsetY: a,
      gradientTransform: h,
      id: c ? "".concat(c, "_").concat(ft()) : ft()
    });
  }
  addColorStop(t) {
    for (const e in t) {
      const s = new Nt(t[e]);
      this.colorStops.push({
        offset: parseFloat(e),
        color: s.toRgb(),
        opacity: s.getAlpha()
      });
    }
    return this;
  }
  toObject(t) {
    return s(s({}, Bt(this, t)), {}, {
      type: this.type,
      coords: s({}, this.coords),
      colorStops: this.colorStops.map(t => s({}, t)),
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      gradientUnits: this.gradientUnits,
      gradientTransform: this.gradientTransform ? [...this.gradientTransform] : void 0
    });
  }
  toSVG(t) {
    let {
      additionalTransform: e
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const i = [],
      r = this.gradientTransform ? this.gradientTransform.concat() : T.concat(),
      n = "pixels" === this.gradientUnits ? "userSpaceOnUse" : "objectBoundingBox",
      o = this.colorStops.map(t => s({}, t)).sort((t, e) => t.offset - e.offset);
    let a = -this.offsetX,
      h = -this.offsetY;
    var c;
    "objectBoundingBox" === n ? (a /= t.width, h /= t.height) : (a += t.width / 2, h += t.height / 2), (c = t) && "function" == typeof c._renderPathCommands && "percentage" !== this.gradientUnits && (a -= t.pathOffset.x, h -= t.pathOffset.y), r[4] -= a, r[5] -= h;
    const l = ['id="SVGID_'.concat(this.id, '"'), 'gradientUnits="'.concat(n, '"'), 'gradientTransform="'.concat(e ? e + " " : "").concat(Jt(r), '"'), ""].join(" ");
    if ("linear" === this.type) {
      const {
        x1: t,
        y1: e,
        x2: s,
        y2: r
      } = this.coords;
      i.push("<linearGradient ", l, ' x1="', t, '" y1="', e, '" x2="', s, '" y2="', r, '">\n');
    } else if ("radial" === this.type) {
      const {
          x1: t,
          y1: e,
          x2: s,
          y2: r,
          r1: n,
          r2: a
        } = this.coords,
        h = n > a;
      i.push("<radialGradient ", l, ' cx="', h ? t : s, '" cy="', h ? e : r, '" r="', h ? n : a, '" fx="', h ? s : t, '" fy="', h ? r : e, '">\n'), h && (o.reverse(), o.forEach(t => {
        t.offset = 1 - t.offset;
      }));
      const c = Math.min(n, a);
      if (c > 0) {
        const t = c / Math.max(n, a);
        o.forEach(e => {
          e.offset += t * (1 - e.offset);
        });
      }
    }
    return o.forEach(t => {
      let {
        color: e,
        offset: s,
        opacity: r
      } = t;
      i.push("<stop ", 'offset="', 100 * s + "%", '" style="stop-color:', e, void 0 !== r ? ";stop-opacity: " + r : ";", '"/>\n');
    }), i.push("linear" === this.type ? "</linearGradient>" : "</radialGradient>", "\n"), i.join("");
  }
  toLive(t) {
    const {
        x1: e,
        y1: s,
        x2: i,
        y2: r,
        r1: n,
        r2: o
      } = this.coords,
      a = "linear" === this.type ? t.createLinearGradient(e, s, i, r) : t.createRadialGradient(e, s, n, i, r, o);
    return this.colorStops.forEach(t => {
      let {
        color: e,
        opacity: s,
        offset: i
      } = t;
      a.addColorStop(i, void 0 !== s ? new Nt(e).setAlpha(s).toRgba() : e);
    }), a;
  }
  static async fromObject(t) {
    const {
      colorStops: e,
      gradientTransform: i
    } = t;
    return new this(s(s({}, t), {}, {
      colorStops: e ? e.map(t => s({}, t)) : void 0,
      gradientTransform: i ? [...i] : void 0
    }));
  }
  static fromElement(t, e, i) {
    const r = An(t),
      n = e._findCenterFromElement();
    return new this(s({
      id: t.getAttribute("id") || void 0,
      type: En(t),
      coords: Fn(t, {
        width: i.viewBoxWidth || i.width,
        height: i.viewBoxHeight || i.height
      }),
      colorStops: Pn(t, i.opacity),
      gradientUnits: r,
      gradientTransform: nr(t.getAttribute("gradientTransform") || "")
    }, "pixels" === r ? {
      offsetX: e.width / 2 - n.x,
      offsetY: e.height / 2 - n.y
    } : {
      offsetX: 0,
      offsetY: 0
    }));
  }
}
t(Ln, "type", "Gradient"), tt.setClass(Ln, "gradient"), tt.setClass(Ln, "linear"), tt.setClass(Ln, "radial");
const Rn = ["type", "source", "patternTransform"];
class In {
  get type() {
    return "pattern";
  }
  set type(t) {
    a("warn", "Setting type has no effect", t);
  }
  constructor(e) {
    t(this, "repeat", "repeat"), t(this, "offsetX", 0), t(this, "offsetY", 0), t(this, "crossOrigin", ""), this.id = ft(), Object.assign(this, e);
  }
  isImageSource() {
    return !!this.source && "string" == typeof this.source.src;
  }
  isCanvasSource() {
    return !!this.source && !!this.source.toDataURL;
  }
  sourceToString() {
    return this.isImageSource() ? this.source.src : this.isCanvasSource() ? this.source.toDataURL() : "";
  }
  toLive(t) {
    return this.source && (!this.isImageSource() || this.source.complete && 0 !== this.source.naturalWidth && 0 !== this.source.naturalHeight) ? t.createPattern(this.source, this.repeat) : null;
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    const {
      repeat: e,
      crossOrigin: i
    } = this;
    return s(s({}, Bt(this, t)), {}, {
      type: "pattern",
      source: this.sourceToString(),
      repeat: e,
      crossOrigin: i,
      offsetX: Ut(this.offsetX, o.NUM_FRACTION_DIGITS),
      offsetY: Ut(this.offsetY, o.NUM_FRACTION_DIGITS),
      patternTransform: this.patternTransform ? [...this.patternTransform] : null
    });
  }
  toSVG(t) {
    let {
      width: e,
      height: s
    } = t;
    const {
        source: i,
        repeat: r,
        id: n
      } = this,
      o = wn(this.offsetX / e, 0),
      a = wn(this.offsetY / s, 0),
      h = "repeat-y" === r || "no-repeat" === r ? 1 + Math.abs(o || 0) : wn(i.width / e, 0),
      c = "repeat-x" === r || "no-repeat" === r ? 1 + Math.abs(a || 0) : wn(i.height / s, 0);
    return ['<pattern id="SVGID_'.concat(n, '" x="').concat(o, '" y="').concat(a, '" width="').concat(h, '" height="').concat(c, '">'), '<image x="0" y="0" width="'.concat(i.width, '" height="').concat(i.height, '" xlink:href="').concat(this.sourceToString(), '"></image>'), "</pattern>", ""].join("\n");
  }
  static async fromObject(t, e) {
    let {
        type: r,
        source: n,
        patternTransform: o
      } = t,
      a = i(t, Rn);
    const h = await Lt(n, s(s({}, e), {}, {
      crossOrigin: a.crossOrigin
    }));
    return new this(s(s({}, a), {}, {
      patternTransform: o && o.slice(0),
      source: h
    }));
  }
}
t(In, "type", "Pattern"), tt.setClass(In), tt.setClass(In, "pattern");
class Bn {
  constructor(e) {
    t(this, "color", "rgb(0, 0, 0)"), t(this, "width", 1), t(this, "shadow", null), t(this, "strokeLineCap", "round"), t(this, "strokeLineJoin", "round"), t(this, "strokeMiterLimit", 10), t(this, "strokeDashArray", null), t(this, "limitedToCanvasSize", !1), this.canvas = e;
  }
  _setBrushStyles(t) {
    t.strokeStyle = this.color, t.lineWidth = this.width, t.lineCap = this.strokeLineCap, t.miterLimit = this.strokeMiterLimit, t.lineJoin = this.strokeLineJoin, t.setLineDash(this.strokeDashArray || []);
  }
  _saveAndTransform(t) {
    const e = this.canvas.viewportTransform;
    t.save(), t.transform(e[0], e[1], e[2], e[3], e[4], e[5]);
  }
  needsFullRender() {
    return new Nt(this.color).getAlpha() < 1 || !!this.shadow;
  }
  _setShadow() {
    if (!this.shadow || !this.canvas) return;
    const t = this.canvas,
      e = this.shadow,
      s = t.contextTop,
      i = t.getZoom() * t.getRetinaScaling();
    s.shadowColor = e.color, s.shadowBlur = e.blur * i, s.shadowOffsetX = e.offsetX * i, s.shadowOffsetY = e.offsetY * i;
  }
  _resetShadow() {
    const t = this.canvas.contextTop;
    t.shadowColor = "", t.shadowBlur = t.shadowOffsetX = t.shadowOffsetY = 0;
  }
  _isOutSideCanvas(t) {
    return t.x < 0 || t.x > this.canvas.getWidth() || t.y < 0 || t.y > this.canvas.getHeight();
  }
}
const Xn = ["path", "left", "top"],
  Wn = ["d"];
class Yn extends yi {
  constructor(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      {
        path: s,
        left: r,
        top: n
      } = e,
      o = i(e, Xn);
    super(), Object.assign(this, Yn.ownDefaults), this.setOptions(o), this._setPath(t || [], !0), "number" == typeof r && this.set(M, r), "number" == typeof n && this.set(P, n);
  }
  _setPath(t, e) {
    this.path = Xr(Array.isArray(t) ? t : sn(t)), this.setBoundingBox(e);
  }
  _findCenterFromElement() {
    const t = this._calcBoundsFromPath();
    return new ot(t.left + t.width / 2, t.top + t.height / 2);
  }
  _renderPathCommands(t) {
    const e = -this.pathOffset.x,
      s = -this.pathOffset.y;
    t.beginPath();
    for (const i of this.path) switch (i[0]) {
      case "L":
        t.lineTo(i[1] + e, i[2] + s);
        break;
      case "M":
        t.moveTo(i[1] + e, i[2] + s);
        break;
      case "C":
        t.bezierCurveTo(i[1] + e, i[2] + s, i[3] + e, i[4] + s, i[5] + e, i[6] + s);
        break;
      case "Q":
        t.quadraticCurveTo(i[1] + e, i[2] + s, i[3] + e, i[4] + s);
        break;
      case "Z":
        t.closePath();
    }
  }
  _render(t) {
    this._renderPathCommands(t), this._renderPaintInOrder(t);
  }
  toString() {
    return "#<Path (".concat(this.complexity(), '): { "top": ').concat(this.top, ', "left": ').concat(this.left, " }>");
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return s(s({}, super.toObject(t)), {}, {
      path: this.path.map(t => t.slice())
    });
  }
  toDatalessObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    const e = this.toObject(t);
    return this.sourcePath && (delete e.path, e.sourcePath = this.sourcePath), e;
  }
  _toSVG() {
    const t = nn(this.path, o.NUM_FRACTION_DIGITS);
    return ["<path ", "COMMON_PARTS", 'd="'.concat(t, '" stroke-linecap="round" />\n')];
  }
  _getOffsetTransform() {
    const t = o.NUM_FRACTION_DIGITS;
    return " translate(".concat(Ut(-this.pathOffset.x, t), ", ").concat(Ut(-this.pathOffset.y, t), ")");
  }
  toClipPathSVG(t) {
    const e = this._getOffsetTransform();
    return "\t" + this._createBaseClipPathSVGMarkup(this._toSVG(), {
      reviver: t,
      additionalTransform: e
    });
  }
  toSVG(t) {
    const e = this._getOffsetTransform();
    return this._createBaseSVGMarkup(this._toSVG(), {
      reviver: t,
      additionalTransform: e
    });
  }
  complexity() {
    return this.path.length;
  }
  setDimensions() {
    this.setBoundingBox();
  }
  setBoundingBox(t) {
    const {
      width: e,
      height: s,
      pathOffset: i
    } = this._calcDimensions();
    this.set({
      width: e,
      height: s,
      pathOffset: i
    }), t && this.setPositionByOrigin(i, D, D);
  }
  _calcBoundsFromPath() {
    const t = [];
    let e = 0,
      s = 0,
      i = 0,
      r = 0;
    for (const n of this.path) switch (n[0]) {
      case "L":
        i = n[1], r = n[2], t.push({
          x: e,
          y: s
        }, {
          x: i,
          y: r
        });
        break;
      case "M":
        i = n[1], r = n[2], e = i, s = r;
        break;
      case "C":
        t.push(...Ir(i, r, n[1], n[2], n[3], n[4], n[5], n[6])), i = n[5], r = n[6];
        break;
      case "Q":
        t.push(...Ir(i, r, n[1], n[2], n[1], n[2], n[3], n[4])), i = n[3], r = n[4];
        break;
      case "Z":
        i = e, r = s;
    }
    return me(t);
  }
  _calcDimensions() {
    const t = this._calcBoundsFromPath();
    return s(s({}, t), {}, {
      pathOffset: new ot(t.left + t.width / 2, t.top + t.height / 2)
    });
  }
  static fromObject(t) {
    return this._fromObject(t, {
      extraParam: "path"
    });
  }
  static async fromElement(t, e, r) {
    const n = ur(t, this.ATTRIBUTE_NAMES, r),
      {
        d: o
      } = n;
    return new this(o, s(s(s({}, i(n, Wn)), e), {}, {
      left: void 0,
      top: void 0
    }));
  }
}
t(Yn, "type", "Path"), t(Yn, "cacheProperties", [...fs, "path", "fillRule"]), t(Yn, "ATTRIBUTE_NAMES", [...ji, "d"]), tt.setClass(Yn), tt.setSVGClass(Yn);
class Vn extends Bn {
  constructor(e) {
    super(e), t(this, "decimate", .4), t(this, "drawStraightLine", !1), t(this, "straightLineKey", "shiftKey"), this._points = [], this._hasStraightLine = !1;
  }
  needsFullRender() {
    return super.needsFullRender() || this._hasStraightLine;
  }
  static drawSegment(t, e, s) {
    const i = e.midPointFrom(s);
    return t.quadraticCurveTo(e.x, e.y, i.x, i.y), i;
  }
  onMouseDown(t, e) {
    let {
      e: s
    } = e;
    this.canvas._isMainEvent(s) && (this.drawStraightLine = !!this.straightLineKey && s[this.straightLineKey], this._prepareForDrawing(t), this._addPoint(t), this._render());
  }
  onMouseMove(t, e) {
    let {
      e: s
    } = e;
    if (this.canvas._isMainEvent(s) && (this.drawStraightLine = !!this.straightLineKey && s[this.straightLineKey], (!0 !== this.limitedToCanvasSize || !this._isOutSideCanvas(t)) && this._addPoint(t) && this._points.length > 1)) if (this.needsFullRender()) this.canvas.clearContext(this.canvas.contextTop), this._render();else {
      const t = this._points,
        e = t.length,
        s = this.canvas.contextTop;
      this._saveAndTransform(s), this.oldEnd && (s.beginPath(), s.moveTo(this.oldEnd.x, this.oldEnd.y)), this.oldEnd = Vn.drawSegment(s, t[e - 2], t[e - 1]), s.stroke(), s.restore();
    }
  }
  onMouseUp(t) {
    let {
      e: e
    } = t;
    return !this.canvas._isMainEvent(e) || (this.drawStraightLine = !1, this.oldEnd = void 0, this._finalizeAndAddPath(), !1);
  }
  _prepareForDrawing(t) {
    this._reset(), this._addPoint(t), this.canvas.contextTop.moveTo(t.x, t.y);
  }
  _addPoint(t) {
    return !(this._points.length > 1 && t.eq(this._points[this._points.length - 1])) && (this.drawStraightLine && this._points.length > 1 && (this._hasStraightLine = !0, this._points.pop()), this._points.push(t), !0);
  }
  _reset() {
    this._points = [], this._setBrushStyles(this.canvas.contextTop), this._setShadow(), this._hasStraightLine = !1;
  }
  _render() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.canvas.contextTop,
      e = this._points[0],
      s = this._points[1];
    if (this._saveAndTransform(t), t.beginPath(), 2 === this._points.length && e.x === s.x && e.y === s.y) {
      const t = this.width / 1e3;
      e.x -= t, s.x += t;
    }
    t.moveTo(e.x, e.y);
    for (let i = 1; i < this._points.length; i++) Vn.drawSegment(t, e, s), e = this._points[i], s = this._points[i + 1];
    t.lineTo(e.x, e.y), t.stroke(), t.restore();
  }
  convertPointsToSVGPath(t) {
    const e = this.width / 1e3;
    return rn(t, e);
  }
  createPath(t) {
    const e = new Yn(t, {
      fill: null,
      stroke: this.color,
      strokeWidth: this.width,
      strokeLineCap: this.strokeLineCap,
      strokeMiterLimit: this.strokeMiterLimit,
      strokeLineJoin: this.strokeLineJoin,
      strokeDashArray: this.strokeDashArray
    });
    return this.shadow && (this.shadow.affectStroke = !0, e.shadow = new us(this.shadow)), e;
  }
  decimatePoints(t, e) {
    if (t.length <= 2) return t;
    let s,
      i = t[0];
    const r = this.canvas.getZoom(),
      n = Math.pow(e / r, 2),
      o = t.length - 1,
      a = [i];
    for (let e = 1; e < o - 1; e++) s = Math.pow(i.x - t[e].x, 2) + Math.pow(i.y - t[e].y, 2), s >= n && (i = t[e], a.push(i));
    return a.push(t[o]), a;
  }
  _finalizeAndAddPath() {
    this.canvas.contextTop.closePath(), this.decimate && (this._points = this.decimatePoints(this._points, this.decimate));
    const t = this.convertPointsToSVGPath(this._points);
    if (function (t) {
      return "M 0 0 Q 0 0 0 0 L 0 0" === nn(t);
    }(t)) return void this.canvas.requestRenderAll();
    const e = this.createPath(t);
    this.canvas.clearContext(this.canvas.contextTop), this.canvas.fire("before:path:created", {
      path: e
    }), this.canvas.add(e), this.canvas.requestRenderAll(), e.setCoords(), this._resetShadow(), this.canvas.fire("path:created", {
      path: e
    });
  }
}
const Hn = ["left", "top", "radius"],
  zn = ["radius", "startAngle", "endAngle", "counterClockwise"];
class Gn extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Gn.ownDefaults);
  }
  constructor(t) {
    super(), Object.assign(this, Gn.ownDefaults), this.setOptions(t);
  }
  _set(t, e) {
    return super._set(t, e), "radius" === t && this.setRadius(e), this;
  }
  _render(t) {
    t.beginPath(), t.arc(0, 0, this.radius, yt(this.startAngle), yt(this.endAngle), this.counterClockwise), this._renderPaintInOrder(t);
  }
  getRadiusX() {
    return this.get("radius") * this.get(G);
  }
  getRadiusY() {
    return this.get("radius") * this.get(N);
  }
  setRadius(t) {
    this.radius = t, this.set({
      width: 2 * t,
      height: 2 * t
    });
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return super.toObject([...zn, ...t]);
  }
  _toSVG() {
    const t = (this.endAngle - this.startAngle) % 360;
    if (0 === t) return ["<circle ", "COMMON_PARTS", 'cx="0" cy="0" ', 'r="', "".concat(this.radius), '" />\n'];
    {
      const {
          radius: e
        } = this,
        s = yt(this.startAngle),
        i = yt(this.endAngle),
        r = rt(s) * e,
        n = nt(s) * e,
        o = rt(i) * e,
        a = nt(i) * e,
        h = t > 180 ? 1 : 0,
        c = this.counterClockwise ? 0 : 1;
      return ['<path d="M '.concat(r, " ").concat(n, " A ").concat(e, " ").concat(e, " 0 ").concat(h, " ").concat(c, " ").concat(o, " ").concat(a, '" '), "COMMON_PARTS", " />\n"];
    }
  }
  static async fromElement(t, e, r) {
    const n = ur(t, this.ATTRIBUTE_NAMES, r),
      {
        left: o = 0,
        top: a = 0,
        radius: h = 0
      } = n;
    return new this(s(s({}, i(n, Hn)), {}, {
      radius: h,
      left: o - h,
      top: a - h
    }));
  }
  static fromObject(t) {
    return super._fromObject(t);
  }
}
t(Gn, "type", "Circle"), t(Gn, "cacheProperties", [...fs, ...zn]), t(Gn, "ownDefaults", {
  radius: 0,
  startAngle: 0,
  endAngle: 360,
  counterClockwise: !1
}), t(Gn, "ATTRIBUTE_NAMES", ["cx", "cy", "r", ...ji]), tt.setClass(Gn), tt.setSVGClass(Gn);
class Nn extends Bn {
  constructor(e) {
    super(e), t(this, "width", 10), this.points = [];
  }
  drawDot(t) {
    const e = this.addPoint(t),
      s = this.canvas.contextTop;
    this._saveAndTransform(s), this.dot(s, e), s.restore();
  }
  dot(t, e) {
    t.fillStyle = e.fill, t.beginPath(), t.arc(e.x, e.y, e.radius, 0, 2 * Math.PI, !1), t.closePath(), t.fill();
  }
  onMouseDown(t) {
    this.points = [], this.canvas.clearContext(this.canvas.contextTop), this._setShadow(), this.drawDot(t);
  }
  _render() {
    const t = this.canvas.contextTop,
      e = this.points;
    this._saveAndTransform(t);
    for (let s = 0; s < e.length; s++) this.dot(t, e[s]);
    t.restore();
  }
  onMouseMove(t) {
    !0 === this.limitedToCanvasSize && this._isOutSideCanvas(t) || (this.needsFullRender() ? (this.canvas.clearContext(this.canvas.contextTop), this.addPoint(t), this._render()) : this.drawDot(t));
  }
  onMouseUp() {
    const t = this.canvas.renderOnAddRemove;
    this.canvas.renderOnAddRemove = !1;
    const e = [];
    for (let t = 0; t < this.points.length; t++) {
      const s = this.points[t],
        i = new Gn({
          radius: s.radius,
          left: s.x,
          top: s.y,
          originX: D,
          originY: D,
          fill: s.fill
        });
      this.shadow && (i.shadow = new us(this.shadow)), e.push(i);
    }
    const s = new Dr(e, {
      canvas: this.canvas
    });
    this.canvas.fire("before:path:created", {
      path: s
    }), this.canvas.add(s), this.canvas.fire("path:created", {
      path: s
    }), this.canvas.clearContext(this.canvas.contextTop), this._resetShadow(), this.canvas.renderOnAddRemove = t, this.canvas.requestRenderAll();
  }
  addPoint(t) {
    let {
      x: e,
      y: s
    } = t;
    const i = {
      x: e,
      y: s,
      radius: an(Math.max(0, this.width - 20), this.width + 20) / 2,
      fill: new Nt(this.color).setAlpha(an(0, 100) / 100).toRgba()
    };
    return this.points.push(i), i;
  }
}
class Un extends Bn {
  constructor(e) {
    super(e), t(this, "width", 10), t(this, "density", 20), t(this, "dotWidth", 1), t(this, "dotWidthVariance", 1), t(this, "randomOpacity", !1), t(this, "optimizeOverlapping", !0), this.sprayChunks = [], this.sprayChunk = [];
  }
  onMouseDown(t) {
    this.sprayChunks = [], this.canvas.clearContext(this.canvas.contextTop), this._setShadow(), this.addSprayChunk(t), this.renderChunck(this.sprayChunk);
  }
  onMouseMove(t) {
    !0 === this.limitedToCanvasSize && this._isOutSideCanvas(t) || (this.addSprayChunk(t), this.renderChunck(this.sprayChunk));
  }
  onMouseUp() {
    const t = this.canvas.renderOnAddRemove;
    this.canvas.renderOnAddRemove = !1;
    const e = [];
    for (let t = 0; t < this.sprayChunks.length; t++) {
      const s = this.sprayChunks[t];
      for (let t = 0; t < s.length; t++) {
        const i = s[t],
          r = new fr({
            width: i.width,
            height: i.width,
            left: i.x + 1,
            top: i.y + 1,
            originX: D,
            originY: D,
            fill: this.color
          });
        e.push(r);
      }
    }
    const s = new Dr(this.optimizeOverlapping ? function (t) {
      const e = {},
        s = [];
      for (let i, r = 0; r < t.length; r++) i = "".concat(t[r].left).concat(t[r].top), e[i] || (e[i] = !0, s.push(t[r]));
      return s;
    }(e) : e, {
      objectCaching: !0,
      subTargetCheck: !1,
      interactive: !1
    });
    this.shadow && s.set("shadow", new us(this.shadow)), this.canvas.fire("before:path:created", {
      path: s
    }), this.canvas.add(s), this.canvas.fire("path:created", {
      path: s
    }), this.canvas.clearContext(this.canvas.contextTop), this._resetShadow(), this.canvas.renderOnAddRemove = t, this.canvas.requestRenderAll();
  }
  renderChunck(t) {
    const e = this.canvas.contextTop;
    e.fillStyle = this.color, this._saveAndTransform(e);
    for (let s = 0; s < t.length; s++) {
      const i = t[s];
      e.globalAlpha = i.opacity, e.fillRect(i.x, i.y, i.width, i.width);
    }
    e.restore();
  }
  _render() {
    const t = this.canvas.contextTop;
    t.fillStyle = this.color, this._saveAndTransform(t);
    for (let t = 0; t < this.sprayChunks.length; t++) this.renderChunck(this.sprayChunks[t]);
    t.restore();
  }
  addSprayChunk(t) {
    this.sprayChunk = [];
    const e = this.width / 2;
    for (let s = 0; s < this.density; s++) this.sprayChunk.push({
      x: an(t.x - e, t.x + e),
      y: an(t.y - e, t.y + e),
      width: this.dotWidthVariance ? an(Math.max(1, this.dotWidth - this.dotWidthVariance), this.dotWidth + this.dotWidthVariance) : this.dotWidth,
      opacity: this.randomOpacity ? an(0, 100) / 100 : 1
    });
    this.sprayChunks.push(this.sprayChunk);
  }
}
class qn extends Vn {
  constructor(t) {
    super(t);
  }
  getPatternSrc() {
    const t = pt(),
      e = t.getContext("2d");
    return t.width = t.height = 25, e && (e.fillStyle = this.color, e.beginPath(), e.arc(10, 10, 10, 0, 2 * Math.PI, !1), e.closePath(), e.fill()), t;
  }
  getPattern(t) {
    return t.createPattern(this.source || this.getPatternSrc(), "repeat");
  }
  _setBrushStyles(t) {
    super._setBrushStyles(t);
    const e = this.getPattern(t);
    e && (t.strokeStyle = e);
  }
  createPath(t) {
    const e = super.createPath(t),
      s = e._getLeftTopCoords().scalarAdd(e.strokeWidth / 2);
    return e.stroke = new In({
      source: this.source || this.getPatternSrc(),
      offsetX: -s.x,
      offsetY: -s.y
    }), e;
  }
}
const Kn = ["x1", "y1", "x2", "y2"],
  Jn = ["x1", "y1", "x2", "y2"],
  Qn = ["x1", "x2", "y1", "y2"];
class Zn extends yi {
  constructor() {
    let [t, e, s, i] = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [0, 0, 0, 0],
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(), Object.assign(this, Zn.ownDefaults), this.setOptions(r), this.x1 = t, this.x2 = s, this.y1 = e, this.y2 = i, this._setWidthHeight();
    const {
      left: n,
      top: o
    } = r;
    "number" == typeof n && this.set(M, n), "number" == typeof o && this.set(P, o);
  }
  _setWidthHeight() {
    const {
      x1: t,
      y1: e,
      x2: s,
      y2: i
    } = this;
    this.width = Math.abs(s - t), this.height = Math.abs(i - e);
    const {
        left: r,
        top: n,
        width: o,
        height: a
      } = me([{
        x: t,
        y: e
      }, {
        x: s,
        y: i
      }]),
      h = new ot(r + o / 2, n + a / 2);
    this.setPositionByOrigin(h, D, D);
  }
  _set(t, e) {
    return super._set(t, e), Qn.includes(t) && this._setWidthHeight(), this;
  }
  _render(t) {
    t.beginPath();
    const e = this.calcLinePoints();
    t.moveTo(e.x1, e.y1), t.lineTo(e.x2, e.y2), t.lineWidth = this.strokeWidth;
    const s = t.strokeStyle;
    var i;
    Zt(this.stroke) ? t.strokeStyle = this.stroke.toLive(t) : t.strokeStyle = null !== (i = this.stroke) && void 0 !== i ? i : t.fillStyle;
    this.stroke && this._renderStroke(t), t.strokeStyle = s;
  }
  _findCenterFromElement() {
    return new ot((this.x1 + this.x2) / 2, (this.y1 + this.y2) / 2);
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return s(s({}, super.toObject(t)), this.calcLinePoints());
  }
  _getNonTransformedDimensions() {
    const t = super._getNonTransformedDimensions();
    return "butt" === this.strokeLineCap && (0 === this.width && (t.y -= this.strokeWidth), 0 === this.height && (t.x -= this.strokeWidth)), t;
  }
  calcLinePoints() {
    const {
        x1: t,
        x2: e,
        y1: s,
        y2: i,
        width: r,
        height: n
      } = this,
      o = t <= e ? -1 : 1,
      a = s <= i ? -1 : 1;
    return {
      x1: o * r / 2,
      x2: o * -r / 2,
      y1: a * n / 2,
      y2: a * -n / 2
    };
  }
  _toSVG() {
    const {
      x1: t,
      x2: e,
      y1: s,
      y2: i
    } = this.calcLinePoints();
    return ["<line ", "COMMON_PARTS", 'x1="'.concat(t, '" y1="').concat(s, '" x2="').concat(e, '" y2="').concat(i, '" />\n')];
  }
  static async fromElement(t, e, s) {
    const r = ur(t, this.ATTRIBUTE_NAMES, s),
      {
        x1: n = 0,
        y1: o = 0,
        x2: a = 0,
        y2: h = 0
      } = r;
    return new this([n, o, a, h], i(r, Kn));
  }
  static fromObject(t) {
    let {
        x1: e,
        y1: r,
        x2: n,
        y2: o
      } = t,
      a = i(t, Jn);
    return this._fromObject(s(s({}, a), {}, {
      points: [e, r, n, o]
    }), {
      extraParam: "points"
    });
  }
}
t(Zn, "type", "Line"), t(Zn, "cacheProperties", [...fs, ...Qn]), t(Zn, "ATTRIBUTE_NAMES", ji.concat(Qn)), tt.setClass(Zn), tt.setSVGClass(Zn);
class $n extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), $n.ownDefaults);
  }
  constructor(t) {
    super(), Object.assign(this, $n.ownDefaults), this.setOptions(t);
  }
  _render(t) {
    const e = this.width / 2,
      s = this.height / 2;
    t.beginPath(), t.moveTo(-e, s), t.lineTo(0, -s), t.lineTo(e, s), t.closePath(), this._renderPaintInOrder(t);
  }
  _toSVG() {
    const t = this.width / 2,
      e = this.height / 2;
    return ["<polygon ", "COMMON_PARTS", 'points="', "".concat(-t, " ").concat(e, ",0 ").concat(-e, ",").concat(t, " ").concat(e), '" />'];
  }
}
t($n, "type", "Triangle"), t($n, "ownDefaults", {
  width: 100,
  height: 100
}), tt.setClass($n), tt.setSVGClass($n);
const to = ["rx", "ry"];
class eo extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), eo.ownDefaults);
  }
  constructor(t) {
    super(), Object.assign(this, eo.ownDefaults), this.setOptions(t);
  }
  _set(t, e) {
    switch (super._set(t, e), t) {
      case "rx":
        this.rx = e, this.set("width", 2 * e);
        break;
      case "ry":
        this.ry = e, this.set("height", 2 * e);
    }
    return this;
  }
  getRx() {
    return this.get("rx") * this.get(G);
  }
  getRy() {
    return this.get("ry") * this.get(N);
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return super.toObject([...to, ...t]);
  }
  _toSVG() {
    return ["<ellipse ", "COMMON_PARTS", 'cx="0" cy="0" rx="'.concat(this.rx, '" ry="').concat(this.ry, '" />\n')];
  }
  _render(t) {
    t.beginPath(), t.save(), t.transform(1, 0, 0, this.ry / this.rx, 0, 0), t.arc(0, 0, this.rx, 0, w, !1), t.restore(), this._renderPaintInOrder(t);
  }
  static async fromElement(t, e, s) {
    const i = ur(t, this.ATTRIBUTE_NAMES, s);
    return i.left = (i.left || 0) - i.rx, i.top = (i.top || 0) - i.ry, new this(i);
  }
}
function so(t) {
  if (!t) return [];
  const e = t.replace(/,/g, " ").trim().split(/\s+/),
    s = [];
  for (let t = 0; t < e.length; t += 2) s.push({
    x: parseFloat(e[t]),
    y: parseFloat(e[t + 1])
  });
  return s;
}
t(eo, "type", "Ellipse"), t(eo, "cacheProperties", [...fs, ...to]), t(eo, "ownDefaults", {
  rx: 0,
  ry: 0
}), t(eo, "ATTRIBUTE_NAMES", [...ji, "cx", "cy", "rx", "ry"]), tt.setClass(eo), tt.setSVGClass(eo);
const io = ["left", "top"],
  ro = {
    exactBoundingBox: !1
  };
class no extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), no.ownDefaults);
  }
  constructor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(), t(this, "strokeDiff", void 0), Object.assign(this, no.ownDefaults), this.setOptions(s), this.points = e;
    const {
      left: i,
      top: r
    } = s;
    this.initialized = !0, this.setBoundingBox(!0), "number" == typeof i && this.set(M, i), "number" == typeof r && this.set(P, r);
  }
  isOpen() {
    return !0;
  }
  _projectStrokeOnPoints(t) {
    return Si(this.points, t, this.isOpen());
  }
  _calcDimensions(t) {
    t = s({
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      skewX: this.skewX,
      skewY: this.skewY,
      strokeLineCap: this.strokeLineCap,
      strokeLineJoin: this.strokeLineJoin,
      strokeMiterLimit: this.strokeMiterLimit,
      strokeUniform: this.strokeUniform,
      strokeWidth: this.strokeWidth
    }, t || {});
    const e = this.exactBoundingBox ? this._projectStrokeOnPoints(t).map(t => t.projectedPoint) : this.points;
    if (0 === e.length) return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      pathOffset: new ot(),
      strokeOffset: new ot(),
      strokeDiff: new ot()
    };
    const i = me(e),
      r = jt(s(s({}, t), {}, {
        scaleX: 1,
        scaleY: 1
      })),
      n = me(this.points.map(t => Ct(t, r, !0))),
      o = new ot(this.scaleX, this.scaleY);
    let a = i.left + i.width / 2,
      h = i.top + i.height / 2;
    return this.exactBoundingBox && (a -= h * Math.tan(yt(this.skewX)), h -= a * Math.tan(yt(this.skewY))), s(s({}, i), {}, {
      pathOffset: new ot(a, h),
      strokeOffset: new ot(n.left, n.top).subtract(new ot(i.left, i.top)).multiply(o),
      strokeDiff: new ot(i.width, i.height).subtract(new ot(n.width, n.height)).multiply(o)
    });
  }
  _findCenterFromElement() {
    const t = me(this.points);
    return new ot(t.left + t.width / 2, t.top + t.height / 2);
  }
  setDimensions() {
    this.setBoundingBox();
  }
  setBoundingBox(t) {
    const {
      left: e,
      top: s,
      width: i,
      height: r,
      pathOffset: n,
      strokeOffset: o,
      strokeDiff: a
    } = this._calcDimensions();
    this.set({
      width: i,
      height: r,
      pathOffset: n,
      strokeOffset: o,
      strokeDiff: a
    }), t && this.setPositionByOrigin(new ot(e + i / 2, s + r / 2), D, D);
  }
  isStrokeAccountedForInDimensions() {
    return this.exactBoundingBox;
  }
  _getNonTransformedDimensions() {
    return this.exactBoundingBox ? new ot(this.width, this.height) : super._getNonTransformedDimensions();
  }
  _getTransformedDimensions() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    if (this.exactBoundingBox) {
      let n;
      if (Object.keys(t).some(t => this.strokeUniform || this.constructor.layoutProperties.includes(t))) {
        var e, s;
        const {
          width: i,
          height: r
        } = this._calcDimensions(t);
        n = new ot(null !== (e = t.width) && void 0 !== e ? e : i, null !== (s = t.height) && void 0 !== s ? s : r);
      } else {
        var i, r;
        n = new ot(null !== (i = t.width) && void 0 !== i ? i : this.width, null !== (r = t.height) && void 0 !== r ? r : this.height);
      }
      return n.multiply(new ot(t.scaleX || this.scaleX, t.scaleY || this.scaleY));
    }
    return super._getTransformedDimensions(t);
  }
  _set(t, e) {
    const s = this.initialized && this[t] !== e,
      i = super._set(t, e);
    return this.exactBoundingBox && s && ((t === G || t === N) && this.strokeUniform && this.constructor.layoutProperties.includes("strokeUniform") || this.constructor.layoutProperties.includes(t)) && this.setDimensions(), i;
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return s(s({}, super.toObject(t)), {}, {
      points: this.points.map(t => {
        let {
          x: e,
          y: s
        } = t;
        return {
          x: e,
          y: s
        };
      })
    });
  }
  _toSVG() {
    const t = [],
      e = this.pathOffset.x,
      s = this.pathOffset.y,
      i = o.NUM_FRACTION_DIGITS;
    for (let r = 0, n = this.points.length; r < n; r++) t.push(Ut(this.points[r].x - e, i), ",", Ut(this.points[r].y - s, i), " ");
    return ["<".concat(this.constructor.type.toLowerCase(), " "), "COMMON_PARTS", 'points="'.concat(t.join(""), '" />\n')];
  }
  _render(t) {
    const e = this.points.length,
      s = this.pathOffset.x,
      i = this.pathOffset.y;
    if (e && !isNaN(this.points[e - 1].y)) {
      t.beginPath(), t.moveTo(this.points[0].x - s, this.points[0].y - i);
      for (let r = 0; r < e; r++) {
        const e = this.points[r];
        t.lineTo(e.x - s, e.y - i);
      }
      !this.isOpen() && t.closePath(), this._renderPaintInOrder(t);
    }
  }
  complexity() {
    return this.points.length;
  }
  static async fromElement(t, e, r) {
    return new this(so(t.getAttribute("points")), s(s({}, i(ur(t, this.ATTRIBUTE_NAMES, r), io)), e));
  }
  static fromObject(t) {
    return this._fromObject(t, {
      extraParam: "points"
    });
  }
}
t(no, "ownDefaults", ro), t(no, "type", "Polyline"), t(no, "layoutProperties", [U, q, "strokeLineCap", "strokeLineJoin", "strokeMiterLimit", "strokeWidth", "strokeUniform", "points"]), t(no, "cacheProperties", [...fs, "points"]), t(no, "ATTRIBUTE_NAMES", [...ji]), tt.setClass(no), tt.setSVGClass(no);
class oo extends no {
  isOpen() {
    return !1;
  }
}
t(oo, "ownDefaults", ro), t(oo, "type", "Polygon"), tt.setClass(oo), tt.setSVGClass(oo);
const ao = ["fontSize", "fontWeight", "fontFamily", "fontStyle"],
  ho = ["underline", "overline", "linethrough"],
  co = [...ao, "lineHeight", "text", "charSpacing", "textAlign", "styles", "path", "pathStartOffset", "pathSide", "pathAlign"],
  lo = [...co, ...ho, "textBackgroundColor", "direction"],
  uo = [...ao, ...ho, J, "strokeWidth", K, "deltaY", "textBackgroundColor"],
  go = {
    _reNewline: F,
    _reSpacesAndTabs: /[ \t\r]/g,
    _reSpaceAndTab: /[ \t\r]/,
    _reWords: /\S+/g,
    fontSize: 40,
    fontWeight: "normal",
    fontFamily: "Times New Roman",
    underline: !1,
    overline: !1,
    linethrough: !1,
    textAlign: M,
    fontStyle: "normal",
    lineHeight: 1.16,
    superscript: {
      size: .6,
      baseline: -.35
    },
    subscript: {
      size: .6,
      baseline: .11
    },
    textBackgroundColor: "",
    stroke: null,
    shadow: null,
    path: void 0,
    pathStartOffset: 0,
    pathSide: M,
    pathAlign: "baseline",
    _fontSizeFraction: .222,
    offsets: {
      underline: .1,
      linethrough: -.315,
      overline: -.88
    },
    _fontSizeMult: 1.13,
    charSpacing: 0,
    deltaY: 0,
    direction: "ltr",
    CACHE_FONT_SIZE: 400,
    MIN_TEXT_WIDTH: 2
  },
  fo = "justify",
  po = "justify-left",
  mo = "justify-right",
  vo = "justify-center";
class yo extends yi {
  isEmptyStyles(t) {
    if (!this.styles) return !0;
    if (void 0 !== t && !this.styles[t]) return !0;
    const e = void 0 === t ? this.styles : {
      line: this.styles[t]
    };
    for (const t in e) for (const s in e[t]) for (const i in e[t][s]) return !1;
    return !0;
  }
  styleHas(t, e) {
    if (!this.styles) return !1;
    if (void 0 !== e && !this.styles[e]) return !1;
    const s = void 0 === e ? this.styles : {
      0: this.styles[e]
    };
    for (const e in s) for (const i in s[e]) if (void 0 !== s[e][i][t]) return !0;
    return !1;
  }
  cleanStyle(t) {
    if (!this.styles) return !1;
    const e = this.styles;
    let s,
      i,
      r = 0,
      n = !0,
      o = 0;
    for (const o in e) {
      s = 0;
      for (const a in e[o]) {
        const h = e[o][a] || {};
        r++, void 0 !== h[t] ? (i ? h[t] !== i && (n = !1) : i = h[t], h[t] === this[t] && delete h[t]) : n = !1, 0 !== Object.keys(h).length ? s++ : delete e[o][a];
      }
      0 === s && delete e[o];
    }
    for (let t = 0; t < this._textLines.length; t++) o += this._textLines[t].length;
    n && r === o && (this[t] = i, this.removeStyle(t));
  }
  removeStyle(t) {
    if (!this.styles) return;
    const e = this.styles;
    let s, i, r;
    for (i in e) {
      for (r in s = e[i], s) delete s[r][t], 0 === Object.keys(s[r]).length && delete s[r];
      0 === Object.keys(s).length && delete e[i];
    }
  }
  _extendStyles(t, e) {
    const {
      lineIndex: i,
      charIndex: r
    } = this.get2DCursorLocation(t);
    this._getLineStyle(i) || this._setLineStyle(i);
    const n = Xt(s(s({}, this._getStyleDeclaration(i, r)), e), t => void 0 !== t);
    this._setStyleDeclaration(i, r, n);
  }
  getSelectionStyles(t, e, s) {
    const i = [];
    for (let r = t; r < (e || t); r++) i.push(this.getStyleAtPosition(r, s));
    return i;
  }
  getStyleAtPosition(t, e) {
    const {
      lineIndex: s,
      charIndex: i
    } = this.get2DCursorLocation(t);
    return e ? this.getCompleteStyleDeclaration(s, i) : this._getStyleDeclaration(s, i);
  }
  setSelectionStyles(t, e, s) {
    for (let i = e; i < (s || e); i++) this._extendStyles(i, t);
    this._forceClearCache = !0;
  }
  _getStyleDeclaration(t, e) {
    var s;
    const i = this.styles && this.styles[t];
    return i && null !== (s = i[e]) && void 0 !== s ? s : {};
  }
  getCompleteStyleDeclaration(t, e) {
    return s(s({}, Bt(this, this.constructor._styleProperties)), this._getStyleDeclaration(t, e));
  }
  _setStyleDeclaration(t, e, s) {
    this.styles[t][e] = s;
  }
  _deleteStyleDeclaration(t, e) {
    delete this.styles[t][e];
  }
  _getLineStyle(t) {
    return !!this.styles[t];
  }
  _setLineStyle(t) {
    this.styles[t] = {};
  }
  _deleteLineStyle(t) {
    delete this.styles[t];
  }
}
t(yo, "_styleProperties", uo);
const _o = /  +/g,
  xo = /"/g;
function Co(t, e, s, i, r) {
  return "\t\t".concat(function (t, e) {
    let {
        left: s,
        top: i,
        width: r,
        height: n
      } = e,
      a = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : o.NUM_FRACTION_DIGITS;
    const h = Qt(K, t, !1),
      [c, l, u, d] = [s, i, r, n].map(t => Ut(t, a));
    return "<rect ".concat(h, ' x="').concat(c, '" y="').concat(l, '" width="').concat(u, '" height="').concat(d, '"></rect>');
  }(t, {
    left: e,
    top: s,
    width: i,
    height: r
  }), "\n");
}
const bo = ["textAnchor", "textDecoration", "dx", "dy", "top", "left", "fontSize", "strokeWidth"];
let wo;
class So extends yo {
  static getDefaults() {
    return s(s({}, super.getDefaults()), So.ownDefaults);
  }
  constructor(e, s) {
    super(), t(this, "__charBounds", []), Object.assign(this, So.ownDefaults), this.setOptions(s), this.styles || (this.styles = {}), this.text = e, this.initialized = !0, this.path && this.setPathInfo(), this.initDimensions(), this.setCoords();
  }
  setPathInfo() {
    const t = this.path;
    t && (t.segmentsInfo = Jr(t.path));
  }
  _splitText() {
    const t = this._splitTextIntoLines(this.text);
    return this.textLines = t.lines, this._textLines = t.graphemeLines, this._unwrappedTextLines = t._unwrappedLines, this._text = t.graphemeText, t;
  }
  initDimensions() {
    this._splitText(), this._clearCache(), this.dirty = !0, this.path ? (this.width = this.path.width, this.height = this.path.height) : (this.width = this.calcTextWidth() || this.cursorWidth || this.MIN_TEXT_WIDTH, this.height = this.calcTextHeight()), this.textAlign.includes(fo) && this.enlargeSpaces();
  }
  enlargeSpaces() {
    let t, e, s, i, r, n, o;
    for (let a = 0, h = this._textLines.length; a < h; a++) if ((this.textAlign === fo || a !== h - 1 && !this.isEndOfWrapping(a)) && (i = 0, r = this._textLines[a], e = this.getLineWidth(a), e < this.width && (o = this.textLines[a].match(this._reSpacesAndTabs)))) {
      s = o.length, t = (this.width - e) / s;
      for (let e = 0; e <= r.length; e++) n = this.__charBounds[a][e], this._reSpaceAndTab.test(r[e]) ? (n.width += t, n.kernedWidth += t, n.left += i, i += t) : n.left += i;
    }
  }
  isEndOfWrapping(t) {
    return t === this._textLines.length - 1;
  }
  missingNewlineOffset(t) {
    return 1;
  }
  get2DCursorLocation(t, e) {
    const s = e ? this._unwrappedTextLines : this._textLines;
    let i;
    for (i = 0; i < s.length; i++) {
      if (t <= s[i].length) return {
        lineIndex: i,
        charIndex: t
      };
      t -= s[i].length + this.missingNewlineOffset(i, e);
    }
    return {
      lineIndex: i - 1,
      charIndex: s[i - 1].length < t ? s[i - 1].length : t
    };
  }
  toString() {
    return "#<Text (".concat(this.complexity(), '): { "text": "').concat(this.text, '", "fontFamily": "').concat(this.fontFamily, '" }>');
  }
  _getCacheCanvasDimensions() {
    const t = super._getCacheCanvasDimensions(),
      e = this.fontSize;
    return t.width += e * t.zoomX, t.height += e * t.zoomY, t;
  }
  _render(t) {
    const e = this.path;
    e && !e.isNotVisible() && e._render(t), this._setTextStyles(t), this._renderTextLinesBackground(t), this._renderTextDecoration(t, "underline"), this._renderText(t), this._renderTextDecoration(t, "overline"), this._renderTextDecoration(t, "linethrough");
  }
  _renderText(t) {
    this.paintFirst === J ? (this._renderTextStroke(t), this._renderTextFill(t)) : (this._renderTextFill(t), this._renderTextStroke(t));
  }
  _setTextStyles(t, e, s) {
    if (t.textBaseline = "alphabetic", this.path) switch (this.pathAlign) {
      case D:
        t.textBaseline = "middle";
        break;
      case "ascender":
        t.textBaseline = P;
        break;
      case "descender":
        t.textBaseline = E;
    }
    t.font = this._getFontDeclaration(e, s);
  }
  calcTextWidth() {
    let t = this.getLineWidth(0);
    for (let e = 1, s = this._textLines.length; e < s; e++) {
      const s = this.getLineWidth(e);
      s > t && (t = s);
    }
    return t;
  }
  _renderTextLine(t, e, s, i, r, n) {
    this._renderChars(t, e, s, i, r, n);
  }
  _renderTextLinesBackground(t) {
    if (!this.textBackgroundColor && !this.styleHas("textBackgroundColor")) return;
    const e = t.fillStyle,
      s = this._getLeftOffset();
    let i = this._getTopOffset();
    for (let e = 0, r = this._textLines.length; e < r; e++) {
      const r = this.getHeightOfLine(e);
      if (!this.textBackgroundColor && !this.styleHas("textBackgroundColor", e)) {
        i += r;
        continue;
      }
      const n = this._textLines[e].length,
        o = this._getLineLeftOffset(e);
      let a,
        h,
        c = 0,
        l = 0,
        u = this.getValueOfPropertyAt(e, 0, "textBackgroundColor");
      for (let d = 0; d < n; d++) {
        const n = this.__charBounds[e][d];
        h = this.getValueOfPropertyAt(e, d, "textBackgroundColor"), this.path ? (t.save(), t.translate(n.renderLeft, n.renderTop), t.rotate(n.angle), t.fillStyle = h, h && t.fillRect(-n.width / 2, -r / this.lineHeight * (1 - this._fontSizeFraction), n.width, r / this.lineHeight), t.restore()) : h !== u ? (a = s + o + l, "rtl" === this.direction && (a = this.width - a - c), t.fillStyle = u, u && t.fillRect(a, i, c, r / this.lineHeight), l = n.left, c = n.width, u = h) : c += n.kernedWidth;
      }
      h && !this.path && (a = s + o + l, "rtl" === this.direction && (a = this.width - a - c), t.fillStyle = h, t.fillRect(a, i, c, r / this.lineHeight)), i += r;
    }
    t.fillStyle = e, this._removeShadow(t);
  }
  _measureChar(t, e, s, i) {
    const r = _.getFontCache(e),
      n = this._getFontDeclaration(e),
      o = s + t,
      a = s && n === this._getFontDeclaration(i),
      h = e.fontSize / this.CACHE_FONT_SIZE;
    let c, l, u, d;
    if (s && void 0 !== r[s] && (u = r[s]), void 0 !== r[t] && (d = c = r[t]), a && void 0 !== r[o] && (l = r[o], d = l - u), void 0 === c || void 0 === u || void 0 === l) {
      const i = function () {
        if (!wo) {
          const t = pt();
          t.width = t.height = 0, wo = t.getContext("2d");
        }
        return wo;
      }();
      this._setTextStyles(i, e, !0), void 0 === c && (d = c = i.measureText(t).width, r[t] = c), void 0 === u && a && s && (u = i.measureText(s).width, r[s] = u), a && void 0 === l && (l = i.measureText(o).width, r[o] = l, d = l - u);
    }
    return {
      width: c * h,
      kernedWidth: d * h
    };
  }
  getHeightOfChar(t, e) {
    return this.getValueOfPropertyAt(t, e, "fontSize");
  }
  measureLine(t) {
    const e = this._measureLine(t);
    return 0 !== this.charSpacing && (e.width -= this._getWidthOfCharSpacing()), e.width < 0 && (e.width = 0), e;
  }
  _measureLine(t) {
    let e,
      s,
      i = 0;
    const r = this.pathSide === A,
      n = this.path,
      o = this._textLines[t],
      a = o.length,
      h = new Array(a);
    this.__charBounds[t] = h;
    for (let r = 0; r < a; r++) {
      const n = o[r];
      s = this._getGraphemeBox(n, t, r, e), h[r] = s, i += s.kernedWidth, e = n;
    }
    if (h[a] = {
      left: s ? s.left + s.width : 0,
      width: 0,
      kernedWidth: 0,
      height: this.fontSize,
      deltaY: 0
    }, n && n.segmentsInfo) {
      let t = 0;
      const e = n.segmentsInfo[n.segmentsInfo.length - 1].length;
      switch (this.textAlign) {
        case M:
          t = r ? e - i : 0;
          break;
        case D:
          t = (e - i) / 2;
          break;
        case A:
          t = r ? 0 : e - i;
      }
      t += this.pathStartOffset * (r ? -1 : 1);
      for (let i = r ? a - 1 : 0; r ? i >= 0 : i < a; r ? i-- : i++) s = h[i], t > e ? t %= e : t < 0 && (t += e), this._setGraphemeOnPath(t, s), t += s.kernedWidth;
    }
    return {
      width: i,
      numOfSpaces: 0
    };
  }
  _setGraphemeOnPath(t, e) {
    const s = t + e.kernedWidth / 2,
      i = this.path,
      r = Qr(i.path, s, i.segmentsInfo);
    e.renderLeft = r.x - i.pathOffset.x, e.renderTop = r.y - i.pathOffset.y, e.angle = r.angle + (this.pathSide === A ? Math.PI : 0);
  }
  _getGraphemeBox(t, e, s, i, r) {
    const n = this.getCompleteStyleDeclaration(e, s),
      o = i ? this.getCompleteStyleDeclaration(e, s - 1) : {},
      a = this._measureChar(t, n, i, o);
    let h,
      c = a.kernedWidth,
      l = a.width;
    0 !== this.charSpacing && (h = this._getWidthOfCharSpacing(), l += h, c += h);
    const u = {
      width: l,
      left: 0,
      height: n.fontSize,
      kernedWidth: c,
      deltaY: n.deltaY
    };
    if (s > 0 && !r) {
      const t = this.__charBounds[e][s - 1];
      u.left = t.left + t.width + a.kernedWidth - a.width;
    }
    return u;
  }
  getHeightOfLine(t) {
    if (this.__lineHeights[t]) return this.__lineHeights[t];
    let e = this.getHeightOfChar(t, 0);
    for (let s = 1, i = this._textLines[t].length; s < i; s++) e = Math.max(this.getHeightOfChar(t, s), e);
    return this.__lineHeights[t] = e * this.lineHeight * this._fontSizeMult;
  }
  calcTextHeight() {
    let t,
      e = 0;
    for (let s = 0, i = this._textLines.length; s < i; s++) t = this.getHeightOfLine(s), e += s === i - 1 ? t / this.lineHeight : t;
    return e;
  }
  _getLeftOffset() {
    return "ltr" === this.direction ? -this.width / 2 : this.width / 2;
  }
  _getTopOffset() {
    return -this.height / 2;
  }
  _renderTextCommon(t, e) {
    t.save();
    let s = 0;
    const i = this._getLeftOffset(),
      r = this._getTopOffset();
    for (let n = 0, o = this._textLines.length; n < o; n++) {
      const o = this.getHeightOfLine(n),
        a = o / this.lineHeight,
        h = this._getLineLeftOffset(n);
      this._renderTextLine(e, t, this._textLines[n], i + h, r + s + a, n), s += o;
    }
    t.restore();
  }
  _renderTextFill(t) {
    (this.fill || this.styleHas(K)) && this._renderTextCommon(t, "fillText");
  }
  _renderTextStroke(t) {
    (this.stroke && 0 !== this.strokeWidth || !this.isEmptyStyles()) && (this.shadow && !this.shadow.affectStroke && this._removeShadow(t), t.save(), this._setLineDash(t, this.strokeDashArray), t.beginPath(), this._renderTextCommon(t, "strokeText"), t.closePath(), t.restore());
  }
  _renderChars(t, e, s, i, r, n) {
    const o = this.getHeightOfLine(n),
      a = this.textAlign.includes(fo),
      h = this.path,
      c = !a && 0 === this.charSpacing && this.isEmptyStyles(n) && !h,
      l = "ltr" === this.direction,
      u = "ltr" === this.direction ? 1 : -1,
      d = e.direction;
    let g,
      f,
      p,
      m,
      v,
      y = "",
      _ = 0;
    if (e.save(), d !== this.direction && (e.canvas.setAttribute("dir", l ? "ltr" : "rtl"), e.direction = l ? "ltr" : "rtl", e.textAlign = l ? M : A), r -= o * this._fontSizeFraction / this.lineHeight, c) return this._renderChar(t, e, n, 0, s.join(""), i, r), void e.restore();
    for (let o = 0, c = s.length - 1; o <= c; o++) m = o === c || this.charSpacing || h, y += s[o], p = this.__charBounds[n][o], 0 === _ ? (i += u * (p.kernedWidth - p.width), _ += p.width) : _ += p.kernedWidth, a && !m && this._reSpaceAndTab.test(s[o]) && (m = !0), m || (g = g || this.getCompleteStyleDeclaration(n, o), f = this.getCompleteStyleDeclaration(n, o + 1), m = Pi(g, f, !1)), m && (h ? (e.save(), e.translate(p.renderLeft, p.renderTop), e.rotate(p.angle), this._renderChar(t, e, n, o, y, -_ / 2, 0), e.restore()) : (v = i, this._renderChar(t, e, n, o, y, v, r)), y = "", g = f, i += u * _, _ = 0);
    e.restore();
  }
  _applyPatternGradientTransformText(t) {
    const e = pt(),
      s = this.width + this.strokeWidth,
      i = this.height + this.strokeWidth,
      r = e.getContext("2d");
    return e.width = s, e.height = i, r.beginPath(), r.moveTo(0, 0), r.lineTo(s, 0), r.lineTo(s, i), r.lineTo(0, i), r.closePath(), r.translate(s / 2, i / 2), r.fillStyle = t.toLive(r), this._applyPatternGradientTransform(r, t), r.fill(), r.createPattern(e, "no-repeat");
  }
  handleFiller(t, e, s) {
    let i, r;
    return Zt(s) ? "percentage" === s.gradientUnits || s.gradientTransform || s.patternTransform ? (i = -this.width / 2, r = -this.height / 2, t.translate(i, r), t[e] = this._applyPatternGradientTransformText(s), {
      offsetX: i,
      offsetY: r
    }) : (t[e] = s.toLive(t), this._applyPatternGradientTransform(t, s)) : (t[e] = s, {
      offsetX: 0,
      offsetY: 0
    });
  }
  _setStrokeStyles(t, e) {
    let {
      stroke: s,
      strokeWidth: i
    } = e;
    return t.lineWidth = i, t.lineCap = this.strokeLineCap, t.lineDashOffset = this.strokeDashOffset, t.lineJoin = this.strokeLineJoin, t.miterLimit = this.strokeMiterLimit, this.handleFiller(t, "strokeStyle", s);
  }
  _setFillStyles(t, e) {
    let {
      fill: s
    } = e;
    return this.handleFiller(t, "fillStyle", s);
  }
  _renderChar(t, e, s, i, r, n, o) {
    const a = this._getStyleDeclaration(s, i),
      h = this.getCompleteStyleDeclaration(s, i),
      c = "fillText" === t && h.fill,
      l = "strokeText" === t && h.stroke && h.strokeWidth;
    if (l || c) {
      if (e.save(), e.font = this._getFontDeclaration(h), a.textBackgroundColor && this._removeShadow(e), a.deltaY && (o += a.deltaY), c) {
        const t = this._setFillStyles(e, h);
        e.fillText(r, n - t.offsetX, o - t.offsetY);
      }
      if (l) {
        const t = this._setStrokeStyles(e, h);
        e.strokeText(r, n - t.offsetX, o - t.offsetY);
      }
      e.restore();
    }
  }
  setSuperscript(t, e) {
    this._setScript(t, e, this.superscript);
  }
  setSubscript(t, e) {
    this._setScript(t, e, this.subscript);
  }
  _setScript(t, e, s) {
    const i = this.get2DCursorLocation(t, !0),
      r = this.getValueOfPropertyAt(i.lineIndex, i.charIndex, "fontSize"),
      n = this.getValueOfPropertyAt(i.lineIndex, i.charIndex, "deltaY"),
      o = {
        fontSize: r * s.size,
        deltaY: n + r * s.baseline
      };
    this.setSelectionStyles(o, t, e);
  }
  _getLineLeftOffset(t) {
    const e = this.getLineWidth(t),
      s = this.width - e,
      i = this.textAlign,
      r = this.direction,
      n = this.isEndOfWrapping(t);
    let o = 0;
    return i === fo || i === vo && !n || i === mo && !n || i === po && !n ? 0 : (i === D && (o = s / 2), i === A && (o = s), i === vo && (o = s / 2), i === mo && (o = s), "rtl" === r && (i === A || i === fo || i === mo ? o = 0 : i === M || i === po ? o = -s : i !== D && i !== vo || (o = -s / 2)), o);
  }
  _clearCache() {
    this._forceClearCache = !1, this.__lineWidths = [], this.__lineHeights = [], this.__charBounds = [];
  }
  getLineWidth(t) {
    if (void 0 !== this.__lineWidths[t]) return this.__lineWidths[t];
    const {
      width: e
    } = this.measureLine(t);
    return this.__lineWidths[t] = e, e;
  }
  _getWidthOfCharSpacing() {
    return 0 !== this.charSpacing ? this.fontSize * this.charSpacing / 1e3 : 0;
  }
  getValueOfPropertyAt(t, e, s) {
    var i;
    return null !== (i = this._getStyleDeclaration(t, e)[s]) && void 0 !== i ? i : this[s];
  }
  _renderTextDecoration(t, e) {
    if (!this[e] && !this.styleHas(e)) return;
    let s = this._getTopOffset();
    const i = this._getLeftOffset(),
      r = this.path,
      n = this._getWidthOfCharSpacing(),
      o = this.offsets[e];
    for (let a = 0, h = this._textLines.length; a < h; a++) {
      const h = this.getHeightOfLine(a);
      if (!this[e] && !this.styleHas(e, a)) {
        s += h;
        continue;
      }
      const c = this._textLines[a],
        l = h / this.lineHeight,
        u = this._getLineLeftOffset(a);
      let d,
        g,
        f = 0,
        p = 0,
        m = this.getValueOfPropertyAt(a, 0, e),
        v = this.getValueOfPropertyAt(a, 0, K);
      const y = s + l * (1 - this._fontSizeFraction);
      let _ = this.getHeightOfChar(a, 0),
        x = this.getValueOfPropertyAt(a, 0, "deltaY");
      for (let s = 0, n = c.length; s < n; s++) {
        const n = this.__charBounds[a][s];
        d = this.getValueOfPropertyAt(a, s, e), g = this.getValueOfPropertyAt(a, s, K);
        const h = this.getHeightOfChar(a, s),
          c = this.getValueOfPropertyAt(a, s, "deltaY");
        if (r && d && g) t.save(), t.fillStyle = v, t.translate(n.renderLeft, n.renderTop), t.rotate(n.angle), t.fillRect(-n.kernedWidth / 2, o * h + c, n.kernedWidth, this.fontSize / 15), t.restore();else if ((d !== m || g !== v || h !== _ || c !== x) && p > 0) {
          let e = i + u + f;
          "rtl" === this.direction && (e = this.width - e - p), m && v && (t.fillStyle = v, t.fillRect(e, y + o * _ + x, p, this.fontSize / 15)), f = n.left, p = n.width, m = d, v = g, _ = h, x = c;
        } else p += n.kernedWidth;
      }
      let C = i + u + f;
      "rtl" === this.direction && (C = this.width - C - p), t.fillStyle = g, d && g && t.fillRect(C, y + o * _ + x, p - n, this.fontSize / 15), s += h;
    }
    this._removeShadow(t);
  }
  _getFontDeclaration() {
    let {
        fontFamily: t = this.fontFamily,
        fontStyle: e = this.fontStyle,
        fontWeight: s = this.fontWeight,
        fontSize: i = this.fontSize
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
      r = arguments.length > 1 ? arguments[1] : void 0;
    const n = t.includes("'") || t.includes('"') || t.includes(",") || So.genericFonts.includes(t.toLowerCase()) ? t : '"'.concat(t, '"');
    return [e, s, "".concat(r ? this.CACHE_FONT_SIZE : i, "px"), n].join(" ");
  }
  render(t) {
    this.visible && (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen() || (this._forceClearCache && this.initDimensions(), super.render(t)));
  }
  graphemeSplit(t) {
    return ki(t);
  }
  _splitTextIntoLines(t) {
    const e = t.split(this._reNewline),
      s = new Array(e.length),
      i = ["\n"];
    let r = [];
    for (let t = 0; t < e.length; t++) s[t] = this.graphemeSplit(e[t]), r = r.concat(s[t], i);
    return r.pop(), {
      _unwrappedLines: s,
      lines: e,
      graphemeText: r,
      graphemeLines: s
    };
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return s(s({}, super.toObject([...lo, ...t])), {}, {
      styles: Ei(this.styles, this.text)
    }, this.path ? {
      path: this.path.toObject()
    } : {});
  }
  set(t, e) {
    const {
      textLayoutProperties: s
    } = this.constructor;
    super.set(t, e);
    let i = !1,
      r = !1;
    if ("object" == typeof t) for (const e in t) "path" === e && this.setPathInfo(), i = i || s.includes(e), r = r || "path" === e;else i = s.includes(t), r = "path" === t;
    return r && this.setPathInfo(), i && this.initialized && (this.initDimensions(), this.setCoords()), this;
  }
  complexity() {
    return 1;
  }
  static async fromElement(t, e, r) {
    const n = ur(t, So.ATTRIBUTE_NAMES, r),
      o = s(s({}, e), n),
      {
        textAnchor: a = M,
        textDecoration: h = "",
        dx: c = 0,
        dy: l = 0,
        top: u = 0,
        left: d = 0,
        fontSize: g = O,
        strokeWidth: f = 1
      } = o,
      p = i(o, bo),
      m = new this((t.textContent || "").replace(/^\s+|\s+$|\n+/g, "").replace(/\s+/g, " "), s({
        left: d + c,
        top: u + l,
        underline: h.includes("underline"),
        overline: h.includes("overline"),
        linethrough: h.includes("line-through"),
        strokeWidth: 0,
        fontSize: g
      }, p)),
      v = m.getScaledHeight() / m.height,
      y = ((m.height + m.strokeWidth) * m.lineHeight - m.height) * v,
      _ = m.getScaledHeight() + y;
    let x = 0;
    return a === D && (x = m.getScaledWidth() / 2), a === A && (x = m.getScaledWidth()), m.set({
      left: m.left - x,
      top: m.top - (_ - m.fontSize * (.07 + m._fontSizeFraction)) / m.lineHeight,
      strokeWidth: f
    }), m;
  }
  static fromObject(t) {
    return this._fromObject(s(s({}, t), {}, {
      styles: Ai(t.styles || {}, t.text)
    }), {
      extraParam: "text"
    });
  }
}
t(So, "textLayoutProperties", co), t(So, "cacheProperties", [...fs, ...lo]), t(So, "ownDefaults", go), t(So, "type", "Text"), t(So, "genericFonts", ["sans-serif", "serif", "cursive", "fantasy", "monospace"]), t(So, "ATTRIBUTE_NAMES", ji.concat("x", "y", "dx", "dy", "font-family", "font-style", "font-weight", "font-size", "letter-spacing", "text-decoration", "text-anchor")), vi(So, [class extends Be {
  _toSVG() {
    const t = this._getSVGLeftTopOffsets(),
      e = this._getSVGTextAndBg(t.textTop, t.textLeft);
    return this._wrapSVGTextAndBg(e);
  }
  toSVG(t) {
    return this._createBaseSVGMarkup(this._toSVG(), {
      reviver: t,
      noStyle: !0,
      withShadow: !0
    });
  }
  _getSVGLeftTopOffsets() {
    return {
      textLeft: -this.width / 2,
      textTop: -this.height / 2,
      lineTop: this.getHeightOfLine(0)
    };
  }
  _wrapSVGTextAndBg(t) {
    let {
      textBgRects: e,
      textSpans: s
    } = t;
    const i = this.getSvgTextDecoration(this);
    return [e.join(""), '\t\t<text xml:space="preserve" ', this.fontFamily ? 'font-family="'.concat(this.fontFamily.replace(xo, "'"), '" ') : "", this.fontSize ? 'font-size="'.concat(this.fontSize, '" ') : "", this.fontStyle ? 'font-style="'.concat(this.fontStyle, '" ') : "", this.fontWeight ? 'font-weight="'.concat(this.fontWeight, '" ') : "", i ? 'text-decoration="'.concat(i, '" ') : "", "rtl" === this.direction ? 'direction="'.concat(this.direction, '" ') : "", 'style="', this.getSvgStyles(!0), '"', this.addPaintOrder(), " >", s.join(""), "</text>\n"];
  }
  _getSVGTextAndBg(t, e) {
    const s = [],
      i = [];
    let r,
      n = t;
    this.backgroundColor && i.push(...Co(this.backgroundColor, -this.width / 2, -this.height / 2, this.width, this.height));
    for (let t = 0, o = this._textLines.length; t < o; t++) r = this._getLineLeftOffset(t), "rtl" === this.direction && (r += this.width), (this.textBackgroundColor || this.styleHas("textBackgroundColor", t)) && this._setSVGTextLineBg(i, t, e + r, n), this._setSVGTextLineText(s, t, e + r, n), n += this.getHeightOfLine(t);
    return {
      textSpans: s,
      textBgRects: i
    };
  }
  _createTextCharSpan(t, e, s, i) {
    const r = this.getSvgSpanStyles(e, t !== t.trim() || !!t.match(_o)),
      n = r ? 'style="'.concat(r, '"') : "",
      a = e.deltaY,
      h = a ? ' dy="'.concat(Ut(a, o.NUM_FRACTION_DIGITS), '" ') : "";
    return '<tspan x="'.concat(Ut(s, o.NUM_FRACTION_DIGITS), '" y="').concat(Ut(i, o.NUM_FRACTION_DIGITS), '" ').concat(h).concat(n, ">").concat(Oi(t), "</tspan>");
  }
  _setSVGTextLineText(t, e, s, i) {
    const r = this.getHeightOfLine(e),
      n = this.textAlign.includes(fo),
      o = this._textLines[e];
    let a,
      h,
      c,
      l,
      u,
      d = "",
      g = 0;
    i += r * (1 - this._fontSizeFraction) / this.lineHeight;
    for (let r = 0, f = o.length - 1; r <= f; r++) u = r === f || this.charSpacing, d += o[r], c = this.__charBounds[e][r], 0 === g ? (s += c.kernedWidth - c.width, g += c.width) : g += c.kernedWidth, n && !u && this._reSpaceAndTab.test(o[r]) && (u = !0), u || (a = a || this.getCompleteStyleDeclaration(e, r), h = this.getCompleteStyleDeclaration(e, r + 1), u = Pi(a, h, !0)), u && (l = this._getStyleDeclaration(e, r), t.push(this._createTextCharSpan(d, l, s, i)), d = "", a = h, "rtl" === this.direction ? s -= g : s += g, g = 0);
  }
  _setSVGTextLineBg(t, e, s, i) {
    const r = this._textLines[e],
      n = this.getHeightOfLine(e) / this.lineHeight;
    let o,
      a = 0,
      h = 0,
      c = this.getValueOfPropertyAt(e, 0, "textBackgroundColor");
    for (let l = 0; l < r.length; l++) {
      const {
        left: r,
        width: u,
        kernedWidth: d
      } = this.__charBounds[e][l];
      o = this.getValueOfPropertyAt(e, l, "textBackgroundColor"), o !== c ? (c && t.push(...Co(c, s + h, i, a, n)), h = r, a = u, c = o) : a += d;
    }
    o && t.push(...Co(c, s + h, i, a, n));
  }
  _getSVGLineTopOffset(t) {
    let e,
      s = 0;
    for (e = 0; e < t; e++) s += this.getHeightOfLine(e);
    const i = this.getHeightOfLine(e);
    return {
      lineTop: s,
      offset: (this._fontSizeMult - this._fontSizeFraction) * i / (this.lineHeight * this._fontSizeMult)
    };
  }
  getSvgStyles(t) {
    return "".concat(super.getSvgStyles(t), " white-space: pre;");
  }
  getSvgSpanStyles(t, e) {
    const {
        fontFamily: s,
        strokeWidth: i,
        stroke: r,
        fill: n,
        fontSize: o,
        fontStyle: a,
        fontWeight: h,
        deltaY: c
      } = t,
      l = this.getSvgTextDecoration(t);
    return [r ? Qt(J, r) : "", i ? "stroke-width: ".concat(i, "; ") : "", s ? "font-family: ".concat(s.includes("'") || s.includes('"') ? s : "'".concat(s, "'"), "; ") : "", o ? "font-size: ".concat(o, "px; ") : "", a ? "font-style: ".concat(a, "; ") : "", h ? "font-weight: ".concat(h, "; ") : "", l ? "text-decoration: ".concat(l, "; ") : l, n ? Qt(K, n) : "", c ? "baseline-shift: ".concat(-c, "; ") : "", e ? "white-space: pre; " : ""].join("");
  }
  getSvgTextDecoration(t) {
    return ["overline", "underline", "line-through"].filter(e => t[e.replace("-", "")]).join(" ");
  }
}]), tt.setClass(So), tt.setSVGClass(So);
class To {
  constructor(e) {
    t(this, "target", void 0), t(this, "__mouseDownInPlace", !1), t(this, "__dragStartFired", !1), t(this, "__isDraggingOver", !1), t(this, "__dragStartSelection", void 0), t(this, "__dragImageDisposer", void 0), t(this, "_dispose", void 0), this.target = e;
    const s = [this.target.on("dragenter", this.dragEnterHandler.bind(this)), this.target.on("dragover", this.dragOverHandler.bind(this)), this.target.on("dragleave", this.dragLeaveHandler.bind(this)), this.target.on("dragend", this.dragEndHandler.bind(this)), this.target.on("drop", this.dropHandler.bind(this))];
    this._dispose = () => {
      s.forEach(t => t()), this._dispose = void 0;
    };
  }
  isPointerOverSelection(t) {
    const e = this.target,
      s = e.getSelectionStartFromPointer(t);
    return e.isEditing && s >= e.selectionStart && s <= e.selectionEnd && e.selectionStart < e.selectionEnd;
  }
  start(t) {
    return this.__mouseDownInPlace = this.isPointerOverSelection(t);
  }
  isActive() {
    return this.__mouseDownInPlace;
  }
  end(t) {
    const e = this.isActive();
    return e && !this.__dragStartFired && (this.target.setCursorByClick(t), this.target.initDelayedCursor(!0)), this.__mouseDownInPlace = !1, this.__dragStartFired = !1, this.__isDraggingOver = !1, e;
  }
  getDragStartSelection() {
    return this.__dragStartSelection;
  }
  setDragImage(t, e) {
    var s;
    let {
      selectionStart: i,
      selectionEnd: r
    } = e;
    const n = this.target,
      o = n.canvas,
      a = new ot(n.flipX ? -1 : 1, n.flipY ? -1 : 1),
      h = n._getCursorBoundaries(i),
      c = new ot(h.left + h.leftOffset, h.top + h.topOffset).multiply(a).transform(n.calcTransformMatrix()),
      l = o.getScenePoint(t).subtract(c),
      u = n.getCanvasRetinaScaling(),
      d = n.getBoundingRect(),
      g = c.subtract(new ot(d.left, d.top)),
      f = o.viewportTransform,
      p = g.add(l).transform(f, !0),
      m = n.backgroundColor,
      v = Ti(n.styles);
    n.backgroundColor = "";
    const y = {
      stroke: "transparent",
      fill: "transparent",
      textBackgroundColor: "transparent"
    };
    n.setSelectionStyles(y, 0, i), n.setSelectionStyles(y, r, n.text.length), n.dirty = !0;
    const _ = n.toCanvasElement({
      enableRetinaScaling: o.enableRetinaScaling,
      viewportTransform: !0
    });
    n.backgroundColor = m, n.styles = v, n.dirty = !0, on(_, {
      position: "fixed",
      left: "".concat(-_.width, "px"),
      border: j,
      width: "".concat(_.width / u, "px"),
      height: "".concat(_.height / u, "px")
    }), this.__dragImageDisposer && this.__dragImageDisposer(), this.__dragImageDisposer = () => {
      _.remove();
    }, re(t.target || this.target.hiddenTextarea).body.appendChild(_), null === (s = t.dataTransfer) || void 0 === s || s.setDragImage(_, p.x, p.y);
  }
  onDragStart(t) {
    this.__dragStartFired = !0;
    const e = this.target,
      i = this.isActive();
    if (i && t.dataTransfer) {
      const i = this.__dragStartSelection = {
          selectionStart: e.selectionStart,
          selectionEnd: e.selectionEnd
        },
        r = e._text.slice(i.selectionStart, i.selectionEnd).join(""),
        n = s({
          text: e.text,
          value: r
        }, i);
      t.dataTransfer.setData("text/plain", r), t.dataTransfer.setData("application/fabric", JSON.stringify({
        value: r,
        styles: e.getSelectionStyles(i.selectionStart, i.selectionEnd, !0)
      })), t.dataTransfer.effectAllowed = "copyMove", this.setDragImage(t, n);
    }
    return e.abortCursorAnimation(), i;
  }
  canDrop(t) {
    if (this.target.editable && !this.target.getActiveControl() && !t.defaultPrevented) {
      if (this.isActive() && this.__dragStartSelection) {
        const e = this.target.getSelectionStartFromPointer(t),
          s = this.__dragStartSelection;
        return e < s.selectionStart || e > s.selectionEnd;
      }
      return !0;
    }
    return !1;
  }
  targetCanDrop(t) {
    return this.target.canDrop(t);
  }
  dragEnterHandler(t) {
    let {
      e: e
    } = t;
    const s = this.targetCanDrop(e);
    !this.__isDraggingOver && s && (this.__isDraggingOver = !0);
  }
  dragOverHandler(t) {
    const {
        e: e
      } = t,
      s = this.targetCanDrop(e);
    !this.__isDraggingOver && s ? this.__isDraggingOver = !0 : this.__isDraggingOver && !s && (this.__isDraggingOver = !1), this.__isDraggingOver && (e.preventDefault(), t.canDrop = !0, t.dropTarget = this.target);
  }
  dragLeaveHandler() {
    (this.__isDraggingOver || this.isActive()) && (this.__isDraggingOver = !1);
  }
  dropHandler(t) {
    var e;
    const {
        e: s
      } = t,
      i = s.defaultPrevented;
    this.__isDraggingOver = !1, s.preventDefault();
    let r = null === (e = s.dataTransfer) || void 0 === e ? void 0 : e.getData("text/plain");
    if (r && !i) {
      const e = this.target,
        i = e.canvas;
      let n = e.getSelectionStartFromPointer(s);
      const {
          styles: o
        } = s.dataTransfer.types.includes("application/fabric") ? JSON.parse(s.dataTransfer.getData("application/fabric")) : {},
        a = r[Math.max(0, r.length - 1)],
        h = 0;
      if (this.__dragStartSelection) {
        const t = this.__dragStartSelection.selectionStart,
          s = this.__dragStartSelection.selectionEnd;
        n > t && n <= s ? n = t : n > s && (n -= s - t), e.removeChars(t, s), delete this.__dragStartSelection;
      }
      e._reNewline.test(a) && (e._reNewline.test(e._text[n]) || n === e._text.length) && (r = r.trimEnd()), t.didDrop = !0, t.dropTarget = e, e.insertChars(r, o, n), i.setActiveObject(e), e.enterEditing(s), e.selectionStart = Math.min(n + h, e._text.length), e.selectionEnd = Math.min(e.selectionStart + r.length, e._text.length), e.hiddenTextarea.value = e.text, e._updateTextarea(), e.hiddenTextarea.focus(), e.fire(H, {
        index: n + h,
        action: "drop"
      }), i.fire("text:changed", {
        target: e
      }), i.contextTopDirty = !0, i.requestRenderAll();
    }
  }
  dragEndHandler(t) {
    let {
      e: e
    } = t;
    if (this.isActive() && this.__dragStartFired && this.__dragStartSelection) {
      var s;
      const t = this.target,
        i = this.target.canvas,
        {
          selectionStart: r,
          selectionEnd: n
        } = this.__dragStartSelection,
        o = (null === (s = e.dataTransfer) || void 0 === s ? void 0 : s.dropEffect) || j;
      o === j ? (t.selectionStart = r, t.selectionEnd = n, t._updateTextarea(), t.hiddenTextarea.focus()) : (t.clearContextTop(), "move" === o && (t.removeChars(r, n), t.selectionStart = t.selectionEnd = r, t.hiddenTextarea && (t.hiddenTextarea.value = t.text), t._updateTextarea(), t.fire(H, {
        index: r,
        action: "dragend"
      }), i.fire("text:changed", {
        target: t
      }), i.requestRenderAll()), t.exitEditing());
    }
    this.__dragImageDisposer && this.__dragImageDisposer(), delete this.__dragImageDisposer, delete this.__dragStartSelection, this.__isDraggingOver = !1;
  }
  dispose() {
    this._dispose && this._dispose();
  }
}
const Oo = /[ \n\.,;!\?\-]/;
class ko extends So {
  constructor() {
    super(...arguments), t(this, "_currentCursorOpacity", 1);
  }
  initBehavior() {
    this._tick = this._tick.bind(this), this._onTickComplete = this._onTickComplete.bind(this), this.updateSelectionOnMouseMove = this.updateSelectionOnMouseMove.bind(this);
  }
  onDeselect(t) {
    return this.isEditing && this.exitEditing(), this.selected = !1, super.onDeselect(t);
  }
  _animateCursor(t) {
    let {
      toValue: e,
      duration: s,
      delay: i,
      onComplete: r
    } = t;
    return As({
      startValue: this._currentCursorOpacity,
      endValue: e,
      duration: s,
      delay: i,
      onComplete: r,
      abort: () => !this.canvas || this.selectionStart !== this.selectionEnd,
      onChange: t => {
        this._currentCursorOpacity = t, this.renderCursorOrSelection();
      }
    });
  }
  _tick(t) {
    this._currentTickState = this._animateCursor({
      toValue: 0,
      duration: this.cursorDuration / 2,
      delay: Math.max(t || 0, 100),
      onComplete: this._onTickComplete
    });
  }
  _onTickComplete() {
    var t;
    null === (t = this._currentTickCompleteState) || void 0 === t || t.abort(), this._currentTickCompleteState = this._animateCursor({
      toValue: 1,
      duration: this.cursorDuration,
      onComplete: this._tick
    });
  }
  initDelayedCursor(t) {
    this.abortCursorAnimation(), this._tick(t ? 0 : this.cursorDelay);
  }
  abortCursorAnimation() {
    let t = !1;
    [this._currentTickState, this._currentTickCompleteState].forEach(e => {
      e && !e.isDone() && (t = !0, e.abort());
    }), this._currentCursorOpacity = 1, t && this.clearContextTop();
  }
  restartCursorIfNeeded() {
    [this._currentTickState, this._currentTickCompleteState].some(t => !t || t.isDone()) && this.initDelayedCursor();
  }
  selectAll() {
    return this.selectionStart = 0, this.selectionEnd = this._text.length, this._fireSelectionChanged(), this._updateTextarea(), this;
  }
  getSelectedText() {
    return this._text.slice(this.selectionStart, this.selectionEnd).join("");
  }
  findWordBoundaryLeft(t) {
    let e = 0,
      s = t - 1;
    if (this._reSpace.test(this._text[s])) for (; this._reSpace.test(this._text[s]);) e++, s--;
    for (; /\S/.test(this._text[s]) && s > -1;) e++, s--;
    return t - e;
  }
  findWordBoundaryRight(t) {
    let e = 0,
      s = t;
    if (this._reSpace.test(this._text[s])) for (; this._reSpace.test(this._text[s]);) e++, s++;
    for (; /\S/.test(this._text[s]) && s < this._text.length;) e++, s++;
    return t + e;
  }
  findLineBoundaryLeft(t) {
    let e = 0,
      s = t - 1;
    for (; !/\n/.test(this._text[s]) && s > -1;) e++, s--;
    return t - e;
  }
  findLineBoundaryRight(t) {
    let e = 0,
      s = t;
    for (; !/\n/.test(this._text[s]) && s < this._text.length;) e++, s++;
    return t + e;
  }
  searchWordBoundary(t, e) {
    const s = this._text;
    let i = t > 0 && this._reSpace.test(s[t]) && (-1 === e || !F.test(s[t - 1])) ? t - 1 : t,
      r = s[i];
    for (; i > 0 && i < s.length && !Oo.test(r);) i += e, r = s[i];
    return -1 === e && Oo.test(r) && i++, i;
  }
  selectWord(t) {
    t = t || this.selectionStart;
    const e = this.searchWordBoundary(t, -1),
      s = Math.max(e, this.searchWordBoundary(t, 1));
    this.selectionStart = e, this.selectionEnd = s, this._fireSelectionChanged(), this._updateTextarea(), this.renderCursorOrSelection();
  }
  selectLine(t) {
    t = t || this.selectionStart;
    const e = this.findLineBoundaryLeft(t),
      s = this.findLineBoundaryRight(t);
    return this.selectionStart = e, this.selectionEnd = s, this._fireSelectionChanged(), this._updateTextarea(), this;
  }
  enterEditing(t) {
    !this.isEditing && this.editable && (this.canvas && (this.canvas.calcOffset(), this.canvas.textEditingManager.exitTextEditing()), this.isEditing = !0, this.initHiddenTextarea(), this.hiddenTextarea.focus(), this.hiddenTextarea.value = this.text, this._updateTextarea(), this._saveEditingProps(), this._setEditingProps(), this._textBeforeEdit = this.text, this._tick(), this.fire("editing:entered", t ? {
      e: t
    } : void 0), this._fireSelectionChanged(), this.canvas && (this.canvas.fire("text:editing:entered", {
      target: this,
      e: t
    }), this.canvas.requestRenderAll()));
  }
  updateSelectionOnMouseMove(t) {
    if (this.getActiveControl()) return;
    const e = this.hiddenTextarea;
    re(e).activeElement !== e && e.focus();
    const s = this.getSelectionStartFromPointer(t),
      i = this.selectionStart,
      r = this.selectionEnd;
    (s === this.__selectionStartOnMouseDown && i !== r || i !== s && r !== s) && (s > this.__selectionStartOnMouseDown ? (this.selectionStart = this.__selectionStartOnMouseDown, this.selectionEnd = s) : (this.selectionStart = s, this.selectionEnd = this.__selectionStartOnMouseDown), this.selectionStart === i && this.selectionEnd === r || (this._fireSelectionChanged(), this._updateTextarea(), this.renderCursorOrSelection()));
  }
  _setEditingProps() {
    this.hoverCursor = "text", this.canvas && (this.canvas.defaultCursor = this.canvas.moveCursor = "text"), this.borderColor = this.editingBorderColor, this.hasControls = this.selectable = !1, this.lockMovementX = this.lockMovementY = !0;
  }
  fromStringToGraphemeSelection(t, e, s) {
    const i = s.slice(0, t),
      r = this.graphemeSplit(i).length;
    if (t === e) return {
      selectionStart: r,
      selectionEnd: r
    };
    const n = s.slice(t, e);
    return {
      selectionStart: r,
      selectionEnd: r + this.graphemeSplit(n).length
    };
  }
  fromGraphemeToStringSelection(t, e, s) {
    const i = s.slice(0, t).join("").length;
    if (t === e) return {
      selectionStart: i,
      selectionEnd: i
    };
    return {
      selectionStart: i,
      selectionEnd: i + s.slice(t, e).join("").length
    };
  }
  _updateTextarea() {
    if (this.cursorOffsetCache = {}, this.hiddenTextarea) {
      if (!this.inCompositionMode) {
        const t = this.fromGraphemeToStringSelection(this.selectionStart, this.selectionEnd, this._text);
        this.hiddenTextarea.selectionStart = t.selectionStart, this.hiddenTextarea.selectionEnd = t.selectionEnd;
      }
      this.updateTextareaPosition();
    }
  }
  updateFromTextArea() {
    if (!this.hiddenTextarea) return;
    this.cursorOffsetCache = {};
    const t = this.hiddenTextarea;
    this.text = t.value, this.set("dirty", !0), this.initDimensions(), this.setCoords();
    const e = this.fromStringToGraphemeSelection(t.selectionStart, t.selectionEnd, t.value);
    this.selectionEnd = this.selectionStart = e.selectionEnd, this.inCompositionMode || (this.selectionStart = e.selectionStart), this.updateTextareaPosition();
  }
  updateTextareaPosition() {
    if (this.selectionStart === this.selectionEnd) {
      const t = this._calcTextareaPosition();
      this.hiddenTextarea.style.left = t.left, this.hiddenTextarea.style.top = t.top;
    }
  }
  _calcTextareaPosition() {
    if (!this.canvas) return {
      left: "1px",
      top: "1px"
    };
    const t = this.inCompositionMode ? this.compositionStart : this.selectionStart,
      e = this._getCursorBoundaries(t),
      s = this.get2DCursorLocation(t),
      i = s.lineIndex,
      r = s.charIndex,
      n = this.getValueOfPropertyAt(i, r, "fontSize") * this.lineHeight,
      o = e.leftOffset,
      a = this.getCanvasRetinaScaling(),
      h = this.canvas.upperCanvasEl,
      c = h.width / a,
      l = h.height / a,
      u = c - n,
      d = l - n,
      g = new ot(e.left + o, e.top + e.topOffset + n).transform(this.calcTransformMatrix()).transform(this.canvas.viewportTransform).multiply(new ot(h.clientWidth / c, h.clientHeight / l));
    return g.x < 0 && (g.x = 0), g.x > u && (g.x = u), g.y < 0 && (g.y = 0), g.y > d && (g.y = d), g.x += this.canvas._offset.left, g.y += this.canvas._offset.top, {
      left: "".concat(g.x, "px"),
      top: "".concat(g.y, "px"),
      fontSize: "".concat(n, "px"),
      charHeight: n
    };
  }
  _saveEditingProps() {
    this._savedProps = {
      hasControls: this.hasControls,
      borderColor: this.borderColor,
      lockMovementX: this.lockMovementX,
      lockMovementY: this.lockMovementY,
      hoverCursor: this.hoverCursor,
      selectable: this.selectable,
      defaultCursor: this.canvas && this.canvas.defaultCursor,
      moveCursor: this.canvas && this.canvas.moveCursor
    };
  }
  _restoreEditingProps() {
    this._savedProps && (this.hoverCursor = this._savedProps.hoverCursor, this.hasControls = this._savedProps.hasControls, this.borderColor = this._savedProps.borderColor, this.selectable = this._savedProps.selectable, this.lockMovementX = this._savedProps.lockMovementX, this.lockMovementY = this._savedProps.lockMovementY, this.canvas && (this.canvas.defaultCursor = this._savedProps.defaultCursor || this.canvas.defaultCursor, this.canvas.moveCursor = this._savedProps.moveCursor || this.canvas.moveCursor), delete this._savedProps);
  }
  _exitEditing() {
    const t = this.hiddenTextarea;
    this.selected = !1, this.isEditing = !1, t && (t.blur && t.blur(), t.parentNode && t.parentNode.removeChild(t)), this.hiddenTextarea = null, this.abortCursorAnimation(), this.selectionStart !== this.selectionEnd && this.clearContextTop();
  }
  exitEditing() {
    const t = this._textBeforeEdit !== this.text;
    return this._exitEditing(), this.selectionEnd = this.selectionStart, this._restoreEditingProps(), this._forceClearCache && (this.initDimensions(), this.setCoords()), this.fire("editing:exited"), t && this.fire(Q), this.canvas && (this.canvas.fire("text:editing:exited", {
      target: this
    }), t && this.canvas.fire("object:modified", {
      target: this
    })), this;
  }
  _removeExtraneousStyles() {
    for (const t in this.styles) this._textLines[t] || delete this.styles[t];
  }
  removeStyleFromTo(t, e) {
    const {
        lineIndex: s,
        charIndex: i
      } = this.get2DCursorLocation(t, !0),
      {
        lineIndex: r,
        charIndex: n
      } = this.get2DCursorLocation(e, !0);
    if (s !== r) {
      if (this.styles[s]) for (let t = i; t < this._unwrappedTextLines[s].length; t++) delete this.styles[s][t];
      if (this.styles[r]) for (let t = n; t < this._unwrappedTextLines[r].length; t++) {
        const e = this.styles[r][t];
        e && (this.styles[s] || (this.styles[s] = {}), this.styles[s][i + t - n] = e);
      }
      for (let t = s + 1; t <= r; t++) delete this.styles[t];
      this.shiftLineStyles(r, s - r);
    } else if (this.styles[s]) {
      const t = this.styles[s],
        e = n - i;
      for (let e = i; e < n; e++) delete t[e];
      for (const i in this.styles[s]) {
        const s = parseInt(i, 10);
        s >= n && (t[s - e] = t[i], delete t[i]);
      }
    }
  }
  shiftLineStyles(t, e) {
    const s = Object.assign({}, this.styles);
    for (const i in this.styles) {
      const r = parseInt(i, 10);
      r > t && (this.styles[r + e] = s[r], s[r - e] || delete this.styles[r]);
    }
  }
  insertNewlineStyleObject(t, e, i, r) {
    const n = {},
      o = this._unwrappedTextLines[t].length,
      a = o === e;
    let h = !1;
    i || (i = 1), this.shiftLineStyles(t, i);
    const c = this.styles[t] ? this.styles[t][0 === e ? e : e - 1] : void 0;
    for (const s in this.styles[t]) {
      const i = parseInt(s, 10);
      i >= e && (h = !0, n[i - e] = this.styles[t][s], a && 0 === e || delete this.styles[t][s]);
    }
    let l = !1;
    for (h && !a && (this.styles[t + i] = n, l = !0), (l || o > e) && i--; i > 0;) r && r[i - 1] ? this.styles[t + i] = {
      0: s({}, r[i - 1])
    } : c ? this.styles[t + i] = {
      0: s({}, c)
    } : delete this.styles[t + i], i--;
    this._forceClearCache = !0;
  }
  insertCharStyleObject(t, e, i, r) {
    this.styles || (this.styles = {});
    const n = this.styles[t],
      o = n ? s({}, n) : {};
    i || (i = 1);
    for (const t in o) {
      const s = parseInt(t, 10);
      s >= e && (n[s + i] = o[s], o[s - i] || delete n[s]);
    }
    if (this._forceClearCache = !0, r) {
      for (; i--;) Object.keys(r[i]).length && (this.styles[t] || (this.styles[t] = {}), this.styles[t][e + i] = s({}, r[i]));
      return;
    }
    if (!n) return;
    const a = n[e ? e - 1 : 1];
    for (; a && i--;) this.styles[t][e + i] = s({}, a);
  }
  insertNewStyleBlock(t, e, s) {
    const i = this.get2DCursorLocation(e, !0),
      r = [0];
    let n,
      o = 0;
    for (let e = 0; e < t.length; e++) "\n" === t[e] ? (o++, r[o] = 0) : r[o]++;
    for (r[0] > 0 && (this.insertCharStyleObject(i.lineIndex, i.charIndex, r[0], s), s = s && s.slice(r[0] + 1)), o && this.insertNewlineStyleObject(i.lineIndex, i.charIndex + r[0], o), n = 1; n < o; n++) r[n] > 0 ? this.insertCharStyleObject(i.lineIndex + n, 0, r[n], s) : s && this.styles[i.lineIndex + n] && s[0] && (this.styles[i.lineIndex + n][0] = s[0]), s = s && s.slice(r[n] + 1);
    r[n] > 0 && this.insertCharStyleObject(i.lineIndex + n, 0, r[n], s);
  }
  removeChars(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : t + 1;
    this.removeStyleFromTo(t, e), this._text.splice(t, e - t), this.text = this._text.join(""), this.set("dirty", !0), this.initDimensions(), this.setCoords(), this._removeExtraneousStyles();
  }
  insertChars(t, e, s) {
    let i = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : s;
    i > s && this.removeStyleFromTo(s, i);
    const r = this.graphemeSplit(t);
    this.insertNewStyleBlock(r, s, e), this._text = [...this._text.slice(0, s), ...r, ...this._text.slice(i)], this.text = this._text.join(""), this.set("dirty", !0), this.initDimensions(), this.setCoords(), this._removeExtraneousStyles();
  }
  setSelectionStartEndWithShift(t, e, s) {
    s <= t ? (e === t ? this._selectionDirection = M : this._selectionDirection === A && (this._selectionDirection = M, this.selectionEnd = t), this.selectionStart = s) : s > t && s < e ? this._selectionDirection === A ? this.selectionEnd = s : this.selectionStart = s : (e === t ? this._selectionDirection = A : this._selectionDirection === M && (this._selectionDirection = A, this.selectionStart = e), this.selectionEnd = s);
  }
}
class Do extends ko {
  initHiddenTextarea() {
    const t = this.canvas && re(this.canvas.getElement()) || m(),
      e = t.createElement("textarea");
    Object.entries({
      autocapitalize: "off",
      autocorrect: "off",
      autocomplete: "off",
      spellcheck: "false",
      "data-fabric": "textarea",
      wrap: "off"
    }).map(t => {
      let [s, i] = t;
      return e.setAttribute(s, i);
    });
    const {
      top: s,
      left: i,
      fontSize: r
    } = this._calcTextareaPosition();
    e.style.cssText = "position: absolute; top: ".concat(s, "; left: ").concat(i, "; z-index: -999; opacity: 0; width: 1px; height: 1px; font-size: 1px; padding-top: ").concat(r, ";"), (this.hiddenTextareaContainer || t.body).appendChild(e), Object.entries({
      blur: "blur",
      keydown: "onKeyDown",
      keyup: "onKeyUp",
      input: "onInput",
      copy: "copy",
      cut: "copy",
      paste: "paste",
      compositionstart: "onCompositionStart",
      compositionupdate: "onCompositionUpdate",
      compositionend: "onCompositionEnd"
    }).map(t => {
      let [s, i] = t;
      return e.addEventListener(s, this[i].bind(this));
    }), this.hiddenTextarea = e;
  }
  blur() {
    this.abortCursorAnimation();
  }
  onKeyDown(t) {
    if (!this.isEditing) return;
    const e = "rtl" === this.direction ? this.keysMapRtl : this.keysMap;
    if (t.keyCode in e) this[e[t.keyCode]](t);else {
      if (!(t.keyCode in this.ctrlKeysMapDown) || !t.ctrlKey && !t.metaKey) return;
      this[this.ctrlKeysMapDown[t.keyCode]](t);
    }
    t.stopImmediatePropagation(), t.preventDefault(), t.keyCode >= 33 && t.keyCode <= 40 ? (this.inCompositionMode = !1, this.clearContextTop(), this.renderCursorOrSelection()) : this.canvas && this.canvas.requestRenderAll();
  }
  onKeyUp(t) {
    !this.isEditing || this._copyDone || this.inCompositionMode ? this._copyDone = !1 : t.keyCode in this.ctrlKeysMapUp && (t.ctrlKey || t.metaKey) && (this[this.ctrlKeysMapUp[t.keyCode]](t), t.stopImmediatePropagation(), t.preventDefault(), this.canvas && this.canvas.requestRenderAll());
  }
  onInput(t) {
    const e = this.fromPaste;
    if (this.fromPaste = !1, t && t.stopPropagation(), !this.isEditing) return;
    const s = () => {
      this.updateFromTextArea(), this.fire(H), this.canvas && (this.canvas.fire("text:changed", {
        target: this
      }), this.canvas.requestRenderAll());
    };
    if ("" === this.hiddenTextarea.value) return this.styles = {}, void s();
    const i = this._splitTextIntoLines(this.hiddenTextarea.value).graphemeText,
      r = this._text.length,
      n = i.length,
      a = this.selectionStart,
      h = this.selectionEnd,
      c = a !== h;
    let l,
      u,
      d,
      g,
      f = n - r;
    const m = this.fromStringToGraphemeSelection(this.hiddenTextarea.selectionStart, this.hiddenTextarea.selectionEnd, this.hiddenTextarea.value),
      v = a > m.selectionStart;
    c ? (u = this._text.slice(a, h), f += h - a) : n < r && (u = v ? this._text.slice(h + f, h) : this._text.slice(a, a - f));
    const y = i.slice(m.selectionEnd - f, m.selectionEnd);
    if (u && u.length && (y.length && (l = this.getSelectionStyles(a, a + 1, !1), l = y.map(() => l[0])), c ? (d = a, g = h) : v ? (d = h - u.length, g = h) : (d = h, g = h + u.length), this.removeStyleFromTo(d, g)), y.length) {
      const {
        copyPasteData: t
      } = p();
      e && y.join("") === t.copiedText && !o.disableStyleCopyPaste && (l = t.copiedTextStyle), this.insertNewStyleBlock(y, a, l);
    }
    s();
  }
  onCompositionStart() {
    this.inCompositionMode = !0;
  }
  onCompositionEnd() {
    this.inCompositionMode = !1;
  }
  onCompositionUpdate(t) {
    let {
      target: e
    } = t;
    const {
      selectionStart: s,
      selectionEnd: i
    } = e;
    this.compositionStart = s, this.compositionEnd = i, this.updateTextareaPosition();
  }
  copy() {
    if (this.selectionStart === this.selectionEnd) return;
    const {
      copyPasteData: t
    } = p();
    t.copiedText = this.getSelectedText(), o.disableStyleCopyPaste ? t.copiedTextStyle = void 0 : t.copiedTextStyle = this.getSelectionStyles(this.selectionStart, this.selectionEnd, !0), this._copyDone = !0;
  }
  paste() {
    this.fromPaste = !0;
  }
  _getWidthBeforeCursor(t, e) {
    let s,
      i = this._getLineLeftOffset(t);
    return e > 0 && (s = this.__charBounds[t][e - 1], i += s.left + s.width), i;
  }
  getDownCursorOffset(t, e) {
    const s = this._getSelectionForOffset(t, e),
      i = this.get2DCursorLocation(s),
      r = i.lineIndex;
    if (r === this._textLines.length - 1 || t.metaKey || 34 === t.keyCode) return this._text.length - s;
    const n = i.charIndex,
      o = this._getWidthBeforeCursor(r, n),
      a = this._getIndexOnLine(r + 1, o);
    return this._textLines[r].slice(n).length + a + 1 + this.missingNewlineOffset(r);
  }
  _getSelectionForOffset(t, e) {
    return t.shiftKey && this.selectionStart !== this.selectionEnd && e ? this.selectionEnd : this.selectionStart;
  }
  getUpCursorOffset(t, e) {
    const s = this._getSelectionForOffset(t, e),
      i = this.get2DCursorLocation(s),
      r = i.lineIndex;
    if (0 === r || t.metaKey || 33 === t.keyCode) return -s;
    const n = i.charIndex,
      o = this._getWidthBeforeCursor(r, n),
      a = this._getIndexOnLine(r - 1, o),
      h = this._textLines[r].slice(0, n),
      c = this.missingNewlineOffset(r - 1);
    return -this._textLines[r - 1].length + a - h.length + (1 - c);
  }
  _getIndexOnLine(t, e) {
    const s = this._textLines[t];
    let i,
      r,
      n = this._getLineLeftOffset(t),
      o = 0;
    for (let a = 0, h = s.length; a < h; a++) if (i = this.__charBounds[t][a].width, n += i, n > e) {
      r = !0;
      const t = n - i,
        s = n,
        h = Math.abs(t - e);
      o = Math.abs(s - e) < h ? a : a - 1;
      break;
    }
    return r || (o = s.length - 1), o;
  }
  moveCursorDown(t) {
    this.selectionStart >= this._text.length && this.selectionEnd >= this._text.length || this._moveCursorUpOrDown("Down", t);
  }
  moveCursorUp(t) {
    0 === this.selectionStart && 0 === this.selectionEnd || this._moveCursorUpOrDown("Up", t);
  }
  _moveCursorUpOrDown(t, e) {
    const s = this["get".concat(t, "CursorOffset")](e, this._selectionDirection === A);
    if (e.shiftKey ? this.moveCursorWithShift(s) : this.moveCursorWithoutShift(s), 0 !== s) {
      const t = this.text.length;
      this.selectionStart = ds(0, this.selectionStart, t), this.selectionEnd = ds(0, this.selectionEnd, t), this.abortCursorAnimation(), this.initDelayedCursor(), this._fireSelectionChanged(), this._updateTextarea();
    }
  }
  moveCursorWithShift(t) {
    const e = this._selectionDirection === M ? this.selectionStart + t : this.selectionEnd + t;
    return this.setSelectionStartEndWithShift(this.selectionStart, this.selectionEnd, e), 0 !== t;
  }
  moveCursorWithoutShift(t) {
    return t < 0 ? (this.selectionStart += t, this.selectionEnd = this.selectionStart) : (this.selectionEnd += t, this.selectionStart = this.selectionEnd), 0 !== t;
  }
  moveCursorLeft(t) {
    0 === this.selectionStart && 0 === this.selectionEnd || this._moveCursorLeftOrRight("Left", t);
  }
  _move(t, e, s) {
    let i;
    if (t.altKey) i = this["findWordBoundary".concat(s)](this[e]);else {
      if (!t.metaKey && 35 !== t.keyCode && 36 !== t.keyCode) return this[e] += "Left" === s ? -1 : 1, !0;
      i = this["findLineBoundary".concat(s)](this[e]);
    }
    return void 0 !== i && this[e] !== i && (this[e] = i, !0);
  }
  _moveLeft(t, e) {
    return this._move(t, e, "Left");
  }
  _moveRight(t, e) {
    return this._move(t, e, "Right");
  }
  moveCursorLeftWithoutShift(t) {
    let e = !0;
    return this._selectionDirection = M, this.selectionEnd === this.selectionStart && 0 !== this.selectionStart && (e = this._moveLeft(t, "selectionStart")), this.selectionEnd = this.selectionStart, e;
  }
  moveCursorLeftWithShift(t) {
    return this._selectionDirection === A && this.selectionStart !== this.selectionEnd ? this._moveLeft(t, "selectionEnd") : 0 !== this.selectionStart ? (this._selectionDirection = M, this._moveLeft(t, "selectionStart")) : void 0;
  }
  moveCursorRight(t) {
    this.selectionStart >= this._text.length && this.selectionEnd >= this._text.length || this._moveCursorLeftOrRight("Right", t);
  }
  _moveCursorLeftOrRight(t, e) {
    const s = "moveCursor".concat(t).concat(e.shiftKey ? "WithShift" : "WithoutShift");
    this._currentCursorOpacity = 1, this[s](e) && (this.abortCursorAnimation(), this.initDelayedCursor(), this._fireSelectionChanged(), this._updateTextarea());
  }
  moveCursorRightWithShift(t) {
    return this._selectionDirection === M && this.selectionStart !== this.selectionEnd ? this._moveRight(t, "selectionStart") : this.selectionEnd !== this._text.length ? (this._selectionDirection = A, this._moveRight(t, "selectionEnd")) : void 0;
  }
  moveCursorRightWithoutShift(t) {
    let e = !0;
    return this._selectionDirection = A, this.selectionStart === this.selectionEnd ? (e = this._moveRight(t, "selectionStart"), this.selectionEnd = this.selectionStart) : this.selectionStart = this.selectionEnd, e;
  }
}
const Mo = t => !!t.button;
class Po extends Do {
  constructor() {
    super(...arguments), t(this, "draggableTextDelegate", void 0);
  }
  initBehavior() {
    this.on("mousedown", this._mouseDownHandler), this.on("mousedown:before", this._mouseDownHandlerBefore), this.on("mouseup", this.mouseUpHandler), this.on("mousedblclick", this.doubleClickHandler), this.on("tripleclick", this.tripleClickHandler), this.__lastClickTime = +new Date(), this.__lastLastClickTime = +new Date(), this.__lastPointer = {}, this.on("mousedown", this.onMouseDown), this.draggableTextDelegate = new To(this), super.initBehavior();
  }
  shouldStartDragging() {
    return this.draggableTextDelegate.isActive();
  }
  onDragStart(t) {
    return this.draggableTextDelegate.onDragStart(t);
  }
  canDrop(t) {
    return this.draggableTextDelegate.canDrop(t);
  }
  onMouseDown(t) {
    if (!this.canvas) return;
    this.__newClickTime = +new Date();
    const e = t.pointer;
    this.isTripleClick(e) && (this.fire("tripleclick", t), pe(t.e)), this.__lastLastClickTime = this.__lastClickTime, this.__lastClickTime = this.__newClickTime, this.__lastPointer = e, this.__lastSelected = this.selected && !this.getActiveControl();
  }
  isTripleClick(t) {
    return this.__newClickTime - this.__lastClickTime < 500 && this.__lastClickTime - this.__lastLastClickTime < 500 && this.__lastPointer.x === t.x && this.__lastPointer.y === t.y;
  }
  doubleClickHandler(t) {
    this.isEditing && this.selectWord(this.getSelectionStartFromPointer(t.e));
  }
  tripleClickHandler(t) {
    this.isEditing && this.selectLine(this.getSelectionStartFromPointer(t.e));
  }
  _mouseDownHandler(t) {
    let {
      e: e
    } = t;
    this.canvas && this.editable && !Mo(e) && !this.getActiveControl() && (this.draggableTextDelegate.start(e) || (this.canvas.textEditingManager.register(this), this.selected && (this.inCompositionMode = !1, this.setCursorByClick(e)), this.isEditing && (this.__selectionStartOnMouseDown = this.selectionStart, this.selectionStart === this.selectionEnd && this.abortCursorAnimation(), this.renderCursorOrSelection())));
  }
  _mouseDownHandlerBefore(t) {
    let {
      e: e
    } = t;
    this.canvas && this.editable && !Mo(e) && (this.selected = this === this.canvas._activeObject);
  }
  mouseUpHandler(t) {
    let {
      e: e,
      transform: s
    } = t;
    const i = this.draggableTextDelegate.end(e);
    if (this.canvas) {
      this.canvas.textEditingManager.unregister(this);
      const t = this.canvas._activeObject;
      if (t && t !== this) return;
    }
    !this.editable || this.group && !this.group.interactive || s && s.actionPerformed || Mo(e) || i || (this.__lastSelected && !this.getActiveControl() ? (this.selected = !1, this.__lastSelected = !1, this.enterEditing(e), this.selectionStart === this.selectionEnd ? this.initDelayedCursor(!0) : this.renderCursorOrSelection()) : this.selected = !0);
  }
  setCursorByClick(t) {
    const e = this.getSelectionStartFromPointer(t),
      s = this.selectionStart,
      i = this.selectionEnd;
    t.shiftKey ? this.setSelectionStartEndWithShift(s, i, e) : (this.selectionStart = e, this.selectionEnd = e), this.isEditing && (this._fireSelectionChanged(), this._updateTextarea());
  }
  getSelectionStartFromPointer(t) {
    const e = this.canvas.getScenePoint(t).transform(bt(this.calcTransformMatrix())).add(new ot(-this._getLeftOffset(), -this._getTopOffset()));
    let s = 0,
      i = 0,
      r = 0;
    for (let t = 0; t < this._textLines.length && s <= e.y; t++) s += this.getHeightOfLine(t), r = t, t > 0 && (i += this._textLines[t - 1].length + this.missingNewlineOffset(t - 1));
    let n = Math.abs(this._getLineLeftOffset(r));
    const o = this._textLines[r].length,
      a = this.__charBounds[r];
    for (let t = 0; t < o; t++) {
      const s = n + a[t].kernedWidth;
      if (e.x <= s) {
        Math.abs(e.x - s) <= Math.abs(e.x - n) && i++;
        break;
      }
      n = s, i++;
    }
    return Math.min(this.flipX ? o - i : i, this._text.length);
  }
}
const Eo = "moveCursorUp",
  Ao = "moveCursorDown",
  jo = "moveCursorLeft",
  Fo = "moveCursorRight",
  Lo = "exitEditing",
  Ro = s({
    selectionStart: 0,
    selectionEnd: 0,
    selectionColor: "rgba(17,119,255,0.3)",
    isEditing: !1,
    editable: !0,
    editingBorderColor: "rgba(102,153,255,0.25)",
    cursorWidth: 2,
    cursorColor: "",
    cursorDelay: 1e3,
    cursorDuration: 600,
    caching: !0,
    hiddenTextareaContainer: null,
    keysMap: {
      9: Lo,
      27: Lo,
      33: Eo,
      34: Ao,
      35: Fo,
      36: jo,
      37: jo,
      38: Eo,
      39: Fo,
      40: Ao
    },
    keysMapRtl: {
      9: Lo,
      27: Lo,
      33: Eo,
      34: Ao,
      35: jo,
      36: Fo,
      37: Fo,
      38: Eo,
      39: jo,
      40: Ao
    },
    ctrlKeysMapDown: {
      65: "selectAll"
    },
    ctrlKeysMapUp: {
      67: "copy",
      88: "cut"
    }
  }, {
    _selectionDirection: null,
    _reSpace: /\s|\r?\n/,
    inCompositionMode: !1
  });
class Io extends Po {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Io.ownDefaults);
  }
  get type() {
    const t = super.type;
    return "itext" === t ? "i-text" : t;
  }
  constructor(t, e) {
    super(t, s(s({}, Io.ownDefaults), e)), this.initBehavior();
  }
  _set(t, e) {
    return this.isEditing && this._savedProps && t in this._savedProps ? (this._savedProps[t] = e, this) : ("canvas" === t && (this.canvas instanceof xn && this.canvas.textEditingManager.remove(this), e instanceof xn && e.textEditingManager.add(this)), super._set(t, e));
  }
  setSelectionStart(t) {
    t = Math.max(t, 0), this._updateAndFire("selectionStart", t);
  }
  setSelectionEnd(t) {
    t = Math.min(t, this.text.length), this._updateAndFire("selectionEnd", t);
  }
  _updateAndFire(t, e) {
    this[t] !== e && (this._fireSelectionChanged(), this[t] = e), this._updateTextarea();
  }
  _fireSelectionChanged() {
    this.fire("selection:changed"), this.canvas && this.canvas.fire("text:selection:changed", {
      target: this
    });
  }
  initDimensions() {
    this.isEditing && this.initDelayedCursor(), super.initDimensions();
  }
  getSelectionStyles() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.selectionStart || 0,
      e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : this.selectionEnd,
      s = arguments.length > 2 ? arguments[2] : void 0;
    return super.getSelectionStyles(t, e, s);
  }
  setSelectionStyles(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : this.selectionStart || 0,
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.selectionEnd;
    return super.setSelectionStyles(t, e, s);
  }
  get2DCursorLocation() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.selectionStart,
      e = arguments.length > 1 ? arguments[1] : void 0;
    return super.get2DCursorLocation(t, e);
  }
  render(t) {
    super.render(t), this.cursorOffsetCache = {}, this.renderCursorOrSelection();
  }
  toCanvasElement(t) {
    const e = this.isEditing;
    this.isEditing = !1;
    const s = super.toCanvasElement(t);
    return this.isEditing = e, s;
  }
  renderCursorOrSelection() {
    if (!this.isEditing) return;
    const t = this.clearContextTop(!0);
    if (!t) return;
    const e = this._getCursorBoundaries();
    this.selectionStart === this.selectionEnd ? this.renderCursor(t, e) : this.renderSelection(t, e), this.canvas.contextTopDirty = !0, t.restore();
  }
  _getCursorBoundaries() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.selectionStart,
      e = arguments.length > 1 ? arguments[1] : void 0;
    const s = this._getLeftOffset(),
      i = this._getTopOffset(),
      r = this._getCursorBoundariesOffsets(t, e);
    return {
      left: s,
      top: i,
      leftOffset: r.left,
      topOffset: r.top
    };
  }
  _getCursorBoundariesOffsets(t, e) {
    return e ? this.__getCursorBoundariesOffsets(t) : this.cursorOffsetCache && "top" in this.cursorOffsetCache ? this.cursorOffsetCache : this.cursorOffsetCache = this.__getCursorBoundariesOffsets(t);
  }
  __getCursorBoundariesOffsets(t) {
    let e = 0,
      s = 0;
    const {
      charIndex: i,
      lineIndex: r
    } = this.get2DCursorLocation(t);
    for (let t = 0; t < r; t++) e += this.getHeightOfLine(t);
    const n = this._getLineLeftOffset(r),
      o = this.__charBounds[r][i];
    o && (s = o.left), 0 !== this.charSpacing && i === this._textLines[r].length && (s -= this._getWidthOfCharSpacing());
    const a = {
      top: e,
      left: n + (s > 0 ? s : 0)
    };
    return "rtl" === this.direction && (this.textAlign === A || this.textAlign === fo || this.textAlign === mo ? a.left *= -1 : this.textAlign === M || this.textAlign === po ? a.left = n - (s > 0 ? s : 0) : this.textAlign !== D && this.textAlign !== vo || (a.left = n - (s > 0 ? s : 0))), a;
  }
  renderCursorAt(t) {
    const e = this._getCursorBoundaries(t, !0);
    this._renderCursor(this.canvas.contextTop, e, t);
  }
  renderCursor(t, e) {
    this._renderCursor(t, e, this.selectionStart);
  }
  _renderCursor(t, e, s) {
    const i = this.get2DCursorLocation(s),
      r = i.lineIndex,
      n = i.charIndex > 0 ? i.charIndex - 1 : 0,
      o = this.getValueOfPropertyAt(r, n, "fontSize"),
      a = this.getObjectScaling().x * this.canvas.getZoom(),
      h = this.cursorWidth / a,
      c = this.getValueOfPropertyAt(r, n, "deltaY"),
      l = e.topOffset + (1 - this._fontSizeFraction) * this.getHeightOfLine(r) / this.lineHeight - o * (1 - this._fontSizeFraction);
    this.inCompositionMode && this.renderSelection(t, e), t.fillStyle = this.cursorColor || this.getValueOfPropertyAt(r, n, K), t.globalAlpha = this._currentCursorOpacity, t.fillRect(e.left + e.leftOffset - h / 2, l + e.top + c, h, o);
  }
  renderSelection(t, e) {
    const s = {
      selectionStart: this.inCompositionMode ? this.hiddenTextarea.selectionStart : this.selectionStart,
      selectionEnd: this.inCompositionMode ? this.hiddenTextarea.selectionEnd : this.selectionEnd
    };
    this._renderSelection(t, s, e);
  }
  renderDragSourceEffect() {
    const t = this.draggableTextDelegate.getDragStartSelection();
    this._renderSelection(this.canvas.contextTop, t, this._getCursorBoundaries(t.selectionStart, !0));
  }
  renderDropTargetEffect(t) {
    const e = this.getSelectionStartFromPointer(t);
    this.renderCursorAt(e);
  }
  _renderSelection(t, e, s) {
    const i = e.selectionStart,
      r = e.selectionEnd,
      n = this.textAlign.includes(fo),
      o = this.get2DCursorLocation(i),
      a = this.get2DCursorLocation(r),
      h = o.lineIndex,
      c = a.lineIndex,
      l = o.charIndex < 0 ? 0 : o.charIndex,
      u = a.charIndex < 0 ? 0 : a.charIndex;
    for (let e = h; e <= c; e++) {
      const i = this._getLineLeftOffset(e) || 0;
      let r = this.getHeightOfLine(e),
        o = 0,
        a = 0,
        d = 0;
      if (e === h && (a = this.__charBounds[h][l].left), e >= h && e < c) d = n && !this.isEndOfWrapping(e) ? this.width : this.getLineWidth(e) || 5;else if (e === c) if (0 === u) d = this.__charBounds[c][u].left;else {
        const t = this._getWidthOfCharSpacing();
        d = this.__charBounds[c][u - 1].left + this.__charBounds[c][u - 1].width - t;
      }
      o = r, (this.lineHeight < 1 || e === c && this.lineHeight > 1) && (r /= this.lineHeight);
      let g = s.left + i + a,
        f = r,
        p = 0;
      const m = d - a;
      this.inCompositionMode ? (t.fillStyle = this.compositionColor || "black", f = 1, p = r) : t.fillStyle = this.selectionColor, "rtl" === this.direction && (this.textAlign === A || this.textAlign === fo || this.textAlign === mo ? g = this.width - g - m : this.textAlign === M || this.textAlign === po ? g = s.left + i - d : this.textAlign !== D && this.textAlign !== vo || (g = s.left + i - d)), t.fillRect(g, s.top + s.topOffset + p, m, f), s.topOffset += o;
    }
  }
  getCurrentCharFontSize() {
    const t = this._getCurrentCharIndex();
    return this.getValueOfPropertyAt(t.l, t.c, "fontSize");
  }
  getCurrentCharColor() {
    const t = this._getCurrentCharIndex();
    return this.getValueOfPropertyAt(t.l, t.c, K);
  }
  _getCurrentCharIndex() {
    const t = this.get2DCursorLocation(this.selectionStart, !0),
      e = t.charIndex > 0 ? t.charIndex - 1 : 0;
    return {
      l: t.lineIndex,
      c: e
    };
  }
  dispose() {
    this._exitEditing(), this.draggableTextDelegate.dispose(), super.dispose();
  }
}
t(Io, "ownDefaults", Ro), t(Io, "type", "IText"), tt.setClass(Io), tt.setClass(Io, "i-text");
class Bo extends Io {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Bo.ownDefaults);
  }
  constructor(t, e) {
    super(t, s(s({}, Bo.ownDefaults), e));
  }
  static createControls() {
    return {
      controls: pi()
    };
  }
  initDimensions() {
    this.initialized && (this.isEditing && this.initDelayedCursor(), this._clearCache(), this.dynamicMinWidth = 0, this._styleMap = this._generateStyleMap(this._splitText()), this.dynamicMinWidth > this.width && this._set("width", this.dynamicMinWidth), this.textAlign.includes(fo) && this.enlargeSpaces(), this.height = this.calcTextHeight());
  }
  _generateStyleMap(t) {
    let e = 0,
      s = 0,
      i = 0;
    const r = {};
    for (let n = 0; n < t.graphemeLines.length; n++) "\n" === t.graphemeText[i] && n > 0 ? (s = 0, i++, e++) : !this.splitByGrapheme && this._reSpaceAndTab.test(t.graphemeText[i]) && n > 0 && (s++, i++), r[n] = {
      line: e,
      offset: s
    }, i += t.graphemeLines[n].length, s += t.graphemeLines[n].length;
    return r;
  }
  styleHas(t, e) {
    if (this._styleMap && !this.isWrapping) {
      const t = this._styleMap[e];
      t && (e = t.line);
    }
    return super.styleHas(t, e);
  }
  isEmptyStyles(t) {
    if (!this.styles) return !0;
    let e,
      s = 0,
      i = t + 1,
      r = !1;
    const n = this._styleMap[t],
      o = this._styleMap[t + 1];
    n && (t = n.line, s = n.offset), o && (i = o.line, r = i === t, e = o.offset);
    const a = void 0 === t ? this.styles : {
      line: this.styles[t]
    };
    for (const t in a) for (const i in a[t]) {
      const n = parseInt(i, 10);
      if (n >= s && (!r || n < e)) for (const e in a[t][i]) return !1;
    }
    return !0;
  }
  _getStyleDeclaration(t, e) {
    if (this._styleMap && !this.isWrapping) {
      const s = this._styleMap[t];
      if (!s) return {};
      t = s.line, e = s.offset + e;
    }
    return super._getStyleDeclaration(t, e);
  }
  _setStyleDeclaration(t, e, s) {
    const i = this._styleMap[t];
    super._setStyleDeclaration(i.line, i.offset + e, s);
  }
  _deleteStyleDeclaration(t, e) {
    const s = this._styleMap[t];
    super._deleteStyleDeclaration(s.line, s.offset + e);
  }
  _getLineStyle(t) {
    const e = this._styleMap[t];
    return !!this.styles[e.line];
  }
  _setLineStyle(t) {
    const e = this._styleMap[t];
    super._setLineStyle(e.line);
  }
  _wrapText(t, e) {
    this.isWrapping = !0;
    const s = this.getGraphemeDataForRender(t),
      i = [];
    for (let t = 0; t < s.wordsData.length; t++) i.push(...this._wrapLine(t, e, s));
    return this.isWrapping = !1, i;
  }
  getGraphemeDataForRender(t) {
    const e = this.splitByGrapheme,
      s = e ? "" : " ";
    let i = 0;
    return {
      wordsData: t.map((t, r) => {
        let n = 0;
        const o = e ? this.graphemeSplit(t) : this.wordSplit(t);
        return 0 === o.length ? [{
          word: [],
          width: 0
        }] : o.map(t => {
          const o = e ? [t] : this.graphemeSplit(t),
            a = this._measureWord(o, r, n);
          return i = Math.max(a, i), n += o.length + s.length, {
            word: o,
            width: a
          };
        });
      }),
      largestWordWidth: i
    };
  }
  _measureWord(t, e) {
    let s,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0,
      r = 0;
    for (let n = 0, o = t.length; n < o; n++) {
      r += this._getGraphemeBox(t[n], e, n + i, s, true).kernedWidth, s = t[n];
    }
    return r;
  }
  wordSplit(t) {
    return t.split(this._wordJoiners);
  }
  _wrapLine(t, e, s) {
    let {
        largestWordWidth: i,
        wordsData: r
      } = s,
      n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0;
    const o = this._getWidthOfCharSpacing(),
      a = this.splitByGrapheme,
      h = [],
      c = a ? "" : " ";
    let l = 0,
      u = [],
      d = 0,
      g = 0,
      f = !0;
    e -= n;
    const p = Math.max(e, i, this.dynamicMinWidth),
      m = r[t];
    let v;
    for (d = 0, v = 0; v < m.length; v++) {
      const {
        word: e,
        width: s
      } = m[v];
      d += e.length, l += g + s - o, l > p && !f ? (h.push(u), u = [], l = s, f = !0) : l += o, f || a || u.push(c), u = u.concat(e), g = a ? 0 : this._measureWord([c], t, d), d++, f = !1;
    }
    return v && h.push(u), i + n > this.dynamicMinWidth && (this.dynamicMinWidth = i - o + n), h;
  }
  isEndOfWrapping(t) {
    return !this._styleMap[t + 1] || this._styleMap[t + 1].line !== this._styleMap[t].line;
  }
  missingNewlineOffset(t, e) {
    return this.splitByGrapheme && !e ? this.isEndOfWrapping(t) ? 1 : 0 : 1;
  }
  _splitTextIntoLines(t) {
    const e = super._splitTextIntoLines(t),
      s = this._wrapText(e.lines, this.width),
      i = new Array(s.length);
    for (let t = 0; t < s.length; t++) i[t] = s[t].join("");
    return e.lines = i, e.graphemeLines = s, e;
  }
  getMinWidth() {
    return Math.max(this.minWidth, this.dynamicMinWidth);
  }
  _removeExtraneousStyles() {
    const t = new Map();
    for (const e in this._styleMap) {
      const s = parseInt(e, 10);
      if (this._textLines[s]) {
        const s = this._styleMap[e].line;
        t.set("".concat(s), !0);
      }
    }
    for (const e in this.styles) t.has(e) || delete this.styles[e];
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    return super.toObject(["minWidth", "splitByGrapheme", ...t]);
  }
}
t(Bo, "type", "Textbox"), t(Bo, "textLayoutProperties", [...Io.textLayoutProperties, "width"]), t(Bo, "ownDefaults", {
  minWidth: 20,
  dynamicMinWidth: 2,
  lockScalingFlip: !0,
  noScaleCache: !1,
  _wordJoiners: /[ \t\r]/,
  splitByGrapheme: !1
}), tt.setClass(Bo);
class Xo extends xr {
  shouldPerformLayout(t) {
    return !!t.target.clipPath && super.shouldPerformLayout(t);
  }
  shouldLayoutClipPath() {
    return !1;
  }
  calcLayoutResult(t, e) {
    const {
        target: s
      } = t,
      {
        clipPath: i,
        group: r
      } = s;
    if (!i || !this.shouldPerformLayout(t)) return;
    const {
        width: n,
        height: o
      } = me(_r(s, i)),
      a = new ot(n, o);
    if (i.absolutePositioned) {
      return {
        center: Se(i.getRelativeCenterPoint(), void 0, r ? r.calcTransformMatrix() : void 0),
        size: a
      };
    }
    {
      const r = i.getRelativeCenterPoint().transform(s.calcOwnMatrix(), !0);
      if (this.shouldPerformLayout(t)) {
        const {
          center: s = new ot(),
          correction: i = new ot()
        } = this.calcBoundingBox(e, t) || {};
        return {
          center: s.add(r),
          correction: i.subtract(r),
          size: a
        };
      }
      return {
        center: s.getRelativeCenterPoint().add(r),
        size: a
      };
    }
  }
}
t(Xo, "type", "clip-path"), tt.setClass(Xo);
class Wo extends xr {
  getInitialSize(t, e) {
    let {
        target: s
      } = t,
      {
        size: i
      } = e;
    return new ot(s.width || i.x, s.height || i.y);
  }
}
t(Wo, "type", "fixed"), tt.setClass(Wo);
class Yo extends Tr {
  subscribeTargets(t) {
    const e = t.target;
    t.targets.reduce((t, e) => (e.parent && t.add(e.parent), t), new Set()).forEach(t => {
      t.layoutManager.subscribeTargets({
        target: t,
        targets: [e]
      });
    });
  }
  unsubscribeTargets(t) {
    const e = t.target,
      s = e.getObjects();
    t.targets.reduce((t, e) => (e.parent && t.add(e.parent), t), new Set()).forEach(t => {
      !s.some(e => e.parent === t) && t.layoutManager.unsubscribeTargets({
        target: t,
        targets: [e]
      });
    });
  }
}
class Vo extends Dr {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Vo.ownDefaults);
  }
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
      e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    super(), Object.assign(this, Vo.ownDefaults), this.setOptions(e);
    const {
      left: s,
      top: i,
      layoutManager: r
    } = e;
    this.groupInit(t, {
      left: s,
      top: i,
      layoutManager: null != r ? r : new Yo()
    });
  }
  _shouldSetNestedCoords() {
    return !0;
  }
  __objectSelectionMonitor() {}
  multiSelectAdd() {
    for (var t = arguments.length, e = new Array(t), s = 0; s < t; s++) e[s] = arguments[s];
    "selection-order" === this.multiSelectionStacking ? this.add(...e) : e.forEach(t => {
      const e = this._objects.findIndex(e => e.isInFrontOf(t)),
        s = -1 === e ? this.size() : e;
      this.insertAt(s, t);
    });
  }
  canEnterGroup(t) {
    return this.getObjects().some(e => e.isDescendantOf(t) || t.isDescendantOf(e)) ? (a("error", "ActiveSelection: circular object trees are not supported, this call has no effect"), !1) : super.canEnterGroup(t);
  }
  enterGroup(t, e) {
    t.parent && t.parent === t.group ? t.parent._exitGroup(t) : t.group && t.parent !== t.group && t.group.remove(t), this._enterGroup(t, e);
  }
  exitGroup(t, e) {
    this._exitGroup(t, e), t.parent && t.parent._enterGroup(t, !0);
  }
  _onAfterObjectsChange(t, e) {
    super._onAfterObjectsChange(t, e);
    const s = new Set();
    e.forEach(t => {
      const {
        parent: e
      } = t;
      e && s.add(e);
    }), t === vr ? s.forEach(t => {
      t._onAfterObjectsChange(mr, e);
    }) : s.forEach(t => {
      t._set("dirty", !0);
    });
  }
  onDeselect() {
    return this.removeAll(), !1;
  }
  toString() {
    return "#<ActiveSelection: (".concat(this.complexity(), ")>");
  }
  shouldCache() {
    return !1;
  }
  isOnACache() {
    return !1;
  }
  _renderControls(t, e, i) {
    t.save(), t.globalAlpha = this.isMoving ? this.borderOpacityWhenMoving : 1;
    const r = s(s({
      hasControls: !1
    }, i), {}, {
      forActiveSelection: !0
    });
    for (let e = 0; e < this._objects.length; e++) this._objects[e]._renderControls(t, r);
    super._renderControls(t, e), t.restore();
  }
}
t(Vo, "type", "ActiveSelection"), t(Vo, "ownDefaults", {
  multiSelectionStacking: "canvas-stacking"
}), tt.setClass(Vo), tt.setClass(Vo, "activeSelection");
class Ho {
  constructor() {
    t(this, "resources", {});
  }
  applyFilters(t, e, s, i, r) {
    const n = r.getContext("2d");
    if (!n) return;
    n.drawImage(e, 0, 0, s, i);
    const o = {
      sourceWidth: s,
      sourceHeight: i,
      imageData: n.getImageData(0, 0, s, i),
      originalEl: e,
      originalImageData: n.getImageData(0, 0, s, i),
      canvasEl: r,
      ctx: n,
      filterBackend: this
    };
    t.forEach(t => {
      t.applyTo(o);
    });
    const {
      imageData: a
    } = o;
    return a.width === s && a.height === i || (r.width = a.width, r.height = a.height), n.putImageData(a, 0, 0), o;
  }
}
class zo {
  constructor() {
    let {
      tileSize: e = o.textureSize
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    t(this, "aPosition", new Float32Array([0, 0, 0, 1, 1, 0, 1, 1])), t(this, "resources", {}), this.tileSize = e, this.setupGLContext(e, e), this.captureGPUInfo();
  }
  setupGLContext(t, e) {
    this.dispose(), this.createWebGLCanvas(t, e);
  }
  createWebGLCanvas(t, e) {
    const s = pt();
    s.width = t, s.height = e;
    const i = s.getContext("webgl", {
      alpha: !0,
      premultipliedAlpha: !1,
      depth: !1,
      stencil: !1,
      antialias: !1
    });
    i && (i.clearColor(0, 0, 0, 0), this.canvas = s, this.gl = i);
  }
  applyFilters(t, e, s, i, r, n) {
    const o = this.gl,
      a = r.getContext("2d");
    if (!o || !a) return;
    let h;
    n && (h = this.getCachedTexture(n, e));
    const c = {
        originalWidth: e.width || e.originalWidth || 0,
        originalHeight: e.height || e.originalHeight || 0,
        sourceWidth: s,
        sourceHeight: i,
        destinationWidth: s,
        destinationHeight: i,
        context: o,
        sourceTexture: this.createTexture(o, s, i, h ? void 0 : e),
        targetTexture: this.createTexture(o, s, i),
        originalTexture: h || this.createTexture(o, s, i, h ? void 0 : e),
        passes: t.length,
        webgl: !0,
        aPosition: this.aPosition,
        programCache: this.programCache,
        pass: 0,
        filterBackend: this,
        targetCanvas: r
      },
      l = o.createFramebuffer();
    return o.bindFramebuffer(o.FRAMEBUFFER, l), t.forEach(t => {
      t && t.applyTo(c);
    }), function (t) {
      const e = t.targetCanvas,
        s = e.width,
        i = e.height,
        r = t.destinationWidth,
        n = t.destinationHeight;
      s === r && i === n || (e.width = r, e.height = n);
    }(c), this.copyGLTo2D(o, c), o.bindTexture(o.TEXTURE_2D, null), o.deleteTexture(c.sourceTexture), o.deleteTexture(c.targetTexture), o.deleteFramebuffer(l), a.setTransform(1, 0, 0, 1, 0, 0), c;
  }
  dispose() {
    this.canvas && (this.canvas = null, this.gl = null), this.clearWebGLCaches();
  }
  clearWebGLCaches() {
    this.programCache = {}, this.textureCache = {};
  }
  createTexture(t, e, s, i, r) {
    const {
        NEAREST: n,
        TEXTURE_2D: o,
        RGBA: a,
        UNSIGNED_BYTE: h,
        CLAMP_TO_EDGE: c,
        TEXTURE_MAG_FILTER: l,
        TEXTURE_MIN_FILTER: u,
        TEXTURE_WRAP_S: d,
        TEXTURE_WRAP_T: g
      } = t,
      f = t.createTexture();
    return t.bindTexture(o, f), t.texParameteri(o, l, r || n), t.texParameteri(o, u, r || n), t.texParameteri(o, d, c), t.texParameteri(o, g, c), i ? t.texImage2D(o, 0, a, a, h, i) : t.texImage2D(o, 0, a, e, s, 0, a, h, null), f;
  }
  getCachedTexture(t, e, s) {
    const {
      textureCache: i
    } = this;
    if (i[t]) return i[t];
    {
      const r = this.createTexture(this.gl, e.width, e.height, e, s);
      return r && (i[t] = r), r;
    }
  }
  evictCachesForKey(t) {
    this.textureCache[t] && (this.gl.deleteTexture(this.textureCache[t]), delete this.textureCache[t]);
  }
  copyGLTo2D(t, e) {
    const s = t.canvas,
      i = e.targetCanvas,
      r = i.getContext("2d");
    if (!r) return;
    r.translate(0, i.height), r.scale(1, -1);
    const n = s.height - i.height;
    r.drawImage(s, 0, n, i.width, i.height, 0, 0, i.width, i.height);
  }
  copyGLTo2DPutImageData(t, e) {
    const s = e.targetCanvas.getContext("2d"),
      i = e.destinationWidth,
      r = e.destinationHeight,
      n = i * r * 4;
    if (!s) return;
    const o = new Uint8Array(this.imageBuffer, 0, n),
      a = new Uint8ClampedArray(this.imageBuffer, 0, n);
    t.readPixels(0, 0, i, r, t.RGBA, t.UNSIGNED_BYTE, o);
    const h = new ImageData(a, i, r);
    s.putImageData(h, 0, 0);
  }
  captureGPUInfo() {
    if (this.gpuInfo) return this.gpuInfo;
    const t = this.gl,
      e = {
        renderer: "",
        vendor: ""
      };
    if (!t) return e;
    const s = t.getExtension("WEBGL_debug_renderer_info");
    if (s) {
      const i = t.getParameter(s.UNMASKED_RENDERER_WEBGL),
        r = t.getParameter(s.UNMASKED_VENDOR_WEBGL);
      i && (e.renderer = i.toLowerCase()), r && (e.vendor = r.toLowerCase());
    }
    return this.gpuInfo = e, e;
  }
}
let Go;
function No() {
  const {
    WebGLProbe: t
  } = p();
  return t.queryWebGL(pt()), o.enableGLFiltering && t.isSupported(o.textureSize) ? new zo({
    tileSize: o.textureSize
  }) : new Ho();
}
function Uo() {
  return !Go && (!(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0]) && (Go = No()), Go;
}
function qo(t) {
  Go = t;
}
const Ko = ["filters", "resizeFilter", "src", "crossOrigin", "type"],
  Jo = ["cropX", "cropY"];
class Qo extends yi {
  static getDefaults() {
    return s(s({}, super.getDefaults()), Qo.ownDefaults);
  }
  constructor(e, s) {
    super(), t(this, "_lastScaleX", 1), t(this, "_lastScaleY", 1), t(this, "_filterScalingX", 1), t(this, "_filterScalingY", 1), this.filters = [], Object.assign(this, Qo.ownDefaults), this.setOptions(s), this.cacheKey = "texture".concat(ft()), this.setElement("string" == typeof e ? (this.canvas && re(this.canvas.getElement()) || m()).getElementById(e) : e, s);
  }
  getElement() {
    return this._element;
  }
  setElement(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    this.removeTexture(this.cacheKey), this.removeTexture("".concat(this.cacheKey, "_filtered")), this._element = t, this._originalElement = t, this._setWidthHeight(e), t.classList.add(Qo.CSS_CANVAS), 0 !== this.filters.length && this.applyFilters(), this.resizeFilter && this.applyResizeFilters();
  }
  removeTexture(t) {
    const e = Uo(!1);
    e instanceof zo && e.evictCachesForKey(t);
  }
  dispose() {
    super.dispose(), this.removeTexture(this.cacheKey), this.removeTexture("".concat(this.cacheKey, "_filtered")), this._cacheContext = null, ["_originalElement", "_element", "_filteredEl", "_cacheCanvas"].forEach(t => {
      const e = this[t];
      e && p().dispose(e), this[t] = void 0;
    });
  }
  getCrossOrigin() {
    return this._originalElement && (this._originalElement.crossOrigin || null);
  }
  getOriginalSize() {
    const t = this.getElement();
    return t ? {
      width: t.naturalWidth || t.width,
      height: t.naturalHeight || t.height
    } : {
      width: 0,
      height: 0
    };
  }
  _stroke(t) {
    if (!this.stroke || 0 === this.strokeWidth) return;
    const e = this.width / 2,
      s = this.height / 2;
    t.beginPath(), t.moveTo(-e, -s), t.lineTo(e, -s), t.lineTo(e, s), t.lineTo(-e, s), t.lineTo(-e, -s), t.closePath();
  }
  toObject() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [];
    const e = [];
    return this.filters.forEach(t => {
      t && e.push(t.toObject());
    }), s(s({}, super.toObject([...Jo, ...t])), {}, {
      src: this.getSrc(),
      crossOrigin: this.getCrossOrigin(),
      filters: e
    }, this.resizeFilter ? {
      resizeFilter: this.resizeFilter.toObject()
    } : {});
  }
  hasCrop() {
    return !!this.cropX || !!this.cropY || this.width < this._element.width || this.height < this._element.height;
  }
  _toSVG() {
    const t = [],
      e = this._element,
      s = -this.width / 2,
      i = -this.height / 2;
    let r = [],
      n = [],
      o = "",
      a = "";
    if (!e) return [];
    if (this.hasCrop()) {
      const t = ft();
      r.push('<clipPath id="imageCrop_' + t + '">\n', '\t<rect x="' + s + '" y="' + i + '" width="' + this.width + '" height="' + this.height + '" />\n', "</clipPath>\n"), o = ' clip-path="url(#imageCrop_' + t + ')" ';
    }
    if (this.imageSmoothing || (a = ' image-rendering="optimizeSpeed"'), t.push("\t<image ", "COMMON_PARTS", 'xlink:href="'.concat(this.getSvgSrc(!0), '" x="').concat(s - this.cropX, '" y="').concat(i - this.cropY, '" width="').concat(e.width || e.naturalWidth, '" height="').concat(e.height || e.naturalHeight, '"').concat(a).concat(o, "></image>\n")), this.stroke || this.strokeDashArray) {
      const t = this.fill;
      this.fill = null, n = ['\t<rect x="'.concat(s, '" y="').concat(i, '" width="').concat(this.width, '" height="').concat(this.height, '" style="').concat(this.getSvgStyles(), '" />\n')], this.fill = t;
    }
    return r = this.paintFirst !== K ? r.concat(n, t) : r.concat(t, n), r;
  }
  getSrc(t) {
    const e = t ? this._element : this._originalElement;
    return e ? e.toDataURL ? e.toDataURL() : this.srcFromAttribute ? e.getAttribute("src") || "" : e.src : this.src || "";
  }
  getSvgSrc(t) {
    return this.getSrc(t);
  }
  setSrc(t) {
    let {
      crossOrigin: e,
      signal: s
    } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    return Lt(t, {
      crossOrigin: e,
      signal: s
    }).then(t => {
      void 0 !== e && this.set({
        crossOrigin: e
      }), this.setElement(t);
    });
  }
  toString() {
    return '#<Image: { src: "'.concat(this.getSrc(), '" }>');
  }
  applyResizeFilters() {
    const t = this.resizeFilter,
      e = this.minimumScaleTrigger,
      s = this.getTotalObjectScaling(),
      i = s.x,
      r = s.y,
      n = this._filteredEl || this._originalElement;
    if (this.group && this.set("dirty", !0), !t || i > e && r > e) return this._element = n, this._filterScalingX = 1, this._filterScalingY = 1, this._lastScaleX = i, void (this._lastScaleY = r);
    const o = pt(),
      a = n.width,
      h = n.height;
    o.width = a, o.height = h, this._element = o, this._lastScaleX = t.scaleX = i, this._lastScaleY = t.scaleY = r, Uo().applyFilters([t], n, a, h, this._element), this._filterScalingX = o.width / this._originalElement.width, this._filterScalingY = o.height / this._originalElement.height;
  }
  applyFilters() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.filters || [];
    if (t = t.filter(t => t && !t.isNeutralState()), this.set("dirty", !0), this.removeTexture("".concat(this.cacheKey, "_filtered")), 0 === t.length) return this._element = this._originalElement, this._filteredEl = void 0, this._filterScalingX = 1, void (this._filterScalingY = 1);
    const e = this._originalElement,
      s = e.naturalWidth || e.width,
      i = e.naturalHeight || e.height;
    if (this._element === this._originalElement) {
      const t = pt();
      t.width = s, t.height = i, this._element = t, this._filteredEl = t;
    } else this._filteredEl && (this._element = this._filteredEl, this._filteredEl.getContext("2d").clearRect(0, 0, s, i), this._lastScaleX = 1, this._lastScaleY = 1);
    Uo().applyFilters(t, this._originalElement, s, i, this._element), this._originalElement.width === this._element.width && this._originalElement.height === this._element.height || (this._filterScalingX = this._element.width / this._originalElement.width, this._filterScalingY = this._element.height / this._originalElement.height);
  }
  _render(t) {
    t.imageSmoothingEnabled = this.imageSmoothing, !0 !== this.isMoving && this.resizeFilter && this._needsResize() && this.applyResizeFilters(), this._stroke(t), this._renderPaintInOrder(t);
  }
  drawCacheOnCanvas(t) {
    t.imageSmoothingEnabled = this.imageSmoothing, super.drawCacheOnCanvas(t);
  }
  shouldCache() {
    return this.needsItsOwnCache();
  }
  _renderFill(t) {
    const e = this._element;
    if (!e) return;
    const s = this._filterScalingX,
      i = this._filterScalingY,
      r = this.width,
      n = this.height,
      o = Math.max(this.cropX, 0),
      a = Math.max(this.cropY, 0),
      h = e.naturalWidth || e.width,
      c = e.naturalHeight || e.height,
      l = o * s,
      u = a * i,
      d = Math.min(r * s, h - l),
      g = Math.min(n * i, c - u),
      f = -r / 2,
      p = -n / 2,
      m = Math.min(r, h / s - o),
      v = Math.min(n, c / i - a);
    e && t.drawImage(e, l, u, d, g, f, p, m, v);
  }
  _needsResize() {
    const t = this.getTotalObjectScaling();
    return t.x !== this._lastScaleX || t.y !== this._lastScaleY;
  }
  _resetWidthHeight() {
    this.set(this.getOriginalSize());
  }
  _setWidthHeight() {
    let {
      width: t,
      height: e
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    const s = this.getOriginalSize();
    this.width = t || s.width, this.height = e || s.height;
  }
  parsePreserveAspectRatioAttribute() {
    const t = Kt(this.preserveAspectRatio || ""),
      e = this.width,
      s = this.height,
      i = {
        width: e,
        height: s
      };
    let r,
      n = this._element.width,
      o = this._element.height,
      a = 1,
      h = 1,
      c = 0,
      l = 0,
      u = 0,
      d = 0;
    return !t || t.alignX === j && t.alignY === j ? (a = e / n, h = s / o) : ("meet" === t.meetOrSlice && (a = h = Mr(this._element, i), r = (e - n * a) / 2, "Min" === t.alignX && (c = -r), "Max" === t.alignX && (c = r), r = (s - o * h) / 2, "Min" === t.alignY && (l = -r), "Max" === t.alignY && (l = r)), "slice" === t.meetOrSlice && (a = h = Pr(this._element, i), r = n - e / a, "Mid" === t.alignX && (u = r / 2), "Max" === t.alignX && (u = r), r = o - s / h, "Mid" === t.alignY && (d = r / 2), "Max" === t.alignY && (d = r), n = e / a, o = s / h)), {
      width: n,
      height: o,
      scaleX: a,
      scaleY: h,
      offsetLeft: c,
      offsetTop: l,
      cropX: u,
      cropY: d
    };
  }
  static fromObject(t, e) {
    let {
        filters: r,
        resizeFilter: n,
        src: o,
        crossOrigin: a,
        type: h
      } = t,
      c = i(t, Ko);
    return Promise.all([Lt(o, s(s({}, e), {}, {
      crossOrigin: a
    })), r && Rt(r, e), n && Rt([n], e), It(c, e)]).then(t => {
      let [e, i = [], [r] = [], n = {}] = t;
      return new this(e, s(s({}, c), {}, {
        src: o,
        filters: i,
        resizeFilter: r
      }, n));
    });
  }
  static fromURL(t) {
    let {
        crossOrigin: e = null,
        signal: s
      } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      i = arguments.length > 2 ? arguments[2] : void 0;
    return Lt(t, {
      crossOrigin: e,
      signal: s
    }).then(t => new this(t, i));
  }
  static async fromElement(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      s = arguments.length > 2 ? arguments[2] : void 0;
    const i = ur(t, this.ATTRIBUTE_NAMES, s);
    return this.fromURL(i["xlink:href"], e, i).catch(t => (a("log", "Unable to parse Image", t), null));
  }
}
function Zo(t) {
  if (!qe.test(t.nodeName)) return {};
  const e = t.getAttribute("viewBox");
  let s,
    i,
    r = 1,
    n = 1,
    o = 0,
    a = 0;
  const h = t.getAttribute("width"),
    c = t.getAttribute("height"),
    l = t.getAttribute("x") || 0,
    u = t.getAttribute("y") || 0,
    d = !(e && Je.test(e)),
    g = !h || !c || "100%" === h || "100%" === c;
  let f = "",
    p = 0,
    m = 0;
  if (d && (l || u) && t.parentNode && "#document" !== t.parentNode.nodeName && (f = " translate(" + qt(l || "0") + " " + qt(u || "0") + ") ", s = (t.getAttribute("transform") || "") + f, t.setAttribute("transform", s), t.removeAttribute("x"), t.removeAttribute("y")), d && g) return {
    width: 0,
    height: 0
  };
  const v = {
    width: 0,
    height: 0
  };
  if (d) return v.width = qt(h), v.height = qt(c), v;
  const y = e.match(Je);
  o = -parseFloat(y[1]), a = -parseFloat(y[2]);
  const _ = parseFloat(y[3]),
    x = parseFloat(y[4]);
  v.minX = o, v.minY = a, v.viewBoxWidth = _, v.viewBoxHeight = x, g ? (v.width = _, v.height = x) : (v.width = qt(h), v.height = qt(c), r = v.width / _, n = v.height / x);
  const C = Kt(t.getAttribute("preserveAspectRatio") || "");
  if (C.alignX !== j && ("meet" === C.meetOrSlice && (n = r = r > n ? n : r), "slice" === C.meetOrSlice && (n = r = r > n ? r : n), p = v.width - _ * r, m = v.height - x * r, "Mid" === C.alignX && (p /= 2), "Mid" === C.alignY && (m /= 2), "Min" === C.alignX && (p = 0), "Min" === C.alignY && (m = 0)), 1 === r && 1 === n && 0 === o && 0 === a && 0 === l && 0 === u) return v;
  if ((l || u) && "#document" !== t.parentNode.nodeName && (f = " translate(" + qt(l || "0") + " " + qt(u || "0") + ") "), s = f + " matrix(" + r + " 0 0 " + n + " " + (o * r + p) + " " + (a * n + m) + ") ", "svg" === t.nodeName) {
    for (i = t.ownerDocument.createElementNS(Ve, "g"); t.firstChild;) i.appendChild(t.firstChild);
    t.appendChild(i);
  } else i = t, i.removeAttribute("x"), i.removeAttribute("y"), s = i.getAttribute("transform") + s;
  return i.setAttribute("transform", s), v;
}
t(Qo, "type", "Image"), t(Qo, "cacheProperties", [...fs, ...Jo]), t(Qo, "ownDefaults", {
  strokeWidth: 0,
  srcFromAttribute: !1,
  minimumScaleTrigger: .5,
  cropX: 0,
  cropY: 0,
  imageSmoothing: !0
}), t(Qo, "CSS_CANVAS", "canvas-img"), t(Qo, "ATTRIBUTE_NAMES", [...ji, "x", "y", "width", "height", "preserveAspectRatio", "xlink:href", "crossOrigin", "image-rendering"]), tt.setClass(Qo), tt.setSVGClass(Qo);
const $o = t => t.tagName.replace("svg:", ""),
  ta = Xe(["pattern", "defs", "symbol", "metadata", "clipPath", "mask", "desc"]);
function ea(t, e) {
  let s,
    i,
    r,
    n,
    o = [];
  for (r = 0, n = e.length; r < n; r++) s = e[r], i = t.getElementsByTagNameNS("http://www.w3.org/2000/svg", s), o = o.concat(Array.from(i));
  return o;
}
const sa = ["gradientTransform", "x1", "x2", "y1", "y2", "gradientUnits", "cx", "cy", "r", "fx", "fy"],
  ia = "xlink:href";
function ra(t, e) {
  var s;
  const i = (null === (s = e.getAttribute(ia)) || void 0 === s ? void 0 : s.slice(1)) || "",
    r = t.getElementById(i);
  if (r && r.getAttribute(ia) && ra(t, r), r && (sa.forEach(t => {
    const s = r.getAttribute(t);
    !e.hasAttribute(t) && s && e.setAttribute(t, s);
  }), !e.children.length)) {
    const t = r.cloneNode(!0);
    for (; t.firstChild;) e.appendChild(t.firstChild);
  }
  e.removeAttribute(ia);
}
const na = ["linearGradient", "radialGradient", "svg:linearGradient", "svg:radialGradient"];
function oa(t) {
  const e = t.getElementsByTagName("style");
  let i, r;
  const n = {};
  for (i = 0, r = e.length; i < r; i++) {
    const t = (e[i].textContent || "").replace(/\/\*[\s\S]*?\*\//g, "");
    "" !== t.trim() && t.split("}").filter((t, e, s) => s.length > 1 && t.trim()).forEach(t => {
      if ((t.match(/{/g) || []).length > 1 && t.trim().startsWith("@")) return;
      const e = t.split("{"),
        o = {},
        a = e[1].trim().split(";").filter(function (t) {
          return t.trim();
        });
      for (i = 0, r = a.length; i < r; i++) {
        const t = a[i].split(":"),
          e = t[0].trim(),
          s = t[1].trim();
        o[e] = s;
      }
      (t = e[0].trim()).split(",").forEach(t => {
        "" !== (t = t.replace(/^svg/i, "").trim()) && (n[t] = s(s({}, n[t] || {}), o));
      });
    });
  }
  return n;
}
const aa = t => tt.getSVGClass($o(t).toLowerCase());
class ha {
  constructor(t, e, s, i, r) {
    this.elements = t, this.options = e, this.reviver = s, this.regexUrl = /^url\(['"]?#([^'"]+)['"]?\)/g, this.doc = i, this.clipPaths = r, this.gradientDefs = function (t) {
      const e = ea(t, na),
        s = {};
      let i = e.length;
      for (; i--;) {
        const r = e[i];
        r.getAttribute("xlink:href") && ra(t, r);
        const n = r.getAttribute("id");
        n && (s[n] = r);
      }
      return s;
    }(i), this.cssRules = oa(i);
  }
  parse() {
    return Promise.all(this.elements.map(t => this.createObject(t)));
  }
  async createObject(t) {
    const e = aa(t);
    if (e) {
      const s = await e.fromElement(t, this.options, this.cssRules);
      return this.resolveGradient(s, t, K), this.resolveGradient(s, t, J), s instanceof Qo && s._originalElement ? cn(s, s.parsePreserveAspectRatioAttribute()) : cn(s), await this.resolveClipPath(s, t), this.reviver && this.reviver(t, s), s;
    }
    return null;
  }
  extractPropertyDefinition(t, e, s) {
    const i = t[e],
      r = this.regexUrl;
    if (!r.test(i)) return;
    r.lastIndex = 0;
    const n = r.exec(i)[1];
    return r.lastIndex = 0, s[n];
  }
  resolveGradient(t, e, i) {
    const r = this.extractPropertyDefinition(t, i, this.gradientDefs);
    if (r) {
      const n = e.getAttribute(i + "-opacity"),
        o = Ln.fromElement(r, t, s(s({}, this.options), {}, {
          opacity: n
        }));
      t.set(i, o);
    }
  }
  async resolveClipPath(t, e) {
    const s = this.extractPropertyDefinition(t, "clipPath", this.clipPaths);
    if (s) {
      const i = bt(t.calcTransformMatrix()),
        r = s[0].parentElement;
      let n = e;
      for (; n.parentElement && n.getAttribute("clip-path") !== t.clipPath;) n = n.parentElement;
      n.parentElement.appendChild(r);
      const o = nr("".concat(n.getAttribute("transform") || "", " ").concat(r.getAttribute("originalTransform") || ""));
      r.setAttribute("transform", "matrix(".concat(o.join(","), ")"));
      const a = await Promise.all(s.map(t => aa(t).fromElement(t, this.options, this.cssRules).then(t => (cn(t), t.fillRule = t.clipRule, delete t.clipRule, t)))),
        h = 1 === a.length ? a[0] : new Dr(a),
        c = wt(i, h.calcTransformMatrix());
      h.clipPath && (await this.resolveClipPath(h, n));
      const {
        scaleX: l,
        scaleY: u,
        angle: d,
        skewX: g,
        translateX: f,
        translateY: p
      } = Ot(c);
      h.set({
        flipX: !1,
        flipY: !1
      }), h.set({
        scaleX: l,
        scaleY: u,
        angle: d,
        skewX: g,
        skewY: 0
      }), h.setPositionByOrigin(new ot(f, p), D, D), t.clipPath = h;
    } else delete t.clipPath;
  }
}
const ca = t => Ue.test($o(t)),
  la = () => ({
    objects: [],
    elements: [],
    options: {},
    allElements: []
  });
async function ua(t, e) {
  let {
    crossOrigin: i,
    signal: r
  } = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
  if (r && r.aborted) return a("log", new c("parseSVGDocument")), la();
  const n = t.documentElement;
  !function (t) {
    const e = ea(t, ["use", "svg:use"]),
      s = ["x", "y", "xlink:href", "href", "transform"];
    for (const i of e) {
      const e = i.attributes,
        r = {};
      for (const t of e) t.value && (r[t.name] = t.value);
      const n = (r["xlink:href"] || r.href || "").slice(1);
      if ("" === n) return;
      const o = t.getElementById(n);
      if (null === o) return;
      let a = o.cloneNode(!0);
      const h = a.attributes,
        c = {};
      for (const t of h) t.value && (c[t.name] = t.value);
      const {
          x: l = 0,
          y: u = 0,
          transform: d = ""
        } = r,
        g = "".concat(d, " ").concat(c.transform || "", " translate(").concat(l, ", ").concat(u, ")");
      if (Zo(a), /^svg$/i.test(a.nodeName)) {
        const t = a.ownerDocument.createElementNS(Ve, "g");
        Object.entries(c).forEach(e => {
          let [s, i] = e;
          return t.setAttributeNS(Ve, s, i);
        }), t.append(...a.childNodes), a = t;
      }
      for (const t of e) {
        if (!t) continue;
        const {
          name: e,
          value: i
        } = t;
        if (!s.includes(e)) if ("style" === e) {
          const t = {};
          hr(i, t), Object.entries(c).forEach(e => {
            let [s, i] = e;
            t[s] = i;
          }), hr(c.style || "", t);
          const s = Object.entries(t).map(t => t.join(":")).join(";");
          a.setAttribute(e, s);
        } else !c[e] && a.setAttribute(e, i);
      }
      a.setAttribute("transform", g), a.setAttribute("instantiated_by_use", "1"), a.removeAttribute("id"), i.parentNode.replaceChild(a, i);
    }
  }(t);
  const o = Array.from(n.getElementsByTagName("*")),
    h = s(s({}, Zo(n)), {}, {
      crossOrigin: i,
      signal: r
    }),
    l = o.filter(t => (Zo(t), ca(t) && !function (t) {
      let e = t;
      for (; e && (e = e.parentElement);) if (e && e.nodeName && ta.test($o(e)) && !e.getAttribute("instantiated_by_use")) return !0;
      return !1;
    }(t)));
  if (!l || l && !l.length) return s(s({}, la()), {}, {
    options: h,
    allElements: o
  });
  const u = {};
  o.filter(t => "clipPath" === $o(t)).forEach(t => {
    t.setAttribute("originalTransform", t.getAttribute("transform") || "");
    const e = t.getAttribute("id");
    u[e] = Array.from(t.getElementsByTagName("*")).filter(t => ca(t));
  });
  const d = new ha(l, h, e, t, u);
  return {
    objects: await d.parse(),
    elements: l,
    options: h,
    allElements: o
  };
}
function da(t, e, s) {
  return ua(new (v().DOMParser)().parseFromString(t.trim(), "text/xml"), e, s);
}
function ga(t, e) {
  let s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
  return new Promise((e, i) => {
    hn(t.replace(/^\n\s*/, "").trim(), {
      onComplete: t => {
        const s = t.responseXML;
        s && e(s), i();
      },
      signal: s.signal
    });
  }).then(t => ua(t, e, s)).catch(() => la());
}
const fa = Y,
  pa = t => function (e, s, i) {
    const {
      points: r,
      pathOffset: n
    } = i;
    return new ot(r[t]).subtract(n).transform(wt(i.getViewportTransform(), i.calcTransformMatrix()));
  },
  ma = (t, e, s, i) => {
    const {
        target: r,
        pointIndex: n
      } = e,
      o = r,
      a = Se(new ot(s, i), void 0, o.calcOwnMatrix());
    return o.points[n] = a.add(o.pathOffset), o.setDimensions(), !0;
  },
  va = (t, e) => function (i, r, n, o) {
    const a = r.target,
      h = new ot(a.points[(t > 0 ? t : a.points.length) - 1]),
      c = h.subtract(a.pathOffset).transform(a.calcOwnMatrix()),
      l = e(i, s(s({}, r), {}, {
        pointIndex: t
      }), n, o),
      u = h.subtract(a.pathOffset).transform(a.calcOwnMatrix()).subtract(c);
    return a.left -= u.x, a.top -= u.y, l;
  },
  ya = t => Xs(fa, va(t, ma));
const _a = (t, e, s) => {
  const {
      path: i,
      pathOffset: r
    } = t,
    n = i[e];
  return new ot(n[s] - r.x, n[s + 1] - r.y).transform(wt(t.getViewportTransform(), t.calcTransformMatrix()));
};
function xa(t, e, s) {
  const {
    commandIndex: i,
    pointIndex: r
  } = this;
  return _a(s, i, r);
}
function Ca(t, e, i, r) {
  const {
      target: n
    } = e,
    {
      commandIndex: o,
      pointIndex: a
    } = this,
    h = ((t, e, s, i, r) => {
      const {
          path: n,
          pathOffset: o
        } = t,
        a = n[(i > 0 ? i : n.length) - 1],
        h = new ot(a[r], a[r + 1]),
        c = h.subtract(o).transform(t.calcOwnMatrix()),
        l = Se(new ot(e, s), void 0, t.calcOwnMatrix());
      n[i][r] = l.x + o.x, n[i][r + 1] = l.y + o.y, t.setDimensions();
      const u = h.subtract(t.pathOffset).transform(t.calcOwnMatrix()).subtract(c);
      return t.left -= u.x, t.top -= u.y, t.set("dirty", !0), !0;
    })(n, i, r, o, a);
  return ke(this.actionName, s(s({}, Fe(t, e, i, r)), {}, {
    commandIndex: o,
    pointIndex: a
  })), h;
}
class ba extends zs {
  constructor(t) {
    super(t);
  }
  render(t, e, i, r, n) {
    const o = s(s({}, r), {}, {
      cornerColor: this.controlFill,
      cornerStrokeColor: this.controlStroke,
      transparentCorners: !this.controlFill
    });
    super.render(t, e, i, o, n);
  }
}
class wa extends ba {
  constructor(t) {
    super(t);
  }
  render(t, e, s, i, r) {
    const {
        path: n
      } = r,
      {
        commandIndex: o,
        pointIndex: a,
        connectToCommandIndex: h,
        connectToPointIndex: c
      } = this;
    t.save(), t.strokeStyle = this.controlStroke, this.connectionDashArray && t.setLineDash(this.connectionDashArray);
    const [l] = n[o],
      u = _a(r, h, c);
    if ("Q" === l) {
      const i = _a(r, o, a + 2);
      t.moveTo(i.x, i.y), t.lineTo(e, s);
    } else t.moveTo(e, s);
    t.lineTo(u.x, u.y), t.stroke(), t.restore(), super.render(t, e, s, i, r);
  }
}
const Sa = (t, e, i, r, n, o) => new (i ? wa : ba)(s(s({
  commandIndex: t,
  pointIndex: e,
  actionName: "modifyPath",
  positionHandler: xa,
  actionHandler: Ca,
  connectToCommandIndex: n,
  connectToPointIndex: o
}, r), i ? r.controlPointStyle : r.pointStyle));
var Ta = Object.freeze({
  __proto__: null,
  changeWidth: Ys,
  createObjectDefaultControls: gi,
  createPathControls: function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const s = {};
    let i = "M";
    return t.path.forEach((t, r) => {
      const n = t[0];
      switch ("Z" !== n && (s["c_".concat(r, "_").concat(n)] = Sa(r, t.length - 2, !1, e)), n) {
        case "C":
          s["c_".concat(r, "_C_CP_1")] = Sa(r, 1, !0, e, r - 1, (t => "C" === t ? 5 : "Q" === t ? 3 : 1)(i)), s["c_".concat(r, "_C_CP_2")] = Sa(r, 3, !0, e, r, 5);
          break;
        case "Q":
          s["c_".concat(r, "_Q_CP_1")] = Sa(r, 1, !0, e, r, 3);
      }
      i = n;
    }), s;
  },
  createPolyActionHandler: ya,
  createPolyControls: function (t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    const i = {};
    for (let r = 0; r < ("number" == typeof t ? t : t.points.length); r++) i["p".concat(r)] = new zs(s({
      actionName: fa,
      positionHandler: pa(r),
      actionHandler: ya(r)
    }, e));
    return i;
  },
  createPolyPositionHandler: pa,
  createResizeControls: fi,
  createTextboxDefaultControls: pi,
  dragHandler: Ie,
  factoryPolyActionHandler: va,
  getLocalPoint: Re,
  polyActionHandler: ma,
  renderCircleControl: Vs,
  renderSquareControl: Hs,
  rotationStyleHandler: Gs,
  rotationWithSnapping: Ns,
  scaleCursorStyleHandler: Js,
  scaleOrSkewActionName: ci,
  scaleSkewCursorStyleHandler: li,
  scalingEqually: Zs,
  scalingX: $s,
  scalingXOrSkewingY: ui,
  scalingY: ti,
  scalingYOrSkewingX: di,
  skewCursorStyleHandler: ri,
  skewHandlerX: oi,
  skewHandlerY: ai,
  wrapWithFireEvent: Xs,
  wrapWithFixedAnchor: Ws
});
const Oa = t => void 0 !== t.webgl,
  ka = (t, e) => {
    const s = pt(),
      i = pt().getContext("webgl"),
      r = {
        imageBuffer: new ArrayBuffer(t * e * 4)
      },
      n = {
        destinationWidth: t,
        destinationHeight: e,
        targetCanvas: s
      };
    let o;
    s.width = t, s.height = e, o = v().performance.now(), zo.prototype.copyGLTo2D.call(r, i, n);
    const a = v().performance.now() - o;
    o = v().performance.now(), zo.prototype.copyGLTo2DPutImageData.call(r, i, n);
    return a > v().performance.now() - o;
  },
  Da = "precision highp float",
  Ma = "\n    ".concat(Da, ";\n    varying vec2 vTexCoord;\n    uniform sampler2D uTexture;\n    void main() {\n      gl_FragColor = texture2D(uTexture, vTexCoord);\n    }"),
  Pa = ["type"],
  Ea = ["type"],
  Aa = new RegExp(Da, "g");
class ja {
  get type() {
    return this.constructor.type;
  }
  constructor() {
    let t = i(arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}, Pa);
    Object.assign(this, this.constructor.defaults, t);
  }
  getFragmentSource() {
    return Ma;
  }
  getVertexSource() {
    return "\n    attribute vec2 aPosition;\n    varying vec2 vTexCoord;\n    void main() {\n      vTexCoord = aPosition;\n      gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);\n    }";
  }
  createProgram(t) {
    let e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : this.getFragmentSource(),
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : this.getVertexSource();
    const {
      WebGLProbe: {
        GLPrecision: i = "highp"
      }
    } = p();
    "highp" !== i && (e = e.replace(Aa, Da.replace("highp", i)));
    const r = t.createShader(t.VERTEX_SHADER),
      n = t.createShader(t.FRAGMENT_SHADER),
      o = t.createProgram();
    if (!r || !n || !o) throw new h("Vertex, fragment shader or program creation error");
    if (t.shaderSource(r, s), t.compileShader(r), !t.getShaderParameter(r, t.COMPILE_STATUS)) throw new h("Vertex shader compile error for ".concat(this.type, ": ").concat(t.getShaderInfoLog(r)));
    if (t.shaderSource(n, e), t.compileShader(n), !t.getShaderParameter(n, t.COMPILE_STATUS)) throw new h("Fragment shader compile error for ".concat(this.type, ": ").concat(t.getShaderInfoLog(n)));
    if (t.attachShader(o, r), t.attachShader(o, n), t.linkProgram(o), !t.getProgramParameter(o, t.LINK_STATUS)) throw new h('Shader link error for "'.concat(this.type, '" ').concat(t.getProgramInfoLog(o)));
    const a = this.getUniformLocations(t, o) || {};
    return a.uStepW = t.getUniformLocation(o, "uStepW"), a.uStepH = t.getUniformLocation(o, "uStepH"), {
      program: o,
      attributeLocations: this.getAttributeLocations(t, o),
      uniformLocations: a
    };
  }
  getAttributeLocations(t, e) {
    return {
      aPosition: t.getAttribLocation(e, "aPosition")
    };
  }
  getUniformLocations(t, e) {
    const s = this.constructor.uniformLocations,
      i = {};
    for (let r = 0; r < s.length; r++) i[s[r]] = t.getUniformLocation(e, s[r]);
    return i;
  }
  sendAttributeData(t, e, s) {
    const i = e.aPosition,
      r = t.createBuffer();
    t.bindBuffer(t.ARRAY_BUFFER, r), t.enableVertexAttribArray(i), t.vertexAttribPointer(i, 2, t.FLOAT, !1, 0, 0), t.bufferData(t.ARRAY_BUFFER, s, t.STATIC_DRAW);
  }
  _setupFrameBuffer(t) {
    const e = t.context;
    if (t.passes > 1) {
      const s = t.destinationWidth,
        i = t.destinationHeight;
      t.sourceWidth === s && t.sourceHeight === i || (e.deleteTexture(t.targetTexture), t.targetTexture = t.filterBackend.createTexture(e, s, i)), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, t.targetTexture, 0);
    } else e.bindFramebuffer(e.FRAMEBUFFER, null), e.finish();
  }
  _swapTextures(t) {
    t.passes--, t.pass++;
    const e = t.targetTexture;
    t.targetTexture = t.sourceTexture, t.sourceTexture = e;
  }
  isNeutralState(t) {
    return !1;
  }
  applyTo(t) {
    Oa(t) ? (this._setupFrameBuffer(t), this.applyToWebGL(t), this._swapTextures(t)) : this.applyTo2d(t);
  }
  applyTo2d(t) {}
  getCacheKey() {
    return this.type;
  }
  retrieveShader(t) {
    const e = this.getCacheKey();
    return t.programCache[e] || (t.programCache[e] = this.createProgram(t.context)), t.programCache[e];
  }
  applyToWebGL(t) {
    const e = t.context,
      s = this.retrieveShader(t);
    0 === t.pass && t.originalTexture ? e.bindTexture(e.TEXTURE_2D, t.originalTexture) : e.bindTexture(e.TEXTURE_2D, t.sourceTexture), e.useProgram(s.program), this.sendAttributeData(e, s.attributeLocations, t.aPosition), e.uniform1f(s.uniformLocations.uStepW, 1 / t.sourceWidth), e.uniform1f(s.uniformLocations.uStepH, 1 / t.sourceHeight), this.sendUniformData(e, s.uniformLocations), e.viewport(0, 0, t.destinationWidth, t.destinationHeight), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  bindAdditionalTexture(t, e, s) {
    t.activeTexture(s), t.bindTexture(t.TEXTURE_2D, e), t.activeTexture(t.TEXTURE0);
  }
  unbindAdditionalTexture(t, e) {
    t.activeTexture(e), t.bindTexture(t.TEXTURE_2D, null), t.activeTexture(t.TEXTURE0);
  }
  sendUniformData(t, e) {}
  createHelpLayer(t) {
    if (!t.helpLayer) {
      const e = pt();
      e.width = t.sourceWidth, e.height = t.sourceHeight, t.helpLayer = e;
    }
  }
  toObject() {
    const t = Object.keys(this.constructor.defaults || {});
    return s({
      type: this.type
    }, t.reduce((t, e) => (t[e] = this[e], t), {}));
  }
  toJSON() {
    return this.toObject();
  }
  static async fromObject(t, e) {
    return new this(i(t, Ea));
  }
}
t(ja, "type", "BaseFilter"), t(ja, "uniformLocations", []);
const Fa = {
  multiply: "gl_FragColor.rgb *= uColor.rgb;\n",
  screen: "gl_FragColor.rgb = 1.0 - (1.0 - gl_FragColor.rgb) * (1.0 - uColor.rgb);\n",
  add: "gl_FragColor.rgb += uColor.rgb;\n",
  difference: "gl_FragColor.rgb = abs(gl_FragColor.rgb - uColor.rgb);\n",
  subtract: "gl_FragColor.rgb -= uColor.rgb;\n",
  lighten: "gl_FragColor.rgb = max(gl_FragColor.rgb, uColor.rgb);\n",
  darken: "gl_FragColor.rgb = min(gl_FragColor.rgb, uColor.rgb);\n",
  exclusion: "gl_FragColor.rgb += uColor.rgb - 2.0 * (uColor.rgb * gl_FragColor.rgb);\n",
  overlay: "\n    if (uColor.r < 0.5) {\n      gl_FragColor.r *= 2.0 * uColor.r;\n    } else {\n      gl_FragColor.r = 1.0 - 2.0 * (1.0 - gl_FragColor.r) * (1.0 - uColor.r);\n    }\n    if (uColor.g < 0.5) {\n      gl_FragColor.g *= 2.0 * uColor.g;\n    } else {\n      gl_FragColor.g = 1.0 - 2.0 * (1.0 - gl_FragColor.g) * (1.0 - uColor.g);\n    }\n    if (uColor.b < 0.5) {\n      gl_FragColor.b *= 2.0 * uColor.b;\n    } else {\n      gl_FragColor.b = 1.0 - 2.0 * (1.0 - gl_FragColor.b) * (1.0 - uColor.b);\n    }\n    ",
  tint: "\n    gl_FragColor.rgb *= (1.0 - uColor.a);\n    gl_FragColor.rgb += uColor.rgb;\n    "
};
class La extends ja {
  getCacheKey() {
    return "".concat(this.type, "_").concat(this.mode);
  }
  getFragmentSource() {
    return "\n      precision highp float;\n      uniform sampler2D uTexture;\n      uniform vec4 uColor;\n      varying vec2 vTexCoord;\n      void main() {\n        vec4 color = texture2D(uTexture, vTexCoord);\n        gl_FragColor = color;\n        if (color.a > 0.0) {\n          ".concat(Fa[this.mode], "\n        }\n      }\n      ");
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = new Nt(this.color).getSource(),
      i = s[0] * this.alpha,
      r = s[1] * this.alpha,
      n = s[2] * this.alpha,
      o = 1 - this.alpha;
    for (let t = 0; t < e.length; t += 4) {
      const s = e[t],
        a = e[t + 1],
        h = e[t + 2];
      switch (this.mode) {
        case "multiply":
          e[t] = s * i / 255, e[t + 1] = a * r / 255, e[t + 2] = h * n / 255;
          break;
        case "screen":
          e[t] = 255 - (255 - s) * (255 - i) / 255, e[t + 1] = 255 - (255 - a) * (255 - r) / 255, e[t + 2] = 255 - (255 - h) * (255 - n) / 255;
          break;
        case "add":
          e[t] = s + i, e[t + 1] = a + r, e[t + 2] = h + n;
          break;
        case "difference":
          e[t] = Math.abs(s - i), e[t + 1] = Math.abs(a - r), e[t + 2] = Math.abs(h - n);
          break;
        case "subtract":
          e[t] = s - i, e[t + 1] = a - r, e[t + 2] = h - n;
          break;
        case "darken":
          e[t] = Math.min(s, i), e[t + 1] = Math.min(a, r), e[t + 2] = Math.min(h, n);
          break;
        case "lighten":
          e[t] = Math.max(s, i), e[t + 1] = Math.max(a, r), e[t + 2] = Math.max(h, n);
          break;
        case "overlay":
          e[t] = i < 128 ? 2 * s * i / 255 : 255 - 2 * (255 - s) * (255 - i) / 255, e[t + 1] = r < 128 ? 2 * a * r / 255 : 255 - 2 * (255 - a) * (255 - r) / 255, e[t + 2] = n < 128 ? 2 * h * n / 255 : 255 - 2 * (255 - h) * (255 - n) / 255;
          break;
        case "exclusion":
          e[t] = i + s - 2 * i * s / 255, e[t + 1] = r + a - 2 * r * a / 255, e[t + 2] = n + h - 2 * n * h / 255;
          break;
        case "tint":
          e[t] = i + s * o, e[t + 1] = r + a * o, e[t + 2] = n + h * o;
      }
    }
  }
  sendUniformData(t, e) {
    const s = new Nt(this.color).getSource();
    s[0] = this.alpha * s[0] / 255, s[1] = this.alpha * s[1] / 255, s[2] = this.alpha * s[2] / 255, s[3] = this.alpha, t.uniform4fv(e.uColor, s);
  }
}
t(La, "defaults", {
  color: "#F95C63",
  mode: "multiply",
  alpha: 1
}), t(La, "type", "BlendColor"), t(La, "uniformLocations", ["uColor"]), tt.setClass(La);
const Ra = {
    multiply: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform sampler2D uImage;\n    uniform vec4 uColor;\n    varying vec2 vTexCoord;\n    varying vec2 vTexCoord2;\n    void main() {\n      vec4 color = texture2D(uTexture, vTexCoord);\n      vec4 color2 = texture2D(uImage, vTexCoord2);\n      color.rgba *= color2.rgba;\n      gl_FragColor = color;\n    }\n    ",
    mask: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform sampler2D uImage;\n    uniform vec4 uColor;\n    varying vec2 vTexCoord;\n    varying vec2 vTexCoord2;\n    void main() {\n      vec4 color = texture2D(uTexture, vTexCoord);\n      vec4 color2 = texture2D(uImage, vTexCoord2);\n      color.a = color2.a;\n      gl_FragColor = color;\n    }\n    "
  },
  Ia = ["type", "image"];
class Ba extends ja {
  getCacheKey() {
    return "".concat(this.type, "_").concat(this.mode);
  }
  getFragmentSource() {
    return Ra[this.mode];
  }
  getVertexSource() {
    return "\n    attribute vec2 aPosition;\n    varying vec2 vTexCoord;\n    varying vec2 vTexCoord2;\n    uniform mat3 uTransformMatrix;\n    void main() {\n      vTexCoord = aPosition;\n      vTexCoord2 = (uTransformMatrix * vec3(aPosition, 1.0)).xy;\n      gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);\n    }\n    ";
  }
  applyToWebGL(t) {
    const e = t.context,
      s = this.createTexture(t.filterBackend, this.image);
    this.bindAdditionalTexture(e, s, e.TEXTURE1), super.applyToWebGL(t), this.unbindAdditionalTexture(e, e.TEXTURE1);
  }
  createTexture(t, e) {
    return t.getCachedTexture(e.cacheKey, e.getElement());
  }
  calculateMatrix() {
    const t = this.image,
      {
        width: e,
        height: s
      } = t.getElement();
    return [1 / t.scaleX, 0, 0, 0, 1 / t.scaleY, 0, -t.left / e, -t.top / s, 1];
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e,
        width: s,
        height: i
      },
      filterBackend: {
        resources: r
      }
    } = t;
    const n = this.image;
    r.blendImage || (r.blendImage = pt());
    const o = r.blendImage,
      a = o.getContext("2d");
    o.width !== s || o.height !== i ? (o.width = s, o.height = i) : a.clearRect(0, 0, s, i), a.setTransform(n.scaleX, 0, 0, n.scaleY, n.left, n.top), a.drawImage(n.getElement(), 0, 0, s, i);
    const h = a.getImageData(0, 0, s, i).data;
    for (let t = 0; t < e.length; t += 4) {
      const s = e[t],
        i = e[t + 1],
        r = e[t + 2],
        n = e[t + 3],
        o = h[t],
        a = h[t + 1],
        c = h[t + 2],
        l = h[t + 3];
      switch (this.mode) {
        case "multiply":
          e[t] = s * o / 255, e[t + 1] = i * a / 255, e[t + 2] = r * c / 255, e[t + 3] = n * l / 255;
          break;
        case "mask":
          e[t + 3] = l;
      }
    }
  }
  sendUniformData(t, e) {
    const s = this.calculateMatrix();
    t.uniform1i(e.uImage, 1), t.uniformMatrix3fv(e.uTransformMatrix, !1, s);
  }
  toObject() {
    return s(s({}, super.toObject()), {}, {
      image: this.image && this.image.toObject()
    });
  }
  static async fromObject(t, e) {
    let {
        type: r,
        image: n
      } = t,
      o = i(t, Ia);
    return Qo.fromObject(n, e).then(t => new this(s(s({}, o), {}, {
      image: t
    })));
  }
}
t(Ba, "type", "BlendImage"), t(Ba, "defaults", {
  mode: "multiply",
  alpha: 1
}), t(Ba, "uniformLocations", ["uTransformMatrix", "uImage"]), tt.setClass(Ba);
class Xa extends ja {
  getFragmentSource() {
    return "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform vec2 uDelta;\n    varying vec2 vTexCoord;\n    const float nSamples = 15.0;\n    vec3 v3offset = vec3(12.9898, 78.233, 151.7182);\n    float random(vec3 scale) {\n      /* use the fragment position for a different seed per-pixel */\n      return fract(sin(dot(gl_FragCoord.xyz, scale)) * 43758.5453);\n    }\n    void main() {\n      vec4 color = vec4(0.0);\n      float total = 0.0;\n      float offset = random(v3offset);\n      for (float t = -nSamples; t <= nSamples; t++) {\n        float percent = (t + offset - 0.5) / nSamples;\n        float weight = 1.0 - abs(percent);\n        color += texture2D(uTexture, vTexCoord + uDelta * percent) * weight;\n        total += weight;\n      }\n      gl_FragColor = color / total;\n    }\n  ";
  }
  applyTo(t) {
    Oa(t) ? (this.aspectRatio = t.sourceWidth / t.sourceHeight, t.passes++, this._setupFrameBuffer(t), this.horizontal = !0, this.applyToWebGL(t), this._swapTextures(t), this._setupFrameBuffer(t), this.horizontal = !1, this.applyToWebGL(t), this._swapTextures(t)) : this.applyTo2d(t);
  }
  applyTo2d(t) {
    t.imageData = this.simpleBlur(t);
  }
  simpleBlur(t) {
    let {
      ctx: e,
      imageData: s,
      filterBackend: {
        resources: i
      }
    } = t;
    const {
      width: r,
      height: n
    } = s;
    i.blurLayer1 || (i.blurLayer1 = pt(), i.blurLayer2 = pt());
    const o = i.blurLayer1,
      a = i.blurLayer2;
    o.width === r && o.height === n || (a.width = o.width = r, a.height = o.height = n);
    const h = o.getContext("2d"),
      c = a.getContext("2d"),
      l = 15,
      u = .06 * this.blur * .5;
    let d, g, f, p;
    for (h.putImageData(s, 0, 0), c.clearRect(0, 0, r, n), p = -15; p <= l; p++) d = (Math.random() - .5) / 4, g = p / l, f = u * g * r + d, c.globalAlpha = 1 - Math.abs(g), c.drawImage(o, f, d), h.drawImage(a, 0, 0), c.globalAlpha = 1, c.clearRect(0, 0, a.width, a.height);
    for (p = -15; p <= l; p++) d = (Math.random() - .5) / 4, g = p / l, f = u * g * n + d, c.globalAlpha = 1 - Math.abs(g), c.drawImage(o, d, f), h.drawImage(a, 0, 0), c.globalAlpha = 1, c.clearRect(0, 0, a.width, a.height);
    e.drawImage(o, 0, 0);
    const m = e.getImageData(0, 0, o.width, o.height);
    return h.globalAlpha = 1, h.clearRect(0, 0, o.width, o.height), m;
  }
  sendUniformData(t, e) {
    const s = this.chooseRightDelta();
    t.uniform2fv(e.uDelta, s);
  }
  isNeutralState() {
    return 0 === this.blur;
  }
  chooseRightDelta() {
    let t = 1;
    const e = [0, 0];
    this.horizontal ? this.aspectRatio > 1 && (t = 1 / this.aspectRatio) : this.aspectRatio < 1 && (t = this.aspectRatio);
    const s = t * this.blur * .12;
    return this.horizontal ? e[0] = s : e[1] = s, e;
  }
}
t(Xa, "type", "Blur"), t(Xa, "defaults", {
  blur: 0
}), t(Xa, "uniformLocations", ["uDelta"]), tt.setClass(Xa);
class Wa extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uBrightness;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    color.rgb += uBrightness;\n    gl_FragColor = color;\n  }\n";
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = Math.round(255 * this.brightness);
    for (let t = 0; t < e.length; t += 4) e[t] = e[t] + s, e[t + 1] = e[t + 1] + s, e[t + 2] = e[t + 2] + s;
  }
  isNeutralState() {
    return 0 === this.brightness;
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uBrightness, this.brightness);
  }
}
t(Wa, "type", "Brightness"), t(Wa, "defaults", {
  brightness: 0
}), t(Wa, "uniformLocations", ["uBrightness"]), tt.setClass(Wa);
class Ya extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  varying vec2 vTexCoord;\n  uniform mat4 uColorMatrix;\n  uniform vec4 uConstants;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    color *= uColorMatrix;\n    color += uConstants;\n    gl_FragColor = color;\n  }";
  }
  applyTo2d(t) {
    const e = t.imageData.data,
      s = this.matrix,
      i = this.colorsOnly;
    for (let t = 0; t < e.length; t += 4) {
      const r = e[t],
        n = e[t + 1],
        o = e[t + 2];
      if (i) e[t] = r * s[0] + n * s[1] + o * s[2] + 255 * s[4], e[t + 1] = r * s[5] + n * s[6] + o * s[7] + 255 * s[9], e[t + 2] = r * s[10] + n * s[11] + o * s[12] + 255 * s[14];else {
        const i = e[t + 3];
        e[t] = r * s[0] + n * s[1] + o * s[2] + i * s[3] + 255 * s[4], e[t + 1] = r * s[5] + n * s[6] + o * s[7] + i * s[8] + 255 * s[9], e[t + 2] = r * s[10] + n * s[11] + o * s[12] + i * s[13] + 255 * s[14], e[t + 3] = r * s[15] + n * s[16] + o * s[17] + i * s[18] + 255 * s[19];
      }
    }
  }
  sendUniformData(t, e) {
    const s = this.matrix,
      i = [s[0], s[1], s[2], s[3], s[5], s[6], s[7], s[8], s[10], s[11], s[12], s[13], s[15], s[16], s[17], s[18]],
      r = [s[4], s[9], s[14], s[19]];
    t.uniformMatrix4fv(e.uColorMatrix, !1, i), t.uniform4fv(e.uConstants, r);
  }
  toObject() {
    return s(s({}, super.toObject()), {}, {
      matrix: [...this.matrix]
    });
  }
}
function Va(e, s) {
  var i;
  const r = (t(i = class extends Ya {
    toObject() {
      return {
        type: this.type,
        colorsOnly: this.colorsOnly
      };
    }
  }, "type", e), t(i, "defaults", {
    colorsOnly: !1,
    matrix: s
  }), i);
  return tt.setClass(r, e), r;
}
t(Ya, "type", "ColorMatrix"), t(Ya, "defaults", {
  matrix: [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0],
  colorsOnly: !0
}), t(Ya, "uniformLocations", ["uColorMatrix", "uConstants"]), tt.setClass(Ya);
const Ha = Va("Brownie", [.5997, .34553, -.27082, 0, .186, -.0377, .86095, .15059, 0, -.1449, .24113, -.07441, .44972, 0, -.02965, 0, 0, 0, 1, 0]),
  za = Va("Vintage", [.62793, .32021, -.03965, 0, .03784, .02578, .64411, .03259, 0, .02926, .0466, -.08512, .52416, 0, .02023, 0, 0, 0, 1, 0]),
  Ga = Va("Kodachrome", [1.12855, -.39673, -.03992, 0, .24991, -.16404, 1.08352, -.05498, 0, .09698, -.16786, -.56034, 1.60148, 0, .13972, 0, 0, 0, 1, 0]),
  Na = Va("Technicolor", [1.91252, -.85453, -.09155, 0, .04624, -.30878, 1.76589, -.10601, 0, -.27589, -.2311, -.75018, 1.84759, 0, .12137, 0, 0, 0, 1, 0]),
  Ua = Va("Polaroid", [1.438, -.062, -.062, 0, 0, -.122, 1.378, -.122, 0, 0, -.016, -.016, 1.483, 0, 0, 0, 0, 0, 1, 0]),
  qa = Va("Sepia", [.393, .769, .189, 0, 0, .349, .686, .168, 0, 0, .272, .534, .131, 0, 0, 0, 0, 0, 1, 0]),
  Ka = Va("BlackWhite", [1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 0, 0, 0, 1, 0]);
class Ja extends ja {
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    super(t), this.subFilters = t.subFilters || [];
  }
  applyTo(t) {
    Oa(t) && (t.passes += this.subFilters.length - 1), this.subFilters.forEach(e => {
      e.applyTo(t);
    });
  }
  toObject() {
    return {
      type: this.type,
      subFilters: this.subFilters.map(t => t.toObject())
    };
  }
  isNeutralState() {
    return !this.subFilters.some(t => !t.isNeutralState());
  }
  static fromObject(t, e) {
    return Promise.all((t.subFilters || []).map(t => tt.getClass(t.type).fromObject(t, e))).then(t => new this({
      subFilters: t
    }));
  }
}
t(Ja, "type", "Composed"), tt.setClass(Ja);
class Qa extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uContrast;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    float contrastF = 1.015 * (uContrast + 1.0) / (1.0 * (1.015 - uContrast));\n    color.rgb = contrastF * (color.rgb - 0.5) + 0.5;\n    gl_FragColor = color;\n  }";
  }
  isNeutralState() {
    return 0 === this.contrast;
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = Math.floor(255 * this.contrast),
      i = 259 * (s + 255) / (255 * (259 - s));
    for (let t = 0; t < e.length; t += 4) e[t] = i * (e[t] - 128) + 128, e[t + 1] = i * (e[t + 1] - 128) + 128, e[t + 2] = i * (e[t + 2] - 128) + 128;
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uContrast, this.contrast);
  }
}
t(Qa, "type", "Contrast"), t(Qa, "defaults", {
  contrast: 0
}), t(Qa, "uniformLocations", ["uContrast"]), tt.setClass(Qa);
const Za = {
  Convolute_3_1: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[9];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 0);\n      for (float h = 0.0; h < 3.0; h+=1.0) {\n        for (float w = 0.0; w < 3.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 1), uStepH * (h - 1));\n          color += texture2D(uTexture, vTexCoord + matrixPos) * uMatrix[int(h * 3.0 + w)];\n        }\n      }\n      gl_FragColor = color;\n    }\n    ",
  Convolute_3_0: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[9];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 1);\n      for (float h = 0.0; h < 3.0; h+=1.0) {\n        for (float w = 0.0; w < 3.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 1.0), uStepH * (h - 1.0));\n          color.rgb += texture2D(uTexture, vTexCoord + matrixPos).rgb * uMatrix[int(h * 3.0 + w)];\n        }\n      }\n      float alpha = texture2D(uTexture, vTexCoord).a;\n      gl_FragColor = color;\n      gl_FragColor.a = alpha;\n    }\n    ",
  Convolute_5_1: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[25];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 0);\n      for (float h = 0.0; h < 5.0; h+=1.0) {\n        for (float w = 0.0; w < 5.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 2.0), uStepH * (h - 2.0));\n          color += texture2D(uTexture, vTexCoord + matrixPos) * uMatrix[int(h * 5.0 + w)];\n        }\n      }\n      gl_FragColor = color;\n    }\n    ",
  Convolute_5_0: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[25];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 1);\n      for (float h = 0.0; h < 5.0; h+=1.0) {\n        for (float w = 0.0; w < 5.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 2.0), uStepH * (h - 2.0));\n          color.rgb += texture2D(uTexture, vTexCoord + matrixPos).rgb * uMatrix[int(h * 5.0 + w)];\n        }\n      }\n      float alpha = texture2D(uTexture, vTexCoord).a;\n      gl_FragColor = color;\n      gl_FragColor.a = alpha;\n    }\n    ",
  Convolute_7_1: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[49];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 0);\n      for (float h = 0.0; h < 7.0; h+=1.0) {\n        for (float w = 0.0; w < 7.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 3.0), uStepH * (h - 3.0));\n          color += texture2D(uTexture, vTexCoord + matrixPos) * uMatrix[int(h * 7.0 + w)];\n        }\n      }\n      gl_FragColor = color;\n    }\n    ",
  Convolute_7_0: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[49];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 1);\n      for (float h = 0.0; h < 7.0; h+=1.0) {\n        for (float w = 0.0; w < 7.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 3.0), uStepH * (h - 3.0));\n          color.rgb += texture2D(uTexture, vTexCoord + matrixPos).rgb * uMatrix[int(h * 7.0 + w)];\n        }\n      }\n      float alpha = texture2D(uTexture, vTexCoord).a;\n      gl_FragColor = color;\n      gl_FragColor.a = alpha;\n    }\n    ",
  Convolute_9_1: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[81];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 0);\n      for (float h = 0.0; h < 9.0; h+=1.0) {\n        for (float w = 0.0; w < 9.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 4.0), uStepH * (h - 4.0));\n          color += texture2D(uTexture, vTexCoord + matrixPos) * uMatrix[int(h * 9.0 + w)];\n        }\n      }\n      gl_FragColor = color;\n    }\n    ",
  Convolute_9_0: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform float uMatrix[81];\n    uniform float uStepW;\n    uniform float uStepH;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = vec4(0, 0, 0, 1);\n      for (float h = 0.0; h < 9.0; h+=1.0) {\n        for (float w = 0.0; w < 9.0; w+=1.0) {\n          vec2 matrixPos = vec2(uStepW * (w - 4.0), uStepH * (h - 4.0));\n          color.rgb += texture2D(uTexture, vTexCoord + matrixPos).rgb * uMatrix[int(h * 9.0 + w)];\n        }\n      }\n      float alpha = texture2D(uTexture, vTexCoord).a;\n      gl_FragColor = color;\n      gl_FragColor.a = alpha;\n    }\n    "
};
class $a extends ja {
  getCacheKey() {
    return "".concat(this.type, "_").concat(Math.sqrt(this.matrix.length), "_").concat(this.opaque ? 1 : 0);
  }
  getFragmentSource() {
    return Za[this.getCacheKey()];
  }
  applyTo2d(t) {
    const e = t.imageData,
      s = e.data,
      i = this.matrix,
      r = Math.round(Math.sqrt(i.length)),
      n = Math.floor(r / 2),
      o = e.width,
      a = e.height,
      h = t.ctx.createImageData(o, a),
      c = h.data,
      l = this.opaque ? 1 : 0;
    let u, d, g, f, p, m, v, y, _, x, C, b, w;
    for (C = 0; C < a; C++) for (x = 0; x < o; x++) {
      for (p = 4 * (C * o + x), u = 0, d = 0, g = 0, f = 0, w = 0; w < r; w++) for (b = 0; b < r; b++) v = C + w - n, m = x + b - n, v < 0 || v >= a || m < 0 || m >= o || (y = 4 * (v * o + m), _ = i[w * r + b], u += s[y] * _, d += s[y + 1] * _, g += s[y + 2] * _, l || (f += s[y + 3] * _));
      c[p] = u, c[p + 1] = d, c[p + 2] = g, c[p + 3] = l ? s[p + 3] : f;
    }
    t.imageData = h;
  }
  sendUniformData(t, e) {
    t.uniform1fv(e.uMatrix, this.matrix);
  }
  toObject() {
    return s(s({}, super.toObject()), {}, {
      opaque: this.opaque,
      matrix: [...this.matrix]
    });
  }
}
t($a, "type", "Convolute"), t($a, "defaults", {
  opaque: !1,
  matrix: [0, 0, 0, 0, 1, 0, 0, 0, 0]
}), t($a, "uniformLocations", ["uMatrix", "uOpaque", "uHalfSize", "uSize"]), tt.setClass($a);
const th = "Gamma";
class eh extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform vec3 uGamma;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    vec3 correction = (1.0 / uGamma);\n    color.r = pow(color.r, correction.r);\n    color.g = pow(color.g, correction.g);\n    color.b = pow(color.b, correction.b);\n    gl_FragColor = color;\n    gl_FragColor.rgb *= color.a;\n  }\n";
  }
  constructor() {
    let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    super(t), this.gamma = t.gamma || this.constructor.defaults.gamma.concat();
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = this.gamma,
      i = 1 / s[0],
      r = 1 / s[1],
      n = 1 / s[2];
    this.rgbValues || (this.rgbValues = {
      r: new Uint8Array(256),
      g: new Uint8Array(256),
      b: new Uint8Array(256)
    });
    const o = this.rgbValues;
    for (let t = 0; t < 256; t++) o.r[t] = 255 * Math.pow(t / 255, i), o.g[t] = 255 * Math.pow(t / 255, r), o.b[t] = 255 * Math.pow(t / 255, n);
    for (let t = 0; t < e.length; t += 4) e[t] = o.r[e[t]], e[t + 1] = o.g[e[t + 1]], e[t + 2] = o.b[e[t + 2]];
  }
  sendUniformData(t, e) {
    t.uniform3fv(e.uGamma, this.gamma);
  }
  isNeutralState() {
    const {
      gamma: t
    } = this;
    return 1 === t[0] && 1 === t[1] && 1 === t[2];
  }
  toObject() {
    return {
      type: th,
      gamma: this.gamma.concat()
    };
  }
}
t(eh, "type", th), t(eh, "defaults", {
  gamma: [1, 1, 1]
}), t(eh, "uniformLocations", ["uGamma"]), tt.setClass(eh);
const sh = {
  average: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 color = texture2D(uTexture, vTexCoord);\n      float average = (color.r + color.b + color.g) / 3.0;\n      gl_FragColor = vec4(average, average, average, color.a);\n    }\n    ",
  lightness: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform int uMode;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 col = texture2D(uTexture, vTexCoord);\n      float average = (max(max(col.r, col.g),col.b) + min(min(col.r, col.g),col.b)) / 2.0;\n      gl_FragColor = vec4(average, average, average, col.a);\n    }\n    ",
  luminosity: "\n    precision highp float;\n    uniform sampler2D uTexture;\n    uniform int uMode;\n    varying vec2 vTexCoord;\n    void main() {\n      vec4 col = texture2D(uTexture, vTexCoord);\n      float average = 0.21 * col.r + 0.72 * col.g + 0.07 * col.b;\n      gl_FragColor = vec4(average, average, average, col.a);\n    }\n    "
};
class ih extends ja {
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    for (let t, s = 0; s < e.length; s += 4) {
      switch (this.mode) {
        case "average":
          t = (e[s] + e[s + 1] + e[s + 2]) / 3;
          break;
        case "lightness":
          t = (Math.min(e[s], e[s + 1], e[s + 2]) + Math.max(e[s], e[s + 1], e[s + 2])) / 2;
          break;
        case "luminosity":
          t = .21 * e[s] + .72 * e[s + 1] + .07 * e[s + 2];
      }
      e[s] = t, e[s + 1] = t, e[s + 2] = t;
    }
  }
  getCacheKey() {
    return "".concat(this.type, "_").concat(this.mode);
  }
  getFragmentSource() {
    return sh[this.mode];
  }
  sendUniformData(t, e) {
    t.uniform1i(e.uMode, 1);
  }
  isNeutralState() {
    return !1;
  }
}
t(ih, "type", "Grayscale"), t(ih, "defaults", {
  mode: "average"
}), t(ih, "uniformLocations", ["uMode"]), tt.setClass(ih);
class rh extends Ya {
  calculateMatrix() {
    const t = this.rotation * Math.PI,
      e = rt(t),
      s = nt(t),
      i = 1 / 3,
      r = Math.sqrt(i) * s,
      n = 1 - e;
    this.matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0], this.matrix[0] = e + n / 3, this.matrix[1] = i * n - r, this.matrix[2] = i * n + r, this.matrix[5] = i * n + r, this.matrix[6] = e + i * n, this.matrix[7] = i * n - r, this.matrix[10] = i * n - r, this.matrix[11] = i * n + r, this.matrix[12] = e + i * n;
  }
  isNeutralState() {
    return 0 === this.rotation;
  }
  applyTo(t) {
    this.calculateMatrix(), super.applyTo(t);
  }
  toObject() {
    return {
      type: this.type,
      rotation: this.rotation
    };
  }
}
t(rh, "type", "HueRotation"), t(rh, "defaults", {
  rotation: 0
}), tt.setClass(rh);
class nh extends ja {
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    for (let t = 0; t < e.length; t += 4) e[t] = 255 - e[t], e[t + 1] = 255 - e[t + 1], e[t + 2] = 255 - e[t + 2], this.alpha && (e[t + 3] = 255 - e[t + 3]);
  }
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform int uInvert;\n  uniform int uAlpha;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    if (uInvert == 1) {\n      if (uAlpha == 1) {\n        gl_FragColor = vec4(1.0 - color.r,1.0 -color.g,1.0 -color.b,1.0 -color.a);\n      } else {\n        gl_FragColor = vec4(1.0 - color.r,1.0 -color.g,1.0 -color.b,color.a);\n      }\n    } else {\n      gl_FragColor = color;\n    }\n  }\n";
  }
  isNeutralState() {
    return !this.invert;
  }
  sendUniformData(t, e) {
    t.uniform1i(e.uInvert, Number(this.invert)), t.uniform1i(e.uAlpha, Number(this.alpha));
  }
}
t(nh, "type", "Invert"), t(nh, "defaults", {
  alpha: !1,
  invert: !0
}), t(nh, "uniformLocations", ["uInvert", "uAlpha"]), tt.setClass(nh);
class oh extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uStepH;\n  uniform float uNoise;\n  uniform float uSeed;\n  varying vec2 vTexCoord;\n  float rand(vec2 co, float seed, float vScale) {\n    return fract(sin(dot(co.xy * vScale ,vec2(12.9898 , 78.233))) * 43758.5453 * (seed + 0.01) / 2.0);\n  }\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    color.rgb += (0.5 - rand(vTexCoord, uSeed, 0.1 / uStepH)) * uNoise;\n    gl_FragColor = color;\n  }\n";
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = this.noise;
    for (let t = 0; t < e.length; t += 4) {
      const i = (.5 - Math.random()) * s;
      e[t] += i, e[t + 1] += i, e[t + 2] += i;
    }
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uNoise, this.noise / 255), t.uniform1f(e.uSeed, Math.random());
  }
  isNeutralState() {
    return 0 === this.noise;
  }
}
t(oh, "type", "Noise"), t(oh, "defaults", {
  noise: 0
}), t(oh, "uniformLocations", ["uNoise", "uSeed"]), tt.setClass(oh);
class ah extends ja {
  applyTo2d(t) {
    let {
      imageData: {
        data: e,
        width: s,
        height: i
      }
    } = t;
    for (let t = 0; t < i; t += this.blocksize) for (let r = 0; r < s; r += this.blocksize) {
      const n = 4 * t * s + 4 * r,
        o = e[n],
        a = e[n + 1],
        h = e[n + 2],
        c = e[n + 3];
      for (let n = t; n < Math.min(t + this.blocksize, i); n++) for (let t = r; t < Math.min(r + this.blocksize, s); t++) {
        const i = 4 * n * s + 4 * t;
        e[i] = o, e[i + 1] = a, e[i + 2] = h, e[i + 3] = c;
      }
    }
  }
  isNeutralState() {
    return 1 === this.blocksize;
  }
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uBlocksize;\n  uniform float uStepW;\n  uniform float uStepH;\n  varying vec2 vTexCoord;\n  void main() {\n    float blockW = uBlocksize * uStepW;\n    float blockH = uBlocksize * uStepH;\n    int posX = int(vTexCoord.x / blockW);\n    int posY = int(vTexCoord.y / blockH);\n    float fposX = float(posX);\n    float fposY = float(posY);\n    vec2 squareCoords = vec2(fposX * blockW, fposY * blockH);\n    vec4 color = texture2D(uTexture, squareCoords);\n    gl_FragColor = color;\n  }\n";
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uBlocksize, this.blocksize);
  }
}
t(ah, "type", "Pixelate"), t(ah, "defaults", {
  blocksize: 4
}), t(ah, "uniformLocations", ["uBlocksize"]), tt.setClass(ah);
class hh extends ja {
  getFragmentSource() {
    return "\nprecision highp float;\nuniform sampler2D uTexture;\nuniform vec4 uLow;\nuniform vec4 uHigh;\nvarying vec2 vTexCoord;\nvoid main() {\n  gl_FragColor = texture2D(uTexture, vTexCoord);\n  if(all(greaterThan(gl_FragColor.rgb,uLow.rgb)) && all(greaterThan(uHigh.rgb,gl_FragColor.rgb))) {\n    gl_FragColor.a = 0.0;\n  }\n}\n";
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = 255 * this.distance,
      i = new Nt(this.color).getSource(),
      r = [i[0] - s, i[1] - s, i[2] - s],
      n = [i[0] + s, i[1] + s, i[2] + s];
    for (let t = 0; t < e.length; t += 4) {
      const s = e[t],
        i = e[t + 1],
        o = e[t + 2];
      s > r[0] && i > r[1] && o > r[2] && s < n[0] && i < n[1] && o < n[2] && (e[t + 3] = 0);
    }
  }
  sendUniformData(t, e) {
    const s = new Nt(this.color).getSource(),
      i = this.distance,
      r = [0 + s[0] / 255 - i, 0 + s[1] / 255 - i, 0 + s[2] / 255 - i, 1],
      n = [s[0] / 255 + i, s[1] / 255 + i, s[2] / 255 + i, 1];
    t.uniform4fv(e.uLow, r), t.uniform4fv(e.uHigh, n);
  }
}
t(hh, "type", "RemoveColor"), t(hh, "defaults", {
  color: "#FFFFFF",
  distance: .02,
  useAlpha: !1
}), t(hh, "uniformLocations", ["uLow", "uHigh"]), tt.setClass(hh);
class ch extends ja {
  sendUniformData(t, e) {
    t.uniform2fv(e.uDelta, this.horizontal ? [1 / this.width, 0] : [0, 1 / this.height]), t.uniform1fv(e.uTaps, this.taps);
  }
  getFilterWindow() {
    const t = this.tempScale;
    return Math.ceil(this.lanczosLobes / t);
  }
  getCacheKey() {
    const t = this.getFilterWindow();
    return "".concat(this.type, "_").concat(t);
  }
  getFragmentSource() {
    const t = this.getFilterWindow();
    return this.generateShader(t);
  }
  getTaps() {
    const t = this.lanczosCreate(this.lanczosLobes),
      e = this.tempScale,
      s = this.getFilterWindow(),
      i = new Array(s);
    for (let r = 1; r <= s; r++) i[r - 1] = t(r * e);
    return i;
  }
  generateShader(t) {
    const e = new Array(t);
    for (let s = 1; s <= t; s++) e[s - 1] = "".concat(s, ".0 * uDelta");
    return "\n      precision highp float;\n      uniform sampler2D uTexture;\n      uniform vec2 uDelta;\n      varying vec2 vTexCoord;\n      uniform float uTaps[".concat(t, "];\n      void main() {\n        vec4 color = texture2D(uTexture, vTexCoord);\n        float sum = 1.0;\n        ").concat(e.map((t, e) => "\n              color += texture2D(uTexture, vTexCoord + ".concat(t, ") * uTaps[").concat(e, "] + texture2D(uTexture, vTexCoord - ").concat(t, ") * uTaps[").concat(e, "];\n              sum += 2.0 * uTaps[").concat(e, "];\n            ")).join("\n"), "\n        gl_FragColor = color / sum;\n      }\n    ");
  }
  applyToForWebgl(t) {
    t.passes++, this.width = t.sourceWidth, this.horizontal = !0, this.dW = Math.round(this.width * this.scaleX), this.dH = t.sourceHeight, this.tempScale = this.dW / this.width, this.taps = this.getTaps(), t.destinationWidth = this.dW, super.applyTo(t), t.sourceWidth = t.destinationWidth, this.height = t.sourceHeight, this.horizontal = !1, this.dH = Math.round(this.height * this.scaleY), this.tempScale = this.dH / this.height, this.taps = this.getTaps(), t.destinationHeight = this.dH, super.applyTo(t), t.sourceHeight = t.destinationHeight;
  }
  applyTo(t) {
    Oa(t) ? this.applyToForWebgl(t) : this.applyTo2d(t);
  }
  isNeutralState() {
    return 1 === this.scaleX && 1 === this.scaleY;
  }
  lanczosCreate(t) {
    return e => {
      if (e >= t || e <= -t) return 0;
      if (e < 1.1920929e-7 && e > -1.1920929e-7) return 1;
      const s = (e *= Math.PI) / t;
      return Math.sin(e) / e * Math.sin(s) / s;
    };
  }
  applyTo2d(t) {
    const e = t.imageData,
      s = this.scaleX,
      i = this.scaleY;
    this.rcpScaleX = 1 / s, this.rcpScaleY = 1 / i;
    const r = e.width,
      n = e.height,
      o = Math.round(r * s),
      a = Math.round(n * i);
    let h;
    h = "sliceHack" === this.resizeType ? this.sliceByTwo(t, r, n, o, a) : "hermite" === this.resizeType ? this.hermiteFastResize(t, r, n, o, a) : "bilinear" === this.resizeType ? this.bilinearFiltering(t, r, n, o, a) : "lanczos" === this.resizeType ? this.lanczosResize(t, r, n, o, a) : new ImageData(o, a), t.imageData = h;
  }
  sliceByTwo(t, e, s, i, r) {
    const n = t.imageData,
      o = .5;
    let a = !1,
      h = !1,
      c = e * o,
      l = s * o;
    const u = t.filterBackend.resources;
    let d = 0,
      g = 0;
    const f = e;
    let p = 0;
    u.sliceByTwo || (u.sliceByTwo = pt());
    const m = u.sliceByTwo;
    (m.width < 1.5 * e || m.height < s) && (m.width = 1.5 * e, m.height = s);
    const v = m.getContext("2d");
    for (v.clearRect(0, 0, 1.5 * e, s), v.putImageData(n, 0, 0), i = Math.floor(i), r = Math.floor(r); !a || !h;) e = c, s = l, i < Math.floor(c * o) ? c = Math.floor(c * o) : (c = i, a = !0), r < Math.floor(l * o) ? l = Math.floor(l * o) : (l = r, h = !0), v.drawImage(m, d, g, e, s, f, p, c, l), d = f, g = p, p += l;
    return v.getImageData(d, g, i, r);
  }
  lanczosResize(t, e, s, i, r) {
    const n = t.imageData.data,
      o = t.ctx.createImageData(i, r),
      a = o.data,
      h = this.lanczosCreate(this.lanczosLobes),
      c = this.rcpScaleX,
      l = this.rcpScaleY,
      u = 2 / this.rcpScaleX,
      d = 2 / this.rcpScaleY,
      g = Math.ceil(c * this.lanczosLobes / 2),
      f = Math.ceil(l * this.lanczosLobes / 2),
      p = {},
      m = {
        x: 0,
        y: 0
      },
      v = {
        x: 0,
        y: 0
      };
    return function t(y) {
      let _, x, C, b, w, S, T, O, k, D, M;
      for (m.x = (y + .5) * c, v.x = Math.floor(m.x), _ = 0; _ < r; _++) {
        for (m.y = (_ + .5) * l, v.y = Math.floor(m.y), w = 0, S = 0, T = 0, O = 0, k = 0, x = v.x - g; x <= v.x + g; x++) if (!(x < 0 || x >= e)) {
          D = Math.floor(1e3 * Math.abs(x - m.x)), p[D] || (p[D] = {});
          for (let t = v.y - f; t <= v.y + f; t++) t < 0 || t >= s || (M = Math.floor(1e3 * Math.abs(t - m.y)), p[D][M] || (p[D][M] = h(Math.sqrt(Math.pow(D * u, 2) + Math.pow(M * d, 2)) / 1e3)), C = p[D][M], C > 0 && (b = 4 * (t * e + x), w += C, S += C * n[b], T += C * n[b + 1], O += C * n[b + 2], k += C * n[b + 3]));
        }
        b = 4 * (_ * i + y), a[b] = S / w, a[b + 1] = T / w, a[b + 2] = O / w, a[b + 3] = k / w;
      }
      return ++y < i ? t(y) : o;
    }(0);
  }
  bilinearFiltering(t, e, s, i, r) {
    let n,
      o,
      a,
      h,
      c,
      l,
      u,
      d,
      g,
      f,
      p,
      m,
      v,
      y = 0;
    const _ = this.rcpScaleX,
      x = this.rcpScaleY,
      C = 4 * (e - 1),
      b = t.imageData.data,
      w = t.ctx.createImageData(i, r),
      S = w.data;
    for (u = 0; u < r; u++) for (d = 0; d < i; d++) for (c = Math.floor(_ * d), l = Math.floor(x * u), g = _ * d - c, f = x * u - l, v = 4 * (l * e + c), p = 0; p < 4; p++) n = b[v + p], o = b[v + 4 + p], a = b[v + C + p], h = b[v + C + 4 + p], m = n * (1 - g) * (1 - f) + o * g * (1 - f) + a * f * (1 - g) + h * g * f, S[y++] = m;
    return w;
  }
  hermiteFastResize(t, e, s, i, r) {
    const n = this.rcpScaleX,
      o = this.rcpScaleY,
      a = Math.ceil(n / 2),
      h = Math.ceil(o / 2),
      c = t.imageData.data,
      l = t.ctx.createImageData(i, r),
      u = l.data;
    for (let t = 0; t < r; t++) for (let s = 0; s < i; s++) {
      const r = 4 * (s + t * i);
      let l = 0,
        d = 0,
        g = 0,
        f = 0,
        p = 0,
        m = 0,
        v = 0;
      const y = (t + .5) * o;
      for (let i = Math.floor(t * o); i < (t + 1) * o; i++) {
        const t = Math.abs(y - (i + .5)) / h,
          r = (s + .5) * n,
          o = t * t;
        for (let t = Math.floor(s * n); t < (s + 1) * n; t++) {
          let s = Math.abs(r - (t + .5)) / a;
          const n = Math.sqrt(o + s * s);
          n > 1 && n < -1 || (l = 2 * n * n * n - 3 * n * n + 1, l > 0 && (s = 4 * (t + i * e), v += l * c[s + 3], g += l, c[s + 3] < 255 && (l = l * c[s + 3] / 250), f += l * c[s], p += l * c[s + 1], m += l * c[s + 2], d += l));
        }
      }
      u[r] = f / d, u[r + 1] = p / d, u[r + 2] = m / d, u[r + 3] = v / g;
    }
    return l;
  }
}
t(ch, "type", "Resize"), t(ch, "defaults", {
  resizeType: "hermite",
  scaleX: 1,
  scaleY: 1,
  lanczosLobes: 3
}), t(ch, "uniformLocations", ["uDelta", "uTaps"]), tt.setClass(ch);
class lh extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uSaturation;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    float rgMax = max(color.r, color.g);\n    float rgbMax = max(rgMax, color.b);\n    color.r += rgbMax != color.r ? (rgbMax - color.r) * uSaturation : 0.00;\n    color.g += rgbMax != color.g ? (rgbMax - color.g) * uSaturation : 0.00;\n    color.b += rgbMax != color.b ? (rgbMax - color.b) * uSaturation : 0.00;\n    gl_FragColor = color;\n  }\n";
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = -this.saturation;
    for (let t = 0; t < e.length; t += 4) {
      const i = Math.max(e[t], e[t + 1], e[t + 2]);
      e[t] += i !== e[t] ? (i - e[t]) * s : 0, e[t + 1] += i !== e[t + 1] ? (i - e[t + 1]) * s : 0, e[t + 2] += i !== e[t + 2] ? (i - e[t + 2]) * s : 0;
    }
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uSaturation, -this.saturation);
  }
  isNeutralState() {
    return 0 === this.saturation;
  }
}
t(lh, "type", "Saturation"), t(lh, "defaults", {
  saturation: 0
}), t(lh, "uniformLocations", ["uSaturation"]), tt.setClass(lh);
class uh extends ja {
  getFragmentSource() {
    return "\n  precision highp float;\n  uniform sampler2D uTexture;\n  uniform float uVibrance;\n  varying vec2 vTexCoord;\n  void main() {\n    vec4 color = texture2D(uTexture, vTexCoord);\n    float max = max(color.r, max(color.g, color.b));\n    float avg = (color.r + color.g + color.b) / 3.0;\n    float amt = (abs(max - avg) * 2.0) * uVibrance;\n    color.r += max != color.r ? (max - color.r) * amt : 0.00;\n    color.g += max != color.g ? (max - color.g) * amt : 0.00;\n    color.b += max != color.b ? (max - color.b) * amt : 0.00;\n    gl_FragColor = color;\n  }\n";
  }
  applyTo2d(t) {
    let {
      imageData: {
        data: e
      }
    } = t;
    const s = -this.vibrance;
    for (let t = 0; t < e.length; t += 4) {
      const i = Math.max(e[t], e[t + 1], e[t + 2]),
        r = (e[t] + e[t + 1] + e[t + 2]) / 3,
        n = 2 * Math.abs(i - r) / 255 * s;
      e[t] += i !== e[t] ? (i - e[t]) * n : 0, e[t + 1] += i !== e[t + 1] ? (i - e[t + 1]) * n : 0, e[t + 2] += i !== e[t + 2] ? (i - e[t + 2]) * n : 0;
    }
  }
  sendUniformData(t, e) {
    t.uniform1f(e.uVibrance, -this.vibrance);
  }
  isNeutralState() {
    return 0 === this.vibrance;
  }
}
t(uh, "type", "Vibrance"), t(uh, "defaults", {
  vibrance: 0
}), t(uh, "uniformLocations", ["uVibrance"]), tt.setClass(uh);
var dh = Object.freeze({
  __proto__: null,
  BaseFilter: ja,
  BlackWhite: Ka,
  BlendColor: La,
  BlendImage: Ba,
  Blur: Xa,
  Brightness: Wa,
  Brownie: Ha,
  ColorMatrix: Ya,
  Composed: Ja,
  Contrast: Qa,
  Convolute: $a,
  Gamma: eh,
  Grayscale: ih,
  HueRotation: rh,
  Invert: nh,
  Kodachrome: Ga,
  Noise: oh,
  Pixelate: ah,
  Polaroid: Ua,
  RemoveColor: hh,
  Resize: ch,
  Saturation: lh,
  Sepia: qa,
  Technicolor: Na,
  Vibrance: uh,
  Vintage: za
});
export { Vo as ActiveSelection, Bn as BaseBrush, Bs as BaseFabricObject, xn as Canvas, Ho as Canvas2dFilterBackend, un as CanvasDOMManager, Gn as Circle, Nn as CircleBrush, Xo as ClipPathLayout, Nt as Color, zs as Control, eo as Ellipse, Qo as FabricImage, yi as FabricObject, So as FabricText, Cr as FitContentLayout, Wo as FixedLayout, Ln as Gradient, Dr as Group, Io as IText, Qo as Image, mi as InteractiveFabricObject, Fs as Intersection, Tr as LayoutManager, xr as LayoutStrategy, Zn as Line, yi as Object, st as Observable, Yn as Path, In as Pattern, qn as PatternBrush, Vn as PencilBrush, ot as Point, oo as Polygon, no as Polyline, fr as Rect, us as Shadow, Un as SprayBrush, ue as StaticCanvas, ce as StaticCanvasDOMManager, So as Text, Bo as Textbox, $n as Triangle, zo as WebGLFilterBackend, _ as cache, tt as classRegistry, o as config, Ta as controlsUtils, ct as createCollectionMixin, dh as filters, oa as getCSSRules, p as getEnv, m as getFabricDocument, v as getFabricWindow, Uo as getFilterBackend, T as iMatrix, No as initFilterBackend, ka as isPutImageFaster, Oa as isWebGLPipelineState, da as loadSVGFromString, ga as loadSVGFromURL, ur as parseAttributes, ar as parseFontDeclaration, so as parsePointsAttribute, ua as parseSVGDocument, cr as parseStyleAttribute, nr as parseTransformAttribute, et as runningAnimations, f as setEnv, qo as setFilterBackend, ln as util, x as version };
