import { ObjectTypeEnum } from '../_enums/object-type.enum';

export enum DrawingTools {
    SELECT = 'SELECT',
    ERASER = 'ERASER',
    ELLIPSE = 'ELLIPSE',
    RECTANGLE = 'RECTANGLE',
    LINE = 'LINE',
    DASHED_LINE = 'DASHED_LINE',
    TEXT = 'TEXT',
    I_TEXT = 'I_TEXT',
    GARBAGE = 'GARBAGE',
    TRIANGLE = 'TRIANGLE',
    DOUBLE_TRIANGLE = 'DOUBLE_TRIANGLE',
    IMAGE = 'IMAGE',
    CHART = 'CHART',
    CLONE = 'CLONE',
    TEMPERATURE = 'TEMPERATURE',
    HUMIDITY = 'HUMIDITY',
    DBM = 'DBM',
}

export interface Pointer {
    x: number;
    y: number;
}

interface CustomFabricProps {
    id: string;
    tooltip?: string;
    objectType?: ObjectTypeEnum;
}

export type CustomFabricObject = any & CustomFabricProps;
export type CustomFabricRect = any & CustomFabricProps;
export type CustomFabricEllipse = any & CustomFabricProps;
export type CustomFabricLine = any & CustomFabricProps;
export type CustomFabricTriangle = any & CustomFabricProps;
export type CustomFabricText = any & CustomFabricProps;
export type CustomFabricIText = any & CustomFabricProps;
export type CustomFabricPath = any & CustomFabricProps;
export type CustomFabricImage = any;

export enum FabricObjectType {
    RECT = 'rect',
    ELLIPSE = 'ellipse',
    I_TEXT = 'i-text',
    TEXT = 'text',
    LINE = 'line',
    PATH = 'path',
    TRIANGLE = 'triangle',
    IMAGE = 'image',
}
