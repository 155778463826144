import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    CloseEditingMnemonicRow,
    CreateMnemonic,
    CreateNewMnemonic,
    GetMnemonicComment,
    InitMnemonicRow,
    SetEditingMnemonicRow,
    UpdateMnemonicComment,
    UpdateMnemonicRow,
} from '../actions/mnemonic-dashboard.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { Router } from '@angular/router';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { MailingCellFieldTypeEnum } from '../../../mailing/_enums/mailing-cell-field-type.enum';
import { ColumnsActionTypeEnum } from '../../../app-shared-elements/_enums/columns-action-type.enum';
import { MnemonicRowInterface } from '../../_interfaces/mnemonic-row.interface';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { MnemonicCommentInterface } from '../../_interfaces/mnemonic-comment.interface';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { GetDevices } from '../../../device-dashboard/_store/actions/user-devices.actions';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';

export interface MnemonicDashboardStateModel {
    mnemonicRow: MnemonicRowInterface[];
    mnemonicComment: MnemonicCommentInterface[];
    dashboardTableColumns: ColumnsTableInterface[];
}

const MNEMONIC_DASHBOARD_TOKEN = new StateToken<MnemonicDashboardStateModel>('mnemonicDashboard');

const initColumns: ColumnsTableInterface[] = [
    {
        title: 'mnemonic.table.create',
        maxWidth: '160px',
        minWidth: '160px',
        grow: false,
        type: ColumnTypeEnum.date,
        name: 'created',
    },
    {
        title: 'mnemonic.table.name',
        grow: true,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        name: 'name',
        isClick: true,
        underline: true,
    },
    {
        title: 'mnemonic.table.owner',
        grow: false,
        maxWidth: '200px',
        minWidth: '200px',
        type: ColumnTypeEnum.text,
        name: 'login',
    },
    {
        title: 'mnemonic.table.comment',
        grow: true,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        name: 'comment',
    },
    {
        title: 'devices.actions',
        maxWidth: '125px',
        minWidth: '125px',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        actionBtns: [
            ColumnsActionTypeEnum.actionBtnsEdit,
            ColumnsActionTypeEnum.actionBtnsDelete,
            ColumnsActionTypeEnum.isShare,
            ColumnsActionTypeEnum.settings,
            ColumnsActionTypeEnum.view,
        ],
        isFullWrapperBtn: true,
        name: 'edit',
    },
];

@State<MnemonicDashboardStateModel>({
    name: MNEMONIC_DASHBOARD_TOKEN,
    defaults: {
        mnemonicRow: [],
        mnemonicComment: [],
        dashboardTableColumns: initColumns,
    },
})
@Injectable()
export class MnemonicDashboardState {
    constructor(
        private http: HttpClient,
        private router: Router,
        private store: Store,
        private notificationsService: NotificationsService,
    ) {}

    @Selector()
    static getColumns(state: MnemonicDashboardStateModel): ColumnsTableInterface[] {
        return state.dashboardTableColumns;
    }

    @Selector()
    static getMnemonicRow(state: MnemonicDashboardStateModel): MnemonicRowInterface[] {
        return state.mnemonicRow.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }

    @Action(CreateMnemonic)
    async createMnemonic(ctx: StateContext<MnemonicDashboardStateModel>, payload: CreateMnemonic): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/virtual-devices', {
                name: payload.row.name,
                type: CreationType.MNEMONIC,
                variablesId: [],
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            await ctx
                .dispatch(
                    new UpdateMnemonicComment({
                        id: result.data.id,
                        description: payload.row.comment,
                    }),
                )
                .toPromise();

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
            });

            await ctx.dispatch(new GetDevices()).toPromise();
            await ctx.dispatch(new InitMnemonicRow()).toPromise();
        }
    }

    @Action(GetMnemonicComment)
    async getMnemonicComment(ctx: StateContext<MnemonicDashboardStateModel>, payload: GetMnemonicComment): Promise<void> {
        const ids = payload.registratorIds.join(',');
        const result: ApiResponse = (await this.http
            .get('/api/virtual-devices/info-mnemonics', {
                params: {
                    registratorsId: ids,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mnemonicComment: result.data,
            });
        }
    }

    @Action(UpdateMnemonicComment)
    async updateMnemonicComment(ctx: StateContext<MnemonicDashboardStateModel>, payload: UpdateMnemonicComment): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/virtual-devices/info-mnemonic', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(CreateNewMnemonic)
    createNewMnemonic(ctx: StateContext<MnemonicDashboardStateModel>): void {
        const state = ctx.getState();

        const newMnemonic: MnemonicRowInterface = {
            name: '',
            comment: '',
            created: Date.now(),
            registratorId: '',
            id: '',
            isEditing: true,
            isNew: true,
            login: '',
        };

        ctx.setState({
            ...state,
            mnemonicRow: [newMnemonic, ...state.mnemonicRow],
        });
    }

    @Action(InitMnemonicRow)
    async initMnemonicRow(ctx: StateContext<MnemonicDashboardStateModel>): Promise<void> {
        const devices = this.store.selectSnapshot(DevicesState.getMnemonicDevices);
        await ctx.dispatch(new GetMnemonicComment(devices.map((d) => d.id))).toPromise();
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mnemonicRow: devices.map((device) => {
                const user = this.store.selectSnapshot(UserState.getUser);
                const currentComment = state.mnemonicComment?.find((f) => f.virtualRegistratorId === device.id);

                return {
                    ...device,
                    name: device.name ?? device.defaultName,
                    comment: currentComment?.description,
                    isEditing: false,
                    disabledActions:
                        device.userId === user.id
                            ? [ColumnsActionTypeEnum.view]
                            : [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.settings, ColumnsActionTypeEnum.isShare],
                };
            }),
        });
    }

    @Action(SetEditingMnemonicRow)
    setEditingUserRow(ctx: StateContext<MnemonicDashboardStateModel>, payload: SetEditingMnemonicRow): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            mnemonicRow: state.mnemonicRow.map((row) => ({ ...row, isEditing: row.id === payload.id })),
        });
    }

    @Action(CloseEditingMnemonicRow)
    closeEditingUserRow(ctx: StateContext<MnemonicDashboardStateModel>, payload: CloseEditingMnemonicRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mnemonicRow: state.mnemonicRow.map((row) => ({
                ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                isEditing: false,
            })),
        });

        ctx.dispatch(new InitMnemonicRow());
    }

    @Action(UpdateMnemonicRow)
    updateMnemonicRow(ctx: StateContext<MnemonicDashboardStateModel>, payload: UpdateMnemonicRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mnemonicRow: state.mnemonicRow.map((row) =>
                row.id === payload.row.id ? { ...payload.row, isEditing: false } : { ...row, isEditing: false },
            ),
        });

        this.notificationsService.onEmit(TooltipStatusEnum.update, false);
    }
}
